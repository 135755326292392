import React, { useState } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { selectModalStyles, multiSelectStylesAutoHeight } from 'styles/modules/reactSelect';
import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';
import '../styles.scss';

import { postNotificationEventSubscribers, putNotificationEventSubscribers } from 'shared/Api';

const EventSubscriberModal = ({
  t,
  events,
  isOpen,
  locations,
  companyId,
  subscribers,
  sendDevices,
  handleCloseModal,
  initialValues = {},
  closeModalAndRefetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);

    const payload = {
      ...formValues,
      location: formValues?.location?.id || null,
      event: formValues?.event?.id,
      subscriber: formValues?.subscriber?.id,
    };

    sendDevices?.forEach((device) => {
      if (formValues?.sendDevices?.some((d) => d.id === device.id)) payload[device.id] = true;
      else payload[device.id] = false;
    });

    const apiFilters = `?company=${companyId}`;
    if (formValues?.id) {
      await putNotificationEventSubscribers(formValues?.id, apiFilters, payload);
    } else {
      await postNotificationEventSubscribers(apiFilters, payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  return (
    <Modal
      isOpen={isOpen}
      handleSave={handleSave}
      size={modalSizes.medium}
      handleClose={handleCloseModal}
      title={formValues?.id ? t('settings.events_and_subscribers.event_subscribers.modal_title_edit_event_subscriber') : t('settings.events_and_subscribers.event_subscribers.modal_title_add_event_subscriber')}
      disableSave={!formValues?.event || !formValues?.subscriber}
    >
      {
        isLoading ?
        <ContentLoader /> :
          <div className="event_subscribers_modal_container">

            <div className="modal_row">
              <div className="left_text">{t('settings.events_and_subscribers.event_subscribers.table_header_subscriber')}: *</div>
              <div className="right_select">
                <Select
                  options={subscribers}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  menuPosition="fixed"
                  placeholder={t('settings.events_and_subscribers.event_subscribers.select_subscriber_placeholder')}
                  onChange={(value) => onChange(value, 'subscriber')}
                  value={get(formValues, 'subscriber', '')}
                  styles={selectModalStyles}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('settings.events_and_subscribers.event_subscribers.table_header_event')}: *</div>
              <div className="right_select">
                <Select
                  options={events}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  menuPosition="fixed"
                  placeholder={t('settings.events_and_subscribers.event_subscribers.select_event_placeholder')}
                  onChange={(value) => onChange(value, 'event')}
                  value={get(formValues, 'event', '')}
                  styles={selectModalStyles}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('settings.events_and_subscribers.event_subscribers.table_header_location')}:</div>
              <div className="right_select">
                <Select
                  options={locations}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isClearable
                  menuPosition="fixed"
                  placeholder={t('settings.events_and_subscribers.event_subscribers.select_location_placeholder')}
                  onChange={(value) => onChange(value, 'location')}
                  value={locations?.find((loc) => loc.id === get(formValues, 'location', ''))}
                  styles={selectModalStyles}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('settings.events_and_subscribers.event_subscribers.table_header_send_devices')}:</div>
              <div className="right_select">
                <Select
                  options={sendDevices}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isClearable
                  isMulti
                  menuPosition="fixed"
                  placeholder={t('settings.events_and_subscribers.event_subscribers.select_send_device_placeholder')}
                  onChange={(value) => onChange(value, 'sendDevices')}
                  value={get(formValues, 'sendDevices', '')}
                  styles={multiSelectStylesAutoHeight}
                />
              </div>
            </div>
          </div>
      }
    </Modal>
  );
};

EventSubscriberModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  events: PropTypes.array.isRequired,
  closeModalAndRefetch: PropTypes.func,
  locations: PropTypes.array.isRequired,
  companyId: PropTypes.number.isRequired,
  sendDevices: PropTypes.array.isRequired,
  subscribers: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(EventSubscriberModal));
