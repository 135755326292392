import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Table, Modal, ContentLoader } from 'shared';
import { IconCloudDownload, IconPreviewDocument } from 'shared/Icons';
import { getFivesFindings, getFivesReportEntries } from 'shared/Api';

import '../styles.scss';

class DetailedAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tables: {},
      isPreviewModalOpen: false,
      isLoadingPreviewModal: false,
      reportEntryImage: '',
      isDownloadingImage: false,
      selectedRow: null,
    };
  }

  componentDidMount() {
    this.getReportDetails();
  }

  getReportDetails = () => {
    const { companyId, reportId } = this.props;

    this.setState({
      isLoading: true,
    });

    const apiFilters = `?company=${companyId}&report_id=${reportId}&last=true`;

    getFivesReportEntries(apiFilters)
      .then((res) => {
        this.setState({
          isLoading: false,
          reportDetails: get(res, 'data.results', []),
        }, () => {
          this.groupDataByCategories();
        });
      });
  }

  getEmoji = (score) => {
    switch (score) {
      case '0.0':
        return '🙁';
      case '0.5':
        return '😐';
      case '1.0':
        return '🙂';
      default:
        return score;
    }
  }

  getReportEntryImage = (entryId) => {
    const { companyId } = this.props;
    this.setState({
      isLoadingPreviewModal: true,
    });

    const apiFilters = `?company=${companyId}&entry=${entryId}`;

    getFivesFindings(apiFilters)
      .then((res) => {
        this.setState({
          isLoadingPreviewModal: false,
          reportEntryImage: res && res.data && res.data.results && res.data.results[0] && res.data.results[0].image ? res.data.results[0].image : '',
        });
      });
  }

  groupDataByCategories = () => {
    const { reportDetails } = this.state;
    const tables = {};

    if (reportDetails && reportDetails.length) {
      reportDetails.forEach((item) => {
        const category = item.requirement.subcategory.category;
        const subcategoryName = item.requirement.subcategory.name;
        const key = `${category}_${subcategoryName}`;

        if (!tables[key]) {
          tables[key] = {
            categoryName: category,
            subcategoryName,
            data: [],
          };
        }
        tables[key].data.push(item);
      });

      this.setState({
        tables,
      });
    }
  }

  openPreviewModal = (row) => {
    const entryId = row.original.id;

    this.setState({
      isPreviewModalOpen: true,
    }, () => {
      this.getReportEntryImage(entryId);
    });
  }

  closePreviewModal = () => {
    this.setState({
      isPreviewModalOpen: false,
      reportEntryImage: '',
    });
  }

  downloadImage = (row) => {
    const { companyId } = this.props;
    const entryId = row.original.id;

    this.setState({
      isDownloadingImage: true,
      selectedRow: entryId,
    });

    const apiFilters = `?company=${companyId}&entry=${entryId}`;

    getFivesFindings(apiFilters)
      .then((res) => {
        const reportEntryImage = get(res, 'data.results[0].image', '');

        this.setState({
          isDownloadingImage: false,
          selectedRow: null,
          reportEntryImage,
        }, () => {
          if (reportEntryImage) {
            fetch(reportEntryImage)
              .then((response) => response.blob())
              .then((blob) => {
                const imgUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = imgUrl;
                link.download = 'report_image';
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(imgUrl);
              });
          }
        });
      });
  };

  renderTables = () => {
    const { t } = this.props;
    const { isDownloadingImage, selectedRow, isLoading, tables } = this.state;

    if (tables) {
      let prevCategoryName = '';

      return Object.keys(tables).map((key) => {
        const tableData = tables[key] && tables[key].data ? tables[key].data : [];
        const categoryName = tables[key] && tables[key].categoryName ? tables[key].categoryName : '';
        const subcategoryName = tables[key] && tables[key].subcategoryName ? tables[key].subcategoryName : '';

        // Conditionally render category header only if current category is different from the previous one
        const renderCategoryHeader = prevCategoryName !== categoryName ? <h2>{categoryName}</h2> : null;
        prevCategoryName = categoryName; // Update previous category name for comparison

        return (
          <div key={key}>
            {renderCategoryHeader}
            <Table
              columns={[
                {
                  Header: subcategoryName,
                  columns: [
                    {
                      Header: () => <span>{t('page_content.5S.table_column_name')}</span>,
                      accessor: 'requirement',
                      Cell: (row) => (row && row.value && row.value.name ? row.value.name : ' '),
                    },
                    {
                      Header: () => <span>{t('page_content.5S.table_column_score')}</span>,
                      accessor: 'score',
                      Cell: (row) => (row && row.value ? this.getEmoji(row.value) : ' '),
                    },
                    {
                      Header: () => <span>{t('page_content.5S.table_column_image')}</span>,
                      Cell: (row) => (
                        row && row.original && row.original.images_exists &&
                        <span className="image_table_icons">
                          <span onClick={() => { this.openPreviewModal(row); }}><IconPreviewDocument height="25px" width="25px" color="green" /></span>
                          <span onClick={() => { this.downloadImage(row); }}>{selectedRow === row.original.id && isDownloadingImage ? <ContentLoader rowVariant text="" /> : <IconCloudDownload height="25px" width="25px" color="green" />}</span>
                        </span>),
                      sortable: false,
                    },
                  ],
                },
              ]}
              data={tableData}
              minRows={0}
              loading={isLoading}
              noDataText=" "
              showPagination={false}
              sortable="true"
              manual={false}
            />
          </div>
        );
      });
    }
    return '';
  };

  render() {
    const { isPreviewModalOpen, isLoadingPreviewModal, reportEntryImage, isLoading } = this.state;

    return (
      <div>
        {isLoading && <ContentLoader />}
        {this.renderTables()}

        <Modal
          isOpen={isPreviewModalOpen}
          handleClose={this.closePreviewModal}
        >
          {
            isLoadingPreviewModal ?
              <ContentLoader /> :
              reportEntryImage &&
              <img
                width="100%"
                src={reportEntryImage}
                alt="Report entry"
              />
          }
        </Modal>
      </div>
    );
  }
}

DetailedAnalytics.propTypes = {
  reportId: PropTypes.number,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
});

export default withRouter(connect(mapStateToProps, null)(DetailedAnalytics));
