import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import api from 'helpers/api';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { defaultDateFormat } from 'shared/constants';
import { IconSearch } from 'shared/Icons';

import { styledWorkInjuryStatusOptions } from 'industry/helpers';

import { ACTIONS } from 'industry/role/definitions/actions';
import { SECTIONS } from 'industry/role/definitions/sections';
import { canPerformAction } from 'industry/role/selectors';

import { getWorkingInjuries, getPaginatedWorkingInjuries, deleteWorkingInjuries } from '../../actions';

import WorkingInjuriesModal from './WorkingInjuriesModal';

const WorkingInjuriesTab = ({ t, locationId, can }) => {
  const [sorting, setSorting] = useState({
    selectedSort: 'date',
    selectedAscDesc: 'desc',
  });

  const [tableData, setTableData] = useState({
    isLoading: true,
    workingInjuries: [],
    next: null,
    previous: null,
  });

  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedInjury: null,
  });

  const [filters, setFilters] = useState({
    status: null,
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const debounceTimeoutRef = useRef(null);
  const [query, setQuery] = useState(null);

  const fetchInjuries = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let urlFilters = '';

    const asc = sorting?.selectedAscDesc === 'desc' ? '-' : '';
    urlFilters += `&order_by=${asc}${sorting?.selectedSort}`;

    if (filters?.status) urlFilters += `&status=${filters?.status?.value}`;
    if (query) urlFilters += `&first_or_last_name=${query}`;

    getWorkingInjuries(locationId, urlFilters)
      .then((re) => {
        setTableData({
          isLoading: false,
          workingInjuries: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  useEffect(() => {
    fetchInjuries();
  }, [locationId, sorting]);

  useEffect(() => {
    if (query !== null) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        fetchInjuries();
      }, 300);
    }
  }, [query]);

  useEffect(() => {
    fetchInjuries();
  }, [filters, sorting]);

  const handleSorting = (sortData) => {
    const column = sortData.id;

    setSorting({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    });
  };

  const fetchPaginatedData = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedWorkingInjuries(url)
      .then((re) => {
        setTableData({
          isLoading: false,
          workingInjuries: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  const closeModal = () => {
    setModalData({
      isOpen: false,
      selectedCompanyTable: null,
    });
  };

  const closeModalAndRefetch = () => {
    setModalData({
      isOpen: false,
      selectedCompanyTable: null,
    });

    fetchInjuries();
  };

  const handleTableRowClick = (injury) => {
    setModalData({
      isOpen: true,
      selectedInjury: injury,
    });
  };

  const handleShowConfirmationDialog = (row) => {
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteWorkingInjuries(deleteData?.id, locationId);
    setShowConfirmationDialog(false);
    fetchInjuries();
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const exportToExcel = () => {
    api.get(`/api/v1/workforce/injuries/?location=${locationId}&format=xlsx&limit=${tableData?.count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Work_injuries.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const eventLocationOptions = [
    { value: 'workplace', name: t('page_content.human_resources.injuries.location_options_workplace') },
    { value: 'travel', name: t('page_content.human_resources.injuries.location_options_travel') },
  ];

  const statusesOptions = [
    { value: 'resolved', name: t('page_content.human_resources.injuries.status_resolved') },
    { value: 'denied', name: t('page_content.human_resources.injuries.status_denied') },
    { value: 'unresolved', name: t('page_content.human_resources.injuries.status_unresolved') },
    { value: 'in_progress', name: t('page_content.human_resources.injuries.status_in_progress') },
  ];

  return (
        <>
          <div className="industry-tab injuries_container fullscreen">
            <div className="injuries_container_filters">
              <div className="injuries_container_filters__input">
                <input
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder={t('page_content.human_resources.rating.search_by_first_or_last_name')}
                  value={query || ''}
                />
                {query && <button onClick={() => setQuery('')}>&times;</button>}
                <div className="ratings_container__table__filters__input__icon">
                  <IconSearch color="#555" height="26px" width="26px" />
                </div>
              </div>
              <div>
                <Select
                  className="select-style"
                  options={statusesOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  placeholder={t('page_content.human_resources.rating.search_by_status')}
                  isClearable
                  onChange={(e) => handleFilterChange('status', e)}
                  value={statusesOptions.find((a) => a.value === filters?.status?.value) || ''}
                  styles={selectModalStyles}
                />
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <Button
                  type="add"
                  style={{ marginRight: '10px' }}
                  disabled={!can(SECTIONS.WORKING_INJURIES_TAB, ACTIONS.ADD)}
                  onClick={() => setModalData({ isOpen: true, selectedInjury: null })}
                >
                  {t('page_content.human_resources.injuries.add_injury')}</Button>
                <Button
                  type="export"
                  onClick={exportToExcel}
                  disabled={!can(SECTIONS.WORKING_INJURIES_TAB, ACTIONS.EXPORT)}
                >
                  {t('settings.ascalia_audit_log.export_to_excel_button')}
                </Button>
              </div>
            </div>

            <Table
              style={{ userSelect: 'text' }}
              columns={[
                {
                  Header: () => <span>{t('page_content.human_resources.injuries.date')}</span>,
                  accessor: 'date',
                  Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
                  style: { cursor: 'default' },
                },
                {
                  Header: () => <span>{t('page_content.human_resources.injuries.worker')}</span>,
                  accessor: 'worker',
                  Cell: (row) => (row && row.value ? `${row.value.name} ${row.value.last_name}` : '-'),
                  style: { cursor: 'default' },
                },
                {
                  Header: () => <span>{t('page_content.human_resources.injuries.event_location')}</span>,
                  accessor: 'event_location',
                  Cell: (row) => (row?.value ? eventLocationOptions?.find((val) => val.value === row.value)?.name : '-'),
                  style: { cursor: 'default' },
                },
                {
                  Header: () => <span>{t('page_content.human_resources.injuries.injured_body_part')}</span>,
                  accessor: 'injured_body_part',
                  style: { cursor: 'default' },
                },
                {
                  Header: () => <span>{t('page_content.human_resources.injuries.injury_source')}</span>,
                  accessor: 'injury_source',
                  style: { cursor: 'default' },
                },
                {
                  Header: () => <span>{t('page_content.human_resources.injuries.injury_origin')}</span>,
                  accessor: 'injury_origin',
                  style: { cursor: 'default' },
                },
                {
                  Header: () => <span>{t('page_content.human_resources.injuries.activity_undertaken')}</span>,
                  accessor: 'activity_undertaken',
                  style: { cursor: 'default' },
                },
                {
                  Header: () => <span>{t('page_content.human_resources.injuries.planned_resolution_date')}</span>,
                  accessor: 'planned_resolution_date',
                  Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
                  style: { cursor: 'default' },
                },
                {
                  Header: () => <span>{t('page_content.human_resources.injuries.resolution_date')}</span>,
                  accessor: 'resolution_date',
                  Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
                  style: { cursor: 'default' },
                },
                {
                  Header: () => <span>{t('page_content.human_resources.injuries.status')}</span>,
                  accessor: 'status',
                  Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span style={row?.value && statusesOptions?.find((val) => val.value === row?.value)?.name ? styledWorkInjuryStatusOptions(row?.value) : {}}>
                      {row?.value ? statusesOptions?.find((val) => val.value === row?.value)?.name : '-'}</span>
                  </div>,
                  style: { cursor: 'default' },
                },
                {
                  Header: () => <span>{t('page_content.human_resources.injuries.responsible_person')}</span>,
                  accessor: 'responsible_person',
                  Cell: (row) => (row && row.value ? `${row.value.name} ${row.value.last_name}` : '-'),
                  style: { cursor: 'default' },
                },
              ]}
              data={tableData.workingInjuries || []}
              defaultPageSize={30}
              loading={tableData.isLoading}
              minRows={0}
              noDataText=""
              enableEdit={can(SECTIONS.WORKING_INJURIES_TAB, ACTIONS.EDIT)}
              enableDelete={can(SECTIONS.WORKING_INJURIES_TAB, ACTIONS.DELETE)}
              onEdit={(original) => handleTableRowClick(original)}
              onDelete={(original) => handleShowConfirmationDialog(original)}
              defaultSorted={[{ id: 'date', desc: true }]}
              onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
            />
            <TableButtons previous={tableData.previous} next={tableData.next} fetchFunction={fetchPaginatedData} count={tableData.count} />
          </div>

          {
            modalData.isOpen &&
            <WorkingInjuriesModal
              t={t}
              isOpen={modalData.isOpen}
              handleCloseModal={closeModal}
              statusesOptions={statusesOptions}
              initialValues={modalData.selectedInjury}
              closeModalAndRefetch={closeModalAndRefetch}
              eventLocationOptions={eventLocationOptions}
            />
          }

          <ConfirmationModal
            showModal={showConfirmationDialog}
            handleCloseModal={() => setShowConfirmationDialog(false)}
            handleConfirmModal={handleDeleteItem}
            type="warning"
          />
        </>
  );
};

WorkingInjuriesTab.propTypes = {
  t: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    locationId: get(state, 'app.location.id', null),
    can: (section, action, useExceptions = false) => canPerformAction(state, section, action, useExceptions),
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(WorkingInjuriesTab)));
