import { getByURL, postAndNotifyByURL, patchAndNotifyByURL } from './CustomService';

export const getRoles = async (filters = '') => {
  return getByURL(`/api/v1/roles/${filters}`);
};

export const postRole = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/roles/${filters}`, data);
};

export const patchRole = async (id, filters = '', data) => {
  return patchAndNotifyByURL(`/api/v1/roles/${id}/${filters}`, data);
};
