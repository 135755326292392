import { getByURL, getByURLBlob } from './CustomService';

// -------------------- SCORING WORKER DATA -------------------- //
export const getScoringWorkerData = async (filters = '') => {
  return getByURL(`/api/v1/scores/worker_data/${filters}`);
};

// -------------------- SCORING INDIVIDUAL GOALS -------------------- //
export const getScoringIndividualGoals = async (filters = '') => {
  return getByURL(`/api/v1/scores/individual_goals/${filters}`);
};

export const getScoringIndividualGoalsBlob = async (filters = '') => {
  return getByURLBlob(`/api/v1/scores/individual_goals/${filters}`);
};

// -------------------- SCORING GOAL SCORES -------------------- //
export const getScoringGoalScores = async (filters = '') => {
  return getByURL(`/api/v1/scores/goal_scores/${filters}`);
};

// -------------------- SCORING GOAL TEMPLATES -------------------- //
export const getScoringGoalTemplates = async (filters = '') => {
  return getByURL(`/api/v1/scores/goal_templates/${filters}`);
};

// -------------------- SCORING EVALUATIONS -------------------- //
export const getScoringEvaluations = async (filters = '') => {
  return getByURL(`/api/v1/scores/evaluations/${filters}`);
};

// -------------------- SCORING WORKER EVALUATIONS -------------------- //
export const getScoringWorkerEvaluations = async (filters = '') => {
  return getByURL(`/api/v1/scores/worker_evaluations/${filters}`);
};

// -------------------- SCORING OPTIONS -------------------- //
export const getScoringOptions = async (filters = '') => {
  return getByURL(`/api/v1/scores/options/${filters}`);
};

// -------------------- SCORING VALUES -------------------- //
export const getScoringValues = async (filters = '') => {
  return getByURL(`/api/v1/scores/values/${filters}`);
};

// -------------------- SCORING DATA DEFINITIONS -------------------- //
export const getScoringDataDefinitions = async (filters = '') => {
  return getByURL(`/api/v1/scores/data_definitions/${filters}`);
};

// -------------------- SCORING CHECK DATA -------------------- //
export const getScoringCheckData = async (filters = '') => {
  return getByURL(`/api/v1/scores/check_data/${filters}`);
};
