import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { Table } from 'shared';
import { numberSeparatorFormat } from 'industry/helpers';
import '../styles.scss';

class AggregateAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { i18n, t, scoreCardData, isLoading } = this.props;

    return (
      <div className="AggregateAnalytics_wrapper">
        <Table
          columns={[
            {
              Header: () => <span>{t('page_content.5S.table_column_category')}</span>,
              accessor: 'report',
              Cell: (row) => (row && row.value ? row.value : ' '),
            },
            {
              Header: () => <span>{t('page_content.5S.table_column_number')}</span>,
              accessor: 'count',
              Cell: (row) => (row && row.value ? row.value : ' '),
            },
            {
              Header: () => <span>{t('page_content.5S.table_column_sum')}</span>,
              accessor: 'sum',
              Cell: (row) => (row && row.value ? numberSeparatorFormat(i18n.language, row.value, 2, 2, true) : ' '),
            },
            {
              Header: () => <span>{t('page_content.5S.table_column_avg')}</span>,
              accessor: 'avg',
              Cell: (row) => (row && row.value ? numberSeparatorFormat(i18n.language, row.value, 2, 2, true) : ' '),
            },
            {
              Header: () => <span>0</span>,
              accessor: '0',
              Cell: (row) => (row && row.value !== undefined ? (row.value === 0 ? '0' : row.value) : ' '),
            },
            {
              Header: () => <span>0,5</span>,
              accessor: '05',
              Cell: (row) => (row && row.value !== undefined ? (row.value === 0 ? '0' : row.value) : ' '),
            },
            {
              Header: () => <span>1</span>,
              accessor: '1',
              Cell: (row) => (row && row.value !== undefined ? (row.value === 0 ? '0' : row.value) : ' '),
            },
          ]}
          data={scoreCardData || []}
          minRows={0}
          loading={isLoading}
          noDataText=" "
          showPagination={false}
          sortable="true"
          manual={false}
        />
      </div>
    );
  }
}

AggregateAnalytics.propTypes = {
  scoreCardData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  i18n: PropTypes.object,
  t: PropTypes.func,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
});

export default withRouter(connect(mapStateToProps, null)(withTranslation()(AggregateAnalytics)));
