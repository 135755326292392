import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import './style.scss';

import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';
import { getByURL, getProductGroups, deleteProductGroup } from 'shared/Api';

import ProductGroupsModal from './components/ProductGroupsModal';

const ProductGroups = ({ t, locationId, companyId }) => {
  const [sorting, setSorting] = useState({
    selectedSort: 'name',
    selectedAscDesc: 'desc',
  });

  const [tableData, setTableData] = useState({
    isLoading: true,
    data: [],
    next: null,
    previous: null,
  });

  const [modalData, setModalData] = useState({
    isOpen: false,
    data: null,
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const [isReadOnly, setIsReadOnly] = useState(false);

  const fetchProductGroups = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let urlFilters = `?location=${locationId}`;

    const asc = sorting?.selectedAscDesc === 'desc' ? '-' : '';
    urlFilters += `&order_by=${asc}${sorting?.selectedSort}`;

    getProductGroups(urlFilters)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  const checkAccess = () => {
    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) redirectToSettingsPage(companyId, locationId);
        else if (access === 1) setIsReadOnly(true);
      });
  };

  useEffect(() => {
    checkAccess();
  }, []);

  useEffect(() => {
    fetchProductGroups();
  }, [sorting]);

  const handleSorting = (sortData) => {
    const column = sortData.id;

    setSorting({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    });
  };

  const fetchPaginatedData = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getByURL(url)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count', 0),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  const closeModal = () => {
    setModalData({
      isOpen: false,
      data: null,
    });
  };

  const closeModalAndRefetch = () => {
    setModalData({
      isOpen: false,
      data: null,
    });
    fetchProductGroups();
  };

  const handleTableRowClick = (original) => {
    setModalData({
      isOpen: true,
      data: original,
    });
  };

  const handleShowConfirmationDialog = (original) => {
    setShowConfirmationDialog(true);
    setDeleteData(original);
  };

  const handleDeleteItem = async () => {
    const apiFilters = `?location=${locationId}`;
    await deleteProductGroup(deleteData?.id, apiFilters);
    setShowConfirmationDialog(false);
    fetchProductGroups();
  };

  return (
        <div className="product-groups-container">
            <div className="product-groups-container__filters">
                <div style={{ marginLeft: 'auto' }}>
                    <Button
                      type="add"
                      onClick={() => setModalData({ isOpen: true, data: null })}
                    >
                      {t('settings.product_groups.add_new_product_group_button')}
                    </Button>
                </div>
            </div>

            <Table
              style={{ userSelect: 'text' }}
              columns={[
                {
                  Header: () => <span>{t('settings.product_groups.table_column_name')}</span>,
                  accessor: 'name',
                  Cell: (row) => (row?.value ? row.value : '-'),
                  style: { cursor: 'default' },
                },
                {
                  Header: () => <span>{t('settings.product_groups.table_column_code')}</span>,
                  accessor: 'code',
                  Cell: (row) => (row?.value ? row.value : '-'),
                  style: { cursor: 'default' },
                },
              ]}
              data={tableData?.data}
              loading={tableData?.isLoading}
              defaultPageSize={30}
              minRows={0}
              enableEdit
              enableDelete
              onEdit={(original) => handleTableRowClick(original)}
              onDelete={(original) => handleShowConfirmationDialog(original)}
              isActionsDisabled={isReadOnly}
              defaultSorted={[{ id: 'name', desc: true }]}
              onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
            />
            <TableButtons previous={tableData.previous} next={tableData.next} fetchFunction={fetchPaginatedData} count={tableData.count} />

            { modalData.isOpen &&
                <ProductGroupsModal
                  locationId={locationId}
                  isOpen={modalData.isOpen}
                  handleCloseModal={closeModal}
                  initialValues={modalData.data}
                  closeModalAndRefetch={closeModalAndRefetch}
                /> }

            <ConfirmationModal
              itemName={`${get(deleteData, 'name')} | ${get(deleteData, 'code')}`}
              showModal={showConfirmationDialog}
              handleCloseModal={() => setShowConfirmationDialog(false)}
              handleConfirmModal={handleDeleteItem}
              type="warning"
            />
        </div>
  );
};

ProductGroups.propTypes = {
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
};

export default withRouter(withTranslation()(ProductGroups));
