import React, { Component } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Modal, Table, Button, JsonEditor, TableButtons, ReactDatePicker } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';
import './styles.scss';

import { defaultDateFormat, modalSizes } from 'shared/constants';

import { getIndustryMetrics, getByURL } from 'shared/Api';

class AssetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingMetrics: true,
      metrics: [],
      previous: null,
      next: null,
      showAddAssetGroup: false,
      showAddAssetType: false,
      asset: {
        name: '',
        external_id: '',
        serial_number: '',
        asset_type: '',
        asset_model: '',
        asset_group: '',
        has_oee: false,
        commissioning_date: moment(new Date()).format('YYYY-MM-DD'),
        label_type: '',
        data: {},
      },
    };
    if (props.assetObject) {
      const { assetObject } = props;
      const updatedAssetObject = {
        ...this.state.asset,
        name: assetObject.name || this.state.asset.name,
        external_id: assetObject.external_id || this.state.asset.external_id,
        serial_number: assetObject.serial_number || this.state.asset.serial_number,
        asset_type: assetObject.asset_type || this.state.asset.asset_type,
        asset_model: assetObject.asset_model || this.state.asset.asset_model,
        asset_group: assetObject.asset_group || this.state.asset.asset_group,
        commissioning_date: assetObject.commissioning_date || this.state.asset.commissioning_date,
        label_type: assetObject.label_type || this.state.asset.label_type,
        has_oee: assetObject.has_oee || this.state.asset.has_oee,
        data: assetObject.data || this.state.asset.data,
        id: assetObject.id,
      };
      this.state = {
        asset: updatedAssetObject,
        new_asset_group: {
          name: '',
          description: '',
          type: '',
          location: props.locationId,
          process_id: null,
        },
        new_asset_type: {
          code: '',
          description: '',
          applies_to: '',
          location: props.locationId,
          company: props.companyId,
        },
        jsonEditorError: false,
        selectedAscDesc: 'asc',
        selectedSort: 'label',
      };
    }
  }

  componentDidMount() {
    this.getMetrics();
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.getMetrics();
    });
  }

  getMetrics = () => {
    const { locationId } = this.props;
    const { asset, selectedAscDesc, selectedSort } = this.state;

    if (!asset.id) return;

    this.setState({ isLoadingMetrics: false });

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    let sortKey = selectedSort;

    if (selectedSort === 'value_type.label') sortKey = 'value_type';

    const sortOrder = `&order_by=${asc}${sortKey}`;
    const apiFilters = `?location=${locationId}&asset=${asset.id}&limit=10${sortOrder}`;
    getIndustryMetrics(apiFilters)
      .then((res) => {
        this.setState({
          metrics: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoadingMetrics: false,
        });
      });
  }

  fetchPaginatedMetrics = (url) => {
    this.setState({ isLoadingMetrics: false });

    getByURL(url)
      .then((re) => {
        this.setState({
          metrics: get(re, 'data.results', []),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
          count: get(re, 'data.count', 0),
          isLoadingMetrics: false,
        });
      });
  }

  toggleShowAddAssetGroup = () => {
    const { showAddAssetGroup } = this.state;
    const { assetGroupTypesDropdown } = this.props;
    this.setState((prevState) => ({
      showAddAssetGroup: !showAddAssetGroup,
      asset: {
        ...prevState.asset,
        asset_group: '',
      },
      new_asset_group: {
        ...prevState.new_asset_group,
        type: assetGroupTypesDropdown && assetGroupTypesDropdown.length > 0 ? assetGroupTypesDropdown[0].id : '',
      },
    }));
  }

  toggleShowAddAssetType = () => {
    const { showAddAssetType, dropdown_appliesTo } = this.state;
    this.setState((prevState) => ({
      showAddAssetType: !showAddAssetType,
      asset: {
        ...prevState.asset,
        asset_type: '',
      },
      new_asset_type: {
        ...prevState.new_asset_type,
        applies_to: dropdown_appliesTo && dropdown_appliesTo.length > 0 ? dropdown_appliesTo[0] : '',
      },
    }));
  }

  handleModalChange = (key, value) => {
    this.setState((prevState) => ({
      asset: {
        ...prevState.asset,
        [key]: value,
      },
    }));
  }

  handleNewAssetGroupChange = (key, value) => {
    this.setState((prevState) => ({
      new_asset_group: {
        ...prevState.new_asset_group,
        [key]: value,
      },
    }));
  }

  handleNewAssetTypeChange = (key, value) => {
    this.setState((prevState) => ({
      new_asset_type: {
        ...prevState.new_asset_type,
        [key]: value,
      },
    }));
  }

  handleSave = () => {
    const { asset, showAddAssetGroup, showAddAssetType, new_asset_group, new_asset_type } = this.state;
    const { updateAsset, createAsset, createAssetType, createAssetGroup, createAssetWithNewGroupAndType } = this.props;

    const updatedAsset = showAddAssetGroup || showAddAssetType
      ? { ...asset,
        asset_group: showAddAssetGroup ? new_asset_group : asset.asset_group,
        asset_type: showAddAssetType ? new_asset_type : asset.asset_type }
      : asset;

    if (updatedAsset.id) {
      updateAsset(updatedAsset.id, updatedAsset);
    } else if (showAddAssetType && !showAddAssetGroup) {
      createAssetType(updatedAsset);
    } else if (showAddAssetGroup && !showAddAssetType) {
      createAssetGroup(updatedAsset);
    } else if (showAddAssetGroup && showAddAssetType) {
      createAssetWithNewGroupAndType(updatedAsset);
    } else {
      createAsset(updatedAsset);
    }
  }

  handleJSONEditorError = (value) => {
    if (value && value.length > 0) this.setState({ jsonEditorError: true });
    else this.setState({ jsonEditorError: false });
  }

  render() {
    const {
      onClose,
      isReadOnly,
      t,
      isOpen,
      assetTypesDropdown,
      assetModelsDropdown,
      assetGroupsDropdown,
      stickerDesignDropdown,
      assetGroupTypesDropdown,
    } = this.props;
    const {
      showAddAssetGroup,
      showAddAssetType,
      metrics,
      isLoadingMetrics,
      next,
      previous,
      asset,
      new_asset_group,
      new_asset_type,
      count,
      jsonEditorError,
    } = this.state;

    const preparedDropdownLabels = stickerDesignDropdown?.map((label) => {
      return {
        id: label[0],
        name: label[1],
      };
    });

    const dropdown_appliesTo = [
      { value: 'all', label: t('page_content.assets.modal.dropdown_applies_to.all') },
      { value: 'industry', label: t('page_content.assets.modal.dropdown_applies_to.industy') },
      { value: '', label: t('page_content.assets.modal.dropdown_applies_to.none') },
    ];

    const metricsTableColumnConfig = [
      {
        Header: () => <span>{t('page_content.assets.modal.table_column_name')}</span>,
        accessor: 'label',
      },
      {
        Header: () => <span>{t('page_content.assets.modal.table_column_code')}</span>,
        accessor: 'code',
      },
      {
        Header: () => <span>{t('page_content.assets.modal.table_column_value_type')}</span>,
        accessor: 'value_type.label',
      },
    ];

    return (
      <Modal
        isOpen={isOpen}
        handleClose={() => { this.setState({ metrics: [] }, () => onClose()); }}
        handleSave={this.handleSave}
        size={modalSizes.medium}
        title={asset && asset.id ? t('page_content.assets.modal.edit_title') : t('page_content.assets.modal.add_title')}
        disableSave={((!asset.name || jsonEditorError) ||
          ((asset.asset_type === '' && (showAddAssetType && !new_asset_type.code)) || (!asset.asset_type && !showAddAssetType)) ||
          ((asset.asset_group === '' && (showAddAssetGroup && !new_asset_group.name)) || (!asset.asset_group && !showAddAssetGroup)))}
      >
        <div className="assetForm_tabContainer">
          <Tabs>
            <TabList>
              <Tab>{t('page_content.assets.modal.tabs.details')}</Tab>
              <Tab>{t('page_content.assets.modal.tabs.metrics')}</Tab>
            </TabList>
            <TabPanel>
              <div className="asset_form_modal_container">
                <div className="modal_row">
                  <div className="left_text">{`${t('page_content.assets.modal.name')}`}</div>
                  <div className="right_input">
                    <input onChange={(e) => this.handleModalChange('name', e.target.value)} value={asset.name} />
                  </div>
                </div>
                <div className="modal_row">
                  <div className="left_text">{`${t('page_content.assets.modal.external_id')}`}</div>
                  <div className="right_input">
                    <input onChange={(e) => this.handleModalChange('external_id', e.target.value)} value={asset.external_id} />
                  </div>
                </div>
                <div className="modal_row">
                  <div className="left_text">{`${t('page_content.assets.modal.serial_number')}`}</div>
                  <div className="right_input">
                    <input onChange={(e) => this.handleModalChange('serial_number', e.target.value)} value={asset.serial_number} />
                  </div>
                </div>
                <div className="modal_row">
                  <div className="left_text">{`${t('page_content.assets.has_oee')}`}</div>
                  <div className="right_checkbox">
                    <input type="checkbox" onChange={(e) => this.handleModalChange('has_oee', e.target.checked)} checked={asset.has_oee} />
                  </div>
                </div>
                <div className="modal_row" style={{ alignItems: 'flex-start' }}>
                  <div className="left_text" style={{ marginTop: '10px' }}>{t('page_content.assets.modal.asset_type')}</div>
                  <div className="right_select">
                    <Select
                      isDisabled={(assetTypesDropdown && assetTypesDropdown.length === 0) || showAddAssetType}
                      options={assetTypesDropdown || []}
                      getOptionLabel={(assetType) => assetType.code}
                      getOptionValue={(assetType) => assetType.id}
                      isSearchable
                      menuPosition="fixed"
                      placeholder={t('shared.asset_type_picker.placeholder')}
                      onChange={(value) => this.handleModalChange('asset_type', value.id)}
                      value={(assetTypesDropdown?.find((aT) => aT.id === asset.asset_type)) || ''}
                      styles={selectModalStyles}
                    />
                    <div className="add_assetType_button_Wrapper">
                      {
                        !asset.id && <Button
                          onClick={this.toggleShowAddAssetType}
                          disabled={isReadOnly}
                          type="add"
                        >
                          {t('page_content.assets.modal.add_asset_type_button')}
                        </Button>
                      }
                    </div>
                    {
                      showAddAssetType &&
                      <div className="nested_right_box">
                        <div className="nested_row">
                          <div>{t('page_content.assets.modal.asset_type_form.name')}</div>
                          <input onChange={(e) => this.handleNewAssetTypeChange('code', e.target.value)} value={new_asset_type.code} />
                        </div>
                        <div className="nested_row">
                          <div>{t('page_content.assets.modal.asset_type_form.description')}</div>
                          <input onChange={(e) => this.handleNewAssetTypeChange('description', e.target.value)} value={new_asset_type.description} />
                        </div>
                        <div className="nested_row">
                          <div>{t('page_content.assets.modal.asset_type_form.group_type')}</div>
                          <Select
                            options={dropdown_appliesTo}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            placeholder={t('shared.asset_type_industry_picker.placeholder')}
                            menuPosition="fixed"
                            onChange={(value) => this.handleNewAssetTypeChange('applies_to', value.value)}
                            value={(dropdown_appliesTo?.find((agt) => agt.value === new_asset_type?.applies_to)) || ''}
                            styles={selectModalStyles}
                          />
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="modal_row">
                  <div className="left_text">{t('page_content.assets.modal.asset_model')}</div>
                  <div className="right_select">
                    <Select
                      isDisabled={assetModelsDropdown && assetModelsDropdown.length === 0}
                      options={assetModelsDropdown || []}
                      getOptionLabel={(assetModel) => assetModel.model}
                      getOptionValue={(assetModel) => assetModel.id}
                      isSearchable
                      menuPosition="fixed"
                      placeholder={t('shared.asset_model_picker.placeholder')}
                      onChange={(value) => this.handleModalChange('asset_model', value.id)}
                      value={(assetModelsDropdown?.find((aM) => aM.id === asset?.asset_model)) || ''}
                      styles={selectModalStyles}
                    />
                  </div>
                </div>
                <div className="modal_row" style={{ alignItems: 'flex-start' }}>
                  <div className="left_text" style={{ marginTop: '10px' }}>{t('page_content.assets.modal.asset_group')}</div>
                  <div className="right_select">
                    <Select
                      isDisabled={(assetGroupsDropdown && assetGroupsDropdown.length === 0) || showAddAssetGroup}
                      options={assetGroupsDropdown || []}
                      getOptionLabel={(assetModel) => assetModel.name}
                      getOptionValue={(assetModel) => assetModel.id}
                      isSearchable
                      menuPosition="fixed"
                      placeholder={t('shared.asset_group_picker.placeholder')}
                      onChange={(value) => this.handleModalChange('asset_group', value.id)}
                      value={(assetGroupsDropdown?.find((aG) => aG.id === asset.asset_group)) || ''}
                      styles={selectModalStyles}
                    />
                    <div className="add_assetGroup_button_Wrapper">
                      {
                        !asset.id && <Button
                          onClick={this.toggleShowAddAssetGroup}
                          disabled={isReadOnly}
                          type="add"
                        >
                          {t('page_content.assets.modal.add_asset_group_button')}
                        </Button>
                      }
                    </div>
                    {
                      showAddAssetGroup &&
                      <div className="nested_right_box">
                        <div className="nested_row">
                          <div>{t('page_content.assets.modal.asset_group_form.name')}</div>
                          <input onChange={(e) => this.handleNewAssetGroupChange('name', e.target.value)} value={new_asset_group.name} />
                        </div>
                        <div className="nested_row">
                          <div>{t('page_content.assets.modal.asset_group_form.description')}</div>
                          <input onChange={(e) => this.handleNewAssetGroupChange('description', e.target.value)} value={new_asset_group.description} />
                        </div>
                        <div className="nested_row">
                          <div>{t('page_content.assets.modal.asset_group_form.group_type')}</div>
                          <Select
                            isDisabled={assetGroupTypesDropdown && assetGroupTypesDropdown.length === 0}
                            options={assetGroupTypesDropdown}
                            getOptionLabel={(groupType) => groupType.description}
                            getOptionValue={(groupType) => groupType.id}
                            isSearchable
                            menuPosition="fixed"
                            placeholder={t('shared.asset_group_type_picker.placeholder')}
                            onChange={(value) => this.handleNewAssetGroupChange('type', value.id)}
                            value={(assetGroupTypesDropdown?.find((agt) => agt.id === new_asset_group?.type)) || ''}
                            styles={selectModalStyles}
                          />
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="modal_row">
                  <div className="left_text">{t('page_content.assets.modal.commissioning_date')}</div>
                  <div className="right_datePicker">
                    <ReactDatePicker
                      placeholderText={`i.e. ${moment().format(defaultDateFormat)} `}
                      selected={asset.commissioning_date ? moment(asset.commissioning_date).toDate() : null}
                      onChange={(date) => this.handleModalChange('commissioning_date', moment(date).format('YYYY-MM-DD'))}
                      showMonthDropdown
                      showYearDropdown
                      disabled={isReadOnly}
                    />
                  </div>
                </div>
                <div className="modal_row">
                  <div className="left_text">{t('page_content.assets.modal.sticker_design')}</div>
                  <div className="right_select">
                    <Select
                      isDisabled={stickerDesignDropdown && stickerDesignDropdown.length === 0}
                      options={preparedDropdownLabels || []}
                      getOptionLabel={(label) => label.name}
                      getOptionValue={(label) => label.id}
                      isSearchable
                      menuPosition="fixed"
                      placeholder="Select label ..."
                      onChange={(value) => this.handleModalChange('label_type', value.id)}
                      value={(preparedDropdownLabels?.find((l) => l.id === asset.label_type)) || ''}
                      styles={selectModalStyles}
                    />
                  </div>
                </div>
                <div className="modal_row">
                  <div className="left_text">{t('page_content.assets.modal.data')}</div>
                  <div className="right_select">
                    <JsonEditor
                      value={asset.data}
                      onChange={(e) => { this.handleModalChange('data', e); }}
                      isError={this.handleJSONEditorError}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div style={{ paddingBottom: '10px' }}>
                <Table
                  style={{ userSelect: 'text' }}
                  columns={metricsTableColumnConfig}
                  data={metrics || []}
                  loading={isLoadingMetrics}
                  minRows={0}
                  noDataText=" "
                  showPagination={false}
                  defaultPageSize={10}
                  defaultSorted={[{ id: 'label', desc: false }]}
                  onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
                />
                <TableButtons next={next} previous={previous} fetchFunction={this.fetchPaginatedMetrics} count={count} />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </Modal>
    );
  }
}

AssetForm.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  assetObject: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  assetTypesDropdown: PropTypes.array.isRequired,
  assetModelsDropdown: PropTypes.array.isRequired,
  assetGroupsDropdown: PropTypes.array.isRequired,
  assetGroupTypesDropdown: PropTypes.array.isRequired,
  stickerDesignDropdown: PropTypes.array.isRequired,
  updateAsset: PropTypes.func.isRequired,
  createAsset: PropTypes.func.isRequired,
  createAssetType: PropTypes.func.isRequired,
  createAssetGroup: PropTypes.func.isRequired,
  createAssetWithNewGroupAndType: PropTypes.func.isRequired,
};

export default (withTranslation()(AssetForm));
