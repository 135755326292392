import React from 'react';
import { get } from 'lodash';
import Tooltip from 'rc-tooltip';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { IconSearch, IconLocked, IconInfo } from 'shared/Icons';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { Modal, Table } from 'shared';

import { getSidebar } from 'shared/Api';

const access = {
  // HIDDEN: 0,
  READ_ONLY: 1,
  FULL_ACCESS: 10,
};

const accessNoForbidden = {
  READ_ONLY: 1,
  FULL_ACCESS: 10,
};

const accessStrings = {
  // HIDDEN: 'Hidden',
  READ_ONLY: 'Read only',
  FULL_ACCESS: 'Full access',
};

class RoleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      searchName: '',
    };
  }

  componentDidMount() {
    const { companyId, role, fetchRoleResources } = this.props;

    const apiFilters = `?company=${companyId}`;
    getSidebar(apiFilters)
      .then((res) => this.setState({ locations: get(res, 'data', []) }));

    if (!role || !role.id) return;

    fetchRoleResources(companyId, role.id);
  }

  getLocation = (rowData) => {
    const { locations } = this.state;

    const locationId = rowData && rowData.code ? rowData.code.split('/')[4] : null;

    if (locations && locations.items && locationId) {
      // eslint-disable-next-line eqeqeq
      const locationName = locations.items.find((x) => x.id == locationId);
      if (!locationName) {
        return '-';
      }
      return locationName.name;
    }
    return '-';
  }

  filterAndSortResources = (resources) => {
    const { searchName } = this.state;
    const { locationId } = this.props;

    const filteredResources = resources ? resources.filter((resource) => !resource.code.includes('user-management')) : [];

    const filteredResourcesByLocation = filteredResources.filter((obj) => {
      const matchLocation = obj.code.match(/\/location\/(\d+)/);
      const location = matchLocation ? matchLocation[1] : null;

      // Check if the name contains the search query and the location ID match
      return (
        obj.name.toLowerCase().includes(searchName.toLowerCase()) &&
        // eslint-disable-next-line eqeqeq
        (location === null || location == locationId)
      );
    });

    const filteredAndSortedResources = filteredResourcesByLocation.sort((a, b) => {
      if (a.code.includes('/location/') && !b.code.includes('/location/')) {
        return -1;
      } if (!a.code.includes('/location/') && b.code.includes('/location/')) {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });

    return filteredAndSortedResources;
  }

  handleSearchChange = (e) => {
    this.setState({ searchName: e.target.value });
  }

  handleClearSearch = () => {
    this.setState({ searchName: '' });
  }

  render() {
    const {
      isLoading,
      searchName,
      locations,
    } = this.state;

    const {
      toggleShowRoleModal,
      title,
      showRoleModal,
      handleSubmit,
      handleChangeData,
      size,
      data,
      resources,
      role,
      changeRoleResource,
      isEditRole,
      showResources,
      roleResources,
      t,
      currentUser,
    } = this.props;

    const accessRights = (resource, roleId) => {
      const roleResource = roleResources.find((rR) => rR.role.id === roleId && rR.resource.id === resource.id);
      let isChecked = false;
      if (roleResource) {
        isChecked = true;
      }

      const accessOptions = (resource.control_level && (resource.control_level === 'interface' || resource.control_level === 'operator-view')) ? access : accessNoForbidden;

      return (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {Object.keys(accessOptions).map(
            (k, index) => (
              <div key={index} style={{ textAlign: 'center' }}>
                <input
                  disabled={isLoading}
                  type="checkbox"
                  checked={isChecked && (roleResource && roleResource.access === access[k])}
                  onChange={(e) => {
                    this.setState({ isLoading: true });
                    changeRoleResource(e.target.checked, access[k], resource.id, roleId, roleResource || null)
                      .then(() => {
                        this.setState({
                          isLoading: false,
                        });
                      });
                  }}
                />
                <div style={{ fontSize: '13px' }}>{accessStrings[k]}</div>
              </div>
            ),
          )}
        </div>
      );
    };

    const isProtectedCheckbox = (resource) => {
      const { handleEditResource } = this.props;
      const roleResource = resources?.find((rR) => rR.id === resource.id)?.is_protected || false;

      return (
        <div>
          <div style={{ textAlign: 'center' }}>
            <input
              disabled={isLoading}
              type="checkbox"
              checked={roleResource}
              onChange={(e) => handleEditResource(resource.id, { is_protected: e.target.checked })}
            />
            <div style={{ fontSize: '13px' }}>{t('page_content.user_management.roles.role_modal.is_protected')}</div>
          </div>
        </div>
      );
    };

    const filteredAndSortedResources = this.filterAndSortResources(resources);

    return (
      <Modal
        isOpen={showRoleModal}
        handleClose={() => toggleShowRoleModal(false)}
        handleSave={() => (showResources ? toggleShowRoleModal(false) : handleSubmit())}
        size={size}
        title={showResources && role?.name ? `${title} - (${role?.name})` : title || ''}
      >
        <div className="default-form user-details-form">
          {
            !showResources &&
              <table>
                <tbody>
                  <tr>
                    <td className="label">
                      {t('page_content.user_management.roles.role_modal.name')}
                    </td>
                    <td className="input">
                      <input
                        type="text"
                        onChange={(e) => { handleChangeData(e.target.value, 'name'); }}
                        value={data.name}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      {t('page_content.user_management.roles.role_modal.code')}
                    </td>
                    <td className="input select">
                      <input
                        type="text"
                        readOnly
                        value={data.code}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      {t('page_content.user_management.roles.role_modal.location')}
                    </td>
                    <td className="input select">
                      <Select
                        options={locations?.items}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder={t('page_content.user_management.roles.role_modal.select_location')}
                        menuPosition="fixed"
                        isSearchable
                        isClearable
                        onChange={(e) => { handleChangeData(e ?? null, 'location'); }}
                        value={(locations?.items.find((opt) => opt.id === data?.location?.id)) || null}
                        styles={selectModalStyles}
                      />
                    </td>
                  </tr>
                  {
                    currentUser?.is_admin && (
                      <tr>
                        <td className="label">
                          {t('page_content.user_management.roles.role_modal.is_protected')}
                        </td>
                        <td className="input">
                          <input
                            type="checkbox"
                            checked={data?.is_protected || false}
                            onChange={(e) => handleChangeData(e.target.checked, 'is_protected')}
                          />
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
          }

          {
            isEditRole && showResources &&
            <div className="search">
              <input
                type="text"
                placeholder={t('page_content.user_management.roles.role_modal.search_placeholder')}
                value={searchName}
                onChange={this.handleSearchChange}
              />
              {
                searchName &&
                <button className="clear" onClick={this.handleClearSearch}>&times;</button>
              }
              <button
                className="submit"
              >
                <IconSearch
                  color="#555"
                  height="26px"
                  width="26px"
                />
              </button>
            </div>
          }

          {
            isEditRole && role && showResources ?
              <React.Fragment>
                <h3>
                  {t('page_content.user_management.roles.role_modal.resources')}
                </h3>
                <Table
                  data={filteredAndSortedResources || []}
                  showPagination={false}
                  sortable
                  manual={false}
                  pageSize={filteredAndSortedResources.length}
                  columns={[
                    {
                      Header: () => <span>{t('page_content.user_management.roles.role_modal.table_column_location')}</span>,
                      accessor: 'code',
                      Cell: (row) => this.getLocation(row.original),
                      maxWidth: 160,
                      minWidth: 110,
                      width: 140,
                      style: { cursor: 'default' },
                    },
                    {
                      Header: () => <span>{t('page_content.user_management.roles.role_modal.table_column_resource')}</span>,
                      accessor: 'name',
                      Cell: (row) => (
                        <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                          {row?.value}
                          {row?.original?.is_protected && (
                            <Tooltip
                              id="tooltip-zoom"
                              trigger={['hover']}
                              placement="top"
                              overlay={<p>This resource is protected, meaning it can only be seen by Super admins (Ascalia admins). To unlock the resource click on the checkbox or visit Django admin.</p>}
                              overlayClassName="where-filter-tooltip"
                            >
                              <span aria-describedby="tooltip-zoom">
                                <IconInfo color="#2e86de" height="15px" width="17px" />
                                <IconLocked height="18px" width="18px" />
                              </span>
                            </Tooltip>
                          )}
                        </span>
                      ),
                      style: { cursor: 'default' },
                    },
                    {
                      Header: () => <span>{t('page_content.user_management.roles.role_modal.is_protected')}</span>,
                      accessor: 'code',
                      Cell: (row) => isProtectedCheckbox(row.original),
                      minWidth: 50,
                      sortable: false,
                      style: { cursor: 'default' },
                      show: currentUser?.is_admin,
                    },
                    {
                      Header: () => <span>{t('page_content.user_management.roles.role_modal.table_column_access')}</span>,
                      accessor: 'code',
                      Cell: (row) => accessRights(row.original, role.id),
                      minWidth: 100,
                      sortable: false,
                      style: { cursor: 'default' },
                    },
                  ]}
                  getTrProps={(state, rowInfo) => {
                    if (rowInfo) {
                      const isProtected = rowInfo.original.is_protected;
                      return {
                        style: { backgroundColor: isProtected ? 'rgb(239, 195, 195)' : null },
                      };
                    }
                  }}
                />
              </React.Fragment> : ''
          }
        </div>
      </Modal>
    );
  }
}

RoleModal.propTypes = {
  toggleShowRoleModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  showRoleModal: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChangeData: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  role: PropTypes.object,
  resources: PropTypes.array.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  changeRoleResource: PropTypes.func.isRequired,
  isEditRole: PropTypes.bool.isRequired,
  showResources: PropTypes.bool.isRequired,
  fetchRoleResources: PropTypes.func.isRequired,
  roleResources: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  handleEditResource: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(RoleModal));
