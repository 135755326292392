import { deleteAndNotifyByURL, getByURL, postAndNotifyByURL, putAndNotifyByURL } from './CustomService';

export const getProductTypes = async (filters = '') => {
  return getByURL(`/api/v1/product_types/${filters}`);
};

export const postProductType = async (data) => {
  return postAndNotifyByURL('/api/v1/product_types/', data);
};

export const putProductType = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/product_types/${id}/${filters}`, data);
};

export const deleteProductType = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/product_types/${id}/${filters}`);
};
