import React, { Component } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { IconRemove } from 'shared/Icons';
import { modalSizes } from 'shared/constants';
import { createOrderCustom, selectModalStyles } from 'styles/modules/reactSelect';
import { Modal, Button, ContentLoader, ReactDatePicker } from 'shared';
import '../style.scss';

import { generateExternalId, getPartners, getShifts } from '../actions';

class CreateOrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partners: [],
      isLoadingModal: true,
      isError: false,
      order: {
        line: null,
        external_id: null,
        order_type: props && props.orderTypes && props.orderTypes[0] && props.orderTypes[0].id ? props.orderTypes[0].id : null,
        customer: null,
        planned_date: null,
        comment: null,
        bom_entries: [],
        product_entries: [
          { product: null, quantity: 1, unit: '' },
        ],
        groups: [{
          name: 'Sastavnica',
          material_code: 'BOM',
        },
        {
          name: 'Proizvod',
          material_code: 'items',
        },
        ],
      },
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    getShifts(locationId)
      .then((re) => {
        const shifts = get(re, 'data.results', []);
        const first_shift = shifts?.find((obj) => obj.order === 1);

        let planned_date;
        if (first_shift && first_shift.default_begin) {
          planned_date = moment().set({
            hour: parseInt(first_shift.default_begin.split(':')[0], 10),
            minute: parseInt(first_shift.default_begin.split(':')[1], 10),
            second: 0,
          });
        } else {
          planned_date = moment();
        }

        this.setState((prevState) => ({
          order: { ...prevState.order, planned_date },
        }));
      });

    getPartners(companyId)
      .then((res) => {
        this.setState({
          partners: res.data && res.data.results ? res.data.results : [],
        });
      });

    generateExternalId(companyId)
      .then((res) => {
        const external_id = res && res.data && res.data.next_external_id ? res.data.next_external_id : null;

        this.setState((prevState) => ({
          order: { ...prevState.order, external_id },
          isLoadingModal: false,
        }));
      })
      .catch(() => this.setState({ isLoadingModal: false }));
  }

  onSaveOrderModal = () => {
    const { onSaveOrderModal } = this.props;
    const { order } = this.state;

    if (!order.external_id || !order.customer || !order.product_entries.length) {
      this.setState({
        isError: true,
      });
      return;
    }

    onSaveOrderModal(this.modifyDataStructure(order));
    this.onCloseOrderModal();
  }

  onCloseOrderModal = () => {
    const { onCloseOrderModal } = this.props;

    onCloseOrderModal();
    this.clearState();
  }

  onResourceChange = (key, value) => {
    const { order } = this.state;

    this.setState({
      order: { ...order, [key]: value },
    });
  }

  clearState = () => {
    this.setState({
      order: {},
    });
  }

  addBomRow = () => {
    const newRow = { product: null, quantity: 1, unit: '' };
    this.setState((prevState) => ({
      order: {
        ...prevState.order,
        bom_entries: [...prevState.order.bom_entries, newRow],
      },
    }));
  }

  addProductRow = () => {
    const newRow = { product: null, quantity: 1, unit: '' };
    this.setState((prevState) => ({
      order: {
        ...prevState.order,
        product_entries: [...prevState.order.product_entries, newRow],
      },
    }));
  }

  handleOrderBomEntryChange = (index, key, value) => {
    this.setState((prevState) => ({
      order: {
        ...prevState.order,
        bom_entries: prevState.order.bom_entries.map((row, i) => {
          // eslint-disable-next-line eqeqeq
          if (index === i) {
            return { ...row, [key]: value };
          }
          return row;
        }),
      },
    }), () => {
      if (key === 'product') {
        const { product_types } = this.props;
        const selectedProduct = product_types.find((x) => x.id === parseInt(value, 10));
        const selectedUnit = (selectedProduct !== null && Object.prototype.hasOwnProperty.call(selectedProduct, 'base_unit')) ? selectedProduct.base_unit : null;
        const unitsElem = document.querySelector(`[name="bom_unit_${index}"]`);
        if (selectedUnit !== null && unitsElem !== undefined && unitsElem !== null) {
          unitsElem.value = selectedUnit;
          this.handleOrderBomEntryChange(index, 'unit', selectedUnit);
        }
      }
    });
  }

  handleOrderProductEntryChange = (index, key, value) => {
    this.setState((prevState) => ({
      order: {
        ...prevState.order,
        product_entries: prevState.order.product_entries.map((row, i) => {
          // eslint-disable-next-line eqeqeq
          if (index === i) {
            return { ...row, [key]: value };
          }
          return row;
        }),
      },
    }), () => {
      if (key === 'product') {
        const { product_types } = this.props;
        const selectedProduct = product_types.find((x) => x.id === parseInt(value, 10));
        const selectedUnit = (selectedProduct !== null && Object.prototype.hasOwnProperty.call(selectedProduct, 'base_unit')) ? selectedProduct.base_unit : null;
        const unitsElem = document.querySelector(`[name="product_unit_${index}"]`);
        if (selectedUnit !== null && unitsElem !== undefined && unitsElem !== null) {
          unitsElem.value = selectedUnit;
          this.handleOrderProductEntryChange(index, 'unit', selectedUnit);
        }
      }
    });
  }

  handleDeleteOrderBomEntry = (index) => {
    const { order } = this.state;

    const updatedOrders = [...order.bom_entries];
    updatedOrders.splice(index, 1);

    this.setState((prevState) => ({
      order: {
        ...prevState.order,
        bom_entries: [...updatedOrders],
      },
    }));
  }

  handleDeleteOrderProductEntry = (index) => {
    const { order } = this.state;

    const updatedOrders = [...order.product_entries];
    updatedOrders.splice(index, 1);

    this.setState((prevState) => ({
      order: {
        ...prevState.order,
        product_entries: [...updatedOrders],
      },
    }));
  }

  modifyDataStructure = (obj) => {
    const { locationId } = this.props;
    const modifyedOrder = { ...obj };

    modifyedOrder.location = locationId;

    modifyedOrder.groups[0].items = modifyedOrder.bom_entries;
    delete modifyedOrder.bom_entries;

    modifyedOrder.groups[1].items = modifyedOrder.product_entries;
    delete modifyedOrder.product_entries;

    if (modifyedOrder && modifyedOrder.customer && typeof modifyedOrder.customer === 'object') {
      modifyedOrder.customer = modifyedOrder.customer.id;
    }

    return modifyedOrder;
  }

  render() {
    const { order, isError, isLoadingModal, partners } = this.state;
    const {
      isCreateOrderModalOpen,
      t,
      product_types,
      lines,
      orderTypes,
    } = this.props;

    const sorted_orderTypes = orderTypes.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    // const bom_product_types = product_types.filter(item => item.product_type === 'bom');
    const bom_product_types = product_types.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    // const product_product_types = product_types.filter(item => item.product_type === 'product');
    const product_product_types = product_types.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    if (isLoadingModal) {
      return <ContentLoader />;
    }

    return (
      <Modal
        size={modalSizes.medium}
        isOpen={isCreateOrderModalOpen}
        handleSave={() => this.onSaveOrderModal()}
        handleClose={() => this.onCloseOrderModal()}
        title={t('page_content.orders.create_order')}
        error={isError ? t('page_content.orders.fill_required') : ''}
      >
        <div className="order_list_form">
          <table>
            <tbody>
              <tr>
                <td className="label">
                  {t('page_content.orders.line')}
                </td>
                <td className="value">
                  <select
                    onChange={(e) => { this.onResourceChange('line', e.target.value); }}
                    value={order.line || ''}
                  >
                    <option disabled value="">---</option>
                    {lines.map((line) => (
                      <option key={line.id} value={line.id}>
                        {line.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>

              <tr>
                <td className="label">
                  {t('page_content.orders.order_number')} *
                </td>
                <td className="value-flex">
                  <input
                    type="text"
                    value={order.external_id || ''}
                    onChange={(e) => { this.onResourceChange('external_id', e.target.value); }}
                  />

                  <select
                    onChange={(e) => { this.onResourceChange('order_type', e.target.value); }}
                    value={order.order_type || ''}
                  >
                    <option disabled value="">---</option>
                    {sorted_orderTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>

              <tr>
                <td className="label">
                  {t('page_content.orders.customer')} *
                </td>
                <td className="value">
                  <Select
                    options={partners}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isSearchable
                    placeholder=""
                    menuPosition="fixed"
                    onChange={(customer) => { this.onResourceChange('customer', customer); }}
                    value={order.customer || ''}
                    styles={selectModalStyles}
                  />
                </td>
              </tr>

              <tr>
                <td className="label">
                  {t('page_content.orders.planned_start')}
                </td>
                <td className="value">
                  <ReactDatePicker
                    selected={order.planned_date ? moment(order.planned_date).toDate() : moment().toDate()}
                    onChange={(e) => { this.onResourceChange('planned_date', e); }}
                    showTimeSelect
                    selectsStart
                  />
                </td>
              </tr>

              <tr>
                <td className="label">
                  {t('page_content.orders.comment')}
                </td>
                <td className="value">
                  <textarea
                    type="text"
                    value={order.comment || ''}
                    onChange={(e) => { this.onResourceChange('comment', e.target.value); }}
                  />
                </td>
              </tr>

            </tbody>
          </table>
        </div>

        <Tabs>
          <TabList>
            <Tab>{t('page_content.orders.manufactured_items')}*</Tab>
            <Tab>{t('page_content.orders.bom')}</Tab>
          </TabList>

          <TabPanel>
            <div style={{ padding: '10px' }}>
              <Button
                type="add"
                style={{ marginRight: '10px', marginBottom: '10px' }}
                onClick={this.addProductRow}
              >
                {t('page_content.orders.add_product')}
              </Button>
            </div>

            {
              order && order.product_entries && order.product_entries.length ?
                <table id="create_order_modal_table">
                  <thead>
                    <tr>
                      <th>
                        {t('page_content.orders.product')}
                      </th>
                      <th>
                        {t('page_content.orders.quantity')}
                      </th>
                      <th>
                        {t('page_content.orders.unit')}
                      </th>
                      <th>
                        {t('page_content.orders.delete')}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {order.product_entries.map((row, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <tr key={index}>
                        <td>
                          <Select
                            options={product_product_types}
                            getOptionLabel={(product) => product.name}
                            getOptionValue={(product) => product.id}
                            placeholder="---"
                            menuPosition="fixed"
                            onChange={(value) => this.handleOrderProductEntryChange(index, 'product', value.id)}
                            value={product_product_types.find((d) => d.id === row.product)}
                            styles={createOrderCustom}
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            value={row.quantity}
                            onChange={(e) => this.handleOrderProductEntryChange(index, 'quantity', e.target.value)}
                          />
                        </td>

                        <td>
                          <input
                            name={`product_unit_${index}`}
                            data-unit
                            type="text"
                            value={row.unit || ''}
                            disabled
                            style={{ background: 'white' }}
                          />
                        </td>

                        <td>
                          <Button type="delete" onClick={() => this.handleDeleteOrderProductEntry(index)}><IconRemove width="14px" height="14px" /></Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table> : ''
            }
          </TabPanel>

          <TabPanel>
            <div style={{ padding: '10px' }}>
              <Button
                type="add"
                style={{ marginRight: '10px', marginBottom: '10px' }}
                onClick={this.addBomRow}
              >
                {t('page_content.orders.add_bom')}
              </Button>
            </div>

            {
              order && order.bom_entries && order.bom_entries.length ?
                <table id="create_order_modal_table">
                  <thead>
                    <tr>
                      <th>
                        {t('page_content.orders.product')}
                      </th>
                      <th>
                        {t('page_content.orders.quantity')}
                      </th>
                      <th>
                        {t('page_content.orders.unit')}
                      </th>
                      <th>
                        {t('page_content.orders.delete')}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {order.bom_entries.map((row, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <tr key={index}>
                        <td>
                          <Select
                            options={bom_product_types}
                            getOptionLabel={(product) => product.name}
                            getOptionValue={(product) => product.id}
                            placeholder="---"
                            menuPosition="fixed"
                            onChange={(value) => this.handleOrderBomEntryChange(index, 'product', value.id)}
                            value={bom_product_types.find((d) => d.id === row.product)}
                            styles={createOrderCustom}
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            value={row.quantity}
                            onChange={(e) => this.handleOrderBomEntryChange(index, 'quantity', e.target.value)}
                          />
                        </td>

                        <td>
                          <input
                            name={`bom_unit_${index}`}
                            type="text"
                            value={row.unit || ''}
                            disabled
                            style={{ background: 'white' }}
                          />
                        </td>

                        <td>
                          <Button type="delete" onClick={() => this.handleDeleteOrderBomEntry(index)}><IconRemove width="14px" height="14px" /></Button>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table> : ''
            }
          </TabPanel>
        </Tabs>
      </Modal>
    );
  }
}

CreateOrderModal.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isCreateOrderModalOpen: PropTypes.bool.isRequired,
  onSaveOrderModal: PropTypes.func.isRequired,
  onCloseOrderModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  lines: PropTypes.array.isRequired,
  product_types: PropTypes.array.isRequired,
  orderTypes: PropTypes.array.isRequired,
};

export default CreateOrderModal;
