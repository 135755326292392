import React from 'react';

import IndividualGoalsTable from './IndividualGoalsTable';
import IndividualScoresTable from './IndividualScoresTable';

const IndividualGoals = () => {
  return (
    <div className="individual_goals_tab">
      <IndividualGoalsTable />
      <IndividualScoresTable />
    </div>
  );
};

export default IndividualGoals;
