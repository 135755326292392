import React, { Component } from 'react';
import { get, isString } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { IconSearch } from 'shared/Icons';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import { Button, Table, TableButtons } from 'shared';
import './styles.scss';

import { checkAccessOnPage, redirectToHomePage, checkModules } from 'industry/helpers';

import { getIndustryDevices, getIndustryDevicesBlob, getByURL } from 'shared/Api';
import { addOrUpdateDevice, setDevices } from './actions';

import { DeviceForm } from './components';

class IndustryLocationDeviceList extends Component {
  constructor(props) {
    super(props);

    let isReadOnly = false;

    if (window.resourceReadOnly && window.resourceReadOnly.length) {
      window.resourceReadOnly.forEach((resource) => {
        if (window.location.href.includes(resource)) {
          isReadOnly = true;
        }
      });
    }

    this.state = {
      activeQuery: '',
      isLoadingDevices: true,
      showDetails: false,
      showDeviceForm: false,
      selectedDevice: null,
      isReadOnly,
      count: null,
      next: null,
      previous: null,
      selectedAscDesc: 'asc',
      selectedSort: 'name',
    };
    this.handleClear = this.handleClear.bind(this);
    this.handleShowFilter = this.handleShowFilter.bind(this);
    this.toggleDetailWindow = this.toggleDetailWindow.bind(this);
    this.handleFormToggle = this.handleFormToggle.bind(this);
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Devices');
        if ((module && !module.is_active) || !module) redirectToHomePage(companyId, locationId);
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) redirectToHomePage(companyId, locationId);
        else if (access === 1) this.setState({ isReadOnly: true });
      });
    this.getDevices();
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.getDevices();
    });
  }

  handleClear() {
    this.setState({ activeQuery: '' });
  }

  handleShowFilter() {
    this.setState((prevState) => ({ showFilter: !prevState.showFilter }));
  }

  exportToExcel = () => {
    const { companyId, locationId } = this.props;
    const { count } = this.state;

    getIndustryDevicesBlob(`?location=${locationId}&company=${companyId}&format=xlsx&limit=${count || 99999}`)
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Device list.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  getDevices = () => {
    const { companyId, locationId } = this.props;
    const { selectedAscDesc, selectedSort } = this.state;

    let apiFilters = `?location=${locationId}&company=${companyId}`;

    this.setState({ isLoadingDevices: true });

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    apiFilters += `&order_by=${asc}${selectedSort}`;

    getIndustryDevices(apiFilters)
      .then((res) => {
        this.setState({
          count: get(res, 'data.count', 0),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          isLoadingDevices: false,
        });
        this.props.setDevices(get(res, 'data.results'));
      });
  }

  toggleDetailWindow(id) {
    this.setState({ showDetails: id });
  }

  handleAddDevice = () => {
    this.setState({ showDeviceForm: true, editingDeviceId: null });
  }

  handleModalClose = () => {
    this.setState({ showDeviceForm: false, editingDeviceId: null });
  }

  handleFormToggle = (value) => {
    this.setState({ showDeviceForm: value });
  }

  handleEditDevice = (id, device) => {
    this.setState({
      selectedDevice: device,
      showDeviceForm: true,
      editingDeviceId: id,
    });
  }

  handleClear = () => {
    this.setState({ activeQuery: '' });
  }

  handleSubmit = (e) => {
    this.setState({ activeQuery: e });
  }

  fetchData = (url) => {
    this.setState({ isLoadingDevices: true });

    getByURL(url)
      .then((res) => {
        this.setState({
          count: get(res, 'data.count', 0),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          isLoadingDevices: false,
        });
        this.props.setDevices(get(res, 'data.results'));
      });
  };

  filterDevices = (devices, query) => {
    if (!query) return devices;

    return devices.filter((device) => {
      return (isString(device.external_id) && device.external_id.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
        (isString(device.device_type) && device.device_type.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    });
  };

  render() {
    const { location, devices, companyId, t } = this.props;

    const {
      activeQuery,
      editingDeviceId,
      isLoadingDevices,
      showDeviceForm,
      selectedDevice,
      isReadOnly,
      next,
      previous,
      count,
    } = this.state;

    return (
      <div className="industry-location-device-list fullscreen">
        <div className="device_list_toolbar_area">
          <div className="input_container">
            <input onChange={(e) => this.handleSubmit(e.target.value)} placeholder={t('page_content.devices.search_placeholder')} value={activeQuery} />
            {activeQuery && <button
              onClick={() => {
                this.handleClear();
              }}
            >&times;</button>}
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <div className="buttons_wrapper">
            <Button
              onClick={this.handleAddDevice}
              title="Add device"
              disabled={isReadOnly}
              type="add"
            >
              {t('page_content.devices.add_device_button')}
            </Button>
            <Button
              onClick={this.exportToExcel}
              type="export"
              style={{ marginLeft: '10px' }}
            >{t('page_content.devices.export_to_excel_button')}</Button>
          </div>
        </div>
        <main>
          <Table
            columns={[
              {
                Header: () => <span>{t('page_content.devices.table_column_serial_number')}</span>,
                accessor: 'external_id',
                Cell: (row) => row.value || '-',
                style: { cursor: 'default' },
              },
              {
                Header: () => <span>{t('page_content.devices.table_column_name')}</span>,
                accessor: 'name',
                Cell: (row) => row.value || '-',
                style: { cursor: 'default' },
              },
              {
                Header: () => <span>{t('page_content.devices.table_column_device_type')}</span>,
                accessor: 'device_type',
                Cell: (row) => (typeof row.value === 'object'
                  ? `${row.value.model} [${row.value.manufacturer}]` : row.value) || '-',
                style: { cursor: 'default' },
              },
              {
                Header: () => <span>{t('page_content.devices.table_column_ip')}</span>,
                accessor: 'ip_address',
                Cell: (row) => row.value || '-',
                style: { cursor: 'default' },
              },
              {
                Header: () => <span>{t('page_content.devices.table_column_in_use')}</span>,
                accessor: 'is_deleted',
                width: 100,
                Cell: (row) => {
                  return typeof row.value === 'undefined' ? '-'
                    : row.value ?
                      <img src={checkMarkTrue} width="18px" height="18px" alt="" />
                      :
                      <img src={checkMarkFalse} width="18px" height="18px" alt="" />;
                },
                style: { cursor: 'default', alignItems: 'center' },
              },
              {
                Header: () => <span>{t('page_content.devices.table_column_comment')}</span>,
                accessor: 'note',
                style: { cursor: 'default' },
              },
            ]}
            data={this.filterDevices(devices, activeQuery)}
            minRows={0}
            loading={isLoadingDevices}
            noDataText=" "
            enableEdit={!isReadOnly}
            onEdit={(original) => this.handleEditDevice(original.id, original)}
            defaultSorted={[{ id: 'name', desc: false }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchData} count={count} />
        </main>
        {
          showDeviceForm &&
          <DeviceForm
            device={selectedDevice}
            onClose={() => this.handleModalClose()}
            id={editingDeviceId}
            addOrUpdateDevice={this.props.addOrUpdateDevice}
            location={location}
            isReadOnly={isReadOnly}
            companyId={companyId}
          />
        }
      </div>
    );
  }
}

IndustryLocationDeviceList.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  addOrUpdateDevice: PropTypes.func.isRequired,
  devices: PropTypes.array,
  location: PropTypes.any,
  setDevices: PropTypes.func,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
  devices: state.industry.container.devices,
});

const mapDispatchToProps = {
  addOrUpdateDevice,
  setDevices,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IndustryLocationDeviceList));
