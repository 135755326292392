import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { ContentLoader, DeviceTypePicker, Modal } from 'shared';
import './styles.scss';

import { postIndustryDevice, patchIndustryDevice } from 'shared/Api';

class DeviceForm extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
    };
  }

  render() {
    const {
      id,
      onClose,
      addOrUpdateDevice,
      isReadOnly,
      companyId,
      t,
    } = this.props;

    const {
      isLoading,
    } = this.state;

    let deviceType = null;
    let name = '';
    let note = '';
    let external_id = null;
    let ip_address = '';

    if (id) {
      deviceType = this.props.device.device_type;
      external_id = this.props.device.external_id;
      name = this.props.device.name;
      note = this.props.device.note;
      ip_address = this.props.device.ip_address;
    }

    return (
      <Formik
        enableReinitialize
        initialValues={{
          name,
          deviceType,
          note,
          external_id,
          ip_address,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.deviceType) errors.deviceType = 'Required';
          return errors;
        }}
        onSubmit={(values) => {
          if (isReadOnly) {
            alert('You do not have access to creating or changing devices');
            return;
          }

          this.setState({ isLoading: true });

          const data = {
            external_id: values.external_id || '',
            name: values.name,
            device_type: values.deviceType && values.deviceType.id ? values.deviceType.id : values.deviceType,
            ip_address: values.ip_address || '',
            note: values.note || '',
          };

          const locationId = window.location.href.split('/location/')[1].split('/')[0];

          if (locationId) data.location = locationId;

          if (id) {
            patchIndustryDevice(id, `?location=${locationId}`, data)
              .then((res) => {
                onClose();
                addOrUpdateDevice(res.data);
              });
          } else {
            postIndustryDevice(`?location=${locationId}`, data)
              .then((res) => {
                addOrUpdateDevice(res.data);
                onClose();
                this.setState({ isLoading: false });
              });
          }
        }}
      >
        {
          ({
            errors,
            submitForm,
            submitCount,
            isValid,
            values,
            setFieldValue,
          }) => (
            <Form ref={this.formRef}>
              <Modal
                error={(submitCount > 0 && !isValid) ? 'Please fill in all the fields' : ''}
                handleClose={onClose}
                handleSave={submitForm}
                isOpen
                style={{ content: { padding: '0', top: '20px', left: '0', right: '0', bottom: 'initial' } }}
                title={id && typeof id === 'number' ? t('page_content.devices.modal.edit_title') : t('page_content.devices.modal.add_title')}
              >
                {
                  isLoading &&
                  <ContentLoader />
                }
                {
                  !isLoading &&
                  <div className="device-form">
                    <Tabs>
                      <TabList>
                        <Tab>
                          {t('page_content.devices.modal.tabs.details')}
                        </Tab>
                      </TabList>
                      <TabPanel>
                        <div className="drone-details default-form">
                          <table>
                            <tbody>
                              <tr>
                                <td className="label">
                                  <label htmlFor="name">
                                    {t('page_content.devices.modal.name')}
                                  </label>
                                </td>
                                <td className="input">
                                  {
                                    errors.name ?
                                      <Field
                                        name="name"
                                        type="text"
                                        className="field-error"
                                      /> :
                                      <Field
                                        name="name"
                                        type="text"
                                        disabled={isReadOnly}
                                      />
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td className="label">
                                  <label htmlFor="type">
                                    {t('page_content.devices.modal.type')}
                                  </label>
                                </td>
                                <td className="input">
                                  {
                                    errors.deviceType ?
                                      <DeviceTypePicker
                                        deviceType={values.deviceType}
                                        onChange={(dType) => setFieldValue('deviceType', dType.id)}
                                        customClassName="field-error"
                                        companyId={companyId}
                                      /> :
                                      <DeviceTypePicker
                                        deviceType={values.deviceType}
                                        onChange={(dType) => setFieldValue('deviceType', dType.id)}
                                        disabled={isReadOnly}
                                        companyId={companyId}
                                      />
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label htmlFor="ip_address">
                                    {t('page_content.devices.modal.ip_address')}
                                  </label>
                                </td>
                                <td>
                                  <Field
                                    name="ip_address"
                                    type="text"
                                    disabled={isReadOnly}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label htmlFor="note">
                                    {t('page_content.devices.modal.comment')}
                                  </label>
                                </td>
                                <td>
                                  <Field
                                    name="note"
                                    type="text"
                                    disabled={isReadOnly}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                }
              </Modal>
            </Form>
          )
        }
      </Formik>
    );
  }
}

DeviceForm.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
  addOrUpdateDevice: PropTypes.func.isRequired,
  device: PropTypes.object,
  isReadOnly: PropTypes.bool.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  t: PropTypes.func.isRequired,
};

DeviceForm.defaultProps = {
  metrics: [],
  external_id: '',
  type: '',
  note: '',
  onClose: () => { },
  location: null,
  device: { external_id: null, device_type: null },
};

export default withTranslation()(DeviceForm);
