import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import CreatableSelect from 'react-select/creatable';

import { multiSelectStylesAutoHeight } from 'styles/modules/reactSelect';

const createOption = (label) => ({
  label,
  value: label,
  id: uniqueId(), // This will create unique IDs like '1', '2', '5', etc.
});

const ReactSelectCreatable = ({
  placeholder,
  initialValue,
  onChange,
  className,
  styles,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(initialValue?.map((item) => createOption(item)));

  const handleKeyDown = (e) => {
    if (!inputValue) return;
    switch (e.key) {
    // If the user presses Enter or Tab, add the new option
      case 'Enter':
      case 'Tab':
        const newValue = [...value, createOption(inputValue)];
        setValue(newValue);
        onChange(newValue.map((item) => item.value));
        setInputValue('');
        e.preventDefault();
        break;

      default:
        break;
    }
  };

  const handleChange = (newValue) => {
    const updatedValue = newValue || [];
    setValue(updatedValue);
    onChange(updatedValue.map((item) => item.value));
  };

  return (
    <CreatableSelect
      className={className}
      getOptionValue={(option) => option.id}
      components={{ DropdownIndicator: null }}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      value={value}
      styles={styles || multiSelectStylesAutoHeight}
    />
  );
};

ReactSelectCreatable.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  initialValue: PropTypes.arrayOf(PropTypes.string),
  styles: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

ReactSelectCreatable.defaultProps = {
  placeholder: '',
  initialValue: [],
  className: '',
  styles: null,
};

export default ReactSelectCreatable;
