import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Button, Table, Notification, TableButtons } from 'shared';
import './style.scss';

import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';
import { getIndustryAssetUtilizations, postIndustryAssetUtilization, putIndustryAssetUtilization } from 'shared/Api';

import AssetUtilizationsForm from './components/AssetUtilizationsForm/index';

class AssetUtilizationTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetUtilizations: [],
      next: null,
      previous: null,
      count: 0,
      isLoadingAssetUtilizations: true,
      isAssetUtilizationsFormOpen: false,
      selectedAssetUtilization: null,
      isSavingAssetUtilization: false,
      selectedSort: 'name',
      selectedAscDesc: 'desc',
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToSettingsPage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    this.fetchData();
  }

  handleAssetUtilizationsTableRowClick = (auType) => {
    const { isReadOnly } = this.state;
    if (!isReadOnly) {
      this.setState({
        selectedAssetUtilization: auType,
      }, () => {
        this.openAssetUtilizationsForm();
      });
    }
  }

  openAssetUtilizationsForm = () => {
    this.setState({
      isAssetUtilizationsFormOpen: true,
    });
  }

  closeAssetUtilizationsFormModal = () => {
    this.setState({
      isAssetUtilizationsFormOpen: false,
      selectedAssetUtilization: null,
    });
  }

  saveAssetUtilizations = (assetUtilization) => {
    const { companyId } = this.props;
    this.setState({
      isSavingAssetUtilization: true,
    });

    assetUtilization.company = companyId;
    const filters = `?company=${companyId}`;

    if (assetUtilization?.id) {
      putIndustryAssetUtilization(assetUtilization.id, `?company=${companyId}`, assetUtilization)
        .then(() => {
          getIndustryAssetUtilizations(filters)
            .then((res) => {
              this.setState({
                assetUtilizations: [],
              }, () => {
                this.setState({
                  assetUtilizations: get(res, 'data.results'),
                });
              });
            }).catch((error) => {
              console.log('error fetching timing settings', error);
            });
          this.setState({
            isAssetUtilizationsFormOpen: false,
            selectedAssetUtilization: null,
          });
        });
    } else {
      postIndustryAssetUtilization(`?company=${companyId}`, assetUtilization)
        .then(() => {
          getIndustryAssetUtilizations(filters)
            .then((res) => {
              this.setState({
                assetUtilizations: [],
              }, () => {
                this.setState({
                  assetUtilizations: get(res, 'data.results'),
                });
              });
            }).catch((error) => {
              console.log('error fetching timing settings', error);
            });
          this.setState({
            isAssetUtilizationsFormOpen: false,
            selectedAssetUtilization: null,
          });
        });
    }
  }

  fetchData = () => {
    const { companyId } = this.props;
    const { selectedAscDesc, selectedSort } = this.state;
    this.setState({
      isSavingAssetUtilization: true,
    });
    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const filters = `?company=${companyId}&orderBy=${asc}${selectedSort}`;
    getIndustryAssetUtilizations(filters)
      .then((re) => {
        const assetUtilizations = get(re, 'data.results');
        this.setState({
          assetUtilizations,
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isLoadingAssetUtilizations: false,
        });
      }).catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  };

  handleSorting = (sortData) => {
    this.setState({ isLoadingAssetUtilizations: true });
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchData();
    });
  }

  render() {
    const {
      assetUtilizations,
      isLoadingAssetUtilizations,
      isAssetUtilizationsFormOpen,
      isSavingAssetUtilization,
      selectedAssetUtilization,
      isReadOnly,
      next,
      previous,
      count,
    } = this.state;
    const {
      companyId,
      locationId,
      t,
    } = this.props;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }} className="default-form assets-utilization-panel">
        <Button
          style={{ width: '200px' }}
          type="add"
          onClick={this.openAssetUtilizationsForm}
          disabled={isReadOnly}
        >
          {t('settings.asset_utilization.add_asset_utilization_button')}
        </Button>
        <div>&nbsp;</div>
        <div>
          <Table
            style={{ userSelect: 'text' }}
            columns={[
              {
                Header: () => <span>{t('settings.asset_utilization.table_column_name')}</span>,
                accessor: 'name',
              },
            ]}
            data={assetUtilizations}
            loading={isLoadingAssetUtilizations}
            minRows={0}
            noDataText=" "
            defaultSorted={[{ id: 'name', desc: true }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
            showPagination={false}
            defaultPageSize={100}
            handleClick={(rowInfo) => this.handleAssetUtilizationsTableRowClick(get(rowInfo, 'original'))}
          />
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchData} count={count} />
        </div>
        {
          isAssetUtilizationsFormOpen && <AssetUtilizationsForm
            closeModal={this.closeAssetUtilizationsFormModal}
            isOpen={isAssetUtilizationsFormOpen}
            isSavingAssetUtilization={isSavingAssetUtilization}
            onSave={this.saveAssetUtilizations}
            assetUtilization={selectedAssetUtilization || null}
            locationId={locationId}
            companyId={companyId}
          />
        }
      </div>);
  }
}

AssetUtilizationTab.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AssetUtilizationTab);
