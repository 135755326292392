import { getByURL, postAndNotifyByURL, patchAndNotifyByURL, deleteAndNotifyByURL } from './CustomService';

export const getProductGroups = async (filters = '') => {
  return getByURL(`/api/v1/product_groups/${filters}`);
};

export const postProductGroup = async (data) => {
  return postAndNotifyByURL('/api/v1/product_groups/', data);
};

export const patchProductGroup = async (id, filters = '', data) => {
  return patchAndNotifyByURL(`/api/v1/product_groups/${id}/${filters}`, data);
};

export const deleteProductGroup = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/product_groups/${id}/${filters}`);
};
