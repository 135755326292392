import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import {
  ContentLoader,
  Modal,
  Notification,
  ReactDatePicker,
} from 'shared';

import { getShifts } from '../../actions';
import { timeRanges } from '../../constants';

class ReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      report: {
        name: '',
        code: '',
        is_active: 1,
        time_range: 'last_month',
        time_from: null,
        time_to: null,
        multiplication_factor: null,
        errors: [],
        shift: null,
      },
      shifts_list: [],
      isLoading: false,
    };

    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    const { locationId } = this.props;
    // document.addEventListener('mousedown', this.handleClickOutside, false);
    getShifts(locationId)
      .then((re) => {
        this.setState({
          shifts_list: (re && re.data && re.data.results) ? re.data.results : [],
        });
      });
    this.getReport();
  }

  getReport = () => {
    const {
      reportId,
      companyId,
    } = this.props;

    if (reportId > 0) {
      this.setState({ isLoading: true });
      api
        .get(`/api/v1/reports/reports/${reportId}/?company=${companyId}`)
        .then(({ data }) => {
          this.setState({
            isLoading: false,
            report: data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  handleSave() {
    const { report } = this.state;
    const {
      reportId,
      companyId,
      t,
    } = this.props;

    report.company = companyId;

    if (reportId > 0) {
      api.patch(`/api/v1/reports/reports/${reportId}/?company=${companyId}`, report)
        .then(() => {
          this.handleClose(true);
          return Notification('success', t('page_content.automatic_reports.reports.success_update'));
        })
        .catch(() => {
          return Notification('error', 'An error occurred', t('page_content.automatic_reports.request_error'));
        });
      // eslint-disable-next-line
    } else {
      api.post('/api/v1/reports/reports/', report)
        .then(() => {
          this.handleClose(true);
          return Notification('success', t('page_content.automatic_reports.reports.success_create'));
        })
        .catch(() => {
          return Notification('error', 'An error occurred', t('page_content.automatic_reports.request_error'));
        });
    }
  }

  changeFactor = (e) => {
    this.updateField('multiplication_factor', e.target.value);
  }

  changeTimeRange = (e) => {
    this.updateField('time_range', e.target.value);
  }

  updateField = (field, value) => {
    const { report } = this.state;
    report[field] = value;
    this.setState({ report });
  }

  updateTimeFrom = (timeFrom) => {
    this.updateField('time_from', timeFrom);
  }

  updateTimeTo = (timeTo) => {
    this.updateField('time_to', timeTo);
  }

  handleClickOutside = (e) => {
    if (this.node.contains(e.target) || e.target.classList.contains('rt-td')) {
      return;
    }
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    this.props.handleClose(false);
  }

  handleClose = (shouldReload) => {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    this.props.handleClose(shouldReload);
  }

  changeShift = (e) => {
    this.updateField('shift', e.target.value);
  }

  render() {
    const {
      isLoading,
      report,
      shifts_list,
    } = this.state;

    const {
      reportId,
      factors,
      t,
    } = this.props;

    const modalTitle = (reportId > 0)
      ? t('page_content.automatic_reports.reports.edit')
      : t('page_content.automatic_reports.reports.create');

    const timeFrom = report.time_from ? moment(report.time_from).toDate() : '';
    const timeTo = report.time_to ? moment(report.time_to).toDate() : '';

    return (
      // eslint-disable-next-line no-return-assign
      <div ref={(node) => (this.node = node)}>
        <Modal
          handleClose={this.props.handleClose}
          handleSave={this.handleSave}
          saveText={modalTitle}
          isOpen
          style={{ content: { padding: '0', top: '20px', left: '0', right: '0', bottom: 'initial' } }}
          title={modalTitle}
        >
          {
            isLoading &&
            <ContentLoader />
          }
          {!isLoading &&
            <div className="default-form">
              <table>
                <tbody>
                  <tr>
                    <td className="label">{t('page_content.automatic_reports.name')}</td>
                    <td className="input"><input
                      id="name"
                      onChange={(e) => this.updateField('name', e.target.value)}
                      type="text"
                      value={report.name}
                    />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">{t('page_content.automatic_reports.code')}</td>
                    <td className="input"><input
                      id="code"
                      onChange={(e) => this.updateField('code', e.target.value)}
                      type="text"
                      value={report.code}
                    /></td>
                  </tr>
                  <tr>
                    <td className="label">{t('page_content.automatic_reports.active')}</td>
                    <td className="input"><input
                      id="is_active"
                      onChange={(e) => this.updateField('is_active', e.target.checked)}
                      type="checkbox"
                      checked={report.is_active}
                    />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">{t('page_content.automatic_reports.select_factor')}</td>
                    <td className="input"><select onChange={this.changeFactor} className="">
                      <option value="" selected>{t('page_content.automatic_reports.select_factor')}</option>
                      {factors.map((factor) => (
                        <option key={factor.id} value={factor.id} selected={(report.multiplication_factor) ? report.multiplication_factor === factor.id : false}>
                          {factor.name}
                        </option>
                      ))}
                    </select></td>
                  </tr>
                  <tr>
                    <td className="label">{t('page_content.automatic_reports.factor_description')}</td>
                    <td className="input"><textarea
                      id="factor_description"
                      onChange={(e) => this.updateField('factor_description', e.target.value)}
                    >{report.factor_description}</textarea></td>
                  </tr>
                  <tr>
                    <td className="label">PDF</td>
                    <td className="input"><input
                      id="format_pdf"
                      onChange={(e) => this.updateField('format_pdf', e.target.checked)}
                      type="checkbox"
                      checked={report.format_pdf}
                    /></td>
                  </tr>
                  <tr>
                    <td className="label">CSV</td>
                    <td className="input"><input
                      id="format_csv"
                      onChange={(e) => this.updateField('format_csv', e.target.checked)}
                      type="checkbox"
                      checked={report.format_csv}
                    /></td>
                  </tr>
                  <tr>
                    <td className="label">XLS</td>
                    <td className="input"><input
                      id="format_xls"
                      onChange={(e) => this.updateField('format_xls', e.target.checked)}
                      type="checkbox"
                      checked={report.format_xls}
                    /></td>
                  </tr>
                  <tr>
                    <td className="label">{t('page_content.automatic_reports.select_timerange')}</td>
                    <td className="input"><select onChange={this.changeTimeRange} className="">
                      <option value={null} disabled selected>{t('page_content.automatic_reports.select_timerange')}</option>
                      {timeRanges.map((timeRange, index) => (
                        <option key={index} value={timeRange} selected={(report.time_range) ? report.time_range === timeRange : false}>
                          {t(`page_content.automatic_reports.time_ranges.${timeRange}`)}
                        </option>
                      ))}
                    </select></td>
                  </tr>
                  {report.time_range === 'custom_range' && <Fragment>
                    <tr>
                      <td className="label">{t('page_content.automatic_reports.from')}</td>
                      <td className="input"><ReactDatePicker
                        placeholderText={t('page_content.automatic_reports.from')}
                        selected={timeFrom}
                        selectsStart
                        onChange={this.updateTimeFrom}
                        timeFormat="HH:mm:ss"
                        showTimeSelect
                        timeCaption={t('date_picker_locale.time_translation')}
                      /></td>
                    </tr>
                    <tr>
                      <td className="label">{t('page_content.automatic_reports.to')}</td>
                      <td className="input"><ReactDatePicker
                        placeholderText={t('page_content.automatic_reports.to')}
                        selected={timeTo}
                        selectsEnd
                        onChange={this.updateTimeTo}
                        timeFormat="HH:mm:ss"
                        showTimeSelect
                        timeCaption={t('date_picker_locale.time_translation')}
                      /></td>
                    </tr>
                  </Fragment>}
                  <tr>
                    <td className="label">{t('page_content.automatic_reports.select_shift')}</td>
                    <td className="input"><select onChange={this.changeShift} className="">
                      <option value="" selected>{t('page_content.automatic_reports.select_shift')}</option>
                      {shifts_list && shifts_list.map((s) => (
                        <option key={s.id} value={s.id} selected={report.shift}>
                          {s.name}
                        </option>
                      ))}
                    </select></td>
                  </tr>
                  <tr>
                    <td className="label">{t('page_content.automatic_reports.group_by_metric')}</td>
                    <td className="input"><input
                      id="group_by_metric"
                      onChange={(e) => this.updateField('group_by_metric', e.target.checked)}
                      type="checkbox"
                      checked={report.group_by_metric}
                    /></td>
                  </tr>
                </tbody>
              </table>
            </div>}
        </Modal>
      </div>
    );
  }
}

ReportForm.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  reportId: PropTypes.number.isRequired,
  factors: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ReportForm);
