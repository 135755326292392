import axios from 'axios';
import { Notification } from 'shared';
import moment from 'moment';

export const getData = ({
  from,
  to,
  axiosSource,
  operation,
  groupBy,
  id,
  limit,
  fetchEvents,
  adjusted_value,
  where,
  gapFill,
  shift,
  productType,
  widgetTitle,
  datapointLabel,
  idx,
  textMetric,
}) => {
  let title = '';
  if (widgetTitle) {
    const noSpacesWidgetTitle = widgetTitle.replaceAll(' ', '_');
    title = `&title=${noSpacesWidgetTitle}`;
  }

  let label = '';
  if (datapointLabel) {
    const noSpacesDatapointLabel = datapointLabel.replaceAll(' ', '_');
    label = `&datapointLabel=${noSpacesDatapointLabel}`;
  }
  const eventsQs = fetchEvents
    ? '&fetch_events=true'
    : '';

  if (adjusted_value && adjusted_value.length && Array.isArray(adjusted_value)) {
    adjusted_value = (typeof idx === 'number')
      ? adjusted_value.find((aV) => aV.metricId === (textMetric || id) && idx === aV.indexData)
      : adjusted_value.find((aV) => aV.metricId === (textMetric || id));
    if (adjusted_value) {
      adjusted_value = encodeURIComponent(adjusted_value.value);
    } else {
      adjusted_value = null;
    }
  }

  if (shift && shift.length) {
    shift = (typeof idx === 'number')
      ? shift.find((s) => s.id === (textMetric || id) && idx === s.indexData)
      : shift.find((s) => s.id === (textMetric || id));
    if (shift && shift.value) {
      shift = `&shift=${parseInt(shift.value, 10)}`;
    } else {
      shift = '';
    }
  } else {
    shift = '';
  }

  if (productType && productType.length) {
    productType = (typeof idx === 'number')
      ? productType.find((pT) => pT.id === (textMetric || id) && idx === pT.indexData)
      : productType.find((pT) => pT.id === (textMetric || id));
    if (productType && productType.value) {
      productType = `&product_type=${parseInt(productType.value, 10)}`;
    } else {
      productType = '';
    }
  } else {
    productType = '';
  }

  let filters = '';

  if (where && where.length && Array.isArray(where)) {
    where = (typeof idx === 'number')
      ? where.find((w) => w.metricId === (textMetric || id) && idx === w.indexData)
      : where.find((w) => w.metricId === (textMetric || id));
    if (where && where.value) {
      const whereParts = where.value.split(',');
      filters = '&filters=';

      whereParts.forEach((wP, i) => {
        if (wP.includes('larger than or equal')) {
          const splitValue = wP.split(' ');
          const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
          const comma = i > 0 ? ',' : '';
          filters = `${filters}${comma}value__gte=${valueNum}`;
        } else if (wP.includes('larger')) {
          const splitValue = wP.split(' ');
          const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
          const comma = i > 0 ? ',' : '';
          filters = `${filters}${comma}value__gt=${valueNum}`;
        } else if (wP.includes('less than or equal')) {
          const splitValue = wP.split(' ');
          const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
          const comma = i > 0 ? ',' : '';
          filters = `${filters}${comma}value__lte=${valueNum}`;
        } else {
          const splitValue = wP.split(' ');
          const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
          const comma = i > 0 ? ',' : '';
          filters = `${filters}${comma}value__lt=${valueNum}`;
        }
      });
    } else {
      where = null;
    }
  }

  // const gapFillPart = (gapFill && gapFill[id]) ? '&gapfill=true' : '';
  const gapFillPart = (gapFill) ? '&gapfill=true' : '';

  return axios
    .get(`/api/v1/industry/datapoints/?group_by=${groupBy}&operation=${operation}&metric__in=${id}&from=${from}&to=${to}${eventsQs}&limit=${limit || ''}&adjusted_value=${adjusted_value}${gapFillPart}${filters}${productType}${shift}${title}${label}`, {
      cancelToken: axiosSource.source,
    })
    .then((response) => {
      if (response.status === undefined || response.status === 400 || response.status === 403 || response.status === 404 || response.status === 500 || !response.data) {
        return Notification('error', 'An error occurred while fetching data', 'We could not perform your request, please try again.');
      }
      return response.data;
    });
};

export const getProductionData = ({
  from,
  to,
  axiosSource,
  operation,
  groupBy,
  id,
  limit,
  fetchEvents,
  adjusted_value,
  where,
  shift,
  productType,
  widgetTitle,
  gapFill,
  datapointLabel,
  idx,
  globalFilters,
  workOperation,
  textMetric,
}) => {
  let title = '';
  if (widgetTitle) {
    const noSpacesWidgetTitle = widgetTitle.replaceAll(' ', '_');
    title = `&title=${noSpacesWidgetTitle}`;
  }

  let order = '';
  if (globalFilters && globalFilters.order) {
    order = `&order_id=${globalFilters.order}`;
  }

  let label = '';
  if (datapointLabel) {
    const noSpacesDatapointLabel = datapointLabel.replaceAll(' ', '_');
    label = `&datapointLabel=${noSpacesDatapointLabel}`;
  }
  const eventsQs = fetchEvents
    ? '&fetch_events=true'
    : '';

  if (adjusted_value && adjusted_value.length && Array.isArray(adjusted_value)) {
    adjusted_value = (typeof idx === 'number')
      ? adjusted_value.find((aV) => aV.metricId === (textMetric || id) && idx === aV.indexData)
      : adjusted_value.find((aV) => aV.metricId === (textMetric || id));
    if (adjusted_value) {
      adjusted_value = encodeURIComponent(adjusted_value.value);
    } else {
      adjusted_value = '';
    }
  }

  if (shift && shift.length) {
    shift = (typeof idx === 'number')
      ? shift.find((s) => s.id === (textMetric || id) && idx === s.indexData)
      : shift.find((s) => s.id === (textMetric || id));
    if (shift && shift.value) {
      shift = `&shift=${parseInt(shift.value, 10)}`;
    } else {
      shift = '';
    }
  } else {
    shift = '';
  }

  if (productType && productType.length) {
    productType = (typeof idx === 'number')
      ? productType.find((pT) => pT.id === (textMetric || id) && idx === pT.indexData)
      : productType.find((pT) => pT.id === (textMetric || id));
    if (productType && productType.value) {
      productType = `&product_type=${parseInt(productType.value, 10)}`;
    } else {
      productType = '';
    }
  } else {
    productType = '';
  }

  if (workOperation && workOperation.length) {
    workOperation = (typeof idx === 'number')
      ? workOperation.find((pT) => pT.id === (textMetric || id) && idx === pT.indexData)
      : workOperation.find((pT) => pT.id === (textMetric || id));
    if (workOperation && workOperation.value) {
      workOperation = `&work_operation_id=${parseInt(workOperation.value, 10)}`;
    } else {
      workOperation = '';
    }
  } else {
    workOperation = '';
  }

  // let adjValue = '';
  // if (adjusted_value) {
  //   adjValue = `&adjusted_value=${adjusted_value}`;
  // }

  let filters = '';

  if (where && where.length && Array.isArray(where)) {
    where = (typeof idx === 'number')
      ? where.find((w) => w.metricId === (textMetric || id) && idx === w.indexData)
      : where.find((w) => w.metricId === (textMetric || id));
    if (where && where.value) {
      const whereParts = where.value.split(',');
      filters = '&filters=';

      whereParts.forEach((wP, i) => {
        if (wP.includes('larger than or equal')) {
          const splitValue = wP.split(' ');
          const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
          const comma = i > 0 ? ',' : '';
          filters = `${filters}${comma}value__gte=${valueNum}`;
        } else if (wP.includes('larger')) {
          const splitValue = wP.split(' ');
          const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
          const comma = i > 0 ? ',' : '';
          filters = `${filters}${comma}value__gt=${valueNum}`;
        } else if (wP.includes('less than or equal')) {
          const splitValue = wP.split(' ');
          const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
          const comma = i > 0 ? ',' : '';
          filters = `${filters}${comma}value__lte=${valueNum}`;
        } else {
          const splitValue = wP.split(' ');
          const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
          const comma = i > 0 ? ',' : '';
          filters = `${filters}${comma}value__lt=${valueNum}`;
        }
      });
    } else {
      where = null;
    }
  }

  const gapFillPart = (gapFill) ? '&gapfill=true' : '';

  return axios
    .get(`/api/v1/industry/production_data/?group_by=${groupBy}&operation=${operation}&metric__in=${id}&from=${from}&to=${to}${eventsQs}&limit=${limit || ''}${gapFillPart}&adjusted_value=${adjusted_value}${title}${productType}${shift}${label}${filters}${order}${workOperation}`, {
      cancelToken: axiosSource.source,
    })
    .then((response) => {
      if (response.status === undefined || response.status === 400 || response.status === 403 || response.status === 404 || response.status === 500 || !response.data) {
        return Notification('error', 'An error occurred while fetching data', 'We could not perform your request, please try again.');
      }
      return response.data;
    });
};

export const getTransformerData = ({
  from,
  to,
  axiosSource,
  operation,
  groupBy,
  id,
  limit,
  fetchEvents,
  adjusted_value,
  where,
  shift,
  productType,
  widgetTitle,
  datapointLabel,
  idx,
  textMetric,
}) => {
  let title = '';
  if (widgetTitle) {
    const noSpacesWidgetTitle = widgetTitle.replaceAll(' ', '_');
    title = `&title=${noSpacesWidgetTitle}`;
  }

  let label = '';
  if (datapointLabel) {
    const noSpacesDatapointLabel = datapointLabel.replaceAll(' ', '_');
    label = `&datapointLabel=${noSpacesDatapointLabel}`;
  }
  const eventsQs = fetchEvents
    ? '&fetch_events=true'
    : '';

  if (adjusted_value && adjusted_value.length && Array.isArray(adjusted_value)) {
    adjusted_value = (typeof idx === 'number')
      ? adjusted_value.find((aV) => aV.metricId === (textMetric || id) && idx === aV.indexData)
      : adjusted_value.find((aV) => aV.metricId === (textMetric || id));
    if (adjusted_value) {
      adjusted_value = encodeURIComponent(adjusted_value.value);
    } else {
      adjusted_value = '';
    }
  }

  if (shift && shift.length) {
    shift = (typeof idx === 'number')
      ? shift.find((s) => s.id === (textMetric || id) && idx === s.indexData)
      : shift.find((s) => s.id === (textMetric || id));
    if (shift && shift.value) {
      shift = `&shift=${parseInt(shift.value, 10)}`;
    } else {
      shift = '';
    }
  } else {
    shift = '';
  }

  if (productType && productType.length) {
    productType = (typeof idx === 'number')
      ? productType.find((pT) => pT.id === (textMetric || id) && idx === pT.indexData)
      : productType.find((pT) => pT.id === (textMetric || id));
    if (productType && productType.value) {
      productType = `&product_type=${parseInt(productType.value, 10)}`;
    } else {
      productType = '';
    }
  } else {
    productType = '';
  }

  // let adjValue = '';
  // if (adjusted_value) {
  //   adjValue = `&adjusted_value=${adjusted_value}`;
  // }

  let filters = '';

  if (where && where.length && Array.isArray(where)) {
    where = (typeof idx === 'number')
      ? where.find((w) => w.metricId === (textMetric || id) && idx === w.indexData)
      : where.find((w) => w.metricId === (textMetric || id));
    if (where && where.value) {
      const whereParts = where.value.split(',');
      filters = '&filters=';

      whereParts.forEach((wP, i) => {
        if (wP.includes('larger than or equal')) {
          const splitValue = wP.split(' ');
          const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
          const comma = i > 0 ? ',' : '';
          filters = `${filters}${comma}value__gte=${valueNum}`;
        } else if (wP.includes('larger')) {
          const splitValue = wP.split(' ');
          const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
          const comma = i > 0 ? ',' : '';
          filters = `${filters}${comma}value__gt=${valueNum}`;
        } else if (wP.includes('less than or equal')) {
          const splitValue = wP.split(' ');
          const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
          const comma = i > 0 ? ',' : '';
          filters = `${filters}${comma}value__lte=${valueNum}`;
        } else {
          const splitValue = wP.split(' ');
          const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
          const comma = i > 0 ? ',' : '';
          filters = `${filters}${comma}value__lt=${valueNum}`;
        }
      });
    } else {
      where = null;
    }
  }

  return axios
    .get(`/api/v1/transformer/?group_by=${groupBy}&operation=${operation}&metric__in=${id}&from=${from}&to=${to}${eventsQs}&limit=${limit || ''}&adjusted_value=${adjusted_value}${title}${productType}${shift}${label}${filters}`, {
      cancelToken: axiosSource.source,
    })
    .then((response) => {
      if (response.status === undefined || response.status === 400 || response.status === 403 || response.status === 404 || response.status === 500 || !response.data) {
        return Notification('error', 'An error occurred while fetching data', 'We could not perform your request, please try again.');
      }
      return response.data;
    });
};

export const getEventsData = ({
  from,
  to,
  axiosSource,
  ids,
}) => {
  const metricQs = ids.map((id) => `&metric__in=${id}`);
  return axios
    .get(`/api/v1/industry/event_points/?${metricQs}&from=${from}&to=${to}`, {
      cancelToken: axiosSource.source,
    })
    .then((response) => {
      if (response.status === undefined || response.status === 400 || response.status === 403 || response.status === 404 || response.status === 500 || !response.data) {
        return Notification('error', 'An error occurred while fetching data', 'We could not perform your request, please try again.');
      }
      return response.data;
    });
};

export const getCalculatedData = ({
  from,
  to,
  axiosSource,
  operation,
  groupBy,
  limit,
  fetchEvents,
  extraFields,
  widgetTitle,
  datapointLabel,
  isProductionData,
}) => {
  let title = '';
  if (widgetTitle) {
    const noSpacesWidgetTitle = widgetTitle.replaceAll(' ', '_');
    title = `&title=${noSpacesWidgetTitle}`;
  }

  let label = '';
  if (datapointLabel) {
    const noSpacesDatapointLabel = datapointLabel.replaceAll(' ', '_');
    label = `&datapointLabel=${noSpacesDatapointLabel}`;
  }
  const eventsQs = fetchEvents
    ? '&fetch_events=true'
    : '';

  let adjusted_value = null;
  if (extraFields.adjusted_value) {
    adjusted_value = encodeURIComponent(extraFields.adjusted_value);
  }

  let shift = '';
  if (extraFields.shift) {
    shift = `&shift=${parseInt(extraFields.shift, 10)}`;
  }

  let productType = '';
  if (extraFields.productType) {
    productType = `&product_type=${parseInt(extraFields.productType, 10)}`;
  }

  let filters = '';

  if (extraFields.where) {
    const whereParts = extraFields.where.split(',');
    filters = '&filters=';

    whereParts.forEach((wP, i) => {
      if (wP.includes('larger than or equal')) {
        const splitValue = wP.split(' ');
        const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
        const comma = i > 0 ? ',' : '';
        filters = `${filters}${comma}value__gte=${valueNum}`;
      } else if (wP.includes('larger')) {
        const splitValue = wP.split(' ');
        const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
        const comma = i > 0 ? ',' : '';
        filters = `${filters}${comma}value__gt=${valueNum}`;
      } else if (wP.includes('less than or equal')) {
        const splitValue = wP.split(' ');
        const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
        const comma = i > 0 ? ',' : '';
        filters = `${filters}${comma}value__lte=${valueNum}`;
      } else {
        const splitValue = wP.split(' ');
        const valueNum = parseInt(splitValue[splitValue.length - 1], 10);
        const comma = i > 0 ? ',' : '';
        filters = `${filters}${comma}value__lt=${valueNum}`;
      }
    });
  }

  const gapFillPart = extraFields.gapFill ? '&gapfill=true' : '';

  let formula = extraFields.formula;
  let calculated = '';
  const vars = extraFields.vars;
  const metricIds = [];
  let metricIdsString = '';

  if (formula !== undefined && !isProductionData) {
    vars.forEach((variable) => {
      metricIds.push(variable.id);
      formula = formula.replace(variable.name, `{${variable.name}}`);
      calculated += `${variable.id}:${variable.name},`;
    });
    calculated = calculated.slice(0, -1);
    formula = encodeURIComponent(formula);
    metricIdsString = metricIds.join(',');

    return axios
      .get(`/api/v1/industry/datapoints/?group_by=${groupBy}&operation=${operation}&metric__in=${metricIdsString}&formula=${formula}&calculated=${calculated}&from=${from}&to=${to}${eventsQs}&limit=${limit || ''}&adjusted_value=${adjusted_value}${gapFillPart}${filters}${productType}${shift}${title}${label}`, {
        cancelToken: axiosSource.source,
      })
      .then((response) => {
        if (response.status === undefined || response.status === 400 || response.status === 403 || response.status === 404 || response.status === 500 || !response.data) {
          return Notification('error', 'An error occurred while fetching data', 'We could not perform your request, please try again.');
        }
        return response.data;
      });
  }

  if (formula !== undefined && isProductionData) {
    vars.forEach((variable) => {
      metricIds.push(variable.id);
      formula = formula.replace(variable.name, `{${variable.name}}`);
      calculated += `${variable.id}:${variable.name},`;
    });
    calculated = calculated.slice(0, -1);
    formula = encodeURIComponent(formula);
    metricIdsString = metricIds.join(',');

    return axios
      .get(`/api/v1/industry/production_data/?group_by=${groupBy}&operation=${operation}&metric__in=${metricIdsString}&formula=${formula}&calculated=${calculated}&from=${from}&to=${to}${eventsQs}&limit=${limit || ''}&adjusted_value=${adjusted_value}${gapFillPart}${filters}${productType}${shift}${title}${label}`, {
        cancelToken: axiosSource.source,
      })
      .then((response) => {
        if (response.status === undefined || response.status === 400 || response.status === 403 || response.status === 404 || response.status === 500 || !response.data) {
          return Notification('error', 'An error occurred while fetching data', 'We could not perform your request, please try again.');
        }
        return response.data;
      });
  }

  vars.forEach((variable) => {
    metricIds.push(variable.id);
  });
  metricIdsString = metricIds.join(',');
  return axios
    .get(`/api/v1/industry/production_data/?group_by=${groupBy}&operation=${operation}&metric__in=${metricIdsString}&from=${from}&to=${to}${eventsQs}&limit=${limit || ''}`, {
      cancelToken: axiosSource.source,
    })
    .then((response) => {
      if (response.status === undefined || response.status === 400 || response.status === 403 || response.status === 404 || response.status === 500 || !response.data) {
        return Notification('error', 'An error occurred while fetching data', 'We could not perform your request, please try again.');
      }
      return response.data;
    });
};

export const getOEE = ({
  axiosSource,
  from,
  to,
  metric_ok,
  metric_bad,
  metric_utilization,
  max_for_minute,
}) => {
  let metric_ok_url = '';
  if (metric_ok) {
    metric_ok_url = `&metric_ok=${metric_ok}`;
  }

  let metric_bad_url = '';
  if (metric_bad) {
    metric_bad_url = `&metric_bad=${metric_bad}`;
  }

  let metric_utilization_url = '';
  if (metric_utilization) {
    metric_utilization_url = `&metric_utilization=${metric_utilization}`;
  }

  let max_for_minute_url = '';
  if (max_for_minute) {
    max_for_minute_url = `&max_for_minute=${max_for_minute}`;
  }

  return axios
    .get(`/api/v1/industry/oee/totals/?from=${from}&to=${to}${metric_ok_url}${metric_bad_url}${metric_utilization_url}${max_for_minute_url}`, {
      cancelToken: axiosSource.source,
    })
    .then((response) => {
      if (response.status === undefined || response.status === 400 || response.status === 403 || response.status === 404 || response.status === 500 || !response.data) {
        return Notification('error', 'An error occurred while fetching data', 'We could not perform your request, please try again.');
      }
      return response.data;
    });
};

export const getOrdersData = ({
  from,
  to,
  axiosSource,
  companyId,
  assets_in,
}) => {
  return axios
    .get(`/api/v1/orders/orders/?company=${companyId}&active_asset__in=${assets_in}&active_from=${from}&active_to=${to}&limit=9999&include_lines=true`, {
      cancelToken: axiosSource.source,
    })
    .then((response) => {
      if (response.status === undefined || response.status === 400 || response.status === 403 || response.status === 404 || response.status === 500 || !response.data) {
        return Notification('error', 'An error occurred while fetching data', 'We could not perform your request, please try again.');
      }
      return response.data.results;
    });
};

export const getOeeTimelineTotals = ({
  from,
  to,
  asset,
  axiosSource,
}) => {
  let start = '';
  if (from) {
    start = moment(from).toISOString();
  }

  let end = '';
  if (to) {
    end = moment(to).toISOString();
  }

  let assetId = null;
  if (asset) {
    assetId = typeof asset === 'object' ? asset.id : asset;
  }
  return axios
    .get(`/api/v1/industry/oee/totals/?from=${start}&to=${end}&asset=${assetId}&timeline=true`, {
      cancelToken: axiosSource.source,
    })
    .then((response) => {
      if (response.status === undefined || response.status === 400 || response.status === 403 || response.status === 404 || response.status === 500 || !response.data) {
        return Notification('error', 'An error occurred while fetching oee data', 'We could not perform your request, please try again.');
      }
      return response.data;
    });
};
