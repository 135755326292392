import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

import {
  Button,
  Table,
  Notification,
  TableButtons,
  ConfirmationModal,
  ReactDatePicker,
} from 'shared';
import { selectStyles } from 'styles/modules/reactSelect';
import { defaultDateTimeFormat } from 'shared/constants';
import { IconSearch } from 'shared/Icons';

import api from 'helpers/api';

import { deleteReportLog } from '../../actions';

import '../../styles.scss';

class ReportLogs extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    this.state = {
      filters: {
        name: '',
        start_date: null,
        end_date: null,
        report: null,
      },
      logs: [],
      reports: [],
      isLoading: false,
      selectedAscDesc: 'desc',
      selectedSort: 'created_at',
      deleteData: null,
      showConfirmationDialog: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.getReports();
    this.getLogs();
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.getLogs();
    });
  }

  getReports = () => {
    const { companyId, locationId } = this.props;
    api
      .get(`/api/v1/reports/reports/?company=${companyId}&location=${locationId}&limit=999`)
      .then((res) => {
        this.setState({
          reports: res && res.data && res.data.results ? res.data.results : [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getLogs = () => {
    const { companyId, locationId } = this.props;
    const { filters } = this.state;

    this.setState({
      isLoading: true,
    });

    let api_filters = '';

    const { selectedAscDesc, selectedSort } = this.state;

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    let sortKey = selectedSort;
    if (selectedSort === 'report.name') {
      sortKey = sortKey.replace('.', '__');
    }
    api_filters = `&order_by=${asc}${sortKey || '-created_at'}`;

    if (filters.name) {
      api_filters += `&name=${filters.name}`;
    }

    if (filters.start_date) {
      api_filters += `&created_at_after=${moment(filters.start_date).startOf('day').toISOString()}`;
    }

    if (filters.end_date) {
      api_filters += `&created_at_before=${moment(filters.end_date).endOf('day').toISOString()}`;
    }

    if (filters.report) {
      api_filters += `&report=${(filters.report)}`;
    }

    api
      .get(`/api/v1/reports/report_logs/?company=${companyId}&location=${locationId}${api_filters}&limit=30`)
      .then((re) => {
        this.setState({
          logs: re.data.results,
          next: re && re.data && re.data.next ? re.data.next : null,
          previous: re && re.data && re.data.previous ? re.data.previous : null,
          isLoading: false,
          count: re && re.data && re.data.count,
        });
      });
  }

  getLogsByUrl = (url) => {
    this.setState({
      isLoading: true,
    });

    api.get(url)
      .then((re) => {
        this.setState({
          logs: re.data.results,
          next: re && re.data && re.data.next ? re.data.next : null,
          previous: re && re.data && re.data.previous ? re.data.previous : null,
          isLoading: false,
          count: re && re.data && re.data.count,
        });
      });
  }

  handleShowConfirmationModal = (row) => {
    this.setState({ showConfirmationDialog: true, deleteData: row });
  }

  handleDelete = () => {
    const {
      companyId,
      t,
    } = this.props;
    this.setState({
      isLoading: true,
    });
    const row = this.state.deleteData;
    deleteReportLog(row.original.id, row.original.report.id, companyId)
      .then(() => {
        this.getLogs(true);
        return Notification('success', 'Delete successful', t('page_content.automatic_reports.report_logs.success_delete'));
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      })
      .finally(() => this.setState({ showConfirmationDialog: false }));
  }

  handlePDF = (row) => {
    if (row && row.original && row.original.pdf_path) {
      window.open(`${window.origin}${row.original.pdf_path}`, '_blank', 'noreferrer noopener');
    }
  }

  handleNonPDF = (row) => {
    if (row && row.original && row.original.filename) {
      window.open(`${row.original.filename}`, '_blank', 'noreferrer noopener');
    }
  }

  clearFilters = () => {
    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        name: '',
        start_date: null,
        end_date: null,
        report: null,
      },
    }), () => {
      this.getLogs();
    });
  }

  updateField = (field, value) => {
    if (field === 'name') {
      this.setState((prevState) => ({
        filters: { ...prevState.filters, [field]: value },
      }));
      if (this.timerRef.current) {
        clearTimeout(this.timerRef.current);
        this.timerRef.current = undefined;
      }
      this.timerRef.current = setTimeout(() => {
        this.timerRef.current = undefined;
        if (value.length < 1 || value.length >= 3) {
          this.getLogs();
        }
      }, 500);
    } else {
      this.setState((prevState) => ({
        filters: { ...prevState.filters, [field]: value },
      }), () => {
        this.getLogs();
      });
    }
  }

  handleChangeStartDate = (t) => {
    const formatedDate = moment(t);
    this.updateField('start_date', formatedDate._d);
  }

  handleChangeEndDate = (t) => {
    const formatedDate = moment(t);
    this.updateField('end_date', formatedDate._d);
  }

  render() {
    const {
      isLoading,
      logs,
      filters,
      reports,
      next,
      previous,
      count,
    } = this.state;

    const {
      t,
    } = this.props;

    return (
      <div className="tab_container">
        <div className="filters_row">
          <Select
            options={reports}
            getOptionLabel={(r) => r.name}
            getOptionValue={(r) => r.id}
            isSearchable
            placeholder={t('page_content.automatic_reports.select_report')}
            onChange={(value) => this.updateField('report', value.id)}
            value={reports.find((r) => r.id === filters.report) || ''}
            styles={selectStyles}
          />
          <div className="input_container">
            <input onChange={(e) => this.updateField('name', e.target.value)} value={filters.name} />
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <div style={{ width: '200px' }}>
            <ReactDatePicker
              placeholderText={t('page_content.automatic_reports.select_start')}
              selected={filters.start_date ? moment(filters.start_date).toDate() : null}
              selectsStart
              onChange={this.handleChangeStartDate}
            />
          </div>
          <div style={{ width: '200px' }}>
            <ReactDatePicker
              placeholderText={t('page_content.automatic_reports.select_end')}
              selected={filters.end_date ? moment(filters.end_date).toDate() : null}
              selectsStart
              onChange={this.handleChangeEndDate}
            />
          </div>
          <Button
            onClick={this.clearFilters}
          >
            {t('page_content.events.events.clear_all_button')}
          </Button>
        </div>
        <div>
          <Table
            style={{ userSelect: 'text' }}
            columns={[
              {
                Header: () => <span>{t('page_content.automatic_reports.report')}</span>,
                accessor: 'report.name',
                Cell: (row) => (<Fragment>
                  {row && row.value ?
                    row.value : ''}
                </Fragment>),
              },
              {
                Header: () => <span>{t('page_content.automatic_reports.name')}</span>,
                accessor: 'name',
                Cell: (row) => (<Fragment>
                  {row && row.value ? row.value : ''}
                </Fragment>),
              },
              {
                Header: () => <span>{t('page_content.automatic_reports.created')}</span>,
                accessor: 'created_at',
                Cell: (row) => moment(row.value).format(defaultDateTimeFormat),
              },
              {
                Header: () => <span>{t('page_content.automatic_reports.actions')}</span>,
                Cell: (row) => (<div className="custom_table_row">
                  {
                    row.original.format !== 'pdf' ?
                      <Button type="export" onClick={() => this.handleNonPDF(row)}>{t('page_content.automatic_reports.report_logs.download')}</Button> :
                      <Button type="export" onClick={() => this.handlePDF(row)}>{t('page_content.automatic_reports.report_logs.download')}</Button>
                  }
                  <Button type="delete" onClick={() => this.handleShowConfirmationModal(row)}>{t('page_content.automatic_reports.delete')}</Button>
                </div>),
                sortable: false,
              },
            ]}
            data={(logs) || []}
            defaultPageSize={30}
            minRows={0}
            noDataText=" "
            showPagination={false}
            loading={isLoading}
            defaultSorted={[{ id: 'created_at', desc: true }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <TableButtons next={next} previous={previous} fetchFunction={this.getLogsByUrl} count={count} />
        </div>
        <ConfirmationModal
          itemName={this.state.deleteData?.original?.name || ''}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDelete}
          type="warning"
        />
      </div>
    );
  }
}

ReportLogs.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ReportLogs);
