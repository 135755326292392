import {
  SET_ROLE_START,
  SET_ROLE_SUCCESS,
  SET_ROLE_FAILURE,
  CLEAR_ROLE,
} from './actionTypes';

const initialState = {
  currentRole: null,
  roleConfig: null,
  loading: true,
  error: null,
  workerId: null,
  managerDepartments: [],
};

// Reducer
export default function roleReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ROLE_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SET_ROLE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null,
      };
    case SET_ROLE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CLEAR_ROLE:
      return initialState;
    default:
      return state;
  }
}
