import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import CategoriesTab from './CategoriesTab';
import FilledForms from './FilledForms';
import FormTemplates from './FormTemplates';

const Forms = ({ t, selectedChildTab, setSelectedChildTab }) => {
  const [reFetchCategories, setReFetchCategories] = useState(false);

  const fetchCategories = () => {
    setReFetchCategories((prevState) => !prevState);
  };

  return (
    <div className="forms_tab_content">
        <div>
          <Tabs selectedIndex={selectedChildTab} onSelect={setSelectedChildTab}>

            <TabList>
              <Tab>{t('page_content.human_resources.forms.table_label_templates')}</Tab>
              <Tab>{t('page_content.human_resources.forms.table_label_filled_forms')}</Tab>
              <Tab>{t('page_content.human_resources.forms.tab_categories')}</Tab>
            </TabList>

            <TabPanel>
              <FormTemplates reFetchCategories={reFetchCategories} />
            </TabPanel>

            <TabPanel>
              <FilledForms />
            </TabPanel>

            <TabPanel>
              <CategoriesTab reFetchCategories={fetchCategories} />
            </TabPanel>

      </Tabs>
      </div>
    </div>
  );
};

Forms.propTypes = {
  t: PropTypes.func.isRequired,
  selectedChildTab: PropTypes.number.isRequired,
  setSelectedChildTab: PropTypes.func.isRequired,
};

export default (withRouter(withTranslation()(Forms)));
