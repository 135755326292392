import { getByURL, getByURLBlob, postAndNotifyByURL, putAndNotifyByURL, patchAndNotifyByURL, deleteAndNotifyByURL } from './CustomService';

// -------------------- ASSET UTILIZATIONS -------------------- //
export const getIndustryAssetUtilizations = async (filters = '') => {
  return getByURL(`/api/v1/industry/asset_utilizations/${filters}`);
};

export const postIndustryAssetUtilization = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/industry/asset_utilizations/${filters}`, data);
};

export const putIndustryAssetUtilization = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/industry/asset_utilizations/${id}/${filters}`, data);
};

// -------------------- TIMINGS -------------------- //
export const getIndustryTimings = async (filters = '') => {
  return getByURL(`/api/v1/industry/timings/${filters}`);
};

export const postIndustryTimings = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/industry/timings/${filters}`, data);
};

export const putIndustryTimings = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/industry/timings/${id}/${filters}`, data);
};

// -------------------- ASSETS -------------------- //
export const getIndustryAssets = async (filters = '') => {
  return getByURL(`/api/v1/industry/assets/${filters}`);
};

export const postIndustryAsset = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/industry/assets/${filters}`, data);
};

export const patchIndustryAsset = async (id, filters = '', data) => {
  return patchAndNotifyByURL(`/api/v1/industry/assets/${id}/${filters}`, data);
};

export const deleteIndustryAsset = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/industry/assets/${id}/${filters}`);
};

export const getIndustryAssetsBlob = async (filters = '') => {
  return getByURLBlob(`/api/v1/industry/assets/${filters}`);
};

// -------------------- ASSETS MODELS -------------------- //
export const getIndustryAssetModels = async (filters = '') => {
  return getByURL(`/api/v1/industry/asset_models/${filters}`);
};

// -------------------- ASSETS GROUPS -------------------- //
export const getIndustryAssetGroups = async (filters = '') => {
  return getByURL(`/api/v1/industry/asset_groups/${filters}`);
};

export const postIndustryAssetGroup = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/industry/asset_groups/${filters}`, data);
};
