import React, { useState, useEffect, Fragment } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ReactTableDefaults } from 'react-table';

import { IconPopup, IconRemove, IconSearch } from 'shared/Icons';
import { Modal, Table, Button, ContentLoader } from 'shared';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { modalSizes } from 'shared/constants';

import { getDepartments } from 'shared/Api';

const DepartmentModal = (props) => {
  const [missingFields, setMissingFields] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [departments, setDepartments] = useState({
    departmentsList: [],
    isLoadingDepartments: false,
  });

  const {
    t,
    title,
    locationId,
    modalState,
    departmentData,
    handleModalBack,
    handleRemoveStep,
    handleModalClose,
    prevDepartmentData,
    handleStepJsonChange,
    handleDepartmentData,
    handleEditDepartment,
    departmentStepsQuery,
    handleInsertNewEmptyStep,
    departmentStepsSearchByOptions,
    handleCreateOrUpdateDepartment,
    departmentStepsSelectedSearchBy,
    handleChangeDepartmentStepsQuery,
    handleChangeDepartmentStepsSelectedSearchBy,
  } = props;

  const fetchDepartments = () => {
    setDepartments((prevState) => ({ ...prevState, isLoadingDepartments: true }));
    const apiFilters = `?location=${locationId}&limit=9999&order_by=name`;
    getDepartments(apiFilters)
      .then((res) => {
        const departmentsList = get(res, 'data.results', []).filter((dep) => dep.id !== departmentData?.id);
        setDepartments({ departmentsList, isLoadingDepartments: false });
      });
  };

  useEffect(() => {
    fetchDepartments();
  }, [modalState]);

  useEffect(() => {
    if (Object.values(missingFields).some((element) => element === true) || !submitted) {
      return;
    }
    handleCreateOrUpdateDepartment();
  }, [missingFields, submitted]);

  const departmentSteps = departmentData.steps;
  const stepsColumnDefinition = [
    {
      Header: () => <span className="step_modal_input">#</span>,
      width: 75,
      Cell: (row) => <span>{row.index + 1}</span>,
    },
    {
      Header: () => {
        return (
          <div className="step_table_header_wrapper">
            <div>{t('page_content.projects.products_tab.steps_modal.table_column_stepName')}</div>
            <div className="add_new_button">
              <Button type="add" onClick={() => handleInsertNewEmptyStep()}>{t('page_content.department.modal_new_step_button')}</Button>
            </div>
          </div>
        );
      },
      width: 300,
      Cell: (row) => (<span className="step_modal_input">
        <input value={row.original.name} onChange={(e) => handleStepJsonChange(e.target.value, row.index, 'name')} placeholder={`${t('page_content.projects.products_tab.table_column_step')} ${row.index + 1}`} />
      </span>),
    },
    {
      Header: () => <span>Code</span>,
      Cell: (row) => (<span className="step_modal_input">
        <input value={row.original.code} onChange={(e) => handleStepJsonChange(e.target.value, row.index, 'code')} placeholder={`Code ${row.index + 1}`} />
      </span>),
    },
    {
      Header: () => <span>{t('page_content.department.department_steps_category_column')}</span>,
      Cell: (row) => (<span className="step_modal_input">
        <input value={row.original.category} maxLength={50} onChange={(e) => handleStepJsonChange(e.target.value, row.index, 'category')} />
      </span>),
    },
    {
      Header: () => <span>{t('page_content.projects.products_tab.steps_modal.modal_column_default_duration')}</span>,
      Cell: (row) => (
        <span className="step_modal_input">
          <input
            type="text"
            value={row.original.default_duration}
            onChange={(e) => handleStepJsonChange(e.target.value, row.index, 'default_duration')}
            onInput={(e) => {
              const value = e.target.value;
              if (!/^\d*\.?\d{0,2}$/.test(value)) {
                e.target.value = value.slice(0, -1);
              }
            }}
            placeholder={t('page_content.projects.products_tab.steps_modal.modal_column_default_duration')}
          />
        </span>
      ),
    },
    {
      Header: () => '',
      width: 70,
      Cell: (row) => <Button type="delete" onClick={() => handleRemoveStep(row)}><IconRemove width="12px" height="12px" /></Button>,
    },
  ];

  const stepColumnDefaults = { ...ReactTableDefaults.column, headerClassName: 'step_table_override', className: 'step_table_override' };

  const childrenColumnDefinition = [
    {
      Header: () => <span>{t('page_content.department.table_column_name')}</span>,
      accessor: 'name',
      Cell: (row) => <span>{row?.value || '-'}</span>,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.department.table_column_production_department')}</span>,
      accessor: 'is_production_department',
      sortable: false,
      Cell: (row) => (row.value ? <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />),
      width: 200,
      style: {
        alignItems: 'center',
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.department.table_column_preview_department')}</span>,
      width: 100,
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button style={{ width: 55, padding: '5px 8px' }} onClick={() => handleEditDepartment(row.original)}>
          <IconPopup
            height="14px"
            width="14px"
            fill="#4285F4"
          />
        </Button>
      </div>),
      style: {
        cursor: 'default',
      },
    },
  ];

  return (
    <Modal
      isOpen={modalState}
      size={modalSizes.full}
      handleClose={() => handleModalClose()}
      handleSave={() => {
        setMissingFields((prevState) => ({ ...prevState, name: !departmentData.name }));
        setSubmitted(true);
      }}
      title={title}
      disabled
    >
      <form className="default-form department-details-form">
        <div className="parts_modal_breadcrumbs">
          {prevDepartmentData && prevDepartmentData.map((dep, index) => {
            return <React.Fragment>
              {index > 0 && <span>{'> '}</span>}
              <p
                key={dep.id}
                style={{ color: 'blue' }}
                onClick={() => handleModalBack(dep)}
              >
                  {dep?.name || '-'}
              </p>
          </React.Fragment>;
          })}
          {prevDepartmentData.length > 0 &&
            <Fragment>
              <span>{'> '}</span>
              <span style={{ color: 'gray' }}>{departmentData?.name || '-'}</span>
            </Fragment>}
        </div>
        <table>
          <tbody>
            <tr>
              <td className="label">
                {`${t('page_content.department.table_column_name')} *`}
              </td>
              <td className="input">
                <input
                  type="text"
                  onChange={(e) => { handleDepartmentData(e.target.value, 'name'); }}
                  value={departmentData.name}
                  style={missingFields.name ? { borderColor: 'red' } : {}}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {`${t('page_content.department.table_production_checkbox_name')}`}
              </td>
              <td className="input">
                <div className="right_checkbox">
                  <input type="checkbox" onChange={(e) => { handleDepartmentData(e.target.checked, 'is_production_department'); }} checked={departmentData.is_production_department || false} />
                </div>
              </td>
            </tr>
            <tr>
              <td className="label">
                {`${t('page_content.department.form_parent_department')}`}
              </td>
              <td className="input">
                <div className="right_checkbox">
                  <div style={{ width: '100%' }}>
                    <Select
                      options={departments?.departmentsList}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isSearchable
                      isClearable
                      menuPosition="fixed"
                      placeholder={`${t('page_content.department.form_select_placeholder_parent_department')}`}
                      onChange={(val) => { handleDepartmentData(val ?? null, 'parent'); }}
                      value={departments?.departmentsList?.find((d) => d?.id === departmentData?.parent?.id || null)}
                      isLoading={departments?.isLoadingDepartments}
                      loadingMessage={() => <ContentLoader />}
                      styles={selectModalStyles}
                    />
                </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <div className="table_wrapper_department_modal">
        <span className="table_wrapper_department_modal__span">
          {t('page_content.department.table_steps_name')}
        </span>
        <div className="input_container_search">
          <div className="input_field_style">
            <input onChange={(e) => handleChangeDepartmentStepsQuery(e.target.value)} placeholder={t('page_content.orders.search_for_placeholder')} value={departmentStepsQuery || ''} />
            {departmentStepsQuery && <button onClick={() => handleChangeDepartmentStepsQuery('')}>&times;</button>}
          </div>
          <Select
            className="select-style"
            options={departmentStepsSearchByOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={(e) => handleChangeDepartmentStepsSelectedSearchBy(e)}
            value={departmentStepsSearchByOptions?.find((a) => a.id === departmentStepsSelectedSearchBy?.id) || ''}
            styles={selectModalStyles}
          />
          <div className="icon_container">
            <IconSearch
              color="#555"
              height="26px"
              width="26px"
            />
          </div>
        </div>
        <Table
          style={{ userSelect: 'text', background: 'white' }}
          columns={stepsColumnDefinition}
          data={departmentSteps}
          loading={departmentData.isLoading}
          defaultPageSize={10}
          column={stepColumnDefaults}
          minRows={0}
          noDataText={t('page_content.department.table_no_data_placeholder')}
          showPagination={departmentSteps?.length > 10}
          sortable={false}
          manual={false}
        />
        <span className="table_wrapper_department_modal__span">
          {t('page_content.department.table_children_name')}
        </span>
        <Table
          style={{ userSelect: 'text', background: 'white' }}
          columns={childrenColumnDefinition}
          data={departmentData?.children}
          defaultPageSize={10}
          minRows={0}
          noDataText={t('page_content.department.table_no_data_placeholder')}
          showPagination={departmentData?.children?.length > 10}
          sortable={false}
          manual={false}
        />
        </div>
    </Modal>
  );
};

DepartmentModal.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  modalState: PropTypes.bool.isRequired,
  handleModalBack: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  departmentData: PropTypes.object.isRequired,
  handleRemoveStep: PropTypes.func.isRequired,
  handleEditDepartment: PropTypes.func.isRequired,
  handleDepartmentData: PropTypes.func.isRequired,
  handleStepJsonChange: PropTypes.func.isRequired,
  departmentStepsQuery: PropTypes.string.isRequired,
  handleInsertNewEmptyStep: PropTypes.func.isRequired,
  handleCreateOrUpdateDepartment: PropTypes.func.isRequired,
  departmentStepsSearchByOptions: PropTypes.array.isRequired,
  handleChangeDepartmentStepsQuery: PropTypes.func.isRequired,
  departmentStepsSelectedSearchBy: PropTypes.object.isRequired,
  handleChangeDepartmentStepsSelectedSearchBy: PropTypes.func.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  prevDepartmentData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default withTranslation()(DepartmentModal);
