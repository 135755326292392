import React, { Component } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { multiSelectStylesAutoHeight } from 'styles/modules/reactSelect';
import { numberSeparatorFormat } from 'industry/helpers';
import { Table, Modal, ContentLoader, ReactDatePicker } from 'shared';
import { modalSizes } from 'shared/constants';
import { getProductionLines, getWorkers } from './../../../actions';

const specificGroupName = 'Stavke';
const inputElementIdPrefix = 'input_';

class ManualEntryModal extends Component {
  constructor() {
    super();
    this.debounceTimeoutRef = React.createRef(null);

    this.state = {
      bookings_entry: [],
      input_values: {},
      textbox_values: {},
      custom_values: {
        production_line: 0,
        booked_at: moment().toDate(),
        ts_start: moment().toDate(),
      },
      lines: [],
      coefficient: null,
      isLoadingWorkers: false,
      workers: [],
    };
  }

  componentDidMount() {
    this.getProductionLines();
  }

  shouldComponentUpdate(nextProps) {
    // eslint-disable-next-line react/prop-types
    if (this.state.coefficient === null || nextProps.coefficient !== this.state.coefficient) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    if (this.props.isManualEntryModalOpen === false) {
      this.state.custom_values = {
        production_line: 0,
        booked_at: moment().toDate(),
        ts_start: moment().toDate(),
      };
    }
  }

  fetchWorkers = (filters) => {
    const { companyId, locationId } = this.props;
    this.setState({
      isLoadingWorkers: true,
    });
    getWorkers(companyId, locationId, filters)
      .then((re) => {
        this.setState({
          workers: get(re, 'data.results', []) || [],
          isLoadingWorkers: false,
        });
      })
      .catch(() => this.setState({
        workers: [],
        isLoadingWorkers: false,
      }));
  }

  onSaveManualEntryModal = () => {
    const { bookings_entry, custom_values } = this.state;
    const { onSaveManualEntryModal } = this.props;

    const workersIds = custom_values?.workers?.length ? custom_values.workers.map((worker) => worker.id) : [];

    onSaveManualEntryModal(bookings_entry, { ...custom_values, ts_end: custom_values.booked_at, workers: workersIds });
    this.setState({
      bookings_entry: [],
      input_values: {},
      textbox_values: {},
      custom_values: {},
    });
  }

  getProductionLines = () => {
    const { company_short_code, locationId } = this.props;

    getProductionLines(company_short_code, locationId)
      .then((res) => {
        this.setState({
          lines: res.data.results ? res.data.results : [],
        });
      });
  }

  getCellValue = (row) => {
    const { i18n } = this.props;

    const unitCode = row.original.unit_code || null;
    const cellValue = row.value || null;

    if (unitCode === 'PCE') {
      return numberSeparatorFormat(i18n.language, cellValue, 0, 0, true);
    } if (unitCode === 'KKO') {
      return numberSeparatorFormat(i18n.language, (cellValue * 1000), 0, 0, true);
    }
    return numberSeparatorFormat(i18n.language, row.value, 3, 3, true);
  }

  getRemainingValue = (row) => {
    const { i18n } = this.props;
    const { input_values } = this.state;

    const unitCode = row.original.unit_code || null;
    const quantity = row.original.quantity || 0;
    const quantity_booked = row.original.quantity_done || 0;
    const quantity_booked_new = input_values[row.original.id] || 0;

    // const remaining = quantity - quantity_booked - (this.state.coefficient * quantity);
    const remaining = quantity - quantity_booked - quantity_booked_new;

    if (unitCode === 'PCE') {
      return numberSeparatorFormat(i18n.language, remaining, 0, 0, true);
    } if (unitCode === 'KKO') {
      return numberSeparatorFormat(i18n.language, (remaining * 1000), 0, 0, true);
    }
    return numberSeparatorFormat(i18n.language, remaining, 3, 3, true);
  }

  closeManualEntryModal = () => {
    const { closeManualEntryModal } = this.props;

    closeManualEntryModal();
    this.setState({
      bookings_entry: [],
      input_values: {},
      textbox_values: {},
    });
  }

  addOrUpdateBooking = (array, booking) => {
    // eslint-disable-next-line eqeqeq
    const i = array.findIndex((_booking) => _booking.order_item_id == booking.order_item_id);

    if (!Object.prototype.hasOwnProperty.call(booking, 'batch')) {
      booking.batch = '';
    }

    if (!Object.prototype.hasOwnProperty.call(booking, 'quantity_booked')) {
      booking.quantity_booked = 0;
    }

    if (i > -1) {
      array[i] = booking;
    } else {
      array.push(booking);
    }

    return array;
  }

  truncateDecimals = (num, digits) => {
    const numS = num.toString();
    const decPos = numS.indexOf('.');
    // eslint-disable-next-line eqeqeq
    const substrLength = decPos == -1 ? numS.length : 1 + decPos + digits;
    const trimmedResult = numS.substr(0, substrLength);
    const finalResult = Number.isNaN(trimmedResult) ? 0 : trimmedResult;

    return parseFloat(finalResult);
  }

  coefficientChanged = () => {
    const { groups } = this.props;
    const { coefficient, bookings_entry, textbox_values } = this.state;

    // eslint-disable-next-line
    let inputs = [];
    let newBookingEntry = {};
    groups.filter((x) => x.name !== specificGroupName).forEach((group) => {
      group.items.forEach((item) => {
        const inputElem = document.querySelector(`#${inputElementIdPrefix}${item.id}`);
        if (inputElem !== null) {
          const inputContainer = inputElem.closest('[role="row"]');
          const inputNormative = inputContainer.querySelector('[data-row-quantity]').dataset.rowQuantity;
          const inputType = inputContainer.querySelector('[data-row-type]').dataset.rowType;
          const inputValue = parseFloat(inputNormative, 10) * coefficient;
          const inputValueFormatted = (inputType === 'PCE') ? this.truncateDecimals(inputValue, 0) : this.truncateDecimals(inputValue, 3);
          inputElem.value = inputValueFormatted;
          inputs[item.id] = inputValueFormatted;
          const booking = {
            order_item_id: item.id,
            batch: textbox_values[item.id] || '',
            quantity_booked: inputValueFormatted,
          };
          newBookingEntry = this.addOrUpdateBooking(bookings_entry, booking);
        }
      });
    });
    this.setState({
      bookings_entry: newBookingEntry,
      input_values: inputs,
    });
  }

  handleInput = (e, row) => {
    const { groups } = this.props;
    const { bookings_entry, input_values, textbox_values } = this.state;

    const value = parseFloat(e.target.value.replace(',', '.')) || null;
    const rowId = row.original.id || null;
    const unitCode = row.original.unit_code || null;

    const booking = {
      order_item_id: rowId,
      // quantity_booked: unitCode === 'KKO' ? Number(value / 1000) : Number(value),
      // batch: textbox_values && textbox_values[row.original.id] ? textbox_values[row.original.id] : null
    };

    if (value !== null) {
      booking.quantity_booked = unitCode === 'KKO' ? Number(value / 1000) : Number(value);
    }

    if (textbox_values && textbox_values[row.original.id] && textbox_values[row.original.id] !== null) {
      booking.batch = textbox_values[row.original.id];
    }

    const newBookingEntry = this.addOrUpdateBooking(bookings_entry, booking);

    this.setState({
      bookings_entry: newBookingEntry,
      input_values: { ...input_values, [row.original.id]: value },
    }, () => {
      let coefficient = 0;
      const rowGroup = groups.find((x) => x.id === row.original.order_group);
      if (rowGroup !== null && rowGroup.name === specificGroupName) {
        coefficient = value / row.original.quantity;
        if (this.state.coefficientChanged !== coefficient) {
          this.setState({
            coefficient,
          }, () => {
            this.coefficientChanged();
          });
        }
      }
    });
  }

  handleTextboxInput = (e, row) => {
    const { bookings_entry, input_values, textbox_values } = this.state;

    const value = e.target.value || null;
    const rowId = row.original.id || null;

    const booking = {
      order_item_id: rowId,
      // quantity_booked: input_values && input_values[row.original.id] ? input_values[row.original.id] : null,
      // batch: value,
    };

    if (value !== null) {
      booking.batch = value;
    }

    if (input_values && input_values[row.original.id] && input_values[row.original.id] !== null) {
      booking.quantity_booked = Number(input_values[row.original.id]);
    }

    const newBookingEntry = this.addOrUpdateBooking(bookings_entry, booking);

    this.setState({
      bookings_entry: newBookingEntry,
      textbox_values: { ...textbox_values, [row.original.id]: value },
    });
  }

  handleWorkersSearch = (value) => {
    if (value.length > 2) {
      if (this.debounceTimeoutRef.current) {
        clearTimeout(this.debounceTimeoutRef.current);
      }

      this.debounceTimeoutRef.current = setTimeout(() => {
        let filters = '&limit=9999';

        if (value) {
          filters += `&first_or_last_name=${value}`;
        }
        this.fetchWorkers(filters);
      }, 300);
    }
  }

  updateCustomValue = (field, value) => {
    const {
      custom_values,
    } = this.state;

    this.setState({
      custom_values: { ...custom_values, [field]: value },
    });
  }

  renderInputCell = (row) => {
    // this render function is to prevent rerenders on react-table
    // because with every "onChange" we lose focus from input
    const { input_values } = this.state;

    const cellValue = input_values[row.original.id];

    return (
      <input
        type="text"
        inputMode="numeric"
        id={`${inputElementIdPrefix}${row.original.id}`}
        defaultValue={cellValue}
        onKeyDown={(evt) => {
          if (evt.key === '.') {
            evt.preventDefault();
          }
          if (evt.key === ',' && evt.target.value.includes(',')) {
            evt.preventDefault();
          }
        }}
        onChange={(e) => {
          // Allow only numbers and a comma
          const value = e.target.value;
          if (/^\d*[,]?\d*$/.test(value)) {
            this.handleInput(e, row);
          }
        }}
      />
    );
  };

  renderTextboxCell = (row) => {
    // this render function is to prevent rerenders on react-table
    // because with every "onChange" we lose focus from input
    const { textbox_values } = this.state;

    const cellValue = textbox_values[row.original.id];

    return (
      <input
        type="text"
        defaultValue={cellValue}
        onChange={(e) => this.handleTextboxInput(e, row)}
      />
    );
  };

  render() {
    const {
      t,
      isManualEntryModalOpen,
      groups,
      order_groups_hidden_columns,
      company_short_code,
    } = this.props;

    const {
      lines,
      custom_values,
      workers,
      isLoadingWorkers,
    } = this.state;

    const sortGroups = (a) => {
      return (a.name === specificGroupName) ? -1 : 0;
    };

    const sortGroupItems = ((a, b) => {
      if (a.item_num > b.item_num) {
        return 1;
      } if (a.item_num < b.item_num) {
        return -1;
      }
      return 0;
    });

    const isValidForm = (Object.prototype.hasOwnProperty.call(custom_values, 'production_line') && parseInt(custom_values.production_line, 10) > 0);

    return (
      <Modal
        isOpen={isManualEntryModalOpen}
        handleSave={isValidForm ? this.onSaveManualEntryModal : () => { }}
        error={isValidForm ? null : `* ${t('page_content.orders.order_details.bookings_tab.manual_entry_error_msg')}`}
        handleClose={this.closeManualEntryModal}
        title={t('page_content.orders.order_details.bookings_tab.manual_entry')}
        size={modalSizes.full}
        contentClass="manual-entry-modal"
      >
        <div style={{ padding: '0 20px 20px 20px' }}>
          <div style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
            <label>{t('page_content.orders.order_details.bookings_tab.start')}</label>
            <div style={{ width: '150px' }}>
              <ReactDatePicker
                selected={(Object.prototype.hasOwnProperty.call(custom_values, 'ts_start')) ? moment(custom_values.ts_start).toDate() : moment().toDate()}
                onChange={(e) => { this.updateCustomValue('ts_start', e); }}
                showTimeSelect
                timeCaption={t('date_picker_locale.time_translation')}
              />
            </div>
          </div>
          <div style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
            <label>{t('page_content.orders.order_details.bookings_tab.end')}</label>
            <div style={{ width: '150px' }}>
              <ReactDatePicker
                selected={(Object.prototype.hasOwnProperty.call(custom_values, 'booked_at')) ? moment(custom_values.booked_at).toDate() : moment().toDate()}
                onChange={(e) => { this.updateCustomValue('booked_at', e); }}
                showTimeSelect
                timeCaption={t('date_picker_locale.time_translation')}
              />
            </div>
          </div>
          <div style={{ margin: '10px 0' }}>
            <label>{t('page_content.orders.order_details.bookings_tab.table_column_line')} *</label>
            <select onChange={(e) => { this.updateCustomValue('production_line', parseInt(e.target.value, 10)); }}>
              <option value="0">-</option>
              {lines.map((line) => <option key={line.id} value={line.id}>{line.name}</option>)}
            </select>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <label>{t('page_content.orders.order_details.bookings_tab.workers')}</label>
          <div style={{ width: '300px' }}>
          <Select
            options={workers || []}
            getOptionLabel={(option) => `${option.name} ${option.last_name}`}
            getOptionValue={(option) => option.id}
            isSearchable
            isMulti
            onInputChange={(e) => this.handleWorkersSearch(e)}
            onChange={(val) => this.updateCustomValue('workers', val)}
            value={custom_values?.workers || []}
            styles={multiSelectStylesAutoHeight}
            menuPosition="fixed"
            isLoading={isLoadingWorkers}
            placeholder={t('page_content.orders.order_details.bookings_tab.search_worker_by_name')}
            loadingMessage={() => <ContentLoader />}
          />
          </div>
          </div>
          {
            groups.sort(sortGroups).map((group, i) => (<div key={i} className="order-details-table">
              <h4 style={{ margin: '10px 0 0 0' }}>
                {group.name} {group && group.material_code ? `- ${group.material_code}` : ''}
              </h4>
              <Table
                style={{ userSelect: 'text', fontSize: '13px' }}
                columns={[
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_item_name')}</span>,
                    accessor: 'name',
                    show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_item_name', { lng: 'en' })),
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_quantity')}</span>,
                    accessor: 'quantity',
                    Cell: (row) => <span data-row-quantity={row.value}>{this.getCellValue(row)}</span>,
                    show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_quantity', { lng: 'en' })),
                    maxWidth: 100,
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_quantity_tracked')}</span>,
                    accessor: 'quantity_tracked',
                    Cell: (row) => <span>{this.getCellValue(row)}</span>,
                    show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_quantity_tracked', { lng: 'en' })) && !(group && group.material_code && group.material_code === 'Resursi'),
                    maxWidth: 100,
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_quantity_final')}</span>,
                    accessor: 'quantity_final',
                    Cell: (row) => <span>{this.getCellValue(row)}</span>,
                    show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_quantity_final', { lng: 'en' })) && !(group && group.material_code && (group.material_code === 'BOM' || group.material_code === 'Resursi')),
                    maxWidth: 100,
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_quantity_done')}</span>,
                    accessor: 'quantity_done',
                    Cell: (row) => <span>{this.getCellValue(row)}</span>,
                    show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_quantity_done', { lng: 'en' })),
                    maxWidth: 100,
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_unit')}</span>,
                    accessor: 'unit_code',
                    Cell: (row) => <span data-row-type={row.value && row.value === 'KKO' ? 'PCE' : row.value}>{row.value && row.value === 'KKO' ? 'PCE' : row.value}</span>,
                    show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_unit', { lng: 'en' })),
                    maxWidth: 80,
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_status')}</span>,
                    accessor: 'status',
                    Cell: (row) => <span className={`order-details-table__status ${row.value}`}>{row.value ? t([`page_content.orders.statuses.${row.value}`]) : ''}</span>,
                    show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_status', { lng: 'en' })) && company_short_code !== 'podravka',
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_batch')}</span>,
                    Cell: this.renderTextboxCell,
                    show: group && group.material_code === 'BOM',
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_quantity_booked')}</span>,
                    Cell: this.renderInputCell,
                    maxWidth: 120,
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_remaining')}</span>,
                    Cell: (row) => <span>{this.getRemainingValue(row)}</span>,
                    maxWidth: 120,
                  },
                ]}
                data={group.items.sort(sortGroupItems)}
                minRows={group.items.length}
                maxRows={group.items.length}
                defaultPageSize={group.items.length}
                showPagination={false}
                sortable
                defaultSorted={[{ id: 'name', desc: false }]}
                manual={false}
              />
            </div>))
          }
        </div>
      </Modal>
    );
  }
}

ManualEntryModal.propTypes = {
  i18n: PropTypes.object,
  t: PropTypes.func.isRequired,
  closeManualEntryModal: PropTypes.func.isRequired,
  isManualEntryModalOpen: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  order_groups_hidden_columns: PropTypes.array,
  company_short_code: PropTypes.string.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSaveManualEntryModal: PropTypes.func.isRequired,
};

export default withTranslation()(ManualEntryModal);
