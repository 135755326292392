import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import '../../../styles.scss';

import { ACTIONS } from 'industry/role/definitions/actions';
import { SECTIONS } from 'industry/role/definitions/sections';
import { canPerformAction } from 'industry/role/selectors';

import { deleteQualification, getQualifications, getPaginatedQualifications } from '../../../actions';

import QualificationsModal from './QualificationsModal';

const QualificationsTable = ({ t, companyId, can }) => {
  const [qualifications, setQualifications] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [qualificationsFilters, setQualificationsFilter] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'name',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchQualifications = () => {
    setQualifications((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = qualificationsFilters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&order_by=${asc}${qualificationsFilters?.selectedSort}`;

    getQualifications(companyId, apiFilters)
      .then((res) => {
        setQualifications({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setQualifications((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedQualifications = (url) => {
    setQualifications((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedQualifications(url)
      .then((res) => {
        setQualifications({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setQualifications((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchQualifications();
  }, [companyId, qualificationsFilters]);

  const handleShowConfirmationDialog = (row) => {
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteQualification(deleteData?.id, companyId);
    setShowConfirmationDialog(false);
    fetchQualifications();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setQualificationsFilter((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleAddNewQualification = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleEditQualification = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchQualifications();
  };

  return (
    <div className="qualifications-container">
      <div className="qualifications-container__table">
        <h3>{t('page_content.human_resources.workers.qualifications')}</h3>

        <div className="qualifications-container__action-buttons">
        <Button
          type="add"
          disabled={!can(SECTIONS.WORKERS_TABS__WORKERS_QUALIFICATIONS_AND_PROFESSIONS_TAB, ACTIONS.ADD)}
          onClick={handleAddNewQualification}
        >
          {t('page_content.human_resources.workers.add_new_qualification')}
        </Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.workers.name')}</span>,
              accessor: 'name',
              style: { cursor: 'default' },
            },
          ]}
          minRows={0}
          noDataText=""
          defaultPageSize={100}
          data={qualifications.data || []}
          loading={qualifications.isLoading}
          defaultSorted={[{ id: 'name', desc: false }]}
          enableEdit={can(SECTIONS.WORKERS_TABS__WORKERS_QUALIFICATIONS_AND_PROFESSIONS_TAB, ACTIONS.EDIT)}
          enableDelete={can(SECTIONS.WORKERS_TABS__WORKERS_QUALIFICATIONS_AND_PROFESSIONS_TAB, ACTIONS.DELETE)}
          onEdit={(original) => handleEditQualification(original)}
          onDelete={(original) => handleShowConfirmationDialog(original)}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons
          next={qualifications.next}
          count={qualifications.count}
          previous={qualifications.previous}
          fetchFunction={fetchPaginatedQualifications}
        />
      </div>

      {modalData?.isOpen &&
      <QualificationsModal
        isOpen={modalData?.isOpen}
        handleCloseModal={handleCloseModal}
        initialValues={modalData?.selectedItem}
        closeModalAndRefetch={closeModalAndRefetch}
      />}

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'name')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </div>
  );
};

QualificationsTable.propTypes = {
  t: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    companyId: get(state, 'app.company.id', null),
    can: (section, action, useExceptions = false) => canPerformAction(state, section, action, useExceptions),
  };
};

export default connect(mapStateToProps)(withTranslation()(QualificationsTable));
