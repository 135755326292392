import React from 'react';
import moment from 'moment';
import { numberSeparatorFormat } from 'industry/helpers';
import { formatTypes } from '../../constants';

export const renderLegend = (props) => {
  // eslint-disable-next-line
  const { payload } = props;
  return (
    <ul className="recharts-default-legend" style={{ padding: '0px', margin: '0px', textAlign: 'center' }}>
      {
        payload.map((entry, index) => (
          // <li key={`item-${index}`}>{entry.value}</li>

          <li key={`item-${index}`} className="recharts-legend-item legend-item-0" style={{ display: 'inline-block', marginRight: '10px' }}>
            <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}>
              <path stroke="none" fill={entry.color} d="M0,4h32v24h-32z" className="recharts-legend-icon" />
            </svg>
            <span className="recharts-legend-item-text">{entry.value}</span>
          </li>
        ))
      }
    </ul>
  );
};

export const formatLabel = (metric) => {
  if (!metric) {
    return '';
  }

  const unit = metric.value_type
    ? metric.value_type.unit
    : metric.unit;

  return `${metric.label} [${unit}]`;
};

function precision(a) {
  // eslint-disable-next-line no-restricted-globals
  if (!isFinite(a)) return 0;
  let e = 1;
  let p = 0;
  while (Math.round(a * e) / e !== a) { e *= 10; p++; }
  return p;
}

export const compactInteger = (number, toPrecision = 1) => {
  const abbreviations = [
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'K' },
  ];

  // Handle negative numbers
  const isNegative = number < 0;
  const absNumber = Math.abs(number);

  // Find the appropriate abbreviation
  for (const { value, symbol } of abbreviations) {
    if (absNumber >= value) {
      const formatted = (absNumber / value)
        .toFixed(toPrecision)
        // Remove trailing zeros after decimal point
        .replace(/\.?0+$/, '');
      return `${isNegative ? '-' : ''}${formatted}${symbol}`;
    }
  }

  // If number is smaller than 1000, return as is
  return `${isNegative ? '-' : ''}${absNumber}`;
};

export const formatTooltip = (value) => {
  if (Number(value) === value && value % 1 !== 0) {
    // float
    return value.toFixed(precision(value));
  }
  // integer
  return compactInteger(value);
};

export const formatTooltipDates = (value, meta) => {
  const diff = Math.floor(meta.dateTo - meta.dateFrom) / (1000 * 60 * 60 * 24);
  if (diff < 1.05) {
    return moment(value).format('DD.MM.YYYY. HH:mm:ss');
  } if (diff < 180) {
    return moment(value).format('DD.MM.YYYY. HH:mm');
  } if (diff < 500) {
    return moment(value).format('DD.MM.YYYY.');
  } if (diff < 1500) {
    return moment(value).format('MM/YYYY.');
  }
  return moment(value).format('YYYY');
};

const formatDates = (value, meta) => {
  if (value && meta) {
    const diff = Math.floor(meta.dateTo - meta.dateFrom) / (1000 * 60 * 60 * 24);
    if (diff < 1 / 12) {
      return moment(value).format('HH:mm:ss');
    } if (diff < 1.05) {
      return moment(value).format('HH:mm');
    } if (diff < 180) {
      return moment(value).format('DD.MM.YYYY.');
    } if (diff < 500) {
      return moment(value).format('MM/YYYY.');
    }
    return moment(value).format('YYYY');
  }
};

export const formatTicks = (value, type = formatTypes.integer, meta, decimalPlaces) => {
  switch (type) {
    case formatTypes.float:
      return numberSeparatorFormat('hr', value, decimalPlaces, decimalPlaces, true);
    case formatTypes.integer:
      return numberSeparatorFormat('hr', value, 0, 0, true);
    case formatTypes.date:
      return formatDates(value, meta);
    default:
      return value;
  }
};

const percentageModifier = 0.30;

export const domainGenerator = (graphData = {}) => {
  let values = [];
  Object.keys(graphData).forEach((key) => {
    const metricData = [];
    Object.keys(graphData[key]).forEach((metricKey) => {
      if (metricKey !== 'time') {
        metricData.push(graphData[key][metricKey]);
      }
    });
    values = [...metricData, ...values];
  });

  const dataMax = Math.max(...values);
  const dataMin = Math.min(...values);

  let diff = Math.abs(dataMax - dataMin);

  if (!Number.isFinite(diff)) {
    diff = 0;
  }

  return [
    // eslint-disable-next-line
    (dataMin) => {
      return dataMin && dataMin < 0 ? (dataMin - (percentageModifier * (diff || dataMin))) : 0;
    },
    // eslint-disable-next-line
    (dataMax) => {
      return dataMax + (percentageModifier * (diff || dataMax));
    },
  ];
};

// export const generateTicks = (from, to, width) => {
//   const numberOfTicks = width ? Math.round(width / 150) : 6;
//   const diff = Math.round((to - from) / numberOfTicks);
//   const arr = Array(numberOfTicks).fill(1).map((d, i) => from + (i * diff));
//   return [...arr, to];
// };

export const generateTicks = (from, to, width) => {
  const range_in_days = Math.round(to - from) / (1000 * 60 * 60 * 24);
  let numberOfTicks = width ? Math.round(width / 150) : 6;
  let diff = Math.round((to - from) / numberOfTicks);

  if (range_in_days > 1) {
    // convert diff to hours and round it to nearest 24 intervals (24, 48, 72...)
    diff = Math.round((diff / (1000 * 60 * 60)) / 24) * 24;
    diff = diff < 24 ? 24 : diff;
    numberOfTicks = Math.round(range_in_days / (diff / 24));
    // convert diff back to miliseconds
    diff = diff * 1000 * 60 * 60;
  }

  const arr = Array(numberOfTicks).fill(1).map((d, i) => (from + (i * diff) < to ? from + (i * diff) : '')).filter((item) => item !== '');
  return [...arr, to];
};
