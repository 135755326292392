import { getByURL, postAndNotifyByURL, putAndNotifyByURL, deleteAndNotifyByURL } from './CustomService';

export const getShifts = async (filters = '') => {
  return getByURL(`/api/v1/shifts/shifts/${filters}`);
};

export const postShift = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/shifts/shifts/${filters}`, data);
};

export const putShift = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/shifts/shifts/${id}/${filters}`, data);
};

export const deleteShift = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/shifts/shifts/${id}/${filters}`);
};
