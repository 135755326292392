import React, { Component } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Button, ShiftPicker, ReactDatePicker } from 'shared';
import { selectStyles, multiSelectStyles } from 'styles/modules/reactSelect';
import './styles.scss';

import { getCurrentUserSuccess } from 'user/actions';
import { saveSortingAndFiltering } from 'industry/actions';
import { checkAccessOnPage, redirectToHomePage, checkActiveFilters } from 'industry/helpers';

import api from 'helpers/api';
import { getShifts } from 'shared/Api';
import {
  getMaintenances,
  getMaintenaceEventSum,
  getAssets,
  editMaintenance,
  addMaintenance,
  getWorkers,
  getReasons,
  getReasonGroups,
  getMaintenanceUrl,
  getSingleMaintenance,
  getAssetTypes,
  deleteMaintenance,
  patchMaintenance,
} from './actions';

import MaintenanceDashboard from './components/MaintenanceDashboard';
import MaintenanceTable from './components/MaintenanceTable';
import MaintenanceModal from './components/MaintenanceModal';
import ReasonGroups from './components/ReasonGroups';
import Reasons from './components/Reasons';

class IndustryLocationMaintenanceModule extends Component {
  static getDefaultMaintenanceObject() {
    return {
      maintenance_start: null,
      maintenance_end: null,
      event_start: moment(),
      event_end: null,
      maintenance_duration: 0,
      waiting_duration: 0,
      total_duration: 0,
      is_maintenance_required: true,
      cost_parts: 0,
      cost_parts_currency: 'HRK',
      cost_travel: 0,
      cost_travel_currency: 'HRK',
      status: 'created',
      operators: [],
      is_malfunction: false,
    };
  }

  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    const sortingAndFiltering = (props.currentUser) ? props.currentUser.data : {};
    let selectedFilterByAssetValue = [];
    let selectedFilterByAssetType = '';
    let selectedFilterByReasonValue = '';
    let selectedFilterByWorkersValue = '';
    let start_date = null;
    let end_date = null;
    let isMalfunctionFilter = null;

    this.defaultFilters = {
      selectedFilterByAssetValue,
      selectedFilterByAssetType,
      selectedFilterByReasonValue,
      selectedFilterByWorkersValue,
      start_date,
      end_date,
      isMalfunctionFilter,
    };

    let filtersActive = false;

    if (sortingAndFiltering.maintenace_module) {
      const sAndF = sortingAndFiltering.maintenace_module;
      selectedFilterByAssetValue = sAndF.selectedFilterByAssetValue || [];
      selectedFilterByAssetType = sAndF.selectedFilterByAssetType || null;
      selectedFilterByReasonValue = sAndF.selectedFilterByReasonValue || null;
      start_date = sAndF.start_date ? moment(sAndF.start_date) : moment().startOf('month');
      end_date = sAndF.end_date ? moment(sAndF.end_date) : moment();
      selectedFilterByWorkersValue = sAndF.selectedFilterByWorkersValue || '';
      filtersActive = checkActiveFilters(this.defaultFilters, sAndF);
      isMalfunctionFilter = sAndF.isMalfunctionFilter || false;
    }

    this.state = {
      start_date: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'), // Setting the default date values on clear
      end_date: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      workers: [],
      isLoadingExportModal: true,
      isLoadingModal: true,
      maintenances: [],
      dashboardMaintenances: [],
      selectedFilterByAssetValue: [],
      selectedFilterByAssetType,
      selectedFilterByReasonValue,
      selectedFilterByWorkersValue,
      reasonsFilteredByAsset: [],
      isMalfunctionFilter,
      isSearching: false,
      isMaintenanceModalOpen: false,
      selectedMaintenance: null,
      errorAsset: false,
      filtersActive,
      count: null,
      maintenance: IndustryLocationMaintenanceModule.getDefaultMaintenanceObject(),
      exportLoader: false,
      query: '',
      topMaintenancesFilter: true,
      shiftsFilter: false,
      next: null,
      previous: null,
      shiftTimes: [],
      maintenaceEventSum: [],
      selectedAscDesc: 'desc',
      selectedSort: 'event_start',
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;
    const { selectedFilterByAssetValue } = this.state;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    getAssets(companyId, locationId)
      .then((re) => {
        this.setState({
          assets: re.data.results,
        }, () => {
          this.search();
        });
      });

    getReasons(companyId, locationId, (selectedFilterByAssetValue !== 'all' && selectedFilterByAssetValue !== null) ? `&asset__in=${selectedFilterByAssetValue.map((asset) => asset.id)}&limit=9999` : '')
      .then((re) => {
        this.setState({
          reasons: re.data.results,
        });
      });

    getReasonGroups(companyId, '&limit=9999', locationId)
      .then((re) => {
        this.setState({
          reason_groups: get(re, 'data.results', []),
        });
      });

    getWorkers(companyId)
      .then((re) => {
        this.setState({
          workers: re.data.results,
        }, () => {
          this.search();
          this.saveSortingAndFiltering();
        });
      });

    getAssetTypes(companyId, locationId)
      .then((re) => {
        this.setState({
          assetType: re.data.results,
        });
      });

    const apiFilters = `?location=${locationId}`;
    getShifts(apiFilters)
      .then((re) => this.setState({ shiftTimes: get(re, 'data.results', []) }));
  }

  componentWillUnmount() {
    this.saveSortingAndFiltering();
  }

  handleChange = (e) => {
    const searchValue = e.target.value;
    this.setState({ selectedFilterByWorkersValue: searchValue }, () => {
      this.handleStateQueryChange();
    });
  }

  handleClearSearch = () => {
    this.setState({ selectedFilterByWorkersValue: '' }, () => {
      this.search();
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.search();
  }

  handleStateQueryChange = () => {
    const { selectedFilterByWorkersValue } = this.state;
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      if (selectedFilterByWorkersValue.length < 1 || selectedFilterByWorkersValue.length >= 3) {
        this.search();
      }
    }, 500);
  }

  saveSortingAndFiltering = () => {
    const {
      selectedFilterByAssetValue,
      selectedFilterByAssetType,
      selectedFilterByReasonValue,
      selectedFilterByWorkersValue,
      start_date,
      end_date,
    } = this.state;

    const {
      currentUser,
      setCurrentUser,
    } = this.props;

    if (currentUser) {
      currentUser.data.maintenace_module = {
        selectedFilterByAssetValue,
        selectedFilterByAssetType,
        selectedFilterByReasonValue,
        selectedFilterByWorkersValue,
        start_date,
        end_date,
      };
      setCurrentUser(currentUser);
      saveSortingAndFiltering({ data: currentUser.data });
      this.checkActiveSortingAndFiltering(currentUser.data.maintenace_module);
    }
  }

  checkActiveSortingAndFiltering = (userFilters) => {
    const filtersActive = checkActiveFilters(this.defaultFilters, userFilters);
    this.setState({ filtersActive });
  }

  handleFilterChange = (key, value) => {
    const { companyId, locationId } = this.props;
    if (key === 'selectedFilterByAssetValue' && value !== 'all') {
      this.setState({
        [key]: value,
        isSearching: true,
      });
      getReasons(companyId, locationId, `&asset__in=${value.map((asset) => asset.id)}&limit=9999`)
        .then((re) => {
          this.setState({
            reasons: re.data.results,
            isSearching: false,
          }, () => {
            this.search();
            this.saveSortingAndFiltering();
          });
        });
    } else {
      this.setState({
        [key]: value,
      }, () => {
        this.search();
        this.saveSortingAndFiltering();
      });
    }
  }

  handleFilterDateChange = (key, t) => {
    let formatedDate = '';
    if (key === 'start_date') {
      formatedDate = moment(t).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
    } else if (key === 'end_date') {
      formatedDate = moment(t).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
    }
    this.setState({
      shiftsFilter: false,
      [key]: formatedDate,
    }, () => {
      this.search();
      this.saveSortingAndFiltering();
    });
  }

  handleDatePickerShiftChange = (startDate, endDate) => {
    this.setState({
      shiftsFilter: true,
      start_date: startDate,
      end_date: endDate,
    }, () => {
      this.search();
      this.saveSortingAndFiltering();
    });
  }

  search = () => {
    const { companyId, locationId } = this.props;
    const {
      isSearching,
      selectedFilterByAssetValue,
      selectedFilterByAssetType,
      selectedFilterByReasonValue,
      selectedReasonGroup,
      selectedFilterByWorkersValue,
      start_date,
      end_date,
      isMalfunctionFilter,
      reasons,
      selectedAscDesc,
      selectedSort,
    } = this.state;

    if (!isSearching) {
      this.setState({
        isSearching: true,
      });
      let filters = '';

      if (start_date) {
        filters += `&event_start_after=${start_date}`;
      }

      if (end_date) {
        filters += `&event_start_before=${end_date}`;
      }

      // if (selectedFilterByAssetValue && selectedFilterByAssetValue.length === 1) {
      //   const selectedFilterByAssetIds = selectedFilterByAssetValue.map(asset => asset.id);
      //   filters += `&asset=${selectedFilterByAssetIds}`;
      // }

      if (selectedFilterByAssetValue && selectedFilterByAssetValue.length >= 1) {
        const selectedFilterByAssetIds = selectedFilterByAssetValue.map((asset) => asset.id);
        filters += `&asset__in=${selectedFilterByAssetIds}`;
      }

      if (selectedFilterByAssetType && selectedFilterByAssetType !== 'all') {
        filters += `&asset_type=${selectedFilterByAssetType}`;
      }

      if (selectedFilterByReasonValue && selectedFilterByReasonValue !== 'all') {
        if (reasons && reasons.some((el) => el.id === selectedFilterByReasonValue)) {
          filters += `&reported_reason=${selectedFilterByReasonValue}`;
        } else {
          this.setState({
            selectedFilterByReasonValue: null,
          });
        }
      }

      if (selectedFilterByWorkersValue) {
        let name = '';
        let lastName = '';
        if (selectedFilterByWorkersValue.includes(' ')) {
          name = selectedFilterByWorkersValue.split(' ')[0];
          lastName = selectedFilterByWorkersValue.split(' ')[1];
          filters += `&first_name=${name}&last_name=${lastName}`;
        } else {
          filters += `&first_or_last_name=${selectedFilterByWorkersValue}`;
        }
      }

      if (isMalfunctionFilter) {
        filters += `&is_malfunction=${isMalfunctionFilter}`;
      }

      if (selectedReasonGroup) {
        filters += `&reported_reason_group=${selectedReasonGroup}`;
      }

      const asc = selectedAscDesc === 'desc' ? '-' : '';
      filters += `&order_by=${asc}${selectedSort}`;

      getMaintenances(companyId, locationId, filters)
        .then((re) => {
          this.setState({
            count: re && re.data ? re.data.count : null,
            maintenances: re && re.data && re.data.results,
            next: re && re.data && re.data.next,
            previous: re && re.data && re.data.previous,
            currentUrl: null,
            isSearching: false,
          }, () => {
            this.saveSortingAndFiltering();
          });
        });

      getMaintenances(companyId, locationId, filters, '9999')
        .then((re) => {
          this.setState({
            dashboardMaintenances: re && re.data && re.data.results,
          });
        });

      getMaintenaceEventSum(companyId, locationId, filters)
        .then((re) => {
          this.setState({
            maintenaceEventSum: re.data[0],
          });
        });
    }
  }

  clearSearch = () => {
    const { companyId, locationId } = this.props;

    this.setState({
      isSearching: false,
      selectedFilterByAssetValue: [],
      selectedFilterByAssetType: '',
      selectedFilterByReasonValue: '',
      selectedReasonGroup: '',
      selectedFilterByWorkersValue: '',
      start_date: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      end_date: moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      isMalfunctionFilter: false,
      shiftsFilter: false,
    }, () => {
      this.saveSortingAndFiltering();
      this.search();
    });

    getReasons(companyId, locationId, '&limit=9999')
      .then((re) => {
        this.setState({
          reasons: re.data.results,
          isSearching: false,
        });
      });
  }

  handleMaintenancesTableRowClick = (row) => {
    const { companyId, locationId } = this.props;
    this.setState({
      isMaintenanceModalOpen: true,
      isLoadingModal: true,
    });
    const maintenance = {
      id: row && row.id ? row.id : null,
      asset: row && row.asset ? row.asset : null,
      maintenance_start: row && row.maintenance_start ? row.maintenance_start : null,
      maintenance_end: row && row.maintenance_end ? row.maintenance_end : null,
      event_start: row && row.event_start ? row.event_start : null,
      event_end: row && row.event_end ? row.event_end : null,
      maintenance_duration: row && row.maintenance_duration ? row.maintenance_duration : 0,
      waiting_duration: row && row.waiting_duration ? row.waiting_duration : 0,
      total_duration: row && row.total_duration ? row.total_duration : 0,
      is_maintenance_required: !!(row && row.is_maintenance_required && row.is_maintenance_required === true),
      cost_parts: row && row.cost_parts ? row.cost_parts : 0,
      cost_parts_currency: row && row.cost_parts_currency ? row.cost_parts_currency : 'HRK',
      cost_travel: row && row.cost_travel ? row.cost_travel : 0,
      cost_travel_currency: row && row.cost_travel_currency ? row.cost_travel_currency : 'HRK',
      status: row && row.status ? row.status : 'created',
      is_malfunction: !!(row && row.reported_reason && row.reported_reason.is_malfunction),
      note: row && row.note ? row.note : '',
      reason_comment: row && row.reason_comment ? row.reason_comment : '',
      allow_comment: row && row.reported_reason && row.reported_reason.allow_comment ? row.reported_reason.allow_comment : false,
    };
    if (row && row.reported_reason && row.reported_reason.id) {
      maintenance.reported_reason = row.reported_reason.id;
    }

    if (row && row.reporter) {
      maintenance.reporter = row.reporter.id;
    }

    getSingleMaintenance(row.id, companyId)
      .then((res) => {
        const operators = res && res.data && res.data.operators ? res.data.operators : [];
        maintenance.operators = operators;

        getReasons(companyId, locationId, `&asset=${row.asset.id}&limit=9999`)
          .then((re) => {
            this.setState({
              reasonsFilteredByAsset: re.data.results,
              isLoadingModal: false,
              isMaintenanceModalOpen: true,
              maintenance,
            });
          });
      });
  }

  closeEditMaintenanceModal = () => {
    this.setState({
      isMaintenanceModalOpen: false,
      isLoadingModal: true,
      selectedMaintenance: null,
      errorAsset: false,
    });
  }

  openNewMaintenanceModal = () => {
    this.setState({
      maintenance: IndustryLocationMaintenanceModule.getDefaultMaintenanceObject(),
      isMaintenanceModalOpen: true,
      isLoadingModal: false,
    });
  }

  changeAsset = async (val) => {
    const { companyId, locationId } = this.props;
    const { maintenance } = this.state;

    let assetId = null;
    if (maintenance && maintenance.asset) {
      assetId = maintenance.asset.id;
    }

    try {
      const re = await getReasons(companyId, locationId, assetId ? `&asset__in=${assetId}&limit=9999` : '&limit=9999');
      this.setState({
        reasonsFilteredByAsset: re.data.results,
        maintenance: { ...maintenance, asset: val, reported_reason: ' ' },
        errorAsset: false,
      });
    } catch (error) {
      // Handle error
      console.error(error);
    }
  }

  handleMaintenanceChange = (key, value) => {
    const { maintenance } = this.state;

    if (key === 'reported_reason') {
      this.setState({
        maintenance: {
          ...maintenance,
          [key]: value,
          reason_comment: '',
        },
      });
    } else {
      this.setState({
        maintenance: {
          ...maintenance,
          [key]: value,
        },
      });
    }
  }

  handleMaintenanceDateChange = (key, t) => {
    const { maintenance } = this.state;

    const formatedDate = moment(t);
    this.setState({
      maintenance: {
        ...maintenance,
        [key]: formatedDate._d,
      },
    });
  }

  handleMaintenanceSave = () => {
    const { companyId } = this.props;
    const { maintenance, errorAsset } = this.state;

    if (!maintenance.asset) {
      this.setState({
        errorAsset: true,
      });
    }
    if (!errorAsset && maintenance.asset) {
      const maintenanceData = maintenance;

      maintenanceData.asset = maintenance.asset.id ? maintenance.asset.id : maintenance.asset;
      maintenanceData.company = companyId;

      if (maintenance.id) {
        editMaintenance(maintenanceData, companyId, maintenance.id)
          .then(() => {
            this.setState({
              maintenance: IndustryLocationMaintenanceModule.getDefaultMaintenanceObject(),
              errorAsset: false,
              isMaintenanceModalOpen: false,
              isLoadingModal: true,
            }, () => {
              this.search();
            });
          });
      } else {
        addMaintenance(companyId, maintenanceData)
          .then(() => {
            this.setState({
              maintenance: IndustryLocationMaintenanceModule.getDefaultMaintenanceObject(),
              errorAsset: false,
              isMaintenanceModalOpen: false,
              isLoadingModal: true,
            }, () => {
              this.search();
            });
          });
      }
    }
  }

  handleDeleteMaintenance = (id) => {
    const { currentUrl } = this.state;
    const { companyId } = this.props;
    deleteMaintenance(companyId, id)
      .then(() => {
        this.setState({
          maintenance: IndustryLocationMaintenanceModule.getDefaultMaintenanceObject(),
          errorAsset: false,
        }, () => {
          if (currentUrl) {
            this.fetchPaginatedMaintenances(currentUrl);
          } else {
            this.search();
          }
        });
      });
  }

  handleApproveMaintenance = (row, e) => {
    const { currentUrl } = this.state;
    const { companyId, currentUser } = this.props;
    e.stopPropagation();

    const maintenanceId = row && row.original && row.original.id ? row.original.id : null;

    const data = {
      status: 'approved',
      approved_user: currentUser && currentUser.id ? currentUser.id : '',
      approved_at: moment().utc().toISOString(),
    };

    patchMaintenance(maintenanceId, companyId, data)
      .then(() => {
        if (currentUrl) {
          this.fetchPaginatedMaintenances(currentUrl);
        } else {
          this.search();
        }
      });
  }

  handleDenyMaintenance = (row, e) => {
    const { currentUrl } = this.state;
    const { companyId, currentUser } = this.props;
    e.stopPropagation();

    const maintenanceId = row && row.original && row.original.id ? row.original.id : null;

    const data = {
      status: 'denied',
      approved_user: currentUser && currentUser.id ? currentUser.id : '',
      approved_at: moment().utc().toISOString(),
    };

    patchMaintenance(maintenanceId, companyId, data)
      .then(() => {
        if (currentUrl) {
          this.fetchPaginatedMaintenances(currentUrl);
        } else {
          this.search();
        }
      });
  }

  handleSorting = (sortData) => {
    this.setState({ isLoadingPartners: true });
    const column = sortData.id;

    let sortKey = column;
    if (column === 'asset.name' ||
    column === 'reporter.last_name' ||
    column === 'approved_user.last_name') {
      sortKey = column.replace('.', '__');
    }

    this.setState({
      selectedSort: sortKey,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.search();
    });
  }

  exportToExcel = () => {
    const { companyId, locationId } = this.props;
    const {
      count,
      selectedFilterByAssetValue,
      selectedFilterByAssetType,
      selectedFilterByReasonValue,
      selectedFilterByWorkersValue,
      start_date,
      end_date,
      isMalfunctionFilter,
    } = this.state;

    let filters = '';

    if (start_date) {
      filters += `&event_start_after=${start_date}`;
    }

    if (end_date) {
      filters += `&event_start_before=${end_date}`;
    }

    if (selectedFilterByAssetValue && selectedFilterByAssetValue.length >= 1) {
      const selectedFilterByAssetIds = selectedFilterByAssetValue.map((asset) => asset.id);
      filters += `&asset__in=${selectedFilterByAssetIds}`;
    }

    if (selectedFilterByAssetType && selectedFilterByAssetType !== 'all') {
      filters += `&asset_type=${selectedFilterByAssetType}`;
    }

    if (selectedFilterByReasonValue && selectedFilterByReasonValue !== 'all') {
      filters += `&reported_reason=${selectedFilterByReasonValue}`;
    }

    if (isMalfunctionFilter) {
      filters += `&is_malfunction=${isMalfunctionFilter}`;
    }

    if (selectedFilterByWorkersValue) {
      if (selectedFilterByWorkersValue) {
        let name = '';
        let lastName = '';
        if (selectedFilterByWorkersValue.includes(' ')) {
          name = selectedFilterByWorkersValue.split(' ')[0];
          lastName = selectedFilterByWorkersValue.split(' ')[1];
          filters += `&first_name=${name}&last_name=${lastName}`;
        } else {
          filters += `&first_or_last_name=${selectedFilterByWorkersValue}`;
        }
      }
    }

    this.setState({ exportLoader: true });
    api.get(`/api/v1/maintenance/events/?company=${companyId}&location=${locationId}${filters}&format=xlsx&limit=${count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'maintenance_list.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({ exportLoader: false });
      }).catch((e) => {
        this.setState({ exportLoader: false });
        console.error(e);
      });
  }

  fetchPaginatedMaintenances = (url) => {
    this.setState({
      isSearching: true,
    });
    getMaintenanceUrl(url)
      .then((re) => {
        this.setState({
          maintenances: get(re, 'data.results') || [],
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          currentUrl: url,
          count: get(re, 'data.count'),
          isSearching: false,
        });
      })
      .catch((e) => console.error('Error while fetching documents', e));
  }

  render() {
    const {
      companyId,
      locationId,
      t,
      currentUser,
    } = this.props;

    const {
      maintenances,
      maintenaceEventSum,
      selectedFilterByAssetValue,
      selectedFilterByAssetType,
      selectedFilterByReasonValue,
      selectedFilterByWorkersValue,
      assets,
      assetType,
      isMaintenanceModalOpen,
      maintenance,
      errorAsset,
      workers,
      isLoadingModal,
      reasonsFilteredByAsset,
      reasons,
      next,
      previous,
      count,
      start_date,
      end_date,
      dashboardMaintenances,
      isMalfunctionFilter,
      isSearching,
      exportLoader,
      isReadOnly,
      topMaintenancesFilter,
      hideGlobalFilters,
      shiftsFilter,
      shiftTimes,
      reason_groups,
      selectedReasonGroup,
    } = this.state;

    const assetStyle = errorAsset ? '_error' : '';
    const filteredReasons = reasons && reasons.sort((a, b) => a.group.name.localeCompare(b.group.name));

    return (
      <div className="industry-tab maintenance_module fullscreen">
        {
          isMaintenanceModalOpen &&
          <MaintenanceModal
            t={t}
            maintenance={maintenance}
            workers={workers}
            isLoadingModal={isLoadingModal}
            reasonsFilteredByAsset={reasonsFilteredByAsset}
            isMaintenanceModalOpen={isMaintenanceModalOpen}
            locationId={locationId}
            assetStyle={assetStyle}
            handleMaintenanceSave={this.handleMaintenanceSave}
            closeEditMaintenanceModal={this.closeEditMaintenanceModal}
            changeAsset={this.changeAsset}
            handleMaintenanceChange={this.handleMaintenanceChange}
            handleMaintenanceDateChange={this.handleMaintenanceDateChange}
            handleSubmit={this.handleSubmit}
          />
        }

        <Tabs>
          <TabList>
            <Tab onClick={() => this.setState({ hideGlobalFilters: false })}>{t('page_content.maintenance.table_tab')}</Tab>
            <Tab onClick={() => this.setState({ hideGlobalFilters: false })}>{t('page_content.maintenance.dashboard_tab')}</Tab>
            <Tab onClick={() => this.setState({ hideGlobalFilters: true })}>{t('page_content.maintenance.reason_groups_tab')}</Tab>
            <Tab onClick={() => this.setState({ hideGlobalFilters: true })}>{t('page_content.maintenance.reasons_tab')}</Tab>
          </TabList>
          {
            !hideGlobalFilters &&
            <div className="maintenance_tools">
              <div className="maintenance_datePicker">
                <ReactDatePicker
                  placeholderText={t('page_content.maintenance.select_start_date')}
                  dateFormat={shiftsFilter ? 'dd.MM.yyyy - HH:mm' : 'dd.MM.yyyy'}
                  selected={start_date ? moment(start_date).toDate() : null}
                  maxDate={end_date ? moment(end_date).toDate() : null}
                  selectsStart
                  onChange={(e) => this.handleFilterDateChange('start_date', e)}
                />
              </div>
              <div className="maintenance_datePicker">
                <ReactDatePicker
                  placeholderText={t('page_content.maintenance.select_end_date')}
                  dateFormat={shiftsFilter ? 'dd.MM.yyyy - HH:mm' : 'dd.MM.yyyy'}
                  selected={end_date ? moment(end_date).toDate() : null}
                  minDate={start_date ? moment(start_date).toDate() : null}
                  selectsStart
                  onChange={(e) => this.handleFilterDateChange('end_date', e)}
                />
              </div>

              <ShiftPicker shifts={shiftTimes} handleShiftPickerChange={this.handleDatePickerShiftChange} startDate={start_date} endDate={end_date} />

              <Select
                options={assets}
                getOptionLabel={(asset) => asset.name}
                getOptionValue={(asset) => asset.id}
                isSearchable
                isMulti
                placeholder={t('page_content.maintenance.filter_by_asset_placeholder')}
                onChange={(value) => this.handleFilterChange('selectedFilterByAssetValue', value || [])}
                value={selectedFilterByAssetValue}
                styles={multiSelectStyles}
              />

              <Select
                options={assetType}
                getOptionLabel={(asset) => asset.description}
                getOptionValue={(asset) => asset.id}
                isSearchable
                isClearable
                placeholder={t('page_content.maintenance.filter_by_asset_type_placeholder')}
                onChange={(value) => this.handleFilterChange('selectedFilterByAssetType', value?.id ?? null)}
                value={(assetType && assetType.find((a) => a.id === selectedFilterByAssetType)) || ''}
                styles={selectStyles}
              />

              <Select
                options={filteredReasons}
                getOptionLabel={(reason) => `${reason.group.name} ${reason.name}`}
                getOptionValue={(reason) => reason.id}
                isSearchable
                isClearable
                placeholder={t('page_content.maintenance.filter_by_reason_placeholder')}
                onChange={(value) => this.handleFilterChange('selectedFilterByReasonValue', value?.id ?? null)}
                value={(reasons && reasons.find((el) => el.id === selectedFilterByReasonValue)) || ''}
                styles={selectStyles}
              />

              <Select
                options={reason_groups}
                isClearable
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(value) => this.handleFilterChange('selectedReasonGroup', value?.id ?? null)}
                value={(reason_groups && reason_groups.find((option) => option.id === selectedReasonGroup)) || ''}
                styles={selectStyles}
                placeholder={t('page_content.maintenance.filter_by_reason_group_placeholder')}
              />

              <div className="maintenance_toolbar_checkboxWrapper">
                <input
                  type="checkbox"
                  checked={isMalfunctionFilter}
                  onChange={(e) => this.handleFilterChange('isMalfunctionFilter', e.target.checked)}
                  style={{ height: '20px', width: '20px' }}
                />
                {t('page_content.maintenance.only_malfunctions')}
              </div>
              <div className="maintenance_tool_clearButton">
                <Button
                  type="plain"
                  onClick={this.clearSearch}
                >
                  {t('page_content.maintenance.clear_button')}
                </Button>
              </div>
            </div>
          }
          <TabPanel>
            <MaintenanceTable
              next={next}
              previous={previous}
              t={t}
              maintenances={maintenances}
              maintenaceEventSum={maintenaceEventSum}
              exportToExcel={this.exportToExcel}
              handleMaintenancesTableRowClick={this.handleMaintenancesTableRowClick}
              fetchPaginatedMaintenances={this.fetchPaginatedMaintenances}
              handleIsMalfunction={this.handleIsMalfunction}
              isSearching={isSearching}
              exportLoader={exportLoader}
              isReadOnly={isReadOnly}
              deleteMaintenance={this.handleDeleteMaintenance}
              handleApproveMaintenance={this.handleApproveMaintenance}
              openEditMaintenanceModal={this.openNewMaintenanceModal}
              handleChange={this.handleChange}
              selectedFilterByWorkersValue={selectedFilterByWorkersValue}
              handleClearSearch={this.handleClearSearch}
              handleDenyMaintenance={this.handleDenyMaintenance}
              count={count}
              handleSortOrder={this.handleSorting}
            />
          </TabPanel>
          <TabPanel>
            <MaintenanceDashboard
              t={t}
              isSearching={isSearching}
              maintenances={dashboardMaintenances}
              topMaintenancesFilter={topMaintenancesFilter}
              handleFilterChange={this.handleFilterChange}
            />
          </TabPanel>
          <TabPanel>
            <ReasonGroups
              t={t}
              companyId={companyId}
              locationId={locationId}
            />
          </TabPanel>
          <TabPanel>
            <Reasons
              t={t}
              companyId={companyId}
              locationId={locationId}
              currentUser={currentUser}
            />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

IndustryLocationMaintenanceModule.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  setCurrentUser: getCurrentUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IndustryLocationMaintenanceModule));
