import { getByURL } from './CustomService';

// -------------------- SURVEYS WORKER -------------------- //
export const getSurveysWorkers = async (filters = '') => {
  return getByURL(`/api/v1/surveys/worker_surveys/${filters}`);
};

// -------------------- SURVEYS QUESTION OPTIONS -------------------- //
export const getSurveysQuestionOptions = async (filters = '') => {
  return getByURL(`/api/v1/surveys/question_options/${filters}`);
};

// -------------------- SURVEYS WORKER ANSWERS -------------------- //
export const getSurveysWorkerAnswers = async (filters = '') => {
  return getByURL(`/api/v1/surveys/worker_answers/${filters}`);
};
