import React from 'react';

import QualificationsTable from './QualificationsTable';
import ProfessionsTable from './ProfessionsTable';

const QualificationsAndProfessions = () => {
  return (
        <div className="qualifications_and_professions_tab">
            <QualificationsTable />

            <ProfessionsTable />
        </div>
  );
};

export default QualificationsAndProfessions;
