import React, { Component } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Modal, ContentLoader, Table, Button } from 'shared';
import { modalSizes } from 'shared/constants';
import { getOeeExceptions, getLines, getProductGroups, putOeeExceptions, postOeeExceptions, deleteOeeExceptions, editOeeGoal, createOeeGoal } from '../actions';
import '../styles.scss'

class OEEGoalsModal extends Component {
  constructor(props) {
    super();
    let asset = null;
    let low = '';
    let high = '';
    let goal = '';

    if (props.selectedOee) {
      const { selectedOee } = props;
      asset = (selectedOee && selectedOee.asset && selectedOee.asset.id) || null;
      low = selectedOee.low || '';
      high = selectedOee.high || '';
      goal = selectedOee.goal || '';
    }
    this.state = {
      asset,
      low,
      high,
      goal,
      oeeExceptions: [],
      isLoadingExceptions: false,
      lines: [],
      isLoadingLines: false,
      tableData: [],
      productGroups: [],
      isLoadingProductGroups: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchOEEExceptions();
    this.fetchLines();
    this.fetchProductGroups();
  }

  fetchLines = async () => {
    const { company_short_code, locationId } = this.props;

    this.setState({ isLoadingLines: true });

    const apiFilters = `?company=${company_short_code}&order_by=name&location=${locationId}&limit=999&is_virtual=true`;
    const res = await getLines(apiFilters);
    this.setState({ lines: res?.data?.results, isLoadingLines: false });
  }

  fetchProductGroups = async () => {
    const { locationId } = this.props;

    this.setState({ isLoadingProductGroups: true });

    const apiFilters = `?order_by=name&location=${locationId}&limit=999`;
    const res = await getProductGroups(apiFilters);
    this.setState({ productGroups: res?.data?.results, isLoadingProductGroups: false });
  }

  fetchOEEExceptions = async () => {
    const { companyId, selectedOee } = this.props;
    if (selectedOee?.id) {
      this.setState({ isLoadingExceptions: true });

      const id = selectedOee?.id || null;
      const apiFilters = `?company=${companyId}&limit=200&oeegoals=${id}`;
      const res = await getOeeExceptions(apiFilters);
  
      this.setState({ oeeExceptions: res?.data?.results, isLoadingExceptions: false });
    }
  }

  onFieldChange = (key, value) => {
    const { oeeHighLimit, oeeLowLimit } = this.props;

    if (key === 'goal' && !isNaN(parseFloat(value))) {
      const numericValue = parseFloat(value);
      const low = numericValue + (numericValue * (oeeLowLimit / 100));
      const high = numericValue + (numericValue * (oeeHighLimit / 100));
      
      this.setState({
        [key]: numericValue,
        low,
        high,
      });
    } else {
      this.setState({
        [key]: value,
      });
    }
  }

  handleParseAndRoundNumber = (num) => {
    const parsedNum = num.toString().replace(',', '.');
    if (parsedNum.includes('.')) {
      return parseFloat(parsedNum).toFixed(1);
    }
    return parsedNum;
  }

  getNumberOfDecimals = (num) => {
    if (Number.isInteger(num)) {
      return 0;
    }

    const decimalStr = num.toString().split('.')[1];
    return decimalStr ? decimalStr.length : 0;
  }

  handleSaveOrEditOee = async () => {
    const { asset, low, high, goal, oeeExceptions } = this.state;
    const { selectedOee, locationId, companyId, handleCloseAndRefetchModal } = this.props;

    this.setState({ isLoading: true });

    const id = selectedOee?.id || null;
    const data = {
      asset: asset || null,
      low: this.handleParseAndRoundNumber(low),
      high: this.handleParseAndRoundNumber(high),
      goal: this.handleParseAndRoundNumber(goal),
      location: locationId,
    };

    if (id) {
      editOeeGoal(id, locationId, data)
        .then(async () => {
          const exceptionsData = oeeExceptions?.map((exception) => ({
            id: exception?.id || null,
            product_group: exception?.product_group?.id || null,
            goal: this.handleParseAndRoundNumber(exception?.goal),
            line: exception?.line?.id || null,
            oeegoals: id,
            company: companyId,
            isDeleted: exception?.isDeleted || false,
          }));
          const promises = exceptionsData?.map((exception) => {
            if (exception?.isDeleted && exception?.id) {
              return deleteOeeExceptions(`${exception?.id}/?company=${companyId}`);
            } else if (exception?.id) {
              return putOeeExceptions(exception?.id, `?company=${companyId}`, exception);
            }
            return postOeeExceptions('', exception);
          });
      
          const res = await Promise.all(promises);
          this.setState({ isLoading: false });
          handleCloseAndRefetchModal();
        });
    } else {
      createOeeGoal(locationId, data)
        .then(async (result) => {
          const exceptionsData = oeeExceptions?.map((exception) => ({
            id: exception?.id || null,
            product_group: exception?.product_group?.id || null,
            goal: this.handleParseAndRoundNumber(exception?.goal),
            line: exception?.line?.id || null,
            oeegoals: result?.data?.id,
            company: companyId,
            isDeleted: exception?.isDeleted || false,
          }));  
          const promises = exceptionsData?.map((exception) => {
            if (exception?.isDeleted && exception?.id) {
              return deleteOeeExceptions(`${exception?.id}/?company=${companyId}`);
            } else if (exception?.id) {
              return putOeeExceptions(exception?.id, `?company=${companyId}`, exception);
            }
            return postOeeExceptions('', exception);
          });
      
          const res = await Promise.all(promises);
          this.setState({ isLoading: false });
          handleCloseAndRefetchModal();
        });
    }
  }

  handleDeleteItem = (rowInfo) => {
    const { oeeExceptions } = this.state;
    const updatedData = oeeExceptions?.map((item) => {
      if (item?.id === rowInfo?.id) return { ...item, isDeleted: true };
      return item;
    });

    this.setState({ oeeExceptions: updatedData });
  }

  handleAddNewItem = () => {
    const newItem = {
      id: '',
      product_group: '',
      goal: '',
      line: '',
      addedOrEdited: 'added',
    };

    this.setState((prevState) => ({ oeeExceptions: [...prevState.oeeExceptions, newItem] }));
  };

  handleInput = (value, row, column) => {
    const { oeeExceptions } = this.state;

    const rowId = row?.original?.id || row?.index;
    const isRowNew = row?.original?.addedOrEdited === 'added';

    let updatedData = [];
    if (!isRowNew) {
      updatedData = oeeExceptions?.map((item) => {
        if (item?.id === rowId) return { ...item, [column]: value, addedOrEdited: 'edited' };
        return item;
      });
    } else {
      updatedData = oeeExceptions?.map((item, index) => {
        if (index === rowId) return { ...item, [column]: value };
        return item;
      });
    }

    this.setState({ oeeExceptions: updatedData });
  };

  renderInputCell = (row, column) => {
    return (
      <input
        type="number"
        value={this.handleParseAndRoundNumber(row.value)}
        onChange={(e) => {
          // max one decimals in input
          const numberOfDecimals = this.getNumberOfDecimals(e.target.value);
          if (numberOfDecimals < 2) {
            this.handleInput(e.target.value, row, column);
          }
        }}
      />
    );
  };

  renderSelectCell = (row, column) => {
    const { lines, isLoadingLines, productGroups, isLoadingProductGroups } = this.state;

    switch (column) {
      case 'product_group':
        return (
          <Select
            className={!row?.value ? 'select-error' : ''}
            options={productGroups || []}
            isLoading={isLoadingProductGroups || false}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menuPosition="fixed"
            placeholder=""
            onChange={(e) => this.handleInput(e || null, row, column)}
            value={row?.value}
            styles={selectModalStyles}
          />
        );
      case 'line':
        return (
          <Select
            className={!row?.value ? 'select-error' : ''}
            options={lines || []}
            isLoading={isLoadingLines || false}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menuPosition="fixed"
            placeholder=""
            onChange={(e) => this.handleInput(e || null, row, column)}
            value={row?.value}
            styles={selectModalStyles}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const {
      asset,
      low,
      high,
      goal,
      oeeExceptions,
      isLoadingExceptions,
      isLoading,
    } = this.state;
    const {
      handleCloseModal,
      isModalOpen,
      isLoadingModal,
      t,
      oeeAssets,
    } = this.props;

    const oeeAssetsConfig = [
      { name: t('page_content.oee.modal.asset_option'), id: null },
      ...oeeAssets.sort((a, b) => a.name.localeCompare(b.name)).map((oeeAsset) => ({
        name: oeeAsset.name,
        id: oeeAsset.id,
      })),
    ];

    const filteredOeeExceptions = oeeExceptions?.filter((exception) => !exception?.isDeleted);

    const disableSave = filteredOeeExceptions?.some((exception) => !exception?.product_group || !exception?.line);

    return (
      <React.Fragment>
        <Modal
          size={modalSizes.medium}
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          handleSave={this.handleSaveOrEditOee}
          disableSave={disableSave}
        >
          {
            isLoadingModal || isLoading ?
              <ContentLoader /> :
              <div className="default-form">
                <table>
                  <tbody>
                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_asset')}
                      </td>
                      <td className="input">
                        <Select
                          cacheOptions
                          options={oeeAssetsConfig || []}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          menuPosition="fixed"
                          placeholder={t('page_content.oee.modal.asset_placeholder')}
                          onChange={(val) => this.onFieldChange('asset', val.id)}
                          value={(oeeAssetsConfig.find((oeeA) => oeeA.id === asset)) || ''}
                          styles={selectModalStyles}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_low')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => {
                            // max one decimals in input
                            const numberOfDecimals = this.getNumberOfDecimals(e.target.value);
                            if (numberOfDecimals < 2) this.onFieldChange('low', e.target.value);
                          }}
                          step="0.1"
                          value={this.handleParseAndRoundNumber(low)}
                          type="number"
                          disabled
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_high')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => {
                            // max one decimals in input
                            const numberOfDecimals = this.getNumberOfDecimals(e.target.value);
                            if (numberOfDecimals < 2) this.onFieldChange('high', e.target.value);
                          }}
                          value={this.handleParseAndRoundNumber(high)}
                          type="number"
                          step="0.1"
                          disabled
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_goal')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => {
                            // max one decimals in input
                            const numberOfDecimals = this.getNumberOfDecimals(e.target.value);
                            if (numberOfDecimals < 2) this.onFieldChange('goal', e.target.value);
                          }}
                          value={this.handleParseAndRoundNumber(goal)}
                          type="number"
                          step="0.1"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Button
                style={{ margin: '10px 0 10px 0' }}
                type="add"
                onClick={this.handleAddNewItem}>
                {t('page_content.oee.add_new_exception')}
                </Button>

                <Table
                  style={{ userSelect: 'text' }}
                  columns={[
                    {
                      Header: () => <span>{t('page_content.oee.product_group')}*</span>,
                      accessor: 'product_group',
                      Cell: (row) => this.renderSelectCell(row, 'product_group'),
                    },
                    {
                      Header: () => <span>{t('page_content.oee.table_column_goal')}</span>,
                      accessor: 'goal',
                      Cell: (row) => this.renderInputCell(row, 'goal'),
                    },
                    {
                      Header: () => <span>{t('page_content.oee.line')}*</span>,
                      accessor: 'line',
                      Cell: (row) => this.renderSelectCell(row, 'line'),
                    },
                  ]}
                  data={filteredOeeExceptions}
                  minRows={0}
                  defaultPageSize={200}
                  noDataText=" "
                  enableDelete
                  onDelete={(rowInfo) => { this.handleDeleteItem(rowInfo); }}
                  loading={isLoadingExceptions}
                />
              </div>
          }

        </Modal>
      </React.Fragment>
    );
  }
}

OEEGoalsModal.propTypes = {
  t: PropTypes.func.isRequired,
  oeeLowLimit: PropTypes.number,
  selectedOee: PropTypes.object,
  oeeHighLimit: PropTypes.number,
  company_short_code: PropTypes.string,
  oeeAssets: PropTypes.array.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  isLoadingModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSaveOrEditOee: PropTypes.func.isRequired,
  handleCloseAndRefetchModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  company_short_code: get(state, 'app.companyConfig.short_code', null),
  oeeHighLimit: get(state, 'app.companyConfig.config.oee_high_limit', 0),
  oeeLowLimit: get(state, 'app.companyConfig.config.oee_low_limit', 0),
});

export default connect(mapStateToProps)(OEEGoalsModal);
