import { getByURL, postByURL, putByURL, deleteAndNotifyByURL } from './CustomService';

export const getWorkOperations = async (filters = '') => {
  return getByURL(`/api/v1/work_operations/${filters}`);
};

export const postWorkOperation = async (filters = '', data) => {
  return postByURL(`/api/v1/work_operations/${filters}`, data);
};

export const putWorkOperation = async (id, filters = '', data) => {
  return putByURL(`/api/v1/work_operations/${id}/${filters}`, data);
};

export const deleteWorkOperation = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/work_operations/${id}/${filters}`);
};
