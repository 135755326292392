import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { IconWarning } from 'shared/Icons';
import { colors } from 'shared/colors';
import { ContentLoader } from 'shared';
import '../../styles.scss';

import { defaultDateFormat } from 'shared/constants';

import { getWorkforceWorkers, getSurveysWorkers, getSurveysQuestionOptions, getSurveysWorkerAnswers } from 'shared/Api';

const WorkProcessesRating = ({ t, companyId, selectedWorker, workProcessRatingCategoryCode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({});

  const [workProcesses, setWorkProcesses] = useState({
    data: [],
    isLoading: false,
  });

  const [valueOptions, setValueOptions] = useState({
    data: [],
    isLoading: false,
  });

  const [previousWorkProcesses, setPreviousWorkProcesses] = useState({
    isLoading: true,
    data: [],
    selectedItem: null,
  });

  const onWorkerEvaluationChange = async (val) => {
    setPreviousWorkProcesses((prevState) => ({ ...prevState, selectedItem: val }));
    setFormValues({});

    if (val) {
      setIsLoading(true);
      const apiFilters = `?worker_survey=${val.id}`;
      const answers = get(await getSurveysWorkerAnswers(apiFilters), 'data.results', []);
      const questions = answers
        ?.map((answer) => answer.question)
        ?.filter((question, index, self) => index === self.findIndex((q) => q.id === question.id));

      setWorkProcesses((prevState) => ({
        ...prevState,
        data: questions,
      }));

      const answerObject = answers?.reduce((acc, curr) => {
        if (curr) {
          const answerId = curr.question.id;
          if (curr.question.multi_select) {
            acc[answerId] = acc[answerId] || [];
            acc[answerId].push(curr);
          } else {
            acc[answerId] = curr;
          }
        }
        return acc;
      }, {});

      Object.entries(answerObject).forEach(([key, value]) => {
        if (value && Array.isArray(value)) {
          value?.sort((a, b) => (b?.answer || 0) - (a?.answer || 0));
          answerObject[key] = value;
        }
      });

      const questionsOptionsPromises = questions.map(
        async (option) => (option?.question_type === 'select' || option?.question_type === 'choice') &&
            // eslint-disable-next-line no-return-await
            await getSurveysQuestionOptions(`?question=${option.id}`).then((response) => get(response, 'data.results', [])),
      );

      const returnedOptions = await Promise.all(questionsOptionsPromises);
      const optionsObject = returnedOptions.reduce((acc, curr) => {
        const questionId = curr[0] && curr[0].question;
        acc[questionId] = curr;
        return acc;
      }, {});

      Object.entries(optionsObject).forEach(([key1, value1]) => {
        if (value1 && Array.isArray(value1)) {
          value1?.sort((a, b) => (b?.value || b?.order || 0) - (a?.value || a?.order || 0));
          optionsObject[key1] = value1;
        }
      });

      setValueOptions((prevState) => ({
        ...prevState,
        data: optionsObject,
      }));

      const filteredAnswers = {};
      Object.entries(answerObject).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          filteredAnswers[key] = [];
          value.forEach((value1) => {
            const answer = value1.answer;
            const matchingOptions = optionsObject[key]?.find((option) => option.value === answer);
            if (matchingOptions) {
              filteredAnswers[key].push(matchingOptions);
            }
          });
        } else if (value?.question?.question_type === 'free_text') filteredAnswers[key] = value?.text_answer;
        else if (value?.question?.question_type === 'date') filteredAnswers[key] = new Date(value?.date_answer);
        else if (value?.question?.question_type === 'number') filteredAnswers[key] = value?.answer;
        else {
          const answer = value.answer;
          const matchingOptions = optionsObject[key]?.find((option) => option.value === answer);
          if (matchingOptions) {
            filteredAnswers[key] = matchingOptions;
          }
        }
      });
      setFormValues(filteredAnswers);

      const apiFilters1 = `?company=${companyId}&user=${val.user}`;
      await getWorkforceWorkers(apiFilters1)
        .then((worker) => {
          worker = get(worker, 'data.results', null);
          worker = worker?.length > 0 && worker[0];
          setPreviousWorkProcesses((prevState) => ({
            ...prevState,
            selectedItem: {
              ...prevState.selectedItem,
              user: worker,
            },
          }));
        });

      setIsLoading(false);
    }
  };

  const fetchPreviousWorkProcesses = () => {
    setPreviousWorkProcesses((prevState) => ({ ...prevState, isLoading: true }));

    setIsLoading(true);

    const filters = `?worker=${selectedWorker.id}&category_code=${workProcessRatingCategoryCode}&order_by=-finish_time&is_finished=true`;

    getSurveysWorkers(filters)
      .then((res) => {
        const data = get(res, 'data.results', []);
        setPreviousWorkProcesses({ data, isLoading: false });
        if (data.length > 0) onWorkerEvaluationChange(data[0]);
        else setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchPreviousWorkProcesses();
  }, []);

  if (isLoading || workProcesses?.isLoading || valueOptions?.isLoading) {
    return <ContentLoader />;
  }

  if (!workProcessRatingCategoryCode) {
    return (
      <span className="ratings__screen__warning">
        <IconWarning color={colors.red} height="18px" width="18px" />
        <p>{t('page_content.administration.rating.no_category_code')}</p>
      </span>);
  }

  return (
    <div className="ratings__screen">
      <div style={{ width: '500px' }}>
        <Select
          options={previousWorkProcesses?.data}
          getOptionLabel={(option) => <span>{t('page_content.administration.rating.evaluation')} - {moment(option?.finish_time).format(defaultDateFormat)} - {option?.survey?.name}</span>}
          getOptionValue={(option) => option}
          isLoading={previousWorkProcesses?.isLoading}
          placeholder={t('page_content.administration.rating.view_previous_work_processes')}
          menuPosition="fixed"
          onChange={(e) => onWorkerEvaluationChange(e || null)}
          value={(previousWorkProcesses?.data?.find((sOption) => (sOption?.id === previousWorkProcesses?.selectedItem?.id))) || ''}
          styles={selectModalStyles}
        />
      </div>

      {
        previousWorkProcesses?.selectedItem?.user &&
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ fontWeight: '600', margin: 0 }}>
            {`${t('page_content.administration.rating.evaluator')}: ${previousWorkProcesses?.selectedItem?.user?.name} ${previousWorkProcesses?.selectedItem?.user?.last_name}`}
          </p>
        </div>
      }

      <div className="custom_inputs_view_work_processes">
        {
          workProcesses?.data?.length > 0 && workProcesses?.data?.map((value) => (
            <div key={value.id} className="modal_row">
                <div className="left_text">
                    <label>{value?.question}:</label>
                </div>
                {
                    ((value?.question_type === 'select' && !value?.multi_select) || value?.question_type === 'choice') &&
                    <p>{`${formValues?.[value.id]?.label} (${formValues?.[value.id]?.value} ${t('page_content.administration.rating.points')})`}</p>
                }
                {
                    (value?.question_type === 'select' && value?.multi_select && Array.isArray(formValues?.[value.id])) && (
                        <div className="right_multiple">
                            <p>{formValues?.[value.id]?.map(item => `${item?.label} (${item?.value} ${t('page_content.administration.rating.points')})`).join(', ')}</p>
                        </div>
                    )
                }
                {
                    (value?.question_type === 'free_text' || value?.question_type === 'number') &&
                    <p>{formValues?.[value.id]}</p>
                }
                {
                    value?.question_type === 'date' &&
                    <p>{moment(formValues?.[value.id]).format(defaultDateFormat)}</p>
                }
            </div>
          ))
        }
      </div>
    </div>
  );
};

WorkProcessesRating.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  selectedWorker: PropTypes.object.isRequired,
  workProcessRatingCategoryCode: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    companyId: get(state, 'app.company.id', null),
    workProcessRatingCategoryCode: get(state, 'app.location.config.work_process_rating_category_code', ''),
  };
};

export default connect(mapStateToProps)(withTranslation()(WorkProcessesRating));
