import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { ContentLoader, ReactSelectCreatable } from 'shared';

import { getRelatedWorkerInfo, getRelatedInfo } from '../../industry/containers/IndustryLocationHumanResources/actions';

const RelatedInfoFields = ({ t, companyId, workerId, handleWorkerRelatedInfoChange, isWorkerDetails = false, isAdministrationWorkerDetails = false }) => {
  const [formHelpers, setFormHelpers] = useState({
    isLoading: true,
    relatedInfo: [],
    workerRelatedInfo: [],
  });

  const fetchRelatedInfo = async () => {
    await getRelatedInfo(companyId, '&limit=9999&is_active=true')
      .then((resp) => setFormHelpers((prevState) => ({ ...prevState, relatedInfo: get(resp, 'data.results', []) })))
      .catch((error) => console.error(error));
  };

  const fetchRelatedWorkerInfo = async () => {
    setFormHelpers((prevState) => ({ ...prevState, isLoading: true }));
    let resp = {};
    if (workerId) resp = await getRelatedWorkerInfo(companyId, `&worker=${workerId}&limit=9999`);
    const response = get(resp, 'data.results', []);
    const newWorkerRelatedInfo = formHelpers?.relatedInfo?.map((info) => {
      const relatedInfo = response.find((r) => r.info.id === info.id);

      if (relatedInfo) return relatedInfo;

      return { id: null, info, data: null };
    });
    setFormHelpers((prevState) => ({ ...prevState, workerRelatedInfo: newWorkerRelatedInfo || [], isLoading: false }));
  };

  useEffect(() => {
    fetchRelatedInfo();
  }, [workerId]);

  useEffect(() => {
    if (formHelpers?.relatedInfo.length > 0) fetchRelatedWorkerInfo();
  }, [formHelpers?.relatedInfo]);

  const fieldDependencies = {
    // company_car_registration: {
    //   dependsOn: 'company_car',
    //   condition: (data) => data?.value === 'true' ? true : false,
    // },
    // Add more fields and dependencies here if needed
  };

  const handleChangeWorkerRelatedInfo = (infoId, value) => {
    const newWorkerRelatedInfo = formHelpers?.workerRelatedInfo?.map((info) => {
      if (info.info.id === infoId && info.info.value_type === 'bool') {
        return {
          ...info,
          data: { value: `${value?.target?.checked}` || `${false}`},
          addedOrEdited: info.id ? 'edited' : 'added',
        };
      }
      if (info.info.id === infoId && info.info.value_type === 'text') {
        return {
          ...info,
          data: { value: value?.target?.value || ''},
          addedOrEdited: info.id ? 'edited' : 'added',
        };
      }
      if (info.info.id === infoId && info.info.value_type === 'list') {
        return {
          ...info,
          data: { value: value || []},
          addedOrEdited: info.id ? 'edited' : 'added',
        };
      }
      return info;
    });

    setFormHelpers((prevState) => ({ ...prevState, workerRelatedInfo: newWorkerRelatedInfo }));
    handleWorkerRelatedInfoChange(newWorkerRelatedInfo);
  };

  const checkDependency = (info, workerRelatedInfo, fieldDependencies) => {
    const dependency = fieldDependencies[info.info.code];
    if (dependency) {
      const dependentData = workerRelatedInfo.find((i) => i.info.code === dependency.dependsOn)?.data;
      return dependency.condition(dependentData);
    }
    return true;
  };

  const modalRelatedInfoFields = () => {
    return (
      formHelpers?.workerRelatedInfo?.length > 0 && (
        formHelpers?.workerRelatedInfo.map((info) => {
          if (!info?.info?.is_active || !checkDependency(info, formHelpers.workerRelatedInfo, fieldDependencies)) return null;

          switch (info?.info?.value_type) {
            case 'bool':
              return (
                  <div key={info?.info?.id} className="modal_row">
                    <div className="left_text">{t(`page_content.human_resources.workers.worker_related_info_${info?.info?.code}`)}:</div>
                    <div className="right_input">
                      <input type="checkbox" checked={(info?.data?.value && info?.data?.value === 'true' ? true : false) || info?.info?.default_data || false} onChange={(val) => handleChangeWorkerRelatedInfo(info.info.id, val)} />
                    </div>
                  </div>
              );
            case 'text':
              return (
                  <div key={info?.info?.id} className="modal_row">
                    <div className="left_text">{t(`page_content.human_resources.workers.worker_related_info_${info?.info?.code}`)}:</div>
                    <div className="right_input_with_limit">
                      <input
                        type="text"
                        maxLength={info.info.max_size || undefined}
                        value={info?.data?.value || info?.info?.default_data || ''}
                        onChange={(val) => handleChangeWorkerRelatedInfo(info?.info?.id, val)}
                      />
                      <span>{`${info?.data?.value?.length || info?.info?.default_data?.length}/${info.info.max_size}`}</span>
                    </div>
                  </div>
              );
            case 'list':
              const initialValues = info?.data?.value.length > 0 ? info.data.value : [];
              return (
                  <div key={info?.info?.id} className="modal_row">
                    <div className="left_text">{t(`page_content.human_resources.workers.worker_related_info_${info?.info?.code}`)}:</div>
                    <div className="right_select">
                      <ReactSelectCreatable
                        placeholder={t(`page_content.human_resources.workers.worker_related_info_${info?.info?.code}`)}
                        initialValue={initialValues}
                        onChange={(val) => handleChangeWorkerRelatedInfo(info?.info?.id, val)}
                      />
                    </div>
                  </div>
              );

            default:
              return null;
          }
        })
      )
    );
  };

  const detailsRelatedInfoFields = () => {
    return (
        formHelpers?.workerRelatedInfo?.length > 0 && (
            formHelpers?.workerRelatedInfo.map((info) => {
            if (!info?.info?.is_active || !checkDependency(info, formHelpers.workerRelatedInfo, fieldDependencies)) return null;

            switch (info?.info?.value_type) {
            case 'bool':
                return (
                <div key={info?.info?.id} className={isAdministrationWorkerDetails ? 'worker-details__general-info-table__row' : 'row'}>
                    {t(`page_content.human_resources.workers.worker_related_info_${info.info.code}`)}:
                    <span>
                    {(info?.data?.value === 'true' ? true : false) ?
                        t('page_content.human_resources.workers.worker_related_info_bool_true') :
                        t('page_content.human_resources.workers.worker_related_info_bool_false')}
                    </span>
                </div>
                );
            case 'text':
                return (
                <div key={info?.info?.id} className={isAdministrationWorkerDetails ? 'worker-details__general-info-table__row' : 'row'}>
                    {t(`page_content.human_resources.workers.worker_related_info_${info.info.code}`)}:
                    <span>{info?.data?.value || info.info.default_data || ''}</span>
                </div>
                );

            default:
                return null;
            }
        })
        )
    );
  };

  return ( formHelpers?.isLoading ? <ContentLoader/> : isWorkerDetails ? detailsRelatedInfoFields() : modalRelatedInfoFields());
};

RelatedInfoFields.propTypes = {
  t: PropTypes.func,
  workerId: PropTypes.number,
  isWorkerDetails: PropTypes.bool,
  isAdministrationWorkerDetails: PropTypes.bool,
  companyId: PropTypes.number.isRequired,
  handleWorkerRelatedInfoChange: PropTypes.func,
};

export default (withTranslation()(RelatedInfoFields));
