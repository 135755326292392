import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import api from 'helpers/api';
import {
  Button,
  Notification,
  ConfirmationModal,
  ReactDatePicker,
} from 'shared';
import { deleteFactorValue } from '../../actions';
import './styles.scss';

class FactorValueForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      factorValueDate: props.factorValueDate,
      factorValueValue: props.factorValueValue,
      deleteData: null,
      showConfirmationDialog: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.isSaveDisabled = this.isSaveDisabled.bind(this);
    this.updateDatetime = this.updateDatetime.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  isSaveDisabled = () => {
    const {
      factorValueValue,
      factorValueDate,
    } = this.state;

    if (factorValueValue !== null && factorValueValue.toString() !== '' && typeof factorValueDate === 'object') {
      return false;
    }

    return true;
  }

  handleSave() {
    const {
      factorValueValue,
      factorValueDate,
    } = this.state;

    const {
      factorValueId,
      reloadEntries,
      companyId,
      factorId,
      t,
    } = this.props;

    const factorValue = {
      company: companyId,
      factor: factorId,
      value: factorValueValue,
      datetime: factorValueDate,
    };

    if (factorValueId > 0) {
      factorValue.id = factorValueId;
      api.patch(`/api/v1/reports/factor_values/${factorValueId}/?factor=${factorId}&company=${companyId}`, factorValue)
        .then(() => {
          reloadEntries(true);
          return Notification('success', t('page_content.automatic_reports.factor_values.success_update'));
        })
        .catch(() => {
          return Notification('error', 'An error occurred', t('page_content.automatic_reports.request_error'));
        });
      // eslint-disable-next-line
    } else {
      api.post('/api/v1/reports/factor_values/', factorValue)
        .then(() => {
          reloadEntries(true);
          return Notification('success', t('page_content.automatic_reports.factor_values.success_create'));
        })
        .catch(() => {
          return Notification('error', 'An error occurred', t('page_content.automatic_reports.request_error'));
        });
    }
  }

  handleShowConfirmationModal = (factorValueId) => {
    this.setState({ showConfirmationDialog: true, deleteData: factorValueId });
  }

  handleDelete() {
    const {
      companyId,
      factorId,
      reloadEntries,
      t,
    } = this.props;
    const id = this.state.deleteData;
    deleteFactorValue(id, factorId, companyId)
      .then(() => {
        reloadEntries(true);
        return Notification('success', 'Delete successful', t('page_content.automatic_reports.factor_values.success_delete'));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => this.setState({ showConfirmationDialog: false }));
  }

  updateDatetime = (fieldValue) => {
    this.setState({ factorValueDate: fieldValue });
  }

  updateValue = (fieldValue) => {
    this.setState({ factorValueValue: fieldValue });
  }

  render() {
    const {
      factorValueDate,
      factorValueValue,
    } = this.state;

    const {
      factorValueId,
      t,
    } = this.props;

    const buttonLabel = (factorValueId > 0)
      ? t('page_content.automatic_reports.edit')
      : t('page_content.automatic_reports.create');

    const isSaveDisabled = this.isSaveDisabled();

    const factorDate = factorValueDate ? moment(factorValueDate).toDate() : '';

    return (
      <div className="formRow">
        <div className="section">
          <div className="label">
            {`${t('page_content.automatic_reports.date')}:`}
          </div>
          <div>
            <ReactDatePicker
              placeholderText={t('page_content.automatic_reports.date')}
              selected={factorDate}
              onChange={this.updateDatetime}
              showTimeSelect
              timeCaption={t('date_picker_locale.time_translation')}
            />
          </div>

        </div>
        <div className="section">
          <div className="label">
            {`${t('page_content.automatic_reports.value')}:`}
          </div>
          <input
            type="number"
            value={factorValueValue}
            onChange={(e) => this.updateValue(e.target.value)}
            step="0.00001"
            required
            id="id_value"
          />
          <Button
            type={isSaveDisabled ? 'plain' : 'success'}
            size="small"
            disabled={isSaveDisabled}
            onClick={this.handleSave}
          >
            {buttonLabel}
          </Button>
          {factorValueId > 0 &&
            <Button
              size="small"
              type="delete"
              onClick={() => this.handleShowConfirmationModal(factorValueId)}
            >
              {t('page_content.automatic_reports.delete')}
            </Button>}
        </div>
        <ConfirmationModal
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDelete}
          type="warning"
        />
      </div>
    );
  }
}

FactorValueForm.propTypes = {
  companyId: PropTypes.number.isRequired,
  factorId: PropTypes.number.isRequired,
  factorValueId: PropTypes.number.isRequired,
  factorValueDate: PropTypes.object.isRequired,
  factorValueValue: PropTypes.string.isRequired,
  reloadEntries: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(FactorValueForm);
