import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, Modal, Button } from 'shared';
import { defaultDateFormat } from 'shared/constants';
import { IconPopup } from 'shared/Icons';

import { getWorkforceDocuments } from 'shared/Api';

const WorkContractsModal = ({ t, handleCloseModal, isOpen, workerId, companyId }) => {
  const [tableData, setTableData] = useState({
    contracts: [],
    isLoading: false,
  });

  const fetchWorkerWorkContracts = async () => {
    setTableData({ ...tableData, isLoading: true });

    const apiFilters = `?company=${companyId}&worker=${workerId}&is_mid=true&order_by=-updated_at&limit=999`;
    getWorkforceDocuments(apiFilters)
      .then((response) => setTableData({ contracts: get(response, 'data.results', []), isLoading: false }));
  };

  useEffect(() => {
    fetchWorkerWorkContracts();
  }, []);

  const handleDownloadDocument = (row) => {
    const link = document.createElement('a');
    link.href = row.file;
    link.target = '_blank';
    link.setAttribute('download', row.name);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const tableColumns = [
    {
      Header: () => <span>{t('page_content.administration.work_contracts_modal.table_column_created_at')}</span>,
      accessor: 'created_at',
      Cell: (row) => (row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.administration.work_contracts_modal.table_column_name')}</span>,
      accessor: 'name',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.administration.work_contracts_modal.table_column_type')}</span>,
      accessor: 'document_type.name',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.administration.work_contracts_modal.table_column_valid_until')}</span>,
      accessor: 'valid_until',
      Cell: (row) => (row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.administration.work_contracts_modal.table_column_open')}</span>,
      width: 100,
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
        <div onClick={() => handleDownloadDocument(row.original)}>
          <Button size="small">
            <IconPopup
              height="16px"
              width="16px"
              color="#555"
            />
          </Button>
        </div>
      </div>),
    },
  ];

  return (
    <Modal
      title={t('page_content.administration.work_contracts_modal.title')}
      isOpen={isOpen}
      handleClose={handleCloseModal}
    >
      <Table
        style={{ userSelect: 'text', marginTop: 20 }}
        columns={tableColumns}
        data={tableData.contracts || []}
        minRows={0}
        defaultPageSize={30}
        sortable
        showPagination={false}
        loading={tableData.isLoading}
        manual={false}
        getTrProps={(state, rowInfo) => {
          const validUntilDate = moment(rowInfo.original.valid_until);
          const today = moment();

          let backgroundColor = null;

          if (validUntilDate.isSameOrBefore(today)) backgroundColor = '#ffcccc';

          return {
            style: { backgroundColor },
          };
        }}
      />
    </Modal>
  );
};

WorkContractsModal.propTypes = {
  t: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  workerId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
};

export default (withRouter(withTranslation()(WorkContractsModal)));
