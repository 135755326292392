import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Router,
  Route,
  Switch,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

// import AccountContainer from './account/AccountContainer/index';
import CompanyRedirect from './company/CompanyRedirect';
import IndustryLocationContainer from './industry/IndustryLocationContainer';

import AuthContainer from './app/AuthContainer';
import AppContainer from './app/AppContainer';
import './App.scss';
import CustomCSS from './app/CustomCSS/index';
import { ContentLoader } from './shared/index';

const history = createBrowserHistory();

class App extends Component {
  constructor() {
    super();
    this.state = {
      isLoadingCustomCss: true,
      customCss: null,
      customLogo: null,
      customLoginBackground: null,
      customLoginLogo: null,
      customTitle: null,
      customFaviconLogo: null,
    };
  }

  componentDidMount() {
    this.addCustomCss();
  }

  addCustomCss = async () => {
    const currentUrl = window.location.href;

    let customCss = null;
    let customLogo = null;
    let customLoginBackground = null;
    let customLoginLogo = null;
    let customTitle = null;
    let customFaviconLogo = null;

    if (currentUrl.includes('telekomcloud')) {
      const htStyles = await import('./app/CustomCssHT/styles');
      const htHeaderLogo = await import('./app/CustomCssHT/logo.svg');
      const htLoginBackground = await import('./app/CustomCssHT/login_background.jpg');
      const htLoginLogo = await import('./app/CustomCssHT/login_logo.svg');
      const htFaviconLogo = await import('./app/CustomCssHT/login_logo.svg');

      customCss = htStyles.styles;
      customLogo = htHeaderLogo.default;
      customLoginBackground = htLoginBackground.default;
      customLoginLogo = htLoginLogo.default;
      customFaviconLogo = htFaviconLogo.default;
      customTitle = 'Pametna tvornica';
    }

    this.setState({
      customCss,
      customLogo,
      customLoginBackground,
      customLoginLogo,
      isLoadingCustomCss: false,
      customTitle,
      customFaviconLogo,
    });
  };

  render() {
    const { store } = this.props;
    const { customCss, isLoadingCustomCss, customLogo, customLoginBackground, customLoginLogo, customTitle, customFaviconLogo } = this.state;

    if (isLoadingCustomCss) {
      return <ContentLoader />;
    }

    return (
      <div>
        <CustomCSS
          css={customCss}
          customLogo={customLogo}
          customLoginBackground={customLoginBackground}
          customLoginLogo={customLoginLogo}
          customTitle={customTitle}
          customFaviconLogo={customFaviconLogo}
        />
        <Provider store={store}>
          <Router history={history}>
            <Switch>
              <AuthContainer>
                <AppContainer customLogo={customLogo}>
                  <Switch>
                    <Route exact path="/:companyId/" component={CompanyRedirect} />
                    <Route path="/:companyId/industry/location/:locationId" component={IndustryLocationContainer} />
                  </Switch>
                </AppContainer>
              </AuthContainer>
            </Switch>
          </Router>
        </Provider>
      </div>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
