import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Notification, AccessControlledNavLink } from 'shared';
import api from 'helpers/api';
import PropTypes from 'prop-types';
import './style.scss';

class SettingsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionList: {},
    };
  }

  componentDidMount() {
    this.getPermissionList();
  }

  getPermissionList = () => {
    const { companyId } = this.props;

    api.get(`/api/v1/permission_list/?company=${companyId}`)
      .then((res) => {
        this.setState({
          permissionList: res.data || {},
        });
      })
      .catch((error) => Notification('error', 'An error occurred while fetching permission list', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.'));
  }

  render() {
    const { permissionList } = this.state;

    const {
      companyId,
      locationId,
      history,
      t,
      userIsCompanyAdmin,
      currentUser,
    } = this.props;

    return (
      <div className="settings_menu fullscreen">
        <Button onClick={() => { history.push(`/${companyId}/industry/location/${locationId}/`); }}>
          {t('settings.back_button')}
        </Button>
        <nav className="settings_menu_nav">
          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            permissionList={permissionList}
            moduleUrlName="asset-utilization"
            linkTo={`/${companyId}/industry/location/${locationId}/settings/asset-utilization`}
            navText={t('settings.tabs.asset_utilization')}
            checkAccessToModule
            isSettings
          />

          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            permissionList={permissionList}
            moduleUrlName="timing-settings"
            linkTo={`/${companyId}/industry/location/${locationId}/settings/timing-settings`}
            navText={t('settings.tabs.timing_settings')}
            checkAccessToModule
            isSettings
          />

          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            permissionList={permissionList}
            moduleUrlName="product-types"
            linkTo={`/${companyId}/industry/location/${locationId}/settings/product-types`}
            navText={t('settings.tabs.product_types')}
            checkAccessToModule
            isSettings
          />

          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            permissionList={permissionList}
            moduleUrlName="product-groups"
            linkTo={`/${companyId}/industry/location/${locationId}/settings/product-groups`}
            navText={t('settings.tabs.product_groups')}
            checkAccessToModule
            isSettings
          />
          {/*
          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            permissionList={permissionList}
            moduleUrlName={'maintenance-reasons'}
            linkTo={`/${companyId}/industry/location/${locationId}/settings/maintenance-reasons`}
            navText={t('settings.tabs.maintenance_reasons')}
            checkAccessToModule
            isSettings
          /> */}

          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            permissionList={permissionList}
            moduleUrlName="operator-view"
            linkTo={`/${companyId}/industry/location/${locationId}/settings/operator-view`}
            navText={t('settings.tabs.operator_view')}
            checkAccessToModule
            isSettings
          />

          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            permissionList={permissionList}
            moduleUrlName="user-role-management"
            linkTo={`/${companyId}/industry/location/${locationId}/settings/user-role-management`}
            navText={t('settings.tabs.user_role_management')}
            checkAccessToModule
            isSettings
          />

          {
            currentUser && currentUser.is_staff ?
              <NavLink
                exact
                to={`/${companyId}/industry/location/${locationId}/settings/ascalia-audit-log`}
                activeClassName="active"
              >
                {t('settings.tabs.ascalia_audit_log')}

              </NavLink> :
              (window.userIsCompanyAdmin || userIsCompanyAdmin) ?
                <NavLink
                  exact
                  to={`/${companyId}/industry/location/${locationId}/settings/ascalia-audit-log`}
                  activeClassName="active"
                >
                  {t('settings.tabs.ascalia_audit_log')}

                </NavLink> : ''
          }

          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            permissionList={permissionList}
            moduleUrlName="partners"
            linkTo={`/${companyId}/industry/location/${locationId}/settings/partners`}
            navText={t('settings.tabs.partners')}
            checkAccessToModule
            isSettings
          />

          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            permissionList={permissionList}
            moduleUrlName="shifts"
            linkTo={`/${companyId}/industry/location/${locationId}/settings/shifts`}
            navText={t('settings.tabs.shifts')}
            checkAccessToModule
            isSettings
          />

          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            permissionList={permissionList}
            moduleUrlName="department"
            linkTo={`/${companyId}/industry/location/${locationId}/settings/department`}
            navText={t('settings.tabs.department')}
            checkAccessToModule
            isSettings
          />

          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            permissionList={permissionList}
            moduleUrlName="technology"
            linkTo={`/${companyId}/industry/location/${locationId}/settings/technology`}
            navText={t('settings.tabs.technology')}
            checkAccessToModule
            isSettings
          />

          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            permissionList={permissionList}
            moduleUrlName="notifications"
            linkTo={`/${companyId}/industry/location/${locationId}/settings/notifications`}
            navText={t('settings.tabs.notifications')}
            checkAccessToModule
            isSettings
          />

          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            permissionList={permissionList}
            moduleUrlName="events-and-subscribers"
            linkTo={`/${companyId}/industry/location/${locationId}/settings/events-and-subscribers`}
            navText={t('settings.tabs.events_and_subscribers')}
            checkAccessToModule
            isSettings
          />

          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            permissionList={permissionList}
            moduleUrlName="custom-data"
            linkTo={`/${companyId}/industry/location/${locationId}/settings/custom-data`}
            navText={t('settings.tabs.custom_data')}
            checkAccessToModule
            isSettings
          />

          {
            currentUser && currentUser.is_admin ?
              <NavLink
                exact
                to={`/${companyId}/industry/location/${locationId}/settings/configs`}
                activeClassName="active"
              >
                Configs
              </NavLink> : ''
          }
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userIsCompanyAdmin: state.app.userIsCompanyAdmin,
    company: state.app.company,
    currentUser: state.currentUser,
  };
};

SettingsMenu.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  userIsCompanyAdmin: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, null)((withTranslation()(SettingsMenu))));
