import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './style.scss';

import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';

import { getIndustryAssets, getShifts, postShift, putShift, deleteShift, getByURL } from 'shared/Api';

import Shifts from './components/Shifts/index';

class ShiftsTab extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    isFetchingShifts: true,
  };

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) redirectToSettingsPage(companyId, locationId);
        else if (access === 1) this.setState({ isReadOnly: true });
      });

    this.getShifts();

    const apiFilters = `?company=${companyId}&fat=1&limit=100`;
    getIndustryAssets(apiFilters)
      .then((re) => this.setState({ assets: get(re, 'data.results', []) }));
  }

  getPaginatedShifts = (url) => {
    this.setState({ isFetchingShifts: true });

    getByURL(url)
      .then((re) => this.setState({
        shifts: get(re, 'data.results', []),
        next: get(re, 'data.next', 0),
        previous: get(re, 'data.previous'),
        count: get(re, 'data.count'),
        isFetchingShifts: false,
      }));
  }

  getShifts = () => {
    const { locationId } = this.props;

    const apiFilters = `?location=${locationId}&limit=30`;
    getShifts(apiFilters)
      .then((re) => this.setState({
        shifts: get(re, 'data.results', []),
        next: get(re, 'data.next', 0),
        previous: get(re, 'data.previous'),
        count: get(re, 'data.count'),
        isFetchingShifts: false,
      }));
  }

  deleteShift = (shift) => {
    const { locationId } = this.props;
    this.setState({ isFetchingShifts: true });

    const apiFilters = `?location=${locationId}`;
    deleteShift(shift.id, apiFilters).then(() => this.getShifts());
  }

  saveShift = (shift) => {
    const { locationId } = this.props;
    this.setState({ isFetchingShifts: true });

    const apiFilters = `?location=${locationId}`;
    if (shift.id) {
      putShift(shift.id, apiFilters, shift).then(() => this.getShifts());
    } else {
      postShift(apiFilters, shift).then(() => this.getShifts());
    }
  }

  render() {
    const { locationId } = this.props;
    const { assets, shifts, isReadOnly, isFetchingShifts, next, previous, count } = this.state;

    return (
      <div className="default-form shifts-tab">
        <Shifts
          next={next}
          count={count}
          shifts={shifts}
          assets={assets}
          previous={previous}
          locationId={locationId}
          isReadOnly={isReadOnly}
          saveShift={this.saveShift}
          deleteShift={this.deleteShift}
          isFetchingShifts={isFetchingShifts}
          fetchShifts={this.getPaginatedShifts}
        />
      </div>);
  }
}

ShiftsTab.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  //   t: PropTypes.func.isRequired,
};

export default withTranslation()(ShiftsTab);
