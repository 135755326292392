import api from 'helpers/api';
import { handleApiError, validateApiResponse, validateAndNotifyApiResponse } from './helpers';

// -------------------- BASE API CALL WITHOUT NOTIFICATION -------------------- //
export const getByURL = async (url) => {
  try {
    const res = await api.get(url);
    validateApiResponse(res);
    return res;
  } catch (error) {
    return handleApiError(error);
  }
};

export const getByURLBlob = async (url) => {
  try {
    const res = await api.get(url, { responseType: 'blob' });
    validateApiResponse(res);
    return res;
  } catch (error) {
    return handleApiError(error);
  }
};

export const postByURL = async (url, data) => {
  try {
    const res = await api.post(url, data);
    validateApiResponse(res);
    return res;
  } catch (error) {
    return handleApiError(error);
  }
};

export const putByURL = async (url, data) => {
  try {
    const res = await api.put(url, data);
    validateApiResponse(res);
    return res;
  } catch (error) {
    return handleApiError(error);
  }
};

export const patchByURL = async (url, data) => {
  try {
    const res = await api.patch(url, data);
    validateApiResponse(res);
    return res;
  } catch (error) {
    return handleApiError(error);
  }
};

export const deleteByURL = async (url) => {
  try {
    const res = await api.delete(url);
    validateApiResponse(res);
    return res;
  } catch (error) {
    return handleApiError(error);
  }
};

// -------------------- BASE API CALL WITH NOTIFICATION -------------------- //
export const postAndNotifyByURL = async (url, data) => {
  try {
    const res = await api.post(url, data);
    validateAndNotifyApiResponse(res);
    return res;
  } catch (error) {
    return handleApiError(error);
  }
};

export const patchAndNotifyByURL = async (url, data) => {
  try {
    const res = await api.patch(url, data);
    validateAndNotifyApiResponse(res);
    return res;
  } catch (error) {
    return handleApiError(error);
  }
};

export const putAndNotifyByURL = async (url, data) => {
  try {
    const res = await api.put(url, data);
    validateAndNotifyApiResponse(res);
    return res;
  } catch (error) {
    return handleApiError(error);
  }
};

export const deleteAndNotifyByURL = async (url) => {
  try {
    const res = await api.delete(url);
    validateAndNotifyApiResponse(res);
    return res;
  } catch (error) {
    return handleApiError(error);
  }
};

// ------------------- CUSTOM DATA ---------------- //
export const getCompanyTables = async (filters = '') => {
  return getByURL(`/api/v1/company-tables/${filters}`);
};

// -------------------- KFK -------------------- //
export const postKFKProfileDefinitions = async (data) => {
  return postAndNotifyByURL('/api/v1/upload_kfk_profile_definitions/', data);
};
