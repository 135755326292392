import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import api from 'helpers/api';
import Select from 'react-select';
import { defaultDateTimeFormat } from 'shared/constants';
import { Table, Button, TableButtons, ValuesMapper } from 'shared';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { IconSearch } from 'shared/Icons';
import { numberSeparatorFormat } from 'industry/helpers';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import 'react-table/react-table.css';
import '../styles.scss';
import { getWarehousePallets, getPaginatedWarehousePallets, getPartners, getListOfWarehouses, issuePallets, issueToProduction, splitPallet, addPallet } from '../actions';
import IssuePalletsModal from './IssuePalletsModal';
import SplitPalletsModal from './SplitPalletsModal';
import AddPalletsModal from './AddPalletsModal';

class Pallets extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    this.state = {
      pallets: [],
      previous: null,
      next: null,
      isLoadingPallets: false,
      selectedPallet: null,
      isIssuePalletsModalOpen: false,
      partners: [],
      listOfWarehouses: [],
      showFilter: {},
      selectedWarehouse: {},
      issuedFilter: {},
      externalIdQuery: '',
      productNameQuery: '',
      isAddPalletsModalOpen: false,
      selectedAscDesc: 'desc',
      selectedSort: 'updated_at',
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    this.fetchWarehousePallets();

    getPartners(companyId)
      .then((res) => {
        this.setState({
          partners: get(res, 'data.results') || [],
        });
      });

    getListOfWarehouses(locationId)
      .then((res) => {
        this.setState({
          listOfWarehouses: get(res, 'data.results') || [],
        });
      });
  }

  onFilterChange = (key, value) => {
    if (key === 'externalIdQuery' || key === 'productNameQuery') {
      this.setState({
        [key]: value,
      });
      if (this.timerRef.current) {
        clearTimeout(this.timerRef.current);
        this.timerRef.current = undefined;
      }
      this.timerRef.current = setTimeout(() => {
        this.timerRef.current = undefined;
        if (value.length < 1 || value.length >= 3) {
          this.fetchWarehousePallets();
        }
      }, 500);
    } else {
      this.setState({
        [key]: value,
      }, () => {
        this.fetchWarehousePallets();
      });
    }
  }

  fetchWarehousePallets = () => {
    const { showFilter, selectedWarehouse, issuedFilter, externalIdQuery, productNameQuery, selectedAscDesc, selectedSort } = this.state;
    const { companyId, locationId, order } = this.props;

    this.setState({
      isLoadingPallets: true,
    });

    const orderId = order && order.id ? order.id : null;
    let filters = '';

    if (externalIdQuery) {
      filters += `&external_id_contains=${externalIdQuery}`;
    }

    if (productNameQuery) {
      filters += `&product_name=${productNameQuery}`;
    }

    if (orderId) {
      filters += `&order=${orderId}`;
    }

    if (selectedWarehouse && selectedWarehouse.id) {
      filters += `&warehouse=${selectedWarehouse.id}`;
    }

    if (showFilter.value) {
      filters += `&stock_status=${showFilter.value}`;
    }

    if (issuedFilter.value) {
      filters += `&issued_to_partner=${issuedFilter.value}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort || 'updated_at'}`;

    getWarehousePallets(companyId, locationId, filters)
      .then((re) => {
        this.setState({
          pallets: get(re, 'data.results') || [],
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isLoadingPallets: false,
        });
      });
  }

  fetchPaginatedWarehousePallets = (url) => {
    this.setState({
      isLoadingPallets: true,
    });
    getPaginatedWarehousePallets(url)
      .then((re) => {
        this.setState({
          pallets: get(re, 'data.results') || [],
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isLoadingPallets: false,
        });
      })
      .catch((e) => console.error('Error while fetching pallets', e));
  }

  clearFilters = () => {
    this.setState({
      externalIdQuery: '',
      productNameQuery: '',
      showFilter: {},
      selectedWarehouse: {},
      issuedFilter: {},
    }, () => {
      this.fetchWarehousePallets();
    });
  }

  issuePallets = (data) => {
    const { companyId, locationId } = this.props;

    this.setState({ isIssuePalletsModalOpen: false, isLoadingPallets: true });
    issuePallets(companyId, locationId, data)
      .then(() => {
        this.fetchWarehousePallets();
      });
  }

  issueToProduction = (data) => {
    const { companyId, locationId } = this.props;

    this.setState({ isIssuePalletsModalOpen: false, isLoadingPallets: true });
    issueToProduction(companyId, locationId, data)
      .then(() => {
        this.fetchWarehousePallets();
      });
  }

  openIssuePalletsModal = (row) => {
    const selectedPallet = row && row.original && row.original.palletstock_set && row.original.palletstock_set[0] ? row.original.palletstock_set[0] : null;
    const palletId = row && row.original && row.original.id ? row.original.id : null;
    const selectedPalletRoot = row?.original ? row.original : null;
    const isParentPallet = !row?.original?.palletstock_set[0] && row?.original?.children_pallets?.length > 0 && row?.original?.parent_pallet === null;

    this.setState({
      palletId,
      selectedPallet,
      selectedPalletRoot,
      isParentPallet,
      isIssuePalletsModalOpen: true,
    });
  }

  closeIssuePalletsModal = () => {
    this.setState({
      isIssuePalletsModalOpen: false,
    });
  }

  splitPallets = (id, data) => {
    const { companyId, locationId } = this.props;

    this.setState({ isSplitPalletsModalOpen: false, isLoadingPallets: true });
    splitPallet(companyId, locationId, id, data)
      .then((res) => {
        const file = new Blob([res.data], { type: 'application/pdf;base64' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.fetchWarehousePallets();
      });
  }

  openSplitPalletsModal = (row) => {
    const selectedPallet = row && row.original && row.original.palletstock_set && row.original.palletstock_set[0] ? row.original.palletstock_set[0] : null;
    const palletId = row && row.original && row.original.id ? row.original.id : null;

    this.setState({
      palletId,
      selectedPallet,
      isSplitPalletsModalOpen: true,
    });
  }

  closeSplitPalletsModal = () => {
    this.setState({
      isSplitPalletsModalOpen: false,
    });
  }

  handlePrint = (row) => {
    const { companyId, locationId } = this.props;

    const pallet_id = row && row.value ? row.value : null;

    if (pallet_id) {
      api.get(`/api/v1/wms/pallets/${pallet_id}/print/?company=${companyId}&location=${locationId}`, {
        responseType: 'arraybuffer',
      })
        .then((res) => {
          const file = new Blob([res.data], { type: 'application/pdf;base64' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        });
    }
  }

  handleQueryClear = (key) => {
    this.setState({
      [key]: '',
    }, () => {
      this.fetchWarehousePallets();
    });
  }

  openAddPalletsModal = () => {
    this.setState({
      isAddPalletsModalOpen: true,
    });
  }

  closeAddPalletsModal = () => {
    this.setState({
      isAddPalletsModalOpen: false,
    });
  }

  addPallets = async (data) => {
    const { locationId } = this.props;

    this.setState({ isAddPalletsModalOpen: false });

    const res = await addPallet({ ...data, location: locationId });

    if (res?.status === 200) {
      const file = new Blob([res?.data], { type: 'application/pdf;base64' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
    this.fetchWarehousePallets();
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchWarehousePallets();
    });
  }

  handleTableChildrenRowStyle = (level) => {
    if (level === 0) {
      return;
    }
    const colorType = level % 2 === 0 ? 'dark' : 'light';
    switch (colorType) {
      case ('light'):
        return {
          background: '#DDF2FD',
        };
      case ('dark'):
        return {
          background: '#9BBEC8',
        };
      default: return {};
    }
  };

  renderTable = (pallets, level) => {
    const { t, i18n, companyId, locationId } = this.props;
    const { isLoadingPallets, isReadOnly } = this.state;

    const currentLevel = level + 1;
    return (
      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span> </span>,
            expander: true,
            width: 100,
            // eslint-disable-next-line react/prop-types
            Expander: ({ isExpanded, ...rest }) => {
              return (
                  <div>{rest?.original?.children_pallets?.length ?
                    isExpanded ?
                    <span className="pallets_showChildren_button_no">{t('page_content.warehouse.pallets_table.collapse')}</span> :
                    <span className="pallets_showChildren_button_yes">{t('page_content.warehouse.pallets_table.show')}</span> :
                    null}</div>
              );
            },
          },
          {
            Header: () => <span>{t('page_content.warehouse.pallets_table.id')}</span>,
            accessor: 'external_id',
          },
          {
            Header: () => <span>{t('page_content.warehouse.pallets_table.updated_at')}</span>,
            accessor: 'updated_at',
            Cell: (row) => (row && row.value ? moment(row.value).format(defaultDateTimeFormat) : ''),
          },
          {
            Header: () => <span>{t('page_content.warehouse.pallets_table.parent_pallet')}</span>,
            accessor: 'parent_pallet',
            Cell: (row) => (row?.value?.external_id ? row.value.external_id : '-'),
          },
          {
            Header: () => <span>{t('page_content.warehouse.pallets_table.rfid')}</span>,
            accessor: 'rfid_tag',
          },
          {
            Header: () => <span>{t('page_content.warehouse.pallets_table.warehouse')}</span>,
            accessor: 'warehouse',
            sortable: false,
            Cell: (row) => (row?.value?.name || '-'),
          },
          {
            Header: () => <span>{t('page_content.warehouse.pallets_table.position')}</span>,
            accessor: 'warehouse_position',
            sortable: false,
            Cell: (row) => (row?.value?.external_id || '-'),
          },
          {
            Header: () => <span>{t('page_content.warehouse.pallets_table.product')}</span>,
            accessor: 'palletstock_set',
            sortable: false,
            Cell: (row) => {
              // Check if children_pallets exist
              if (Array.isArray(row?.original?.children_pallets) && row.original.children_pallets.length > 0) {
                // Extract product names from children_pallets
                const productNames = row.original.children_pallets
                  .flatMap((childPallet) => childPallet?.palletstock_set?.map((item) => item?.product_type?.name))
                  .filter((name) => name); // Remove undefined or null values

                // Get unique product names
                const uniqueProductNames = [...new Set(productNames)];

                // Pass unique product names to ValuesMapper
                return <ValuesMapper t={t} data={uniqueProductNames} sliceNumber={1} />;
              }

              // Handle palletstock_set for the current row
              if (Array.isArray(row.value) && row.value.length > 0) {
                if (row.value.length === 1 && row.value[0].product_type) {
                  return row?.value[0]?.product_type?.name;
                }
                return <ValuesMapper t={t} data={row?.value?.map((item) => item?.product_type?.name)} sliceNumber={1} />;
              }

              return '-';
            },
          },
          {
            Header: () => <span>{t('page_content.warehouse.pallets_table.tara')}</span>,
            accessor: 'tare_weight',
            Cell: (row) => {
              if (row?.original?.children_pallets?.length > 0) {
                let totalChildTareWeight = row.original.children_pallets.reduce((sum, childPallet) => {
                  const palletStockTare = parseFloat(childPallet?.tare_weight) || 0;
                  return sum + palletStockTare;
                }, parseFloat(row?.value));

                if (Number.isNaN(totalChildTareWeight)) {
                  totalChildTareWeight = 0;
                }

                const childBaseUnit = row.original.children_pallets[0]?.palletstock_set?.[0]?.product_type?.base_unit?.toLowerCase() || '';
                const roundedChildQuantity = childBaseUnit === 'pce' || childBaseUnit === 'pcs' || childBaseUnit === 'kom' ? 0 : 2;

                return `${numberSeparatorFormat(i18n.language, totalChildTareWeight, roundedChildQuantity, roundedChildQuantity, true)} ${childBaseUnit.toLowerCase()}`;
              }
              if (row && row.value && row.value > 0) {
                const baseUnit = row.row.original?.palletstock_set?.[0]?.product_type?.base_unit?.toLowerCase() || '';
                const roundedDecimals = baseUnit === 'pce' || baseUnit === 'pcs' || baseUnit === 'kom' ? 0 : 2;

                return `${numberSeparatorFormat(i18n.language, row.value, roundedDecimals, roundedDecimals, true)} kg`;
              }
              return '-';
            },
          },
          {
            Header: () => <span>{t('page_content.warehouse.pallets_table.quantity')}</span>,
            accessor: 'palletstock_set',
            sortable: false,
            Cell: (row) => {
              // Check if children_pallets exist and sum their quantities
              if (row?.original?.children_pallets?.length > 0) {
                let totalChildQuantity = row.original.children_pallets.reduce((sum, childPallet) => {
                  // Access palletstock_set[0].quantity and ensure it's a valid number
                  const palletStockQuantity = parseFloat(childPallet?.palletstock_set?.[0]?.quantity) || 0;
                  return sum + palletStockQuantity;
                }, 0);

                // Fallback to 0 if totalChildQuantity is NaN
                if (Number.isNaN(totalChildQuantity)) {
                  totalChildQuantity = 0;
                }

                const childBaseUnit = row.original.children_pallets[0]?.palletstock_set?.[0]?.product_type?.base_unit?.toLowerCase() || '';
                const roundedChildQuantity = childBaseUnit === 'pce' || childBaseUnit === 'pcs' || childBaseUnit === 'kom' ? 0 : 2;

                return `${numberSeparatorFormat(i18n.language, totalChildQuantity, roundedChildQuantity, roundedChildQuantity, true)} ${childBaseUnit.toLowerCase()}`;
              } if (Array.isArray(row.value) && row.value.length > 0) {
                let totalQuantity = row.value.reduce((sum, item) => {
                  return sum + (parseFloat(item?.quantity) || 0); // Ensure quantity is a valid number
                }, 0);

                // Fallback to 0 if totalQuantity is NaN
                if (Number.isNaN(totalQuantity)) {
                  totalQuantity = 0;
                }

                const baseUnit = row.value[0]?.product_type?.base_unit?.toLowerCase() || '';
                const roundedQuantity = baseUnit === 'pce' || baseUnit === 'pcs' || baseUnit === 'kom' ? 0 : 2;

                return `${numberSeparatorFormat(i18n.language, totalQuantity, roundedQuantity, roundedQuantity, true)} ${baseUnit.toLowerCase()}`;
              }

              return '-';
            },
          },
          {
            Header: () => <span>{t('page_content.warehouse.pallets_table.order')}</span>,
            accessor: 'palletstock_set',
            sortable: false,
            Cell: (row) => (Array.isArray(row.value) && row.value.length > 0 ? <Link to={`/${companyId}/industry/location/${locationId}/orders/${row?.value[0]?.order?.id}`}>
                {row?.value[0]?.order?.external_id}
              </Link> : '-'),
          },
          {
            Header: () => <span>{t('page_content.warehouse.pallets_table.partner')}</span>,
            accessor: 'palletstock_set',
            sortable: false,
            Cell: (row) => (Array.isArray(row.value) && row.value.length > 0 && row.value[0].partner_name ? row.value[0].partner_name :
              row.original && row.original.order && row.original.order.partner ? row.original.order.partner
                : '-'),
          },
          {
            Header: () => <span>{t('page_content.warehouse.pallets_table.nett_weight')}</span>,
            accessor: 'palletstock_set',
            sortable: false,
            Cell: (row) => {
              // Check if children_pallets exist and sum their quantities
              if (row?.original?.children_pallets?.length > 0) {
                let totalChildQuantityWeight = row.original.children_pallets.reduce((sum, childPallet) => {
                  // Access palletstock_set[0].quantity_weight and ensure it's a valid number
                  const palletStockQuantity = parseFloat(childPallet?.palletstock_set?.[0]?.quantity_weight) || 0;
                  return sum + palletStockQuantity;
                }, 0);

                // Fallback to 0 if totalChildQuantityWeight is NaN
                if (Number.isNaN(totalChildQuantityWeight)) {
                  totalChildQuantityWeight = 0;
                }

                const childBaseUnit = row.original.children_pallets[0]?.palletstock_set?.[0]?.product_type?.base_unit?.toLowerCase() || '';
                const roundedChildQuantity = childBaseUnit === 'pce' || childBaseUnit === 'pcs' || childBaseUnit === 'kom' ? 0 : 2;

                return `${numberSeparatorFormat(i18n.language, totalChildQuantityWeight, roundedChildQuantity, roundedChildQuantity, true)} ${childBaseUnit.toLowerCase()}`;
              }
              if (Array.isArray(row.value) && row.value.length > 0) {
                const totalNettWeight = row.value.reduce((sum, item) => {
                  if (item?.quantity_weight) {
                    return sum + item?.quantity_weight;
                  }
                  return sum;
                }, 0);

                const baseUnit = row.value[0]?.product_type?.base_unit?.toLowerCase() || '';
                const roundedQuantity = baseUnit === 'pce' || baseUnit === 'pcs' || baseUnit === 'kom' ? 0 : 2;

                return `${numberSeparatorFormat(i18n.language, totalNettWeight, roundedQuantity, roundedQuantity, true)} kg`;
              }
              return '-';
            },
          },
          {
            Header: () => <span>{t('page_content.warehouse.pallets_table.issued_to_partner')}</span>,
            accessor: 'issued_to_partner',
            Cell: (row) => (<div style={{ margin: 'auto' }}>{row?.value ?
                <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />}</div>),
          },
          {
            Header: () => <span>{t('page_content.warehouse.pallets_table.issue_pallets')}</span>,
            sortable: false,
            Cell: (row) => {
              const isParentPallet = !row?.original?.palletstock_set[0] && row?.original?.children_pallets?.length > 0 && row?.original?.parent_pallet === null;

              if (isParentPallet) {
                return (
                <Button
                  type="dark-blue"
                  disabled={isReadOnly}
                  onClick={() => this.openIssuePalletsModal(row)}
                >
                  {t('page_content.warehouse.pallets_table.issue_pallets')}</Button>
                );
              }
              return (
                row?.original?.palletstock_set && row?.original?.palletstock_set[0] && row.original.palletstock_set[0].quantity && row.original.palletstock_set[0].quantity !== 0 ?
                  <Button
                    type="dark-blue"
                    disabled={isReadOnly}
                    onClick={() => this.openIssuePalletsModal(row)}
                  >
                    {t('page_content.warehouse.pallets_table.issue_pallets')}
                    </Button> : '');
            },
          },
          {
            Header: () => <span>{t('page_content.warehouse.split_pallet')}</span>,
            sortable: false,
            Cell: (row) => (row && row.original && row.original.palletstock_set && row.original.palletstock_set[0] && row.original.palletstock_set[0].quantity && row.original.palletstock_set[0].quantity !== 0 ?
                <Button type="dark-blue" disabled={(row && row.original && row.original.issued_to_partner) || isReadOnly} onClick={() => this.openSplitPalletsModal(row)}>{t('page_content.warehouse.split_pallet')}</Button> : ''),
          },
          {
            Header: () => <span>{t('page_content.warehouse.print')}</span>,
            accessor: 'id',
            sortable: false,
            Cell: (row) => (<Button type="dark-blue" onClick={() => this.handlePrint(row)}>{t('page_content.warehouse.print')}</Button>),
          },
        ]}
        data={pallets}
        minRows={0}
        showPagination={false}
        defaultPageSize={30}
        noDataText=" "
        loading={isLoadingPallets}
        defaultSorted={[{ id: 'updated_at', desc: true }]}
        onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
        sortable={false}
        expanded={pallets?.children_pallets}
        collapseOnDataChange={false}
        getTrProps={() => {
          return {
            style: this.handleTableChildrenRowStyle(level),
          };
        }}
        SubComponent={(childrenData) => {
          if (!childrenData?.original?.children_pallets?.length) {
            return;
          }
          return <div style={{ paddingLeft: 30, paddingTop: 10 }}>
            {this.renderTable(childrenData?.original?.children_pallets, currentLevel)}
          </div>;
        }}
      />
    );
  };

  render() {
    const {
      pallets,
      previous,
      next,
      isIssuePalletsModalOpen,
      partners,
      listOfWarehouses,
      selectedPallet,
      palletId,
      isSplitPalletsModalOpen,
      showFilter,
      selectedWarehouse,
      issuedFilter,
      externalIdQuery,
      productNameQuery,
      isAddPalletsModalOpen,
      count,
      selectedPalletRoot,
      isParentPallet,
    } = this.state;
    const { t, order, companyId, locationId, isReadOnly } = this.props;

    const filterOptions = [
      { stateKey: 'externalIdQuery', value: externalIdQuery, placeholder: t('page_content.warehouse.pallets_table.id') },
      { stateKey: 'productNameQuery', value: productNameQuery, placeholder: t('page_content.warehouse.pallets_table.product') },
    ];

    const showFilterOptions = [
      { value: 'in_stock', name: t('page_content.warehouse.show_options.only_loaded') },
      { value: 'not_in_stock', name: t('page_content.warehouse.show_options.only_free') },
    ];

    const issuedOptions = [
      { value: 'true', name: t('page_content.warehouse.issued_options.issued') },
      { value: 'false', name: t('page_content.warehouse.issued_options.not_issued') },
    ];

    const warehouseOptions = [
      ...listOfWarehouses.sort((a, b) => a.name.localeCompare(b.name)).map((warehouse) => ({
        name: warehouse.name,
        id: warehouse.id,
      })),
    ];

    const selectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '180px',
        minHeight: '34px',
        height: '36px',
        padding: 0,
        fontSize: '13px',
        color: '#555',
        marginRight: '5px',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0px 0px 0px 5px',
      }),
      clearIndicator: (provided) => ({
        ...provided,
        padding: '0px 3px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: 10,
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: 200,
        borderRadius: 0,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    return (
      <div className="warehouse_pallets_container">
        <div className="warehouse_pallets_toolbar_area">
          {!order && filterOptions.map((query) => (
            <div key={query.stateKey} className="warehouse_pallets_input_container">
              <input onChange={(e) => this.onFilterChange(query.stateKey, e.target.value)} placeholder={query.placeholder} value={query.value} />
              {query.value && <button
                onClick={() => {
                  this.handleQueryClear(query.stateKey);
                }}
              >&times;</button>}
              <div className="warehouse_pallets_icon_container">
                <IconSearch
                  color="#555"
                  height="26px"
                  width="26px"
                />
              </div>
            </div>
          ))}
          <div className="warehouse_pallets_selector_area">
            <div className="warehouse_pallets_selector_item">
              <Select
                options={warehouseOptions}
                getOptionLabel={(warehouse) => warehouse.name}
                getOptionValue={(warehouse) => warehouse.id}
                isSearchable
                isClearable
                placeholder={t('page_content.warehouse.warehouse_filter_placeholder')}
                onChange={(value) => this.onFilterChange('selectedWarehouse', value ?? '')}
                value={(warehouseOptions.find((whouse) => whouse.id === selectedWarehouse.id)) || ''}
                styles={selectStyles}
              />
            </div>
            <div className="warehouse_pallets_selector_item">
              {`${t('page_content.warehouse.show')}:`}
              <Select
                options={showFilterOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                isSearchable
                isClearable
                placeholder={t('page_content.warehouse.show_options.all')}
                onChange={(value) => this.onFilterChange('showFilter', value ?? '')}
                value={(showFilterOptions.find((filter) => filter.value === showFilter.value)) || ''}
                styles={selectStyles}
              />
            </div>
            <div className="warehouse_pallets_selector_item">
              {`${t('page_content.warehouse.issued_options.issued')}:`}
              <Select
                options={issuedOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                isSearchable
                isClearable
                placeholder={t('page_content.warehouse.issued_options.all')}
                onChange={(value) => this.onFilterChange('issuedFilter', value ?? '')}
                value={(issuedOptions.find((filter) => filter.value === issuedFilter.value)) || ''}
                styles={selectStyles}
              />
            </div>
          </div>
          <div className="warehouse_pallets_clearFilters_button">
            <Button
              onClick={this.clearFilters}
            >
              {t('shared.clear_button')}
            </Button>

            {
              !order &&
              <Button
                type="add"
                style={{ marginLeft: 'auto' }}
                onClick={this.openAddPalletsModal}
              >
                {t('page_content.warehouse.add_pallet')}
              </Button>
            }

          </div>
        </div>
        <div className="warehouse_pallets_table_area">
          {this.renderTable(pallets, 0)}
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchPaginatedWarehousePallets} count={count} />
        </div>

        {isIssuePalletsModalOpen && !isReadOnly &&
          <IssuePalletsModal
            companyId={companyId}
            locationId={locationId}
            listOfWarehouses={listOfWarehouses}
            partners={partners}
            issuePallets={this.issuePallets}
            issueToProduction={this.issueToProduction}
            closeIssuePalletsModal={this.closeIssuePalletsModal}
            order={order}
            t={t}
            selectedPallet={selectedPallet}
            palletId={palletId}
            selectedPalletRoot={selectedPalletRoot}
            isParentPallet={isParentPallet}
          />}

        {isSplitPalletsModalOpen && !isReadOnly &&
          <SplitPalletsModal
            splitPallets={this.splitPallets}
            closeSplitPalletsModal={this.closeSplitPalletsModal}
            t={t}
            selectedPallet={selectedPallet}
            palletId={palletId}
          />}

        {isAddPalletsModalOpen && !isReadOnly &&
          <AddPalletsModal
            companyId={companyId}
            addPallets={this.addPallets}
            closeAddPalletsModal={this.closeAddPalletsModal}
            t={t}
          />}
      </div>
    );
  }
}
Pallets.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  order: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  i18n: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default (withTranslation()(Pallets));
