import { getByURL, postAndNotifyByURL, putAndNotifyByURL, deleteAndNotifyByURL } from './CustomService';

export const getDepartmentTechnologies = async (filters = '') => {
  return getByURL(`/api/v1/department_technologies/${filters}`);
};

export const postDepartmentTechnology = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/department_technologies/${filters}`, data);
};

export const putDepartmentTechnology = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/department_technologies/${id}/${filters}`, data);
};

export const deleteDepartmentTechnology = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/department_technologies/${id}/${filters}`);
};
