import { get } from 'lodash';

import { checkAccess, redirectToHomePage } from 'industry/helpers';
// import { redirectToHomePage } from 'industry/helpers';

import ROLE_CONFIG from 'industry/role/definitions/config/roleConfig';

import api from 'helpers/api';

import {
  SET_ROLE_START,
  SET_ROLE_SUCCESS,
  SET_ROLE_FAILURE,
  CLEAR_ROLE,
} from './actionTypes';

export const setRoleStart = () => ({
  type: SET_ROLE_START,
});

export const setRoleSuccess = (roleData) => ({
  type: SET_ROLE_SUCCESS,
  payload: roleData,
});

export const setRoleFailure = (error) => ({
  type: SET_ROLE_FAILURE,
  payload: error,
});

export const clearRole = () => ({
  type: CLEAR_ROLE,
});

export const determineUserRole = () => async (dispatch, getState) => {
  const state = getState();
  const prevRole = get(state, 'role', null);
  const currentUser = get(state, 'currentUser', null);
  const companyId = get(state, 'app.company.id', null);
  const locationId = get(state, 'app.location.id', null);

  if (!currentUser || !companyId || !locationId || (prevRole?.currentRole && !prevRole?.error)) {
    return;
  }

  dispatch(setRoleStart());

  try {
    if (!currentUser) {
      dispatch(setRoleFailure('No user provided'));
      return;
    }

    if (currentUser?.is_admin) {
      dispatch(setRoleSuccess({
        currentRole: 'Admin',
        roleConfig: ROLE_CONFIG.ADMIN,
      }));
      return;
    }

    if (companyId && locationId) {
      const administrationAccessLvl = get(await checkAccess(`/${companyId}/industry/location/${locationId}/administration`, companyId), 'data.access', 0);
      // Uprava Role check
      if (administrationAccessLvl === 10) {
        dispatch(setRoleSuccess({
          currentRole: 'Uprava (read & write)',
          roleConfig: ROLE_CONFIG.UPRAVA_READ_WRITE,
        }));
        return;
      }

      // Uprava Role check
      if (administrationAccessLvl === 1) {
        dispatch(setRoleSuccess({
          currentRole: 'Uprava (read only)',
          roleConfig: ROLE_CONFIG.UPRAVA_READ_ONLY,
        }));
        return;
      }

      // Kadrovska voditelj check
      const isKadrovskaVoditelj = get(await checkAccess('x-kadrovska-voditelj', companyId), 'data.access', 0) === 10;
      if (isKadrovskaVoditelj) {
        console.log(ROLE_CONFIG.KADROVSKA_VODITELJ);
        dispatch(setRoleSuccess({
          currentRole: 'Kadrovska voditelj',
          roleConfig: ROLE_CONFIG.KADROVSKA_VODITELJ,
        }));
        return;
      }

      // Kadrovska zamjenik check
      const isKadrovskaZamjenik = get(await checkAccess('x-kadrovska-zamjenik', companyId), 'data.access', 0) === 10;
      if (isKadrovskaZamjenik) {
        dispatch(setRoleSuccess({
          currentRole: 'Kadrovska zamjenik',
          roleConfig: ROLE_CONFIG.KADROVSKA_ZAMJENIK,
        }));
        return;
      }

      // Kadrovska dijelatnik Role check
      const isKadrovskaDijelatnik = get(await checkAccess('x-kadrovska-dijelatnik', companyId), 'data.access', 0) === 10;
      if (isKadrovskaDijelatnik) {
        dispatch(setRoleSuccess({
          currentRole: 'Kadrovska dijelatnik',
          roleConfig: ROLE_CONFIG.KADROVSKA_DIJELATNIK,
        }));
        return;
      }

      // Voditelj check
      const workerId = get(currentUser, 'worker_id', null);
      if (workerId) {
        const data = await api.get(`/api/v1/workforce/department_managers/?location=${locationId}&worker=${workerId}&is_active=true`);
        const results = get(data, 'data.results', null);
        const isManager = results?.some((manager) => manager.is_manager || manager.is_deputy_manager);
        const managerDepartments = results?.filter((manager) => manager.is_manager || manager.is_deputy_manager)?.map((obj) => obj.department);
        const isZNRVoditelj = managerDepartments?.some((department) => department?.name?.toLowerCase() === 'znr');
        const isITVoditelj = managerDepartments?.some((department) => department?.name?.toLowerCase() === 'it');

        if (isManager && !isZNRVoditelj && !isITVoditelj) {
          dispatch(setRoleSuccess({
            currentRole: 'Voditelj',
            roleConfig: ROLE_CONFIG.VODITELJ,
            workerId,
            managerDepartments,
          }));
          return;
        }

        if (isManager && isZNRVoditelj) {
          dispatch(setRoleSuccess({
            currentRole: 'Voditelj ZNR',
            roleConfig: ROLE_CONFIG.VODITELJ_ZNR,
            workerId,
            managerDepartments,
          }));
          return;
        }

        if (isManager && isITVoditelj) {
          dispatch(setRoleSuccess({
            currentRole: 'Voditelj IT',
            roleConfig: ROLE_CONFIG.VODITELJ_IT,
            workerId,
            managerDepartments,
          }));
          return;
        }

        // dispatch(setRoleSuccess({
        //   currentRole: 'Worker',
        //   roleConfig: ROLE_CONFIG.WORKER,
        //   workerId,
        // }));
        redirectToHomePage(companyId, locationId);
        return;
      }
    }

    dispatch(setRoleFailure('Invalid role configuration'));
  } catch (error) {
    dispatch(setRoleFailure(error.message));
    redirectToHomePage(companyId, locationId);
  }
};
