import React from 'react';

import '../styles.scss';

import CompanyValuesTable from './CompanyValuesTable';
import ValueOptionsTable from './ValueOptionsTable';
import ValueScoresTable from './ValueScoresTable';

const CompanyValues = () => {
  return (
    <div className="company_values_tab">
      <CompanyValuesTable />
      <ValueScoresTable />
      <ValueOptionsTable />
    </div>
  );
};

export default CompanyValues;
