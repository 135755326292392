/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'shared';
import { ReactTableDefaults } from 'react-table';
import { get } from 'lodash';
import { IconSearch } from 'shared/Icons';
import { withTranslation } from 'react-i18next';
import { checkAccessOnPage, redirectToHomePage, checkModules } from 'industry/helpers';
import { getBomItems, getPaginatedBomItems, getBomChildren } from './../actions';
import '../styles.scss';
import TableButtons from '../../../../shared/TableButtons/index';

class ProductBomTab extends Component {
  constructor() {
    super();
    this.timerRef = React.createRef();
    this.state = {
      items: [],
      isLoading: false,
      partNumberQuery: '',
      bomChildrenDataLevel1: [],
      bomChildrenDataLevel2: [],
      bomChildrenDataLevel3: [],
      bomChildrenDataLevel4: [],
      loadingBomChildren: false,
      tableExpandStatuses: {
        level1: {},
        level2: {},
        level3: {},
        level4: {},
        level5: {},
      },
      selectedAscDesc: 'asc',
      selectedSort: 'quantity',
    };
  }

  componentDidMount() {
    const {
      companyId,
      locationId,
    } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Projects');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    this.fetchBomItems();
  }

  fetchBomItems = () => {
    const {
      projectId,
      companyId,
      locationId,
    } = this.props;
    const { selectedAscDesc, selectedSort, partNumberQuery } = this.state;
    this.setState({
      isLoading: true,
    });

    let column = selectedSort;
    const asc = selectedAscDesc === 'desc' ? '-' : '';
    if (column === 'product_type.description' || column === 'product_type.erp_id') {
      column = column.replace('.', '__');
    }
    const filter = `${asc}${column}`;

    getBomItems(projectId, companyId, locationId, partNumberQuery, filter)
      .then((res) => {
        this.setState({
          isLoading: false,
          items: get(res, 'data.results'),
          count: get(res, 'data.count'),
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
        });
      });
  }

  fetchPaginatedBomItems = (url) => {
    this.setState({
      isLoading: true,
    });
    getPaginatedBomItems(url)
      .then((re) => {
        this.setState({
          items: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoading: false,
        });
      })
      .catch((e) => console.error('Error while fetching orders', e));
  }

  handleQueryChange = (key, e) => {
    const value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleQueryClear = (key) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: '',
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleStateQueryChange = () => {
    const { partNumberQuery } = this.state;
    const { companyId, projectId, locationId } = this.props;
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      if (partNumberQuery.length < 1 || partNumberQuery.length >= 2) {
        this.setState({
          isLoading: true,
        });
        this.fetchBomItems();
      }
    }, 600);
  }

  fetchBomChildrenFromTable(rowId, handleOriginal, bomChildLevel) {
    const { companyId, locationId } = this.props;
    const stateKey = `bomChildrenDataLevel${bomChildLevel}`;
    this.setState({ loadingBomChildren: true });
    getBomChildren(companyId, locationId, null, rowId)
      .then((resp) => {
        this.setState({
          [stateKey]: {
            // eslint-disable-next-line react/no-access-state-in-setstate
            ...this.state[stateKey],
            [rowId]: get(resp, 'data.results'),
          },
          loadingBomChildren: false,
        });
        handleOriginal();
      });
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchBomItems();
    });
  }

  handleFirstLevelTableExpanderChange(level, expandedState) {
    this.setState((prevState) => ({
      tableExpandStatuses: {
        ...prevState.tableExpandStatuses,
        [level]: expandedState,
      },
    }));
  }

  handleTableExpanderChange(level, expandedState, rowId) {
    this.setState((prevState) => ({
      tableExpandStatuses: {
        ...prevState.tableExpandStatuses,
        [level]: {
          ...prevState.tableExpandStatuses[level],
          [rowId]: expandedState,
        },
      },
    }));
  }

  handleTableBomChildrenRowStyle(level) {
    switch (level) {
      case (1):
        return {
          background: '#DDF2FD',
        };
      case (2):
        return {
          background: '#9BBEC8',
        };
      case (3):
        return {
          background: '#8DAEB8',
        };
      case (4):
        return {
          background: '#8FAFB9',
        };
      default: return {};
    }
  }

  clearAllFiltersAndNesting() {
    this.setState({
      tableExpandStatuses: {
        level1: {},
        level2: {},
        level3: {},
        level4: {},
        level5: {},
      },
      partNumberQuery: '',
    });
    this.fetchBomItems();
  }

  render() {
    const {
      items,
      partNumberQuery,
      previous,
      next,
      isLoading,
      loadingBomChildren,
      bomChildrenDataLevel1,
      bomChildrenDataLevel2,
      bomChildrenDataLevel3,
      bomChildrenDataLevel4,
      tableExpandStatuses,
      selectedRow,
      count,
    } = this.state;

    const { t, companyId, locationId, projectId } = this.props;
    const columnDefaults = { ...ReactTableDefaults.column, headerClassName: 'project_parts_table_header', className: 'project_parts_table_header' };
    const nestedColumnDefaults = { ...ReactTableDefaults.column, headerClassName: 'nested_table_header', className: 'project_parts_table_header' };

    const columnConfig = [
      {
        expander: true,
        Header: () => <span>{t('page_content.projects.product_bom_tab.sub_parts')}</span>,
        width: 90,
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Expander: ({ isExpanded, ...rest }) => {
          if (rest.original.bom_childs && rest.original.bom_childs.length === 0) {
            return null;
          }
          return (
            <div>{isExpanded ? <span className="parts_modal_showChildren_no">{t('page_content.projects.product_bom_tab.show_parts_collapse')}</span> : <span className="parts_modal_showChildren_button_yes">{t('page_content.projects.product_bom_tab.show_parts_yes')}</span>}</div>
          );
        },
        Cell: (row) => (row.original.id ? row.original.id : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.product_bom_tab.external_id')}</span>,
        accessor: 'external_id',
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.product_bom_tab.quantity')}</span>,
        accessor: 'quantity',
        width: 90,
        Cell: (row) => (row.value ? Math.floor(row.value) : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.product_bom_tab.description')}</span>,
        accessor: 'product_type.description',
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.product_bom_tab.article_number')}</span>,
        accessor: 'product_type.erp_id',
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.product_bom_tab.material_type')}</span>,
        sortable: false,
        accessor: 'metadata.material_type',
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.product_bom_tab.finish_treatment')}</span>,
        sortable: false,
        accessor: 'metadata.finish_treatment',
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.product_bom_tab.component_type')}</span>,
        sortable: false,
        accessor: 'metadata.component_type',
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.product_bom_tab.place_of_installation')}</span>,
        sortable: false,
        accessor: 'metadata.place_of_installation',
        Cell: (row) => (row.value ? row.value : '-'),
      },
    ];

    return (
      <div className="product_bom">
        <div className="product_bom_toolbar">
          <div className="product_bom_toolbar_input_container">
            <input onChange={(e) => this.handleQueryChange('partNumberQuery', e)} placeholder={t('page_content.projects.product_bom_tab.part_number_filter')} value={partNumberQuery} />
            {
              partNumberQuery &&
              <button
                className="product_bom_clear_button"
                onClick={() => {
                  this.handleQueryClear('partNumberQuery');
                }}
              >&times;</button>
            }
            <div className="product_bom_toolbar_icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <Button onClick={() => this.clearAllFiltersAndNesting()}>{t('page_content.projects.product_bom_tab.clear_all_button')}</Button>
        </div>
        <div
          className="product_bom-table"
          style={{ background: 'white' }}
        >
          {/* Table with BOM Parents - Expands to Level 1 */}
          <Table
            style={{ userSelect: 'text' }}
            columns={columnConfig}
            data={items}
            minRows={0}
            defaultPageSize={30}
            noDataText=" "
            showPagination={false}
            sortable
            column={columnDefaults}
            loading={isLoading || loadingBomChildren}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
            defaultSorted={[{ id: 'quantity', desc: false }]}
            onExpandedChange={(expanded, index, event) => {
              this.handleFirstLevelTableExpanderChange('level1', expanded);
            }}
            expanded={tableExpandStatuses.level1}
            getTdProps={(rowInfo, instance, state) => {
              return {
                onClick: (e, handleOriginal) => {
                  const rowId = instance.row._original.id;
                  const bomChildsExists = instance.row._original.bom_childs;
                  if (handleOriginal && bomChildsExists.length > 0) {
                    this.fetchBomChildrenFromTable(rowId, handleOriginal, 1);
                  }
                },
              };
            }}
            SubComponent={(firstLevelRow) => {
              // Table with BOM Children - Level 1. Expands to Level 2.
              return (
                <div style={{ paddingLeft: '30px', paddingTop: '10px' }}>
                  <Table
                    style={{ userSelect: 'text' }}
                    columns={columnConfig}
                    data={bomChildrenDataLevel1[firstLevelRow.original.id]}
                    pageSize={bomChildrenDataLevel1[firstLevelRow.original.id].length}
                    showPagination={false}
                    column={nestedColumnDefaults}
                    loading={loadingBomChildren}
                    manual={false}
                    onExpandedChange={(expanded, index, event) => {
                      this.handleTableExpanderChange('level2', expanded, firstLevelRow.original.id);
                    }}
                    expanded={tableExpandStatuses.level2[firstLevelRow.original.id]}
                    getTrProps={(state, rowInfo, column) => {
                      return {
                        style: this.handleTableBomChildrenRowStyle(1),
                      };
                    }}
                    getTdProps={(rowInfo, instance) => {
                      return {
                        onClick: (e, handleOriginal) => {
                          const rowId = instance.row._original.id;
                          const bomChildsExists = instance.row._original.bom_childs;
                          if (handleOriginal && bomChildsExists.length > 0) {
                            this.fetchBomChildrenFromTable(rowId, handleOriginal, 2);
                          }
                        },
                      };
                    }}
                    SubComponent={(secondLevelRow) => {
                      // Table with BOM Children - Level 2. Expands to Level 3.
                      return (
                        <div style={{ paddingLeft: '30px', paddingTop: '10px' }}>
                          <Table
                            style={{ userSelect: 'text' }}
                            columns={columnConfig}
                            data={bomChildrenDataLevel2[secondLevelRow.original.id]}
                            pageSize={bomChildrenDataLevel2[secondLevelRow.original.id].length}
                            showPagination={false}
                            manual={false}
                            column={nestedColumnDefaults}
                            loading={loadingBomChildren}
                            onExpandedChange={(expanded, index, event) => {
                              this.handleTableExpanderChange('level3', expanded, secondLevelRow.original.id);
                            }}
                            expanded={tableExpandStatuses.level3[secondLevelRow.original.id]}
                            getTrProps={(state, rowInfo, column) => {
                              return {
                                style: this.handleTableBomChildrenRowStyle(2),
                              };
                            }}
                            getTdProps={(rowInfo, instance) => {
                              return {
                                onClick: (e, handleOriginal) => {
                                  const rowId = instance.row._original.id;
                                  const bomChildsExists = instance.row._original.bom_childs;
                                  if (handleOriginal && bomChildsExists.length > 0) {
                                    this.fetchBomChildrenFromTable(rowId, handleOriginal, 3);
                                  }
                                },
                              };
                            }}
                            SubComponent={(thirdLevelRow) => {
                              // Table with BOM Children - Level 3. Expands to Level 4.
                              return (
                                <div style={{ paddingLeft: '30px', paddingTop: '10px' }}>
                                  <Table
                                    style={{ userSelect: 'text' }}
                                    columns={columnConfig}
                                    data={bomChildrenDataLevel3[thirdLevelRow.original.id]}
                                    pageSize={bomChildrenDataLevel3[thirdLevelRow.original.id].length}
                                    showPagination={false}
                                    manual={false}
                                    column={nestedColumnDefaults}
                                    loading={loadingBomChildren}
                                    onExpandedChange={(expanded, index, event) => {
                                      this.handleTableExpanderChange('level4', expanded, thirdLevelRow.original.id);
                                    }}
                                    expanded={tableExpandStatuses.level4[thirdLevelRow.original.id]}
                                    getTrProps={(state, rowInfo, column) => {
                                      return {
                                        style: this.handleTableBomChildrenRowStyle(3),
                                      };
                                    }}
                                    getTdProps={(rowInfo, instance) => {
                                      return {
                                        onClick: (e, handleOriginal) => {
                                          const rowId = instance.row._original.id;
                                          const bomChildsExists = instance.row._original.bom_childs;
                                          if (handleOriginal && bomChildsExists.length > 0) {
                                            this.fetchBomChildrenFromTable(rowId, handleOriginal, 4);
                                          }
                                        },
                                      };
                                    }}
                                    SubComponent={(fourthLevelRow) => {
                                      // Table with BOM Children - Level 4. Expands to Level 5.
                                      return (
                                        <div style={{ paddingLeft: '30px', paddingTop: '10px' }}>
                                          <Table
                                            style={{ userSelect: 'text' }}
                                            columns={columnConfig}
                                            data={bomChildrenDataLevel4[fourthLevelRow.original.id]}
                                            pageSize={bomChildrenDataLevel4[fourthLevelRow.original.id].length}
                                            showPagination={false}
                                            manual={false}
                                            column={nestedColumnDefaults}
                                            loading={loadingBomChildren}
                                            onExpandedChange={(expanded, index, event) => {
                                              this.handleTableExpanderChange('level5', expanded, fourthLevelRow.original.id);
                                            }}
                                            expanded={tableExpandStatuses.level5[fourthLevelRow.original.id]}
                                            getTrProps={(state, rowInfo, column) => {
                                              return {
                                                style: this.handleTableBomChildrenRowStyle(4),
                                              };
                                            }}
                                          />
                                        </div>
                                      );
                                    }}
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
        <div>
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchPaginatedBomItems} count={count} />
        </div>
      </div>
    );
  }
}

ProductBomTab.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default (withTranslation()(ProductBomTab));
