import { getByURL, postAndNotifyByURL, putAndNotifyByURL, deleteAndNotifyByURL, deleteByURL } from './CustomService';

export const getOperationTechnologies = async (filters = '') => {
  return getByURL(`/api/v1/operation_technologies/${filters}`);
};

export const postOperationTechnology = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/operation_technologies/${filters}`, data);
};

export const putOperationTechnology = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/operation_technologies/${id}/${filters}`, data);
};

export const deleteOperationTechnology = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/operation_technologies/${id}/${filters}`);
};

export const deleteOperationTechnologyWithoutNotification = async (id, filters = '') => {
  return deleteByURL(`/api/v1/operation_technologies/${id}/${filters}`);
};
