import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import '../../../styles.scss';

import { ACTIONS } from 'industry/role/definitions/actions';
import { SECTIONS } from 'industry/role/definitions/sections';
import { canPerformAction } from 'industry/role/selectors';

import { deleteProfession, getProfessions, getPaginatedProfessions } from '../../../actions';

import ProfessionsModal from './ProfessionsModal';

const ProfessionsTable = ({ t, companyId, can }) => {
  const [professions, setProfessions] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [professionsFilters, setProfessionsFilter] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'name',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchProfessions = () => {
    setProfessions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = professionsFilters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&order_by=${asc}${professionsFilters?.selectedSort}`;

    getProfessions(companyId, apiFilters)
      .then((res) => {
        setProfessions({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setProfessions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedProfessions = (url) => {
    setProfessions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedProfessions(url)
      .then((res) => {
        setProfessions({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setProfessions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchProfessions();
  }, [companyId, professionsFilters]);

  const handleShowConfirmationDialog = (row) => {
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteProfession(deleteData?.id, companyId);
    setShowConfirmationDialog(false);
    fetchProfessions();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setProfessionsFilter((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleAddNewProfession = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleEditProfession = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchProfessions();
  };

  return (
    <div className="professions-container">
      <div className="professions-container__table">
        <h3>{t('page_content.human_resources.workers.professions')}</h3>

        <div className="professions-container__action-buttons">
        <Button
          type="add"
          disabled={!can(SECTIONS.WORKERS_TABS__WORKERS_QUALIFICATIONS_AND_PROFESSIONS_TAB, ACTIONS.ADD)}
          onClick={handleAddNewProfession}
        >
          {t('page_content.human_resources.workers.add_new_profession')}
        </Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.workers.name')}</span>,
              accessor: 'name',
              style: { cursor: 'default' },
            },
          ]}
          minRows={0}
          noDataText=""
          defaultPageSize={100}
          data={professions.data || []}
          loading={professions.isLoading}
          enableEdit={can(SECTIONS.WORKERS_TABS__WORKERS_QUALIFICATIONS_AND_PROFESSIONS_TAB, ACTIONS.EDIT)}
          enableDelete={can(SECTIONS.WORKERS_TABS__WORKERS_QUALIFICATIONS_AND_PROFESSIONS_TAB, ACTIONS.DELETE)}
          onEdit={(original) => handleEditProfession(original)}
          onDelete={(original) => handleShowConfirmationDialog(original)}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons
          next={professions.next}
          count={professions.count}
          previous={professions.previous}
          fetchFunction={fetchPaginatedProfessions}
        />
      </div>

      {modalData?.isOpen &&
      <ProfessionsModal
        isOpen={modalData?.isOpen}
        handleCloseModal={handleCloseModal}
        initialValues={modalData?.selectedItem}
        closeModalAndRefetch={closeModalAndRefetch}
      />}

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'name')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </div>
  );
};

ProfessionsTable.propTypes = {
  t: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    companyId: get(state, 'app.company.id', null),
    can: (section, action, useExceptions = false) => canPerformAction(state, section, action, useExceptions),
  };
};

export default connect(mapStateToProps)(withTranslation()(ProfessionsTable));
