import api from 'helpers/api';
import { Notification } from 'shared';

export const getReasons = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/reasons/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching stoppage reasons', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const saveReason = (data) => {
  return api.post(`/api/v1/industry/reasons/?company=${data.company}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return Notification('success', 'Save successful', 'Stoppage reason was successfully created.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const saveOperatorView = (data) => {
  return api.post(`/api/v1/dashboards/operator-views/?location=${data.location}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Operator view was successfully added.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const editReason = (data) => {
  return api.put(`/api/v1/industry/reasons/${data.id}/?company=${data.company}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Save successful', 'Stoppage reason was successfully updated.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getOperatorViews = async (url) => {
  try {
    const res = await api
      .get(`${url}&limit=100`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching operator views', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const editOperatorView = (data, operatorView) => {
  return api.put(`/api/v1/dashboards/operator-views/${operatorView.id}/?location=${data.location}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Save successful', 'Operator view was successfully updated.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getMaintenanceGroups = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/maintenance/reason_groups/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching maintenances', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getMaintenanceReasons = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/maintenance/reasons/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching maintenances', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const editMaintenance = (data) => {
  return api.put(`/api/v1/maintenance/reasons/${data.id}/?company=${data.company}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Save successful', 'Maintenance reason was successfully updated.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const saveMaintenance = (data) => {
  return api.post(`/api/v1/maintenance/reasons/?company=${data.company}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return Notification('success', 'Save successful', 'Maintenance reason was successfully created.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const saveMaintenanceGroup = (data) => {
  return api.post(`/api/v1/maintenance/reason_groups/?company=${data.company}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return Notification('success', 'Save successful', 'Maintenance group was successfully created.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};
