import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Modal, ContentLoader } from 'shared';

const EditERPModal = ({
  isEditERPModalOpen,
  selectedERPItem,
  handleERPChange,
  closeEditERPModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    erp_id: '',
  });

  useEffect(() => {
    if (selectedERPItem) {
      setFormData((prevState) => ({
        ...prevState,
        erp_id: get(selectedERPItem, 'product_type.erp_id', ''),
      }));
    }
  }, [selectedERPItem]);

  const handleFormChange = (key, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);

    // const orderId = getOrderId();
    const id = get(selectedERPItem, 'product_type.id', null);

    const payload = {
      code: get(selectedERPItem, 'product_type.code', ''),
      company: get(selectedERPItem, 'product_type.company', ''),
      erp_id: get(formData, 'erp_id', ''),
    };

    await handleERPChange(id, payload);
    setIsLoading(false);
    closeEditERPModal();
  };

  return (
    <Modal
      isOpen={isEditERPModalOpen}
      handleClose={closeEditERPModal}
      handleSave={handleSave}
    >
      {isLoading ?
        <ContentLoader /> :
        <div className="edit_product_modal_container">
          <div className="modal_row">
            <div className="left_text">ERP ID</div>
            <div className="right_input">
              <input
                onChange={(e) => handleFormChange('erp_id', e.target.value)}
                value={formData.erp_id || ''}
              />
            </div>
          </div>
        </div>}
    </Modal>
  );
};

EditERPModal.propTypes = {
  isEditERPModalOpen: PropTypes.bool,
  selectedERPItem: PropTypes.object,
  handleERPChange: PropTypes.func,
  closeEditERPModal: PropTypes.func,
};

export default EditERPModal;
