import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { defaultDateFormat } from 'shared/constants';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { Table, TableButtons, Button, ConfirmationModal } from 'shared';

import { ACTIONS } from 'industry/role/definitions/actions';
import { SECTIONS } from 'industry/role/definitions/sections';
import { canPerformAction } from 'industry/role/selectors';

import { deleteExceptionDay, getExceptionDays, getPaginatedExceptionDays } from '../actions';

import ExceptionModal from './ExceptionModal';

const ExceptionsTab = ({ t, companyId, departments, can }) => {
  const [shiftExceptions, setShiftExceptions] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [filters, setFilters] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'name',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchShiftExceptions = () => {
    setShiftExceptions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&order_by=${asc}${filters?.selectedSort}`;

    getExceptionDays(companyId, apiFilters)
      .then((res) => {
        setShiftExceptions({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setShiftExceptions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedShiftExceptions = (url) => {
    setShiftExceptions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedExceptionDays(url)
      .then((res) => {
        setShiftExceptions({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setShiftExceptions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchShiftExceptions();
  }, [companyId, filters]);

  const handleShowConfirmationDialog = (row) => {
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteExceptionDay(deleteData?.id, companyId);
    setShowConfirmationDialog(false);
    fetchShiftExceptions();
  };

  const handleSorting = (sortData) => {
    let sortKey = sortData.id;
    sortKey = sortKey.replace('.', '__');

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewCompanyValue = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchShiftExceptions();
  };

  return (
    <div className="exception-days">
      <div className="exception-days__table">
        <div className="exception-days__action-buttons">
        <Button
          type="add"
          disabled={!can(SECTIONS.SHIFTS_TABS__EXCEPTIONS_TAB, ACTIONS.ADD)}
          onClick={handleAddNewCompanyValue}
        >
            {t('page_content.human_resources.shifts.exceptions.add_new_exception')}
        </Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.shifts.exceptions.date')}</span>,
              accessor: 'date',
              width: 125,
              Cell: (row) => (row?.value ? moment(row?.value).format(defaultDateFormat) : '-'),
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('page_content.human_resources.shifts.exceptions.department')}</span>,
              accessor: 'department.name',
              Cell: (row) => (row?.value || '-'),
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('page_content.human_resources.shifts.exceptions.department_shift')}</span>,
              accessor: 'department_shift.name',
              Cell: (row) => (row?.value || '-'),
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('page_content.human_resources.shifts.exceptions.is_working_day')}</span>,
              accessor: 'is_working_day',
              width: 125,
              Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>,
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('page_content.human_resources.shifts.exceptions.begin_time')}</span>,
              accessor: 'begin_time',
              Cell: (row) => (row?.value || '-'),
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('page_content.human_resources.shifts.exceptions.end_time')}</span>,
              accessor: 'end_time',
              Cell: (row) => (row?.value || '-'),
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('page_content.human_resources.shifts.exceptions.begin_break')}</span>,
              accessor: 'begin_break',
              Cell: (row) => (row?.value || '-'),
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('page_content.human_resources.shifts.exceptions.end_break')}</span>,
              accessor: 'end_break',
              style: { cursor: 'default' },
              Cell: (row) => (row?.value || '-'),
            },
          ]}
          minRows={0}
          noDataText=""
          selectedRow={null}
          defaultPageSize={100}
          showPagination={false}
          enableEdit
          enableDelete
          onEdit={(original) => handleTableRowClick(original)}
          onDelete={(original) => handleShowConfirmationDialog(original)}
          isActionsDisabled={!can(SECTIONS.SHIFTS_TABS__EXCEPTIONS_TAB, ACTIONS.EDIT) || !can(SECTIONS.SHIFTS_TABS__EXCEPTIONS_TAB, ACTIONS.DELETE)}
          data={shiftExceptions.data || []}
          loading={shiftExceptions.isLoading}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons
          next={shiftExceptions.next}
          count={shiftExceptions.count}
          previous={shiftExceptions.previous}
          fetchFunction={fetchPaginatedShiftExceptions}
        />
      </div>

      {modalData?.isOpen &&
      <ExceptionModal
        isOpen={modalData?.isOpen}
        handleCloseModal={handleCloseModal}
        initialValues={modalData?.selectedItem}
        closeModalAndRefetch={closeModalAndRefetch}
        departments={departments}
      />}

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'date')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </div>
  );
};

ExceptionsTab.propTypes = {
  t: PropTypes.func.isRequired,
  departments: PropTypes.array,
  can: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    can: (section, action, useExceptions = false) => canPerformAction(state, section, action, useExceptions),
  };
};

export default connect(mapStateToProps)(withTranslation()(ExceptionsTab));
