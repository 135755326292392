import * as actionTypes from './actionTypes';

export const addOrUpdateDevice = (device) => {
  return {
    type: actionTypes.addOrUpdateDevice,
    payload: device,
  };
};

export const setDevices = (devices) => {
  return {
    type: actionTypes.setDevices,
    payload: devices,
  };
};
