import api from 'helpers/api';
import { Notification } from 'shared';

export const deleteOrderAttachment = (id, orderId) => {
  return api.delete(`/api/v1/orders/order_files/${id}/?order=${orderId}`)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Delete successful', 'Attachment was successfuly deleted.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const editOrderAttachment = async (id, orderId, data) => {
  return api.patch(`/api/v1/orders/order_files/${id}/?order=${orderId}`, data);
};

export const addOrderAttachment = async (data) => {
  try {
    const res = await api
      .post('/api/v1/orders/order_files/', data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while adding order attachment', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrderAttachments = async (orderId, filter = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_files/?order=${orderId}${filter}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order files', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrderDetails = async (orderId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/orders/${orderId}/?expand=groups,items${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order details', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrderParts = async (orderId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_item_parts/?order=${orderId}&limit=50${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order parts', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrderHistory = async (orderId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_logs/?order=${orderId}&limit=50${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order history', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrderPartHistory = async (orderId, partId) => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_logs/?order=${orderId}&order_item_part=${partId}&limit=50`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order part history', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrderStatusHistory = async (orderId) => {
  try {
    const res = await api
      .get(`/api/v1/orders/status_history/?order=${orderId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order status history', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getItemStatusHistory = async (orderId, itemId) => {
  try {
    const res = await api
      .get(`/api/v1/orders/status_history/?order=${orderId}&order_item=${itemId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching item status history', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrderTimeline = async (orderId) => {
  try {
    const res = await api
      .get(`/api/v1/orders/timeline/?order=${orderId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order timeline', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getLocations = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/sidebar/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching locations', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAssetGroups = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/asset_groups/?company=${companyId}&order_by=name&limit=200`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching locations', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrderItemHistory = async (orderId, itemId) => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_logs/?order=${orderId}&order_item=${itemId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order item history', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrderPallets = async (orderId) => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_pallets?order=${orderId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order pallets', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addOrderPallets = async (orderId, data) => {
  try {
    const res = await api
      .post(`/api/v1/orders/order_pallets_batch/${orderId}/`, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while adding order pallets', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getSawData = async (orderId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/saw_data/?order=${orderId}${filters}&limit=30`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching saw data', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAuditLogs = async (orderId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_audit_logs/?order=${orderId}&order_by=-created_at&limit=100${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching audit logs', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getBookingsUrl = async (url) => {
  try {
    const res = await api
      .get(`${url}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching bookings', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getBookings = async (orderId, filter = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_bookings/?order=${orderId}${filter}&limit=30`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching bookings', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getBookingItems = async (orderId) => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_item_bookings/?order=${orderId}&limit=30&order_by=-booked_at`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching booking items', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getCompanyData = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/companies/${companyId}/`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching companies', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAssets = async (companyId, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/assets/?company=${companyId}&location=${locationId}&fat=1`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching assets', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const updateQuantity = async (itemId, data) => {
  try {
    const res = await api
      .post(`/api/v1/orders/order_item_bookings/${itemId}/update_quantity/`, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while updating quantity', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const updateToBook = async (itemId, data) => {
  try {
    const res = await api
      .post(`/api/v1/orders/order_item_bookings/${itemId}/update_quantity/`, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while updating quantity', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addManualEntry = async (data) => {
  try {
    const res = await api
      .post('/api/v1/orders/order_bookings/batch/', data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while adding booking', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addManualEntryComponents = async (data) => {
  try {
    const res = await api
      .post('/api/v1/orders/order_bookings/bom_batch/', data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while adding booking', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getListOfWarehouses = async (locationId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/warehouses?location=${locationId}${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching warehouses list', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const updateBatch = async (orderItemBookingId, data) => {
  try {
    const res = await api
      .post(`/api/v1/orders/order_item_bookings/${orderItemBookingId}/update_batch/`, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while updating', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const duplicateBookingItem = async (orderItemBookingId, data) => {
  try {
    const res = await api
      .post(`/api/v1/orders/order_item_bookings/${orderItemBookingId}/duplicate/`, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while duplicating', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteBookingItem = async (orderItemBookingId, data) => {
  try {
    const res = await api
      .post(`/api/v1/orders/order_item_bookings/${orderItemBookingId}/delete/`, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while deleting', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProductionLines = async (companyId, locationId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/planning/lines?company=${companyId}&location=${locationId}${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching production lines list', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getBatchData = async (companyId, material_code = '', filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/stocks/?company=${companyId}&material_code=${material_code}${filters}&limit=100&status=OK`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching batch data', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getLocationDetails = async (locationId) => {
  try {
    const res = await api
      .get(`/api/v1/locations/${locationId}/`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching location details', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const saveOrder = (data) => {
  return api.post('/api/v1/orders/project_products_order/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Order was successfully created.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const editProductType = async (companyId, id, productTypeData) => {
  return api.put(`/api/v1/product_types/${id}/?company=${companyId}`, productTypeData)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const addComment = (data) => {
  return api.post('/api/v1/orders/order_audit_logs/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Comment was successfully created.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const updateOrderStatus = (data) => {
  return api.post('/api/v1/orders/status/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Status was successfully updated.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const joinBookings = async (data) => {
  try {
    const res = await api
      .post('/api/v1/orders/order_bookings/merge/', data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while joining bookings', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProductTypes = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/product_types/?company=${companyId}&limit=999`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching product types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const editOrderGroupItem = (data) => {
  return api.put(`/api/v1/orders/order_items/${data.id}/edit/`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Item was successfully updated.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const deleteOrderGroupItem = (id) => {
  return api.delete(`/api/v1/orders/order_items/${id}/edit/`);
};

export const editBooking = (id, data) => {
  return api.patch(`/api/v1/orders/order_bookings/${id}/`, data);
};

export const checkBookingSap = () => {
  return api.get('/api/v1/check-sap/');
};

export const exportBom = async (orderId, items) => {
  try {
    const res = await api
      .get(`/api/v1/orders/${orderId}/bom_export/?items=${items}`, { responseType: 'blob' });
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching BOM', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const bomImport = async (orderId, data) => {
  try {
    const res = await api
      .post(`/api/v1/orders/${orderId}/bom_import/`, data);
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred', error.response.data.message || 'We could not perform your request, please try again.');
  }
};

export const getOrderCancellationReasons = async (locationId) => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_cancellation_reasons/?location=${locationId}&limit=999`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching reasons', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const saveOrderCancellationReason = async (orderId, data) => {
  return api.patch(`/api/v1/orders/orders/${orderId}/`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', '');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getBatchList = async (companyId, filter = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_batches/?company=${companyId}${filter}&limit=30`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching batches', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getBatchItems = async (batchId, filter = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_batch_items/?order_batch=${batchId}${filter}&limit=30`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching batch items', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getUsers = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/workforce/workers/?company=${companyId}&limit=9999`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching workers', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const wmsOrderVerify = async (data) => {
  try {
    const res = await api
      .post('/api/v1/orders/wms_order_verify/', data);
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred', error.response.data.message || 'We could not perform your request, please try again.');
  }
};

export const launchLine = async (line, date, orderId) => {
  try {
    const res = await api
      .post('/api/v1/planning/line_plans/', { line, planned_start: date, order: orderId });
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred', error.response.data.message || 'We could not perform your request, please try again.');
  }
};

export const getOrderLinePlans = async (orderId) => {
  const urlString = '/api/v1/planning/line_plans';
  let urlParams = '?limit=1000&simple_order=1&order_by=-planned_start';

  if (orderId) {
    urlParams += `&order=${orderId}`;
  }

  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order line plans', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getWorkers = async (companyId, locationId, filters) => {
  try {
    const res = await api
      .get(`/api/v1/workforce/workers/?company=${companyId}&location=${locationId}${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching workers', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAPIEvents = async (companyId, locationId, filters) => {
  try {
    const res = await api
      .get(`/api/v1/simple/api_events/?company=${companyId}&location=${locationId}${filters}&limit=30`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching events', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedAPIEvents = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching events', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const triggerOrderImport = async (data) => {
  try {
    const res = await api
      .post('/api/v1/trigger_order_import/', data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while triggering order import', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrderTypes = async (locationId) => {
  try {
    const res = await api
      .get(`/api/v1/orders/order_types/?location=${locationId}&limit=9999&order_by=name`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const updateOrderType = (data) => {
  return api.post('/api/v1/orders/change_order_type/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Order type was successfully updated.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};
