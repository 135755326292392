import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';

import { modalSizes } from 'shared/constants';
import { numberSeparatorFormat } from 'industry/helpers';

import { Modal, Table, TableButtons, Button, ConfirmationModal } from 'shared';

import { getByURL, postAndNotifyByURL, patchAndNotifyByURL, deleteAndNotifyByURL, getProductTypes } from 'shared/Api';

const PodravkaRastepModal = ({
  t,
  i18n,
  isOpen,
  companyId,
  locationId,
  isReadOnly,
  handleClose,
  selectedCompanyTable,
}) => {
  const [currentView, setCurrentView] = useState('table');

  const [tableData, setTableData] = useState({
    isLoading: false,
    data: [],
    count: 0,
    next: null,
    previous: null,
    currentUrl: null,
  });

  const [selectedRow, setSelectedRow] = useState(null);
  const [isSelectedRowUpdated, setIsSelectedRowUpdated] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const [newRow, setNewRow] = useState(null);
  const [productTypes, setProductTypes] = useState(null);

  const [selectedSort, setSelectedSort] = useState('material.code');
  const [selectedAscDesc, setSelectedAscDesc] = useState('asc');

  const getTable = async (endpoint) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const tableFilter = `&order_by=${asc}${selectedSort}`;

    const results = await getByURL(`${endpoint}?location=${locationId}&limit=15${tableFilter}`);
    setTableData({
      isLoading: false,
      data: get(results, 'data.results', []),
      count: get(results, 'data.count', 0),
      next: get(results, 'data.next', null),
      previous: get(results, 'data.previous', null),
    });
  };

  const fetchData = async () => {
    if (selectedCompanyTable?.endpoint) {
      getTable(selectedCompanyTable.endpoint);
      const apiFilters = `?company=${companyId}&limit=100&order_by=name`;
      const results = await getProductTypes(apiFilters);
      setProductTypes(get(results, 'data.results', []));
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedCompanyTable, selectedAscDesc, selectedSort, companyId]);

  const onChange = (value, field) => {
    setSelectedRow({
      ...selectedRow,
      [field]: value,
    });
    setIsSelectedRowUpdated(true);
  };

  const onAddChange = (value, field) => {
    setNewRow((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const fetchPaginatedTable = async (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const results = await getByURL(url);
    setTableData({
      isLoading: false,
      data: get(results, 'data.results', []),
      count: get(results, 'data.count', 0),
      next: get(results, 'data.next', null),
      previous: get(results, 'data.previous', null),
      currentUrl: url,
    });
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setCurrentView('table');
    setIsSelectedRowUpdated(false);
    handleClose();
  };

  const handleEditItem = (original) => {
    setSelectedRow(original);
    setCurrentView('form');
  };

  const handleSorting = (sortData) => {
    const column = sortData.id;
    setSelectedSort(column);
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
  };

  const handleShowConfirmationModal = (original) => {
    setDeleteData(original);
    setShowConfirmationDialog(true);
  };

  const handleDeleteItem = async () => {
    const row = deleteData;
    if (row?.id && selectedCompanyTable?.endpoint) {
      setTableData((prevState) => ({ ...prevState, isLoading: true, data: [] }));
      setCurrentView('table');
      await deleteAndNotifyByURL(`${selectedCompanyTable.endpoint}${row.id}/`);
      getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
      setShowConfirmationDialog(false);
    }
  };

  const handleBack = () => {
    setSelectedRow(null);
    setCurrentView('table');
    setIsSelectedRowUpdated(false);
    setNewRow(null);
  };

  const handleAddButton = () => {
    setSelectedRow(null);
    setCurrentView('add');
    setIsSelectedRowUpdated(false);
  };

  const handleInputChange = (val) => {
    if (val?.length >= 3) {
      const apiFilters = `?company=${companyId}&limit=100&order_by=name&name_contains=${val}`;
      getProductTypes(apiFilters).then((results) => setProductTypes(get(results, 'data.results', [])));
    } else if (val?.length === 0 && productTypes.length === 0) fetchData();
  };

  const updateItem = async () => {
    if (selectedCompanyTable?.endpoint) {
      setTableData((prevState) => ({ ...prevState, isLoading: true, data: [] }));
      setCurrentView('table');
      const selectedRowData = JSON.parse(JSON.stringify(selectedRow));
      selectedRowData.component = selectedRow.component.id;
      selectedRowData.material = selectedRow.material.id;
      await patchAndNotifyByURL(`${selectedCompanyTable.endpoint}${selectedRow.id}/`, selectedRowData);
      handleBack();
      getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
    }
  };

  const addItem = async () => {
    const data = { ...newRow, location: locationId };
    if (selectedCompanyTable?.endpoint) {
      await postAndNotifyByURL(selectedCompanyTable.endpoint, data);
      handleBack();
      getTable(selectedCompanyTable.endpoint);
    }
  };

  const renderTable = () => {
    return (
      <div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <Button
            type="add"
            style={{ margin: '10px' }}
            onClick={handleAddButton}
          >
            {t('settings.custom_data.new_data')}
          </Button>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('settings.custom_data.material_code')}</span>,
              accessor: 'material.code',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.material_name')}</span>,
              accessor: 'material.name',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.component_code')}</span>,
              accessor: 'component.code',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.component_name')}</span>,
              accessor: 'component.name',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.quantity')}</span>,
              accessor: 'quantity',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.unit')}</span>,
              accessor: 'unit',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.scrap_percentage')}</span>,
              accessor: 'scrap_percentage',
              Cell: (row) => (row.value ? `${numberSeparatorFormat(i18n.language, row.value, 1, 1)}%` : '-'),
              style: {
                cursor: 'default',
              },
            },
          ]}
          data={tableData.data || []}
          loading={tableData.isLoading}
          defaultPageSize={15}
          minRows={0}
          enableEdit
          enableDelete
          onEdit={(original) => handleEditItem(original)}
          onDelete={(original) => handleShowConfirmationModal(original)}
          isActionsDisabled={isReadOnly}
          defaultSorted={[{ id: 'material.code', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons
          previous={tableData.previous}
          next={tableData.next}
          fetchFunction={fetchPaginatedTable}
          count={tableData.count}
        />
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="default-form">
        <Button onClick={handleBack}>{t('settings.custom_data.back')}</Button>
        <div className="custom_data_modal_container">

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.material_code')}:</div>
            <div className="right_input">
              <input type="text" disabled value={get(selectedRow, 'material.code', '')} onChange={(e) => onChange(e.target.value, 'material.code')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.material_name')}:</div>
            <div className="right_input">
              <input type="text" disabled value={get(selectedRow, 'material.name', '')} onChange={(e) => onChange(e.target.value, 'material.name')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.component_code')}:</div>
            <div className="right_input">
              <input type="text" disabled value={get(selectedRow, 'component.code', '')} onChange={(e) => onChange(e.target.value, 'component.code')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.component_name')}:</div>
            <div className="right_input">
              <input type="text" disabled value={get(selectedRow, 'component.name', '')} onChange={(e) => onChange(e.target.value, 'component.name')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.quantity')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'quantity', '')} onChange={(e) => onChange(e.target.value, 'quantity')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.unit')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'unit', '')} onChange={(e) => onChange(e.target.value, 'unit')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.scrap_percentage')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'scrap_percentage', '')} onChange={(e) => onChange(e.target.value, 'scrap_percentage')} />
            </div>
          </div>

        </div>
      </div>
    );
  };

  const renderAddForm = () => {
    return (
      <div className="default-form">
        <Button onClick={handleBack}>{t('settings.custom_data.back')}</Button>
        <div className="custom_data_modal_container">

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.component_name')}:</div>
            <div className="right_select">
              <div style={{ width: '100%' }}>
                <Select
                  options={productTypes}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isClearable
                  menuPosition="fixed"
                  placeholder={t('settings.custom_data.search_placeholder')}
                  onChange={(val) => onAddChange(val?.id, 'component')}
                  onInputChange={handleInputChange}
                  value={productTypes?.find((d) => d.id === get(newRow, 'component', '') || '')}
                  styles={selectModalStyles}
                />
              </div>
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.material_name')}:</div>
            <div className="right_select">
              <div style={{ width: '100%' }}>
                <Select
                  options={productTypes}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isClearable
                  menuPosition="fixed"
                  placeholder={t('settings.custom_data.search_placeholder')}
                  onChange={(val) => { onAddChange(val?.id, 'material'); }}
                  onInputChange={handleInputChange}
                  value={productTypes?.find((d) => d.id === get(newRow, 'material', '') || '')}
                  styles={selectModalStyles}
                />
              </div>
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.quantity')}:</div>
            <div className="right_input">
              <input type="text" value={get(newRow, 'quantity', '')} onChange={(e) => onAddChange(e.target.value, 'quantity')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.unit')}:</div>
            <div className="right_input">
              <input type="text" value={get(newRow, 'unit', '')} onChange={(e) => onAddChange(e.target.value, 'unit')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.scrap_percentage')}:</div>
            <div className="right_input">
              <input type="text" value={get(newRow, 'scrap_percentage', '')} onChange={(e) => onAddChange(e.target.value, 'scrap_percentage')} />
            </div>
          </div>

        </div>
      </div>
    );
  };

  return (
    <Modal
      size={modalSizes.full}
      handleClose={handleCloseModal}
      handleSave={currentView === 'form' ? updateItem : currentView === 'add' ? addItem : null}
      disableSave={(currentView === 'form' && !isSelectedRowUpdated) || (currentView === 'add' && !(newRow?.component && newRow?.material && newRow?.scrap_percentage && newRow?.unit && newRow?.quantity))}
      isOpen={isOpen}
      title={get(selectedCompanyTable, 'name', '')}
    >
      {currentView === 'table' && renderTable()}
      {currentView === 'form' && renderForm()}
      {currentView === 'add' && renderAddForm()}
      <ConfirmationModal
        itemName={`${get(deleteData, 'material.name')} | ${get(deleteData, 'component.name')}`}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleDeleteItem}
        type="warning"
      />
    </Modal>
  );
};

PodravkaRastepModal.propTypes = {
  isReadOnly: PropTypes.bool,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedCompanyTable: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

export default withTranslation()(PodravkaRastepModal);
