import React, { Component } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { checkAccessOnPage, redirectToHomePage, checkModules } from 'industry/helpers';
import { getFivesReports, getByURL } from 'shared/Api';
import { selectStyles } from 'styles/modules/reactSelect';
import { Button, DateFilters } from 'shared';

import Reports from './components/Reports';
import './styles.scss';

class Industry5S extends Component {
  constructor(props) {
    super(props);

    let isReadOnly = false;

    if (window.resourceReadOnly && window.resourceReadOnly.length) {
      window.resourceReadOnly.forEach((resource) => {
        if (window.location.href.includes(resource)) {
          isReadOnly = true;
        }
      });
    }

    this.state = {
      reports: [],
      isLoadingReports: true,
      // eslint-disable-next-line react/no-unused-state
      isReadOnly,
      startDate: null,
      endDate: null,
      selectedStatus: null,
      selectedFilter: 'created_at',
      clearFilters: false,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === '5S');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            isReadOnly: true,
          });
        }
      });

    this.fetchReports();
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchReports();
    });
  }

  fetchReports = () => {
    const { companyId, locationId } = this.props;
    const { startDate, endDate, selectedStatus, selectedSort, selectedAscDesc } = this.state;

    this.setState({
      isLoadingReports: true,
    });

    let filters = `?company=${companyId}&location=${locationId}&limit=30`;
    let orderFilter = '';

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    orderFilter += `&order_by=${asc}${selectedSort || '-updated_at'}`;

    if (startDate) {
      filters += `&created_at_after=${moment(startDate).startOf('day').toISOString()}`;
    }

    if (endDate) {
      filters += `&created_at_before=${moment(endDate).endOf('day').toISOString()}`;
    }

    if (selectedStatus && selectedStatus !== 'all') {
      filters += `&status=${selectedStatus}`;
    }

    getFivesReports(`${filters}${orderFilter}`)
      .then((res) => {
        this.setState({
          isLoadingReports: false,
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          reports: get(res, 'data.results'),
          count: get(res, 'data.count'),
        });
      });
  }

  fetchReportsByUrl = (url) => {
    this.setState({
      isLoadingReports: true,
    });

    getByURL(url)
      .then((res) => {
        this.setState({
          isLoadingReports: false,
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          reports: get(res, 'data.results'),
          count: get(res, 'data.count'),
        });
      });
  }

  displayUserName = (user) => {
    return (user.first_name !== '' && user.last_name !== '')
      ? `${user.first_name} ${user.last_name}`
      : `${user.email}`;
  }

  handleFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    }, () => {
      this.fetchReports();
    });
  }

  handleSelectedDateFilter = (filters, start, end) => {
    this.setState({
      startDate: start,
      endDate: end,
      selectedFilter: filters,
    }, () => {
      this.fetchReports();
    });
  }

  clearSearch = () => {
    const { clearFilters } = this.state;

    this.setState({
      startDate: null,
      endDate: null,
      selectedStatus: null,
      clearFilters: !clearFilters,
    }, () => {
      this.fetchReports();
    });
  }

  render() {
    const { t, history } = this.props;
    const {
      reports,
      isLoadingReports,
      next,
      previous,
      selectedStatus,
      count,
      clearFilters,
    } = this.state;

    const statusOptions = [
      { value: 'all', name: t('page_content.5S.statuses.all') },
      { value: 'created', name: t('page_content.5S.statuses.created') },
      { value: 'in_progress', name: t('page_content.5S.statuses.in_progress') },
      { value: 'finished', name: t('page_content.5S.statuses.finished') },
      { value: 'closed', name: t('page_content.5S.statuses.closed') },
      { value: 'graded', name: t('page_content.5S.statuses.graded') },
    ];

    const filterOptions = [
      { id: 'created_at', label: t('page_content.5S.table_column_created_at'), showTime: false },
    ];

    return (
      <div className="industry-tab industry-location-5S fullscreen">
        <Tabs>
          <TabList>
            <Tab>{t('page_content.5S.reports')}</Tab>
          </TabList>

          <div className="reports_tools">
          <DateFilters
            selectedFilterProp={this.handleSelectedDateFilter}
            filterOptions={filterOptions}
            clearFilters={clearFilters}
          />

            <Select
              options={statusOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              onChange={(e) => this.handleFilterChange('selectedStatus', e.value)}
              value={(statusOptions && statusOptions.find((status) => status.value === selectedStatus)) || ''}
              styles={selectStyles}
              placeholder={t('page_content.5S.status_placeholder')}
            />

            <div className="reports_tool_clearButton">
              <Button
                type="plain"
                onClick={this.clearSearch}
              >
                {t('page_content.5S.clear_button')}
              </Button>
            </div>
          </div>

          <TabPanel>
            <Reports
              t={t}
              displayUserName={this.displayUserName}
              reports={reports}
              isLoadingReports={isLoadingReports}
              next={next}
              previous={previous}
              count={count}
              fetchReportsByUrl={this.fetchReportsByUrl}
              history={history}
              handleSorting={this.handleSorting}
            />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

Industry5S.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
});

export default withRouter(connect(mapStateToProps, null)(withTranslation()(Industry5S)));
