import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import {
  ContentLoader,
  Modal,
  Notification,
  ReactDatePicker,
} from 'shared';

import api from 'helpers/api';

import { scheduleTypes } from '../../constants';

class RunnerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      runner: {
        report: null,
        schedule_type: null,
        minutes: '',
        next_run: null,
      },
      reports: [],
      isLoading: false,
    };

    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    // document.addEventListener('mousedown', this.handleClickOutside, false);
    this.getRunner();
    this.getReports();
  }

  getRunner = () => {
    const {
      runnerId,
      companyId,
    } = this.props;

    if (runnerId > 0) {
      this.setState({ isLoading: true });
      api
        .get(`/api/v1/reports/task_queues/${runnerId}/?company=${companyId}`)
        .then(({ data }) => {
          const runner = {
            report: data.report.id,
            schedule_type: data.schedule.schedule_type,
            next_run: data.schedule.next_run,
            minutes: data.schedule.minutes || '',
          };
          this.setState({
            runner,
            isLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  getReports = () => {
    const {
      companyId,
    } = this.props;

    api
      .get(`/api/v1/reports/reports/?company=${companyId}`)
      .then(({ data }) => {
        this.setState({
          reports: ((data.results) ? data.results : []),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSave() {
    const { runner } = this.state;
    const {
      runnerId,
      companyId,
      t,
    } = this.props;

    if (runnerId > 0) {
      api.put(`/api/v1/reports/task_queues/${runnerId}/?company=${companyId}`, runner)
        .then(() => {
          this.handleClose(true);
          return Notification('success', t('page_content.automatic_reports.report_run.success_update'));
        })
        .catch(() => {
          return Notification('error', 'An error occurred', t('page_content.automatic_reports.request_error'));
        });
      // eslint-disable-next-line
    } else {
      api.post(`/api/v1/reports/task_queues/?company=${companyId}`, runner)
        .then(() => {
          this.handleClose(true);
          return Notification('success', t('page_content.automatic_reports.report_run.success_create'));
        })
        .catch(() => {
          return Notification('error', 'An error occurred', t('page_content.automatic_reports.request_error'));
        });
    }
  }

  changeReportId = (e) => {
    const { runner } = this.state;
    runner.report = parseInt(e.target.value, 10);
    this.setState({ runner });
  }

  changeScheduleType = (e) => {
    this.updateField('schedule_type', e.target.value);
  }

  updateNextRun = (nextRun) => {
    this.updateField('next_run', nextRun);
  }

  updateField = (field, value) => {
    const { runner } = this.state;
    runner[field] = value || '';
    this.setState({ runner });
  }

  handleClickOutside = (e) => {
    if (this.node.contains(e.target) || e.target.classList.contains('rt-td')) {
      return;
    }
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    this.props.handleClose(false);
  }

  handleClose = (shouldReload) => {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    this.props.handleClose(shouldReload);
  }

  render() {
    const {
      isLoading,
      runner,
      reports,
    } = this.state;

    const {
      runnerId,
      t,
    } = this.props;

    const modalTitle = (runnerId > 0)
      ? t('page_content.automatic_reports.report_run.edit')
      : t('page_content.automatic_reports.report_run.create');

    const nextRun = runner.next_run ? moment(runner.next_run).toDate() : '';

    return (
      // eslint-disable-next-line no-return-assign
      <div ref={(node) => (this.node = node)}>
        <Modal
          handleClose={this.props.handleClose}
          handleSave={this.handleSave}
          saveText={modalTitle}
          isOpen
          style={{ content: { padding: '0', top: '20px', left: '0', right: '0', bottom: 'initial' } }}
          title={modalTitle}
        >
          {
            isLoading &&
            <ContentLoader />
          }
          {!isLoading &&
            <Fragment>
              <div className="default-form">
                <table>
                  <tbody>
                    <tr>
                      <td className="label">{t('page_content.automatic_reports.report_run.report')}</td>
                      <td className="input"><select onChange={this.changeReportId} className="" value={runner.report || ''}>
                        <option value="">{t('page_content.automatic_reports.report_run.select_report')}</option>
                        {reports.map((report) => (
                          <option key={report.id} value={report.id}>
                            {report.name}
                          </option>
                        ))}
                      </select></td>
                    </tr>
                    <tr>
                      <td className="label">{t('page_content.automatic_reports.report_run.schedule_type')}</td>
                      <td className="input"><select onChange={this.changeScheduleType} className="" value={runner.schedule_type || ''}>
                        <option value="">{t('page_content.automatic_reports.report_run.select_schedule_type')}</option>
                        {scheduleTypes.map((scheduleType, index) => (
                          <option key={index} value={scheduleType}>
                            {t(`page_content.automatic_reports.report_run.schedule_type_options.option_${scheduleType}`)}
                          </option>
                        ))}
                      </select></td>
                    </tr>
                    <tr>
                      <td className="label">{t('page_content.automatic_reports.report_run.minutes')}</td>
                      <td className="input"><input
                        id="minutes"
                        onChange={(e) => this.updateField('minutes', (e.target.value) ? parseInt(e.target.value, 10) : '')}
                        type="number"
                        value={runner.minutes}
                      />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">{t('page_content.automatic_reports.report_run.next_run')}</td>
                      <td className="input"><ReactDatePicker
                        placeholderText={t('page_content.automatic_reports.report_run.next_run')}
                        selected={nextRun}
                        selectsStart
                        onChange={this.updateNextRun}
                        showTimeSelect
                        timeCaption={t('date_picker_locale.time_translation')}
                      /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Fragment>}
        </Modal>
      </div>
    );
  }
}

RunnerForm.propTypes = {
  companyId: PropTypes.number.isRequired,
  runnerId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(RunnerForm);
