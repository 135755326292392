import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { IconSearch } from 'shared/Icons';
import { defaultDateTimeFormat } from 'shared/constants';
import { Button, Table, TableButtons, ReactDatePicker } from 'shared';
import { selectStyles, multiSelectStyles } from 'styles/modules/reactSelect';

import { getOCRData, getOCRDataURL } from '../../actions';
import { OCRStatuses } from './helpers';

import ImageModal from './components/ImageModal';

const OCRTab = ({ t, locationId, assets }) => {
  const debounceTimeoutRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [OCRData, setOCRData] = useState({
    data: [],
    count: 0,
    next: null,
    previous: null,
  });
  const [filters, setFilters] = useState({
    activeQuery: '',
    selectedAsset: null,
    selectedStatus: [],
    startDate: null,
    endDate: null,
  });
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    id: null,
  });

  const fetchOCRData = () => {
    setIsLoading(true);

    let apiFilters = '';

    if (filters.activeQuery) {
      apiFilters += `&order_name=${filters.activeQuery}`;
    }

    if (filters.selectedAsset) {
      apiFilters += `&asset=${filters.selectedAsset}`;
    }

    if (filters.selectedStatus && filters.selectedStatus.length) {
      const namesArray = filters.selectedStatus.map((obj) => obj.name);
      apiFilters += `&status=${namesArray}`;
    }

    if (filters.startDate) {
      apiFilters += `&date_from=${moment(filters.startDate).format('YYYY-MM-DD')}`;
    }

    if (filters.endDate) {
      apiFilters += `&date_to=${moment(filters.endDate).format('YYYY-MM-DD')}`;
    }

    getOCRData(locationId, apiFilters)
      .then((res) => {
        setOCRData({
          data: get(res, 'data.results', []),
          count: get(res, 'data.count', 0),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
        });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      fetchOCRData();
    }, 200);
  }, [filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const fetchOCRDataURL = (url) => {
    setIsLoading(true);

    getOCRDataURL(url)
      .then((res) => {
        setOCRData({
          data: get(res, 'data.results', []),
          count: get(res, 'data.count', 0),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
        });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const clearFilters = () => {
    setFilters({
      activeQuery: '',
      selectedAsset: null,
      selectedStatus: [],
      startDate: null,
      endDate: null,
    });
  };

  const getAssetNameByExternalId = (external_id) => {
    const asset = assets.find((a) => a.external_id === external_id);

    if (asset && asset.name) {
      return asset.name;
    }
    return '-';
  };

  const openImageModal = (id) => {
    setImageModal({ isOpen: true, id });
  };

  const closeImageModal = () => {
    setImageModal({ isOpen: false, id: null });
  };

  return (
    <div className="OCR">
      <div className="OCR__filters">
        <div className="OCR__filters__input">
          <input
            onChange={(e) => handleFilterChange('activeQuery', e.target.value)}
            placeholder={t('page_content.product_library.search_by_order_name')}
            value={filters.activeQuery}
          />
          <div className="OCR__filters__input__icon">
            <IconSearch
              color="#555"
              height="26px"
              width="26px"
            />
          </div>
        </div>

        <Select
          options={assets}
          getOptionLabel={(asset) => asset.name}
          getOptionValue={(asset) => asset.external_id}
          isSearchable
          placeholder={t('page_content.product_library.asset_placeholder')}
          onChange={(value) => handleFilterChange('selectedAsset', value.external_id)}
          value={(assets.find((a) => a.external_id === filters.selectedAsset)) || ''}
          styles={selectStyles}
        />

        <Select
          isMulti
          options={OCRStatuses}
          getOptionLabel={(status) => t(`page_content.product_library.ocr_statuses.${status.name}`)}
          getOptionValue={(status) => status.name}
          isSearchable
          placeholder={t('page_content.product_library.status_placeholder')}
          onChange={(value) => handleFilterChange('selectedStatus', value)}
          value={filters.selectedStatus}
          styles={multiSelectStyles}
        />

        <div className="OCR__filters__datepicker">
          <ReactDatePicker
            placeholderText={t('page_content.product_library.start_date')}
            selected={filters.startDate ? moment(filters.startDate).toDate() : null}
            onChange={(e) => handleFilterChange('startDate', e)}
            maxDate={filters.endDate ? moment(filters.endDate).toDate() : null}
          />
        </div>

        <div className="OCR__filters__datepicker">
          <ReactDatePicker
            placeholderText={t('page_content.product_library.end_date')}
            selected={filters.endDate ? moment(filters.endDate).toDate() : null}
            onChange={(e) => handleFilterChange('endDate', e)}
            minDate={filters.startDate ? moment(filters.startDate).toDate() : null}
          />
        </div>

        <Button onClick={clearFilters}>
          {t('shared.clear_button')}
        </Button>
      </div>

      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span>{t('page_content.product_library.created_at')}</span>,
            accessor: 'created_at',
            Cell: (row) => (moment(row.value).format(defaultDateTimeFormat) || '-'),
          },
          {
            Header: () => <span>{t('page_content.product_library.snowflake_id')}</span>,
            accessor: 'snowflake_id',
            Cell: (row) => (row.value || '-'),
          },
          {
            Header: () => <span>{t('page_content.product_library.asset')}</span>,
            accessor: 'external_id',
            Cell: (row) => (getAssetNameByExternalId(row.value) || '-'),
          },
          {
            Header: () => <span>{t('page_content.product_library.order_name')}</span>,
            accessor: 'order_name',
            Cell: (row) => (row.value || '-'),
          },
          {
            Header: () => <span>{t('page_content.product_library.position')}</span>,
            accessor: 'position',
            Cell: (row) => (row.value || '-'),
          },
          {
            Header: () => <span>{t('page_content.product_library.dimensions')}</span>,
            accessor: 'dimensions',
            Cell: (row) => (row.value || '-'),
          },
          {
            Header: () => <span>{t('page_content.product_library.status')}</span>,
            accessor: 'status',
            Cell: (row) => (row.value || '-'),
          },
          {
            Header: () => <span>{t('page_content.product_library.image')}</span>,
            accessor: 'id',
            Cell: (row) => <Button onClick={() => openImageModal(row.value || null)}>{t('page_content.product_library.show_image')}</Button>,
          },
        ]}
        data={OCRData.data || []}
        loading={isLoading}
        noDataText=" "
        showPagination={false}
        minRows={0}
        defaultPageSize={30}
        sortable={false}
      />
      <div style={{ float: 'right' }}>
        <TableButtons
          next={OCRData.next}
          previous={OCRData.previous}
          fetchFunction={fetchOCRDataURL}
          count={OCRData.count}
          showCount
        />
      </div>

      {
        imageModal.isOpen &&
        <ImageModal
          t={t}
          isOpen={imageModal.isOpen}
          handleClose={closeImageModal}
          id={imageModal.id}
          getAssetNameByExternalId={getAssetNameByExternalId}
        />
      }
    </div>
  );
};

OCRTab.propTypes = {
  t: PropTypes.func,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  assets: PropTypes.array.isRequired,
};

export default OCRTab;
