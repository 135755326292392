import { getByURL, getByURLBlob } from './CustomService';

// -------------------- REPORTS -------------------- //
export const getFivesReports = async (filters = '') => {
  return getByURL(`/api/v1/fives/reports/${filters}`);
};

// -------------------- REPORT ENTRIES -------------------- //
export const getFivesReportEntries = async (filters = '') => {
  return getByURL(`/api/v1/fives/report_entries/${filters}`);
};

export const getFivesReportEntriesBlob = async (filters = '') => {
  return getByURLBlob(`/api/v1/fives/report_entries/${filters}`);
};

// -------------------- REPORTS -------------------- //
export const getFivesFindings = async (filters = '') => {
  return getByURL(`/api/v1/fives/findings/${filters}`);
};
