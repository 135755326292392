import React, { useEffect, useState, useRef } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { IconSearch } from 'shared/Icons';
import './styles.scss';

import { getByURL, getCompanyMembers, getNotificationSubscribers, deleteNotificationSubscriber } from 'shared/Api';

import SubscribersModal from './components/SubscribersModal';

const EventSubscribers = ({ t, companyId, locationFilter, isUserProfile, currentUser, isReadOnly }) => {
  const [subscribers, setSubscribers] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [filters, setFilters] = useState({
    selectedAscDesc: 'desc',
    selectedSort: 'location',
    selectedLocation: null,
    selectedUser: null,
  });

  const debounceTimeoutRef = useRef(null);
  const [query, setQuery] = useState(null);

  const [users, setUsers] = useState({
    data: [],
    isLoading: false,
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchSubscribers = () => {
    setSubscribers((prevState) => ({ ...prevState, isLoading: true }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    let apiFilters = `?company=${companyId}&limit=15&order_by=${asc}${filters.selectedSort}`;

    if (filters?.selectedLocation?.id) apiFilters += `&location=${filters.selectedLocation.id}`;

    if (filters?.selectedUser?.id) apiFilters += `&user=${filters.selectedUser.id}`;

    if (query) apiFilters += `&name=${query}`;

    if (isUserProfile) apiFilters += `&user=${currentUser.id}`;

    getNotificationSubscribers(apiFilters)
      .then((res) => {
        setSubscribers({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      });
  };

  const fetchPaginatedSubscribers = (url) => {
    setSubscribers((prevState) => ({ ...prevState, isLoading: true }));

    getByURL(url)
      .then((res) => {
        setSubscribers({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      });
  };

  const fetchUsers = (value) => {
    setUsers((prevState) => ({ ...prevState, isLoading: true }));

    const apiFilters = `?company=${companyId}&first_or_last_name=${value}`;
    getCompanyMembers(apiFilters)
      .then((res) => setUsers({ data: get(res, 'data.results', []), isLoading: false }));
  };

  useEffect(() => {
    fetchSubscribers();
  }, [companyId, filters]);

  useEffect(() => {
    if (query !== null) {
      if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current);

      debounceTimeoutRef.current = setTimeout(() => fetchSubscribers(), 300);
    }
  }, [query]);

  const handleShowConfirmationDialog = (row) => {
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    const apiFilters = `?company=${companyId}`;
    await deleteNotificationSubscriber(deleteData?.id, apiFilters);
    setShowConfirmationDialog(false);
    fetchSubscribers();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewSubscriber = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchSubscribers();
  };

  const setSelectedFilters = (key, value) => {
    setFilters((prevState) => ({ ...prevState, [key]: value }));
  };

  const clearAllFilters = () => {
    setQuery('');
    setFilters((prevState) => ({
      ...prevState,
      selectedLocation: null,
      selectedUser: null,
    }));
  };

  const columns = [
    {
      Header: () => <span>{t('settings.events_and_subscribers.subscribers.name_label')}</span>,
      accessor: 'name',
      Cell: (row) => <div>{row?.value || '-'}</div>,
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('settings.events_and_subscribers.subscribers.email_label')}</span>,
      accessor: 'email',
      Cell: (row) => <div>{row?.value || '-'}</div>,
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('settings.events_and_subscribers.subscribers.mobile_number_label')}</span>,
      accessor: 'mobile_phone',
      Cell: (row) => <div>{row?.value || '-'}</div>,
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('settings.events_and_subscribers.subscribers.location_label')}</span>,
      accessor: 'location',
      Cell: (row) => <div>{row?.value?.name || '-'}</div>,
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('settings.events_and_subscribers.subscribers.user_label')}</span>,
      accessor: 'user',
      Cell: (row) => <div>{row?.value?.first_name && row?.value?.last_name ? `${row?.value?.first_name} ${row?.value?.last_name}` : '-'}</div>,
      style: { cursor: 'default' },
    },
  ];

  return (
    <div className="subscribers">
        <div className="subscribers__table">
            <div className="subscribers__table__filters">
                {
                  !isUserProfile && (
                    <div className="input_container">
                      <input
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder={t('settings.events_and_subscribers.subscribers.search_by_name_placeholder')}
                        value={query || ''}
                        type="text"
                      />
                      {
                        query &&
                        <button onClick={() => setQuery('')}>&times;</button>
                      }
                      <div className="icon_container">
                        <IconSearch
                          color="#555"
                          height="26px"
                          width="26px"
                        />
                      </div>
                    </div>
                  )
                }
                <div style={{ width: '200px' }}>
                    <Select
                      options={locationFilter.data}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isLoading={locationFilter.isLoading}
                      isClearable
                      isSearchable
                      menuPosition="fixed"
                      placeholder={t('settings.events_and_subscribers.subscribers.filter_by_location_placeholder')}
                      onChange={(value) => setSelectedFilters('selectedLocation', value)}
                      value={filters?.selectedLocation || ''}
                      styles={selectModalStyles}
                    />
                </div>
                {
                  !isUserProfile && (
                    <div style={{ width: '350px' }}>
                      <Select
                        options={users.data}
                        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                        getOptionValue={(option) => option.id}
                        isLoading={users.isLoading}
                        isClearable
                        menuPosition="fixed"
                        placeholder={t('settings.events_and_subscribers.subscribers.search_user_hint')}
                        onInputChange={(value) => {
                          if (typeof value === 'string' && value.length >= 3) fetchUsers(value);
                        }}
                        onChange={(value) => {
                          if (value && typeof value === 'object') setSelectedFilters('selectedUser', value);
                          else setSelectedFilters('selectedUser', null);
                        }}
                        value={filters?.selectedUser || null}
                        styles={selectModalStyles}
                      />
                    </div>
                  )
                }
                {
                  !isUserProfile && (
                    <Button onClick={clearAllFilters}>{t('shared.clear_button')}</Button>
                  )
                }
                <div className="subscribers__table__filters__action-buttons">
                    <Button type="add" onClick={handleAddNewSubscriber} disabled={isReadOnly}>
                        {t('settings.events_and_subscribers.subscribers.add_new_event_subscriber_button')}
                    </Button>
                </div>
            </div>
            <Table
              style={{ userSelect: 'text' }}
              columns={columns}
              minRows={0}
              enableEdit
              enableDelete
              onEdit={(original) => handleTableRowClick(original)}
              onDelete={(original) => handleShowConfirmationDialog(original)}
              isActionsDisabled={isReadOnly}
              data={subscribers.data || []}
              loading={subscribers.isLoading}
              defaultSorted={[{ id: 'location', desc: true }]}
              onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
            />
            <TableButtons
              next={subscribers.next}
              count={subscribers.count}
              previous={subscribers.previous}
              fetchFunction={fetchPaginatedSubscribers}
            />
        </div>

        {
            modalData.isOpen &&
            <SubscribersModal
              isOpen={modalData.isOpen}
              isUserProfile={isUserProfile}
              locations={locationFilter.data}
              handleCloseModal={handleCloseModal}
              initialValues={modalData.selectedItem}
              closeModalAndRefetch={closeModalAndRefetch}
            />
        }

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'name')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </div>
  );
};

EventSubscribers.propTypes = {
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  isUserProfile: PropTypes.bool.isRequired,
  locationFilter: PropTypes.object.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(EventSubscribers));
