import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Table, Modal, TableButtons, Button } from 'shared';
import { IconPopup } from 'shared/Icons';
import '../styles.scss';

import { defaultDateFormat, modalSizes } from 'shared/constants';

import { getWorkforceDocumentTypes, getWorkforceDocuments, getByURL } from 'shared/Api';

const WarningDocumentsModal = ({ t, companyId, workerId, isOpen, handleCloseModal }) => {
  const [tableData, setTableData] = useState({
    isLoading: true,
    documents: [],
    next: null,
    previous: null,
  });

  const [filters, setFilters] = useState({
    selectedSort: 'date',
    selectedAscDesc: 'desc',
    documentType: null,
  });

  const fetchDocumentTypes = async () => {
    const urlFilters = `?company=${companyId}&limit=999`;
    const re = await getWorkforceDocumentTypes(urlFilters);
    const documentType = get(re, 'data.results', []).find((type) => type.name === 'Opomena na radu')?.id || null;

    setFilters((prevFilters) => ({ ...prevFilters, documentType }));

    return documentType;
  };

  const fetchDocuments = async () => {
    setTableData((prevState) => ({ ...prevState, isLoading: true }));

    const documentType = filters?.documentType || (await fetchDocumentTypes());

    if (documentType) {
      let urlFilters = '';
      const asc = filters.selectedAscDesc === 'desc' ? '-' : '';
      urlFilters += `?order_by=${asc}${filters.selectedSort}&company=${companyId}&worker=${workerId}&limit=20`;

      if (documentType) urlFilters += `&document_type=${documentType}`;

      const re = await getWorkforceDocuments(urlFilters);
      setTableData({
        isLoading: false,
        documents: get(re, 'data.results', []),
        count: get(re, 'data.count'),
        next: get(re, 'data.next', null),
        previous: get(re, 'data.previous', null),
      });
    } else {
      setTableData((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [companyId, workerId, filters.selectedSort, filters.selectedAscDesc]);

  const fetchPaginatedData = (url) => {
    setTableData((prevState) => ({ ...prevState, isLoading: true }));

    getByURL(url)
      .then((re) => {
        setTableData({
          isLoading: false,
          documents: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  const handleSorting = (sortData) => {
    const column = sortData.id;

    setFilters({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    });
  };

  const handleDownloadDocument = (row) => {
    const link = document.createElement('a');
    link.href = row.file;
    link.target = '_blank';
    link.setAttribute('download', row.name);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const tableColumns = [
    {
      Header: t('page_content.administration.date'),
      accessor: 'created_at',
      Cell: (row) => <span>{row.value ? moment(row.value).format(defaultDateFormat) : '-'}</span>,
      style: { cursor: 'default' },
    },
    {
      Header: t('page_content.administration.title'),
      accessor: 'name',
      Cell: (row) => row?.value || '-',
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.administration.table_column_open')}</span>,
      width: 100,
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
          <div onClick={() => handleDownloadDocument(row.original)}>
              <Button size="small">
                  <IconPopup
                    height="16px"
                    width="16px"
                    color="#555"
                  />
              </Button>
          </div>
        </div>),
    },
  ];

  return (
    <Modal
      size={modalSizes.large}
      isOpen={isOpen}
      handleClose={handleCloseModal}
      title={t('page_content.administration.warning_documents')}
    >
      <div className="salaries-modal">
        <Table
          style={{ userSelect: 'text', marginTop: 20 }}
          columns={tableColumns}
          data={tableData?.documents || []}
          minRows={0}
          defaultPageSize={20}
          noDataText=" "
          sortable
          showPagination={false}
          loading={tableData?.isLoading}
          defaultSorted={[{ id: 'date', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons
          previous={tableData?.previous}
          next={tableData?.next}
          fetchFunction={fetchPaginatedData}
          count={tableData?.count}
        />
      </div>
    </Modal>
  );
};

WarningDocumentsModal.propTypes = {
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  workerId: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default (withTranslation()(WarningDocumentsModal));
