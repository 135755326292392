import React, { useEffect, useState, createRef } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Tooltip from 'rc-tooltip';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { IconInfo, IconRemove, IconShowPassword, IconHidePassword } from 'shared/Icons';
import { Modal, Button, ContentLoader, Notification, ReactDatePicker } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';
import userGreen from 'shared/Icons/workforce/user-green.png';

import api from 'helpers/api';

import RelatedInfoFields from 'shared/RelatedInfoFields/index';

import { getWorkforceWorkerCategory } from 'shared/Api';
import { getWorkerDetails, getQualifications, getDepartmentShifts, addRelatedWorkerInfo, editRelatedWorkerInfo, patchWorker } from '../../../actions';

const WorkerModalForm = ({
  t,
  companyId,
  locationId,
  showModal,
  handleClose,
  departments,
  currentUser,
  initialData = {},
  companiesOptions,
  customDepartment,
  handleCloseAndRefetch,
}) => {
  const modalRef = createRef(null);
  const [formData, setFormData] = useState();
  const [updatedFormData, setUpdatedFormData] = useState({});
  const [formHelpers, setFormHelpers] = useState({
    workingPlacesList: [],
    qualificationsList: [],
    revealPassword: false,
    department_shifts: [],
    workerCategories: [],
  });
  const [formErrors, setFormErrors] = useState({
    formValid: true,
    userNotCreatedError: '',
  });
  const [isLoadingShifts, setIsLoadingShifts] = useState(true);
  const [workerRelatedInfo, setWorkerRelatedInfo] = useState([]);
  const [isLoadingWorkerDetails, setIsLoadingWorkerDetails] = useState(false);

  useEffect(() => {
    if (showModal && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [!formData?.create_user]);

  const handleChange = (value, key) => {
    if (key === 'is_cooperant') {
      setFormData((prevState) => ({
        ...prevState,
        [key]: value,
        partner: null,
        worker_category: null,
      }));
      setUpdatedFormData((prevState) => ({
        ...prevState,
        [key]: value,
        partner: null,
        worker_category: null,
      }));
    } else if (key === 'work_permit' && value === false) {
      setFormData((prevState) => ({
        ...prevState,
        [key]: value,
        work_permit_valid_until: null,
      }));
      setUpdatedFormData((prevState) => ({
        ...prevState,
        [key]: value,
        work_permit_valid_until: null,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [key]: value,
      }));
      setUpdatedFormData((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const fetchQualifications = async () => {
    await getQualifications(companyId)
      .then((resp) => setFormHelpers((prevState) => ({ ...prevState, qualificationsList: get(resp, 'data.results', []) })))
      .catch((error) => console.error(error));
  };

  const fetchDepartmentShifts = () => {
    setIsLoadingShifts(true);
    setFormHelpers((prevState) => ({ ...prevState, department_shifts: [] }));

    let apiFilters = '&limit=9999&order_by=department__name';

    if (formData?.location_department) {
      apiFilters += `&department=${
        typeof formData?.location_department === 'object'
          ? formData?.location_department?.id
          : formData?.location_department
      }`;
    }

    getDepartmentShifts(companyId, apiFilters)
      .then((res) => {
        setFormHelpers((prevState) => ({ ...prevState, department_shifts: get(res, 'data.results', []) }));
        setIsLoadingShifts(false);
      })
      .catch(() => {
        setFormHelpers((prevState) => ({ ...prevState, department_shifts: [] }));
        setIsLoadingShifts(false);
      });
  };

  const fetchWorker = async () => {
    setIsLoadingWorkerDetails(true);

    getWorkerDetails(locationId, companyId, initialData.id)
      .then((resp) => {
        const workerData = get(resp, 'data', {});
        setFormData(workerData);
        setIsLoadingWorkerDetails(false);
      })
      .catch(() => setIsLoadingWorkerDetails(false));
  };

  const fetchWorkerCategories = async () => {
    getWorkforceWorkerCategory(`?company=${companyId}&limit=999&order_by=name`)
      .then((res) => setFormHelpers((prevState) => ({ ...prevState, workerCategories: get(res, 'data.results', []) })));
  };

  useEffect(() => {
    fetchQualifications();
    fetchWorkerCategories();
    if (initialData?.id) fetchWorker();
  }, []);

  useEffect(() => {
    fetchDepartmentShifts();
  }, [formData?.location_department]);

  const handleCloseModal = () => {
    setFormData({});
    handleClose();
  };

  const onChangeAttachments = (e) => {
    if (e.target.files && e.target.files[0]) handleChange(e.target.files[0], 'profile_picture');
  };

  const requiredSign = <span style={{ margin: '0 5px' }}> *</span>;

  const handleWorkerRelatedInfoChange = (newWorkerRelatedInfo) => {
    setWorkerRelatedInfo(newWorkerRelatedInfo);
  };

  const handleSave = async () => {
    try {
      const apiFormData = new FormData();
      apiFormData.append('location', locationId);
      apiFormData.append('company', companyId);
      // Append data to FormData object with conditional handling
      for (const key in updatedFormData) {
        if (key !== 'locationId' && key !== 'companyId') {
          // Fields that need IDs
          if (['location_department', 'qualification', 'department_shift'].includes(key)) {
            apiFormData.append(key, updatedFormData[key]?.id || null);
          } else if (['seniority_days', 'seniority_years', 'seniority_months'].includes(key)) {
            const seniorityValue = updatedFormData[key] || 0;
            apiFormData.append(key, seniorityValue);

            // Conditionally set 'seniority_date' to null if 'seniorityValue' and years, months, days is 0
            if (!seniorityValue) {
              const seniorityDays = updatedFormData?.seniority_days || formData?.seniority_days || null;
              const seniorityYears = updatedFormData?.seniority_years || formData?.seniority_years || null;
              const seniorityMonths = updatedFormData?.seniority_months || formData?.seniority_months || null;

              if (!seniorityDays && !seniorityYears && !seniorityMonths) {
                apiFormData.append('seniority_date', '');
              }
            }
          } else if ((key === 'is_cooperant' || key === 'partner' || key === 'worker_category') && !apiFormData.has('partner') && !apiFormData.has('worker_category')) {
            // Conditionally append 'is_cooperant' based on initialData comparison
            const isCooperantValue = typeof updatedFormData?.is_cooperant === 'boolean' ? updatedFormData?.is_cooperant : initialData?.is_cooperant || false;
            apiFormData.append('is_cooperant', isCooperantValue);

            // Conditionally add 'partner' if 'is_cooperant' is true
            if (isCooperantValue && updatedFormData?.partner?.id && !updatedFormData?.worker_category?.id) {
              apiFormData.append('partner', updatedFormData.partner.id);
            } else if (!isCooperantValue && !updatedFormData?.partner?.id && updatedFormData?.worker_category?.id) {
              apiFormData.append('worker_category', updatedFormData.worker_category.id);
            }

            if (!isCooperantValue && !updatedFormData?.partner?.id) {
              apiFormData.append('partner', ''); // Explicitly set to null when partner is false
            } else if (isCooperantValue && !updatedFormData?.worker_category?.id) {
              apiFormData.append('worker_category', ''); // Explicitly set to null when worker_category is false
            }
          } else if ((key === 'work_permit' || key === 'work_permit_valid_until') && !apiFormData.has('work_permit_valid_until')) {
            // Conditionally append 'work_permit' based on initialData comparison
            const workPermitValue = typeof updatedFormData?.work_permit === 'boolean' ? updatedFormData?.work_permit : initialData?.work_permit || false;
            apiFormData.append('work_permit', workPermitValue);

            // Conditionally add 'work_permit_valid_until' if 'work_permit' is true
            if (workPermitValue && updatedFormData?.work_permit_valid_until) {
              apiFormData.append('work_permit_valid_until', moment(updatedFormData.work_permit_valid_until).format('YYYY-MM-DD'));
            } else if (!workPermitValue) {
              apiFormData.append('work_permit_valid_until', ''); // Explicitly set to null when work_permit is false
            }
          } else if (key === 'profile_picture') {
            // Append profile picture as a file
            apiFormData.append('profile_picture', updatedFormData.profile_picture || '');
          } else if (!['work_permit_valid_until', 'partner', 'worker_category'].includes(key)) {
            // Default append for fields that don't need specific conditions
            apiFormData.append(key, updatedFormData[key]);
          }
        }
      }

      let response = {};

      if (!formData?.id) {
        // ---------------------------------- Creating a new worker ----------------------------------
        response = await api.post(`/api/v1/workforce/workers/${formData.create_user ? '?create_user=true' : ''}`, apiFormData);
      } else if (formData?.id) {
        // ----------------------------- Updating already created worker -----------------------------
        response = await patchWorker(apiFormData, companyId, locationId, initialData.id);
      }
      return response;
    } catch (error) {
      return get(error, 'response.data[0]');
    }
  };

  const handleSaveWorker = async () => {
    let requiredKeyValues = [];

    if (!formData?.is_cooperant) {
      requiredKeyValues = [
        'name',
        'last_name',
        'date_of_birth',
        'gender',
        'address',
        'mobile_phone',
        'tax_id',
        `${customDepartment ? 'location_department' : 'department'}`,
        'qualification',
        'worker_category',
      ];
      if (formData?.seniority_years || formData?.seniority_months || formData?.seniority_days) requiredKeyValues.push('seniority_date');
      if (formData?.work_permit) requiredKeyValues.push('work_permit_valid_until');
    } else {
      requiredKeyValues = [
        'name',
        'last_name',
        'gender',
        `${customDepartment ? 'location_department' : 'department'}`,
        'partner',
      ];
    }
    if (formData?.create_user) {
      requiredKeyValues.push('password');
      requiredKeyValues.push('email');
    }

    let isValid = formData !== null && formData !== undefined;
    if (isValid) {
      requiredKeyValues.forEach((key) => {
        if (formData[key] === '' || formData[key] === null || formData[key] === undefined) {
          isValid = false;
        }
      });
    }
    setFormErrors((prevState) => ({
      ...prevState,
      formValid: isValid,
    }));
    if (isValid) {
      // saving worker
      const response = await handleSave();
      if (response?.statusText !== 'Created' && response?.statusText !== 'OK') {
        let error = '';
        if (response === 'User with that e-mail already exists.') error = t('page_content.human_resources.workers.user_exists_error');
        if (response === 'e-mail is required') error = t('page_content.human_resources.workers.user_email_error');
        setFormErrors((prevState) => ({ ...prevState, userNotCreatedError: error }));
      } else if (response?.statusText === 'Created' || response?.statusText === 'OK') {
        // saving worker related info
        const workerId = get(response, 'data.id', null);
        if (workerRelatedInfo?.length > 0) {
          const addedWorkerRelatedInfo = workerRelatedInfo.filter((info) => !info.id && info.addedOrEdited === 'added');
          const editedWorkerRelatedInfo = workerRelatedInfo.filter((info) => info.id && info.addedOrEdited === 'edited');

          if (addedWorkerRelatedInfo?.length > 0 || editedWorkerRelatedInfo?.length > 0) {
            Promise.all(
              addedWorkerRelatedInfo?.map((info) => addRelatedWorkerInfo(companyId, { ...info, worker: workerId, info: info.info.id, user: currentUser?.id })),
              editedWorkerRelatedInfo?.map((info) => editRelatedWorkerInfo(companyId, info.id, { ...info, worker: workerId, info: info.info.id, user: currentUser?.id })),
            );
          }
        }
        Notification('success', 'Save successful', response?.statusText === 'Created' ? 'Worker was successfully created.' : 'Worker was successfully updated.');
        handleCloseAndRefetch();
      } else {
        handleClose();
      }
    }
  };

  const handleNumberInput = (value) => {
    const numericValue = value.replace(/[^0-9]/g, '');
    return numericValue ? parseInt(numericValue, 10) : '';
  };

  const genderOptions = [
    { id: 'male', name: t('page_content.human_resources.workers.gender_option_male') },
    { id: 'female', name: t('page_content.human_resources.workers.gender_option_female') },
  ];

  return (
    <Modal
      title={formData?.id ?
        t('page_content.human_resources.workers.edit_worker_modal_title') :
        t('page_content.human_resources.workers.new_worker_modal_title')}
      isOpen={showModal}
      handleClose={handleCloseModal}
      handleSave={handleSaveWorker}
      enableSaveOnEnter={false}
      error={!formErrors?.formValid ? t('page_content.human_resources.workers.error_msg') : formErrors?.userNotCreatedError || ''}
    >
      <div className="custom_data_modal_container">
        {
          isLoadingWorkerDetails ? <ContentLoader /> : (
            <>
              <span style={{ fontSize: 12 }}>{requiredSign} {t('page_content.human_resources.workers.mandatory_fields_label')}</span>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.input_label_is_cooperant')}</div>
                <div className="right_input">
                  <input type="checkbox" checked={formData?.is_cooperant || false} onChange={(e) => handleChange(e.target.checked, 'is_cooperant')} />
                </div>
              </div>
              {
                formData?.is_cooperant ? (
                  <div className="modal_row">
                    <div className="left_text">{t('page_content.human_resources.workers.input_label_company')}:{requiredSign}</div>
                    <Select
                      className="right_select"
                      options={companiesOptions}
                      menuPosition="fixed"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder={t('page_content.human_resources.workers.input_label_company_placeholder')}
                      onChange={(e) => handleChange(e || '', 'partner')}
                      isSearchable
                      isDisabled={!formData?.is_cooperant}
                      value={formData?.partner?.id ? companiesOptions?.find((option) => option.id === formData.partner.id) : null}
                      styles={selectModalStyles}
                    />
                  </div>
                ) : (
                  <div className="modal_row">
                    <div className="left_text">{t('page_content.human_resources.workers.input_label_worker_category')}:{requiredSign}</div>
                    <Select
                      className="right_select"
                      options={formHelpers?.workerCategories}
                      menuPosition="fixed"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder={t('page_content.human_resources.workers.input_label_worker_category_placeholder')}
                      onChange={(e) => handleChange(e || '', 'worker_category')}
                      isSearchable
                      isDisabled={formData?.is_cooperant}
                      value={formData?.worker_category?.id ? formHelpers?.workerCategories?.find((option) => option.id === formData.worker_category.id) : null}
                      styles={selectModalStyles}
                    />
                  </div>
                )
              }
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.table_column_first_name')}:{requiredSign}</div>
                <div className="right_input">
                  <input type="text" defaultValue={formData?.name || ''} onChange={(e) => { handleChange(e.target.value, 'name'); }} />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.table_column_last_name')}:{requiredSign} </div>
                <div className="right_input">
                  <input type="text" defaultValue={formData?.last_name || ''} onChange={(e) => { handleChange(e.target.value, 'last_name'); }} />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.table_column_email')}:{formData?.create_user ? requiredSign : ''}</div>
                <div className="right_input">
                  <input type="email" defaultValue={formData?.email || ''} onChange={(e) => { handleChange(e.target.value, 'email'); }} />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.table_column_private_email')}: </div>
                <div className="right_input">
                  <input type="email" defaultValue={formData?.private_email || ''} onChange={(e) => { handleChange(e.target.value, 'private_email'); }} />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.input_label_birthday')}:{formData?.is_cooperant ? '' : requiredSign}</div>
                <div className="right_date_picker">
                  <ReactDatePicker
                    selected={formData?.date_of_birth ? new Date(formData.date_of_birth) : null}
                    onChange={(date) => handleChange(date ? moment(date).format('YYYY-MM-DD') : null, 'date_of_birth')}
                    maxDate={new Date()}
                  />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.input_label_gender')}:{requiredSign}</div>
                <Select
                  className="right_select"
                  options={genderOptions}
                  menuPosition="fixed"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder={t('page_content.human_resources.workers.input_label_gender_placeholder')}
                  onChange={(e) => handleChange(e.id, 'gender')}
                  isSearchable
                  value={genderOptions.find((d) => d.id === (formData?.gender || '')) || null}
                  styles={selectModalStyles}
                />
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.table_column_address')}:{formData?.is_cooperant ? '' : requiredSign}</div>
                <div className="right_input">
                  <input type="text" defaultValue={formData?.address || ''} onChange={(e) => { handleChange(e.target.value, 'address'); }} />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.input_label_mobile')}:{formData?.is_cooperant ? '' : requiredSign}</div>
                <div className="right_input">
                  <input type="tel" defaultValue={formData?.mobile_phone || ''} onChange={(e) => { handleChange(e.target.value, 'mobile_phone'); }} />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.input_label_tax')}:{formData?.is_cooperant ? '' : requiredSign}</div>
                <div className="right_input">
                  <input type="number" defaultValue={formData?.tax_id || ''} onChange={(e) => { handleChange(e.target.value, 'tax_id'); }} />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.table_column_department')}:{requiredSign}</div>
                <Select
                  className="right_select"
                  options={departments?.sort((a, b) => a.name.localeCompare(b.name))}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  menuPosition="fixed"
                  placeholder={t('page_content.human_resources.workers.input_label_department_placeholder')}
                  onChange={(e) => handleChange(e, customDepartment || 'department')}
                  isSearchable
                  value={departments?.find((d) => d.id === (customDepartment ? (formData?.location_department ? formData?.location_department?.id : '') : formData?.department?.id))}
                  styles={selectModalStyles}
                />
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.default_shift')}:</div>
                <Select
                  className="right_select"
                  options={formHelpers?.department_shifts}
                  getOptionLabel={(option) => `${option?.name} (${moment(option?.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(option?.end_time, 'HH:mm:ss').format('HH:mm')})`}
                  getOptionValue={(option) => option.id}
                  menuPosition="fixed"
                  placeholder={t('page_content.human_resources.workers.input_label_shifts_placeholder')}
                  onChange={(e) => handleChange(e, 'department_shift')}
                  isSearchable
                  value={formHelpers?.department_shifts?.find((d) => d.id === (typeof formData?.department_shift === 'object' ? formData?.department_shift?.id : formData?.department_shift)) || null}
                  styles={selectModalStyles}
                  isDisabled={!formData?.location_department}
                  isLoading={isLoadingShifts}
                  loadingMessage={() => <ContentLoader />}
                />
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.input_label_qualifications')}:{formData?.is_cooperant ? '' : requiredSign}</div>
                <Select
                  className="right_select"
                  options={formHelpers?.qualificationsList?.sort((a, b) => a.name.localeCompare(b.name))}
                  menuPosition="fixed"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder={t('page_content.human_resources.workers.input_label_qualification_placeholder')}
                  onChange={(e) => handleChange(e, 'qualification')}
                  isSearchable
                  value={formHelpers?.qualificationsList?.find((d) => d.id === formData?.qualification?.id) || null}
                  styles={selectModalStyles}
                />
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.column_seniority')}:</div>
                <div className="right_inputs">
                  <input type="number" value={formData?.seniority_years || ''} onChange={(e) => handleChange(handleNumberInput(e.target.value), 'seniority_years')} placeholder={t('page_content.human_resources.workers.column_seniority_in_years')} />
                  <input type="number" value={formData?.seniority_months || ''} onChange={(e) => handleChange(handleNumberInput(e.target.value), 'seniority_months')} placeholder={t('page_content.human_resources.workers.column_seniority_in_months')} />
                  <input type="number" value={formData?.seniority_days || ''} onChange={(e) => handleChange(handleNumberInput(e.target.value), 'seniority_days')} placeholder={t('page_content.human_resources.workers.column_seniority_in_days')} />
                </div>
              </div>
              {
                formData?.seniority_years || formData?.seniority_months || formData?.seniority_days ?
                  <div className="modal_row">
                    <div className="left_text">{t('page_content.human_resources.workers.column_seniority_changed')}:{formData?.seniority_years || formData?.seniority_months || formData?.seniority_days ? requiredSign : null}</div>
                    <div className="right_date_picker">
                      <ReactDatePicker
                        selected={formData?.seniority_date ? new Date(formData.seniority_date) : null}
                        onChange={(date) => handleChange(date ? moment(date).format('YYYY-MM-DD') : null, 'seniority_date')}
                        maxDate={new Date()}
                      />
                    </div>
                  </div> : null
              }
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.input_label_info')}: </div>
                <div className="right_textarea">
                  <textarea defaultValue={formData?.note || ''} onChange={(e) => handleChange(e.target.value, 'note')} />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.input_label_work_permit')}:</div>
                <div className="right_input">
                  <input type="checkbox" checked={formData?.work_permit || false} onChange={(e) => handleChange(e.target.checked, 'work_permit')} />
                </div>
              </div>
              {
                formData?.work_permit && (
                  <div className="modal_row">
                    <div className="left_text">{t('page_content.human_resources.workers.input_label_work_permit_valid_until')}:{requiredSign}</div>
                    <div className="right_date_picker">
                      <ReactDatePicker
                        selected={formData?.work_permit_valid_until ? new Date(formData.work_permit_valid_until) : null}
                        onChange={(date) => handleChange(date ? moment(date).format('YYYY-MM-DD') : null, 'work_permit_valid_until')}
                      />
                    </div>
                  </div>
                )
              }

              <RelatedInfoFields companyId={companyId} workerId={initialData?.id} handleWorkerRelatedInfoChange={handleWorkerRelatedInfoChange} />

              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.workers.input_label_profile_picture')}:</div>
                <div className="right_profile_picture">
                  <div className="input_container">
                  {
                    (!formData?.profile_picture?.full_size) &&
                      <input type="file" accept="image/*" onChange={onChangeAttachments} />
                  }
                  </div>
                  <div className="profile_picture_preview">
                    {
                      (formData?.profile_picture?.full_size || formData?.profile_picture instanceof Blob || formData?.profile_picture instanceof File) &&
                      <Button type="delete" onClick={() => handleChange(null, 'profile_picture')}><IconRemove width="15px" height="15px" /></Button>
                    }
                    <img
                      alt=""
                      className="profile_picture_style"
                      src={initialData && formData?.profile_picture?.full_size ? formData?.profile_picture?.full_size : (formData?.profile_picture instanceof Blob || formData?.profile_picture instanceof File ? URL.createObjectURL(formData?.profile_picture) : userGreen)}
                    />
                  </div>
                </div>
              </div>
              {!initialData &&
                <div className="modal_row">
                  <div className="left_text">{t('page_content.human_resources.workers.input_label_create_user')}<div style={{ marginLeft: 5 }}>
                    <Tooltip
                      id="tooltip-zoom"
                      trigger={['hover']}
                      placement="left"
                      overlay={t('page_content.human_resources.workers.input_label_create_user_tooltip')}
                      overlayClassName="where-filter-tooltip"
                    >
                      <span aria-describedby="tooltip-zoom">
                        <IconInfo color="#2e86de" height="15px" width="17px" />
                      </span>
                    </Tooltip>
                  </div>
                  </div>
                  <div className="right_input">
                    <input type="checkbox" checked={formData?.create_user || false} onChange={(e) => handleChange(e.target.checked, 'create_user')} />
                  </div>
                </div>}
              {formData?.create_user &&
                <div className="modal_row">
                  <div className="left_text">{t('page_content.human_resources.workers.input_label_password')}: </div>
                  <div className="right_input_password">
                    <input type={formHelpers?.revealPassword ? 'text' : 'password'} defaultValue="" onChange={(e) => { handleChange(e.target.value, 'password'); }} />
                    <div className="icon_show_hide_password" onClick={() => setFormHelpers((prevState) => ({ ...prevState, revealPassword: !prevState.revealPassword }))}>
                      {!formHelpers?.revealPassword ? <IconShowPassword height="20px" width="20px" /> : <IconHidePassword height="20px" width="20px" />}
                    </div>
                  </div>
                </div>}
              <div ref={modalRef} />
            </>
          )
        }
      </div>
    </Modal>
  );
};

WorkerModalForm.propTypes = {
  t: PropTypes.func,
  showModal: PropTypes.bool,
  handleClose: PropTypes.func,
  companyId: PropTypes.number,
  locationId: PropTypes.number,
  departments: PropTypes.array,
  initialData: PropTypes.object,
  companiesOptions: PropTypes.array,
  customDepartment: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  handleCloseAndRefetch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    companyId: get(state, 'app.company.id', null),
    locationId: get(state, 'app.location.id', null),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(WorkerModalForm));
