// Define all possible sections
export const SECTIONS = {
  // HR -------------------------------------------------------------------------------------------------------------------------------------
  WORKERS_TABS: 'workers_tabs',
  WORKERS_TABS__WORKERS_LIST_TAB: 'workers_list_tab',
  WORKERS_TABS__WORKERS_AVAILABILITY_TAB: 'workers_availability_tab',
  WORKERS_TABS__WORKERS_QUALIFICATIONS_AND_PROFESSIONS_TAB: 'workers_qualifications_and_professions_tab',
  WORKERS_TABS__WORKER_CATEGORIES_TAB: 'worker_categories_tab',

  EMPLOYMENTS_TAB: 'employments_tab',
  DEPARTMENTS_TAB: 'departments_tab',

  SHIFTS_TABS: 'shifts_tabs',
  SHIFTS_TABS__SHIFTS_TAB: 'shift_tab',
  SHIFTS_TABS__EXCEPTIONS_TAB: 'exceptions_tab',

  FORMS_TABS: 'forms_tabs',
  FORMS_TABS__FORMS_TEMPLATES_TAB: 'forms_templates_tab',
  FORMS_TABS__FORMS_TAB: 'forms_tab',
  FORMS_TABS__CATEGORIES_TAB: 'categories_tab',

  ASSIGNED_EQUIPMENT_TABS: 'assigned_equipment_tabs',
  ASSIGNED_EQUIPMENT_TABS__ASSIGNED_EQUIPMENT_TAB: 'assigned_equipment_tab',
  ASSIGNED_EQUIPMENT_TABS__EQUIPMENT_CATALOGUE_TAB: 'equipment_catalogue_tab',

  WORKING_INJURIES_TAB: 'working_injuries_tab',

  RATINGS_TABS: 'ratings_tabs',
  RATINGS_TABS__INDIVIDUAL_GOALS_TAB: 'individual_goals_tab',
  RATINGS_TABS__COMPANY_VALUES_TAB: 'company_values_tab',
  RATINGS_TABS__WORK_PROCESSES_TAB: 'work_processes_tab',
  RATINGS_TABS__PERSONNEL_DATA_TAB: 'personnel_data_tab',

  CONTROL_PANEL_TABS: 'control_panel_tabs',
  CONTROL_PANEL_TABS__CATEGORY_SCORING_TAB: 'category_scoring_tab',
  CONTROL_PANEL_TABS__INDIVIDUAL_GOALS_TAB: 'individual_goals_tab',
  CONTROL_PANEL_TABS__KFK_VALUES_TAB: 'kfk_values_tab',
  CONTROL_PANEL_TABS__ACTIONS_TAB: 'actions_tab',

  // HR - EXTRA -----------------------------------------------------------------------------------------------------------------------------
  DEPARTMENT_MANAGER: 'department_manager',
  RATINGS_SCORING: 'ratings_scoring',
  RATING_HISTORY: 'rating_history',

  // Worker details -------------------------------------------------------------------------------------------------------------------------
  WORKER_DETAILS_TAB: 'worker_details_tab',
  WORKER_DETAILS_TAB__BREADCRUMBS: 'breadcrumbs',

  // Worker details 'Work contracts' box, 'All contracts' and 'Download contract' buttons
  WORKER_DETAILS_TAB__WORK_CONTRACTS_BOX: 'work_contracts_box',
  WORKER_DETAILS_TAB__ALL_CONTRACTS_BTN: 'all_contracts_btn',
  WORKER_DETAILS_TAB__DOWNLOAD_CONTRACT_BTN: 'work_contracts_download_btn',

  // Worker details 'Other documentation' box and modal
  WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_BOX: 'other_documentation_box',
  WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_BTN: 'other_documentation_btn',
  WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__ALL_DOCUMENTS_TAB: 'other_documentation_modal_all_documents_tab',
  WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__ALL_DOCUMENTS_TAB__IS_MID: 'other_documentation_modal_all_documents_tab_is_mid',
  WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__DOCUMENT_TYPES_TAB: 'other_documentation_modal_document_types_tab',
  WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__DOCUMENT_TYPES_TAB__IS_MID: 'other_documentation_modal_document_types_tab_is_mid',

  // Worker details 'View absences'
  WORKER_DETAILS_TAB__VIEW_ABSENCES_BTN: 'view_absences_btn',
  WORKER_DETAILS_TAB__MODAL__ABSENCES_TAB: 'absences_tab',
  WORKER_DETAILS_TAB__MODAL__VACATIONS_TAB: 'vacations_tab',

  // Worker details 'General info' and 'Salary' table
  WORKER_DETAILS_TAB__GENERAL_INFO_TAB: 'general_info_tab',
  WORKER_DETAILS_TAB__GENERAL_INFO_TAB__SALARY_TABLE: 'salary_table',

  // Worker details tabs
  WORKER_DETAILS_TAB__MY_FORMS_TAB: 'my_forms_tab',
  WORKER_DETAILS_TAB__WORKING_INJURIES_TAB: 'working_injuries_tab',
  WORKER_DETAILS_TAB__WORKER_SCHEDULER_TAB: 'worker_scheduler_tab',
  WORKER_DETAILS_TAB__WORKING_PLACES_TAB: 'working_places_tab',

  // other sections ------------------------------------------------------------------------------------------------------------------------
};

// Helper function to create section groups
export const createSectionGroup = (...sections) => sections;

// Define logical groupings of sections
export const SECTION_GROUPS = {
  // HR related sections
  HR: createSectionGroup(
    SECTIONS.WORKERS_TABS,
    SECTIONS.WORKERS_TABS__WORKERS_LIST_TAB,
    SECTIONS.WORKERS_TABS__WORKERS_AVAILABILITY_TAB,
    SECTIONS.WORKERS_TABS__WORKERS_QUALIFICATIONS_AND_PROFESSIONS_TAB,
    SECTIONS.WORKERS_TABS__WORKER_CATEGORIES_TAB,

    SECTIONS.EMPLOYMENTS_TAB,
    SECTIONS.DEPARTMENTS_TAB,

    SECTIONS.SHIFTS_TABS,
    SECTIONS.SHIFTS_TABS__SHIFTS_TAB,
    SECTIONS.SHIFTS_TABS__EXCEPTIONS_TAB,

    SECTIONS.FORMS_TABS,
    SECTIONS.FORMS_TABS__FORMS_TEMPLATES_TAB,
    SECTIONS.FORMS_TABS__FORMS_TAB,
    SECTIONS.FORMS_TABS__CATEGORIES_TAB,

    SECTIONS.ASSIGNED_EQUIPMENT_TABS,
    SECTIONS.ASSIGNED_EQUIPMENT_TABS__ASSIGNED_EQUIPMENT_TAB,
    SECTIONS.ASSIGNED_EQUIPMENT_TABS__EQUIPMENT_CATALOGUE_TAB,

    SECTIONS.WORKING_INJURIES_TAB,

    SECTIONS.RATINGS_TABS,
    // SECTIONS.RATINGS_TABS__INDIVIDUAL_GOALS_TAB,
    // SECTIONS.RATINGS_TABS__COMPANY_VALUES_TAB,
    // SECTIONS.RATINGS_TABS__WORK_PROCESSES_TAB,
    // SECTIONS.RATINGS_TABS__PERSONNEL_DATA_TAB,

    // SECTIONS.CONTROL_PANEL_TABS,
    // SECTIONS.CONTROL_PANEL_TABS__CATEGORY_SCORING_TAB,
    // SECTIONS.CONTROL_PANEL_TABS__INDIVIDUAL_GOALS_TAB,
    // SECTIONS.CONTROL_PANEL_TABS__KFK_VALUES_TAB,
    // SECTIONS.CONTROL_PANEL_TABS__ACTIONS_TAB,

    SECTIONS.DEPARTMENT_MANAGER,
    SECTIONS.RATINGS_SCORING,
    SECTIONS.RATING_HISTORY,
  ),

  RATINGS: createSectionGroup(
    SECTIONS.RATINGS_TABS,
    SECTIONS.RATINGS_TABS__INDIVIDUAL_GOALS_TAB,
    SECTIONS.RATINGS_TABS__COMPANY_VALUES_TAB,
    SECTIONS.RATINGS_TABS__WORK_PROCESSES_TAB,
    SECTIONS.RATINGS_TABS__PERSONNEL_DATA_TAB,
  ),

  CONTROL_PANEL: createSectionGroup(
    SECTIONS.CONTROL_PANEL_TABS,
    SECTIONS.CONTROL_PANEL_TABS__CATEGORY_SCORING_TAB,
    SECTIONS.CONTROL_PANEL_TABS__INDIVIDUAL_GOALS_TAB,
    SECTIONS.CONTROL_PANEL_TABS__KFK_VALUES_TAB,
    SECTIONS.CONTROL_PANEL_TABS__ACTIONS_TAB,
  ),

  // Worker details related sections
  WORKER_DETAILS: createSectionGroup(
    SECTIONS.WORKER_DETAILS_TAB,
    SECTIONS.WORKER_DETAILS_TAB__BREADCRUMBS,

    SECTIONS.WORKER_DETAILS_TAB__WORK_CONTRACTS_BOX,
    SECTIONS.WORKER_DETAILS_TAB__ALL_CONTRACTS_BTN,
    SECTIONS.WORKER_DETAILS_TAB__DOWNLOAD_CONTRACT_BTN,

    SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_BOX,
    SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_BTN,
    SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__ALL_DOCUMENTS_TAB,
    SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__ALL_DOCUMENTS_TAB__IS_MID,
    SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__DOCUMENT_TYPES_TAB,
    SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__DOCUMENT_TYPES_TAB__IS_MID,

    SECTIONS.WORKER_DETAILS_TAB__VIEW_ABSENCES_BTN,
    SECTIONS.WORKER_DETAILS_TAB__MODAL__ABSENCES_TAB,
    SECTIONS.WORKER_DETAILS_TAB__MODAL__VACATIONS_TAB,

    SECTIONS.WORKER_DETAILS_TAB__GENERAL_INFO_TAB,
    SECTIONS.WORKER_DETAILS_TAB__GENERAL_INFO_TAB__SALARY_TABLE,

    SECTIONS.WORKER_DETAILS_TAB__MY_FORMS_TAB,
    SECTIONS.WORKER_DETAILS_TAB__WORKING_INJURIES_TAB,
    SECTIONS.WORKER_DETAILS_TAB__WORKER_SCHEDULER_TAB,
    SECTIONS.WORKER_DETAILS_TAB__WORKING_PLACES_TAB,
  ),
};

// Helper function to create exceptions/permissions for section groups
export const createSectionGroups = (sectionGroup, actions) => {
  return sectionGroup?.reduce((acc, section) => {
    acc[section] = actions;
    return acc;
  }, {});
};
