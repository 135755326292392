import React, { Component } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { checkAccessOnPage, redirectToHomePage, checkModules } from 'industry/helpers';
import { Button, Table, TableButtons, ConfirmationModal } from 'shared';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import { defaultDateFormat } from 'shared/constants';
import { IconSearch } from 'shared/Icons';
import './styles.scss';

import { getCurrentUserSuccess } from 'user/actions';
import { saveSortingAndFiltering } from 'industry/actions';

import api from 'helpers/api';
import {
  getIndustryAssets,
  postIndustryAsset,
  patchIndustryAsset,
  deleteIndustryAsset,
  getIndustryAssetsBlob,

  getIndustryAssetModels,
  getIndustryAssetGroups,
  postIndustryAssetGroup,

  getAssetTypes,
  postAssetType,

  getAssetGroupTypes,
  getByURL,
} from 'shared/Api';

import { filterAssets } from './helpers';

import { AssetForm } from './components/';

class IndustryLocationAssetList extends Component {
  constructor(props) {
    super(props);
    let isReadOnly = false;

    if (window.resourceReadOnly && window.resourceReadOnly.length) {
      window.resourceReadOnly.forEach((resource) => {
        if (window.location.href.includes(resource)) {
          isReadOnly = true;
        }
      });
    }

    // Used for reading saved User soring and filtering
    // let selectedSort = 'name';
    // let selectedAscDesc = 'desc';

    // const sortingAndFiltering = props && props.currentUser && props.currentUser.data ? props.currentUser.data : {};
    // if (sortingAndFiltering.assetList) {
    //   const sAndF = sortingAndFiltering.assetList;
    //   selectedSort = sAndF.selectedSort || selectedSort;
    //   selectedAscDesc = sAndF.selectedAscDesc || selectedAscDesc;
    // }

    this.state = {
      activeQuery: '',
      assets: [],
      assetModels: [],
      assetTypes: [],
      assetGroups: [],
      assetStatuses: [],
      assetGroupTypes: [],
      isLoadingAssets: true,
      showDetails: false,
      showAssetForm: false,
      isReadOnly,
      selectedAscDesc: 'desc',
      selectedSort: 'name',
      count: null,
      selectedAssetObject: [],
      next: null,
      previous: null,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Assets');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) redirectToHomePage(companyId, locationId);
        else if (access === 1) this.setState({ isReadOnly: true });
      });

    this.getAssets();
    this.fetchAssetModels();
    this.fetchAssetTypes();
    this.fetchAssetGroups();
    this.fetchAssetStatuses();
    this.fetchAssetGroupTypes();
  }

  getAssets = () => {
    const { companyId, locationId } = this.props;
    const { selectedAscDesc, selectedSort } = this.state;

    this.setState({ isLoadingAssets: true });

    const apiFilters = `?location=${locationId}&company=${companyId}&fat=1&order_by=${selectedAscDesc === 'desc' ? '-' : ''}${selectedSort}`;
    getIndustryAssets(apiFilters)
      .then((res) => {
        this.setState({
          count: get(res, 'data.count', 0),
          assets: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          isLoadingAssets: false,
        });
      });
  }

  fetchAssetModels = () => {
    const { companyId } = this.props;
    const apiFilters = `?company=${companyId}`;
    getIndustryAssetModels(apiFilters)
      .then((res) => this.setState({ assetModels: get(res, 'data.results') }));
  }

  fetchAssetTypes = () => {
    const { companyId, locationId } = this.props;
    const apiFilters = `?account_type=industry&company=${companyId}&location=${locationId}`;
    getAssetTypes(apiFilters)
      .then((res) => this.setState({ assetTypes: get(res, 'data.results') }));
  }

  fetchAssetGroups = () => {
    const { companyId, locationId } = this.props;
    const apiFilters = `?location=${locationId}&company=${companyId}&order_by=name`;
    getIndustryAssetGroups(apiFilters)
      .then((res) => this.setState({ assetGroups: get(res, 'data.results') }));
  }

  fetchAssetStatuses = () => {
    api.get('/api/v1/industry/all_statuses')
      .then((res) => {
        const assetStatuses = get(res, 'data.Asset.label_type', []);
        if (assetStatuses.length > 0) this.setState({ assetStatuses });
      });
  }

  fetchAssetGroupTypes = () => {
    const { companyId } = this.props;
    const apiFilters = `?company=${companyId}`;
    getAssetGroupTypes(apiFilters)
      .then((res) => this.setState({ assetGroupTypes: get(res, 'data.results') }));
  }

  exportToExcel = () => {
    const { companyId, locationId } = this.props;
    const { count } = this.state;

    getIndustryAssetsBlob(`?location=${locationId}&company=${companyId}&fat=1&format=xlsx&limit=${count || 99999}`)
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Asset list.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  saveSortingAndFiltering = () => {
    const {
      selectedAscDesc,
      selectedSort,
    } = this.state;

    const {
      currentUser,
      setCurrentUser,
    } = this.props;

    if (currentUser) {
      currentUser.data.assetList = {
        selectedAscDesc: selectedAscDesc === 'desc' ? 'desc' : 'asc',
        selectedSort,
      };
      setCurrentUser(currentUser);
      saveSortingAndFiltering({ data: currentUser.data });
    }
  }

  handleClear = () => {
    this.setState({ activeQuery: '' });
  }

  handleSubmit = (e) => {
    this.setState({ activeQuery: e });
  }

  handleShowConfirmationModal = (asset) => {
    this.setState({ deleteData: asset, showConfirmationDialog: true });
  }

  handleDeleteAsset = () => {
    const id = this.state.deleteData.id;
    return deleteIndustryAsset(id, '?fat=1')
      .then(() => this.getAssets())
      .finally(() => this.setState({ showConfirmationDialog: false }));
  }

  handleSorting = (sortData) => {
    const { locationId, companyId } = this.props;

    this.setState({ isLoadingAssets: true });

    const column = sortData.id;
    const isDescending = sortData.desc;
    let sortKey = column;

    if (column === 'model' || column === 'type') {
      sortKey = `asset_${column}`;
    }

    if (column === 'metrics_count') {
      sortKey = 'num_metrics';
    }

    this.setState({
      selectedSort: sortKey,
      selectedAscDesc: isDescending ? 'desc' : 'asc',
    });

    const currentUrl = `?location=${locationId}&company=${companyId}&fat=1`;
    const newUrl = `${currentUrl}&order_by=${isDescending ? '-' : ''}${sortKey}`;
    getIndustryAssets(newUrl)
      .then((res) => {
        this.setState({
          assets: get(res, 'data.results'),
          isLoadingAssets: false,
        });
        this.saveSortingAndFiltering();
      });
  }

  handleAssetsTableRowClick = (assetObject) => {
    this.setState({
      selectedAssetObject: assetObject,
    }, () => {
      this.openAssetForm();
    });
  }

  openAssetForm = () => {
    this.setState({
      showAssetForm: true,
    });
  }

  closeAssetForm = () => {
    this.setState({
      showAssetForm: false,
      selectedAssetObject: {},
    });
  }

  handleUpdateAsset = (assetId, assetData) => {
    this.setState({ isLoadingAssets: true });
    patchIndustryAsset(assetId, '', assetData)
      .then(() => {
        this.setState({ isLoadingAssets: false }, () => {
          this.closeAssetForm();
          this.getAssets();
          this.fetchAssetGroups();
        });
      });
  }

  handleCreateAsset = (assetData) => {
    const { companyId } = this.props;

    this.setState({ isLoadingAssets: true });

    const apiFilters = `?company=${companyId}`;
    postIndustryAsset(apiFilters, assetData)
      .then(() => {
        this.setState({
          isLoadingAssets: false,
        }, () => {
          this.closeAssetForm();
          this.getAssets();
        });
      });
  }

  handleCreateAssetType = (assetData) => {
    const { companyId } = this.props;
    this.setState({ isLoadingAssets: true });

    const modifiedObject = { ...assetData };
    postAssetType(assetData.asset_type)
      .then((re) => {
        if (re?.status >= 200 && re?.status < 300) {
          modifiedObject.asset_type = re?.data?.id;
          this.fetchAssetTypes();
          const apiFilters = `?company=${companyId}`;
          postIndustryAsset(apiFilters, modifiedObject).then(() => {
            this.setState({ isLoadingAssets: false }, () => {
              this.closeAssetForm();
              this.getAssets();
            });
          });
        }
      });
  }

  handleCreateAssetGroup = (assetData) => {
    const { companyId } = this.props;
    this.setState({ isLoadingAssets: true });

    const modifiedObject = { ...assetData };
    postIndustryAssetGroup('', assetData.asset_group)
      .then((re) => {
        if (re?.status >= 200 && re?.status < 300) {
          modifiedObject.asset_group = re?.data?.id;
          this.fetchAssetGroups();
          const apiFilters = `?company=${companyId}`;
          postIndustryAsset(apiFilters, modifiedObject).then(() => {
            this.setState({
              isLoadingAssets: false,
            }, () => {
              this.closeAssetForm();
              this.getAssets();
            });
          });
        }
      });
  }

  createAssetWithNewGroupAndType = (assetData) => {
    const { companyId } = this.props;
    this.setState({ isLoadingAssets: true });

    const modifiedObject = { ...assetData };
    postIndustryAssetGroup('', assetData.asset_group)
      .then((re) => {
        if (re?.status >= 200 && re?.status < 300) {
          modifiedObject.asset_group = re?.data?.id;
          this.fetchAssetGroups();
          postAssetType(assetData.asset_type)
            .then((r) => {
              if (r?.status >= 200 && r?.status < 300) {
                modifiedObject.asset_type = r?.data?.id;
                this.fetchAssetTypes();
                const apiFilters = `?company=${companyId}`;
                postIndustryAsset(apiFilters, modifiedObject).then(() => {
                  this.setState({ isLoadingAssets: false }, () => {
                    this.closeAssetForm();
                    this.getAssets();
                  });
                });
              }
            });
        }
      });
  }

  fetchData = (url) => {
    this.setState({ isLoadingAssets: true });

    getByURL(url)
      .then((res) => {
        this.setState({
          count: get(res, 'data.count', 0),
          assets: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          isLoadingAssets: false,
        });
      });
  };

  render() {
    const { locationId, companyId, currentUser, t } = this.props;

    const {
      activeQuery,
      assets,
      isLoadingAssets,
      showAssetForm,
      showDetails,
      isReadOnly,
      selectedAssetObject,
      assetTypes,
      assetModels,
      assetGroups,
      assetStatuses,
      assetGroupTypes,
      next,
      previous,
      count,
    } = this.state;

    const filteredAssets = filterAssets(assets, activeQuery);
    if (filteredAssets !== undefined && assetModels && assetModels.length > 0 && assetTypes && assetTypes.length > 0) {
      filteredAssets.forEach((a) => {
        let metricsCount = 0;
        if (a.interfaces && a.interfaces.length) {
          a.interfaces.forEach((i) => {
            metricsCount += i.metrics ? i.metrics.length : 0;
          });
        }
        a.metricsCount = metricsCount;
        if (assetModels && assetModels.length > 0 && a.asset_model !== null && a.asset_model !== undefined) {
          a.model = assetModels?.find((m) => m.id === a?.asset_model)?.model;
        }
        if (assetTypes && assetTypes.length > 0 && a.asset_type !== null && a.asset_type !== undefined) {
          a.type = assetTypes?.find((m) => m.id === a?.asset_type)?.code;
        }
      });
    }

    return (
      <div className="industry-location-drone-list fullscreen">
        <div className="assets_toolbar_area">
          <div className="assets_input_container">
            <input onChange={(e) => this.handleSubmit(e.target.value)} placeholder={t('page_content.assets.search_placeholder')} value={activeQuery} />
            {activeQuery && <button
              onClick={() => {
                this.handleClear();
              }}
            >&times;</button>}
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <div className="buttons_wrapper">
            <Button
              onClick={this.openAssetForm}
              title="Add asset"
              disabled={isReadOnly}
              type="add"
            >
              {t('page_content.assets.add_asset_button')}
            </Button>
            <Button
              type="export"
              style={{ marginLeft: '10px' }}
              onClick={this.exportToExcel}
            >{t('page_content.assets.export_to_excel_button')}</Button>
          </div>
        </div>
        <main>
          <Table
            columns={[
              {
                Header: () => <span>{t('page_content.assets.table_column_name')}</span>,
                accessor: 'name',
                maxWidth: 200,
                Cell: (row) => row.value || '-',
                style: { cursor: 'default' },
              },
              {
                Header: () => <span>{t('page_content.assets.table_column_serial_number')}</span>,
                accessor: 'serial_number',
                maxWidth: 200,
                Cell: (row) => row.value || '-',
                style: { cursor: 'default' },
              },
              {
                Header: () => <span>{t('page_content.assets.table_column_inventory_id')}</span>,
                accessor: 'external_id',
                maxWidth: 200,
                Cell: (row) => row.value || '-',
                style: { cursor: 'default' },
              },
              {
                Header: () => <span>{t('page_content.assets.table_column_type')}</span>,
                accessor: 'type',
                Cell: (row) => row.value || '-',
                style: { cursor: 'default' },
              },
              {
                Header: () => <span>{t('page_content.assets.table_column_model')}</span>,
                accessor: 'model',
                Cell: (row) => row.value || '-',
                style: { cursor: 'default' },
              },
              {
                Header: () => <span>{t('page_content.assets.table_column_commissioning_date')}</span>,
                accessor: 'commissioning_date',
                Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
                style: { cursor: 'default' },
              },
              {
                Header: () => <span>{t('page_content.assets.table_column_number_of_metrics')}</span>,
                accessor: 'metrics_count',
                style: { cursor: 'default' },
              },
              {
                Header: () => <span>{t('page_content.assets.has_oee')}</span>,
                accessor: 'has_oee',
                width: 100,
                Cell: (row) => (<div style={{ margin: 'auto' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>),
                style: { cursor: 'default' },
              },
            ]}
            data={filteredAssets}
            defaultPageSize={100}
            loading={isLoadingAssets}
            minRows={0}
            selectedRow={showDetails.id}
            sortable
            enableEdit
            enableDelete={currentUser?.is_admin}
            onEdit={(original) => this.handleAssetsTableRowClick(original)}
            onDelete={(original) => this.handleShowConfirmationModal(original)}
            isActionsDisabled={isReadOnly}
            defaultSorted={[{ id: 'name', desc: true }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchData} count={count} />
        </main>
        {
          showAssetForm &&
          <AssetForm
            isReadOnly={isReadOnly}
            isOpen={showAssetForm}
            onClose={this.closeAssetForm}
            companyId={companyId}
            locationId={locationId}
            assetObject={selectedAssetObject}
            assetTypesDropdown={assetTypes}
            assetModelsDropdown={assetModels}
            assetGroupsDropdown={assetGroups}
            assetGroupTypesDropdown={assetGroupTypes}
            stickerDesignDropdown={assetStatuses}
            updateAsset={this.handleUpdateAsset}
            createAsset={this.handleCreateAsset}
            createAssetType={this.handleCreateAssetType}
            createAssetGroup={this.handleCreateAssetGroup}
            createAssetWithNewGroupAndType={this.createAssetWithNewGroupAndType}
          />
        }
         <ConfirmationModal
           itemName={this.state.deleteData?.name}
           showModal={this.state.showConfirmationDialog}
           handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
           handleConfirmModal={this.handleDeleteAsset}
           type="warning"
         />
      </div>
    );
  }
}

IndustryLocationAssetList.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  setCurrentUser: getCurrentUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IndustryLocationAssetList));
