import React, { useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { modalSizes } from 'shared/constants';
import { Modal, ContentLoader, ReactDatePicker } from 'shared';

import { addWorkerSalary, editWorkerSalary } from '../../../actions';

const SalaryModal = ({ t, companyId, currentUser, workerDetails, initialValues = {}, isOpen, handleCloseModal, closeModalAndRefetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      ...formValues,
      worker: workerDetails?.id,
      user: currentUser?.id,
    };

    if (formValues?.id) {
      await editWorkerSalary(companyId, formValues?.id, payload);
    } else {
      await addWorkerSalary(companyId, payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  return (
    <Modal
      size={modalSizes.medium}
      handleClose={handleCloseModal}
      handleSave={handleSave}
      isOpen={isOpen}
      title={formValues?.id ? t('page_content.human_resources.workers.salary_increase_table.modal_title_edit') : t('page_content.human_resources.workers.salary_increase_table.modal_title_add')}
    >
      {
        isLoading ? <ContentLoader /> :
          <div className="asset_form_modal_container">
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.workers.salary_increase_table.table_column_date')}:</div>
              <div className="right_datePicker">
                <ReactDatePicker
                  selected={get(formValues, 'date', '') ? moment(get(formValues, 'date', '')).toDate() : null}
                  onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'), 'date')}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.workers.salary_increase_table.table_column_net_salary')}:</div>
              <div className="right_input">
                <input
                  type="number"
                  step="0.01"
                  value={get(formValues, 'net_salary', '')}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Limit input to 2 decimal places
                    const sanitizedValue = value.match(/^\d*\.?\d{0,2}$/) ? value : get(formValues, 'net_salary', '');
                    onChange(sanitizedValue, 'net_salary');
                  }}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.workers.salary_increase_table.table_column_gross_salary')}:</div>
              <div className="right_input">
                <input
                  type="number"
                  step="0.01"
                  value={get(formValues, 'gross_salary', '')}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Limit input to 2 decimal places
                    const sanitizedValue = value.match(/^\d*\.?\d{0,2}$/) ? value : get(formValues, 'gross_salary', '');
                    onChange(sanitizedValue, 'gross_salary');
                  }}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.workers.salary_increase_table.table_column_gross_salary_2')}:</div>
              <div className="right_input">
                <input
                  type="number"
                  step="0.01"
                  value={get(formValues, 'gross_salary_2', '')}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Limit input to 2 decimal places
                    const sanitizedValue = value.match(/^\d*\.?\d{0,2}$/) ? value : get(formValues, 'gross_salary_2', '');
                    onChange(sanitizedValue, 'gross_salary_2');
                  }}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.workers.salary_increase_table.table_column_food_fee')}:</div>
              <div className="right_input">
                <input
                  type="number"
                  step="0.01"
                  value={get(formValues, 'food_fee', '')}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Limit input to 2 decimal places
                    const sanitizedValue = value.match(/^\d*\.?\d{0,2}$/) ? value : get(formValues, 'food_fee', '');
                    onChange(sanitizedValue, 'food_fee');
                  }}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.workers.salary_increase_table.table_column_transport_fee')}:</div>
              <div className="right_input">
                <input
                  type="number"
                  step="0.01"
                  value={get(formValues, 'transport_fee', '')}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Limit input to 2 decimal places
                    const sanitizedValue = value.match(/^\d*\.?\d{0,2}$/) ? value : get(formValues, 'transport_fee', '');
                    onChange(sanitizedValue, 'transport_fee');
                  }}
                />
              </div>
            </div>
          </div>
      }
    </Modal>
  );
};

SalaryModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  workerDetails: PropTypes.object,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id', null),
  currentUser: get(state, 'currentUser', null),
});

export default connect(mapStateToProps, null)(withTranslation()(SalaryModal));
