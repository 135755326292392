import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import WorkersList from './components/WorkersList';
import WorkersAvailability from './components/WorkersAvailability';
import QualificationsAndProfessions from './components/QualificationsAndProfessions';
import WorkerCategories from './components/WorkerCategoriesTab/WorkerCategories';

const Workers = ({
  t,
  hrTabs,
  selectedChildTab,
  setSelectedChildTab,
  department,
}) => {
  const shouldDisplayTab = (tabName) => {
    const defaultHrTabs = hrTabs || [];
    return defaultHrTabs.some((o) => o.name === tabName);
  };
  return (
      <Tabs selectedIndex={selectedChildTab} onSelect={setSelectedChildTab}>
        <TabList>
          <Tab>{t('page_content.human_resources.workers.workers_list')}</Tab>
          {shouldDisplayTab('Workers -> Workers Availability') && <Tab>{t('page_content.human_resources.workers.workers_availability')}</Tab>}
          {shouldDisplayTab('Workers -> Qualifications and professions') && <Tab>{t('page_content.human_resources.workers.qualifications_and_professions')}</Tab>}
          {shouldDisplayTab('Workers -> Worker categories') && <Tab>{t('page_content.human_resources.workers.tab_worker_categories')}</Tab>}
        </TabList>

        <TabPanel>
          <WorkersList department={department} />
        </TabPanel>

        <TabPanel>
          <WorkersAvailability />
        </TabPanel>

        <TabPanel>
          <QualificationsAndProfessions />
        </TabPanel>

        <TabPanel>
          <WorkerCategories />
        </TabPanel>
      </Tabs>
  );
};

Workers.propTypes = {
  t: PropTypes.func,
  department: PropTypes.number,
  hrTabs: PropTypes.array.isRequired,
  selectedChildTab: PropTypes.number.isRequired,
  setSelectedChildTab: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    hrTabs: get(state, 'app.companyConfig.config.hr_tabs', null),
  };
}

export default connect(mapStateToProps)(withTranslation()(Workers));
