import React, { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';

import { postProductGroup, patchProductGroup } from 'shared/Api';

const CompanyValuesModal = ({
  t,
  isOpen,
  locationId,
  handleCloseModal,
  initialValues = {},
  closeModalAndRefetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);

    const payload = {
      ...formValues,
      location: locationId,
    };

    const apiFilters = `?location=${locationId}`;
    if (formValues?.id) {
      await patchProductGroup(formValues?.id, apiFilters, payload);
    } else {
      await postProductGroup(payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  return (
    <Modal
      isOpen={isOpen}
      handleSave={handleSave}
      size={modalSizes.medium}
      handleClose={handleCloseModal}
      disableSave={!formValues?.name || !formValues?.code || isLoading}
      title={!formValues?.id ? t('settings.product_groups.add_product_group_title') : t('settings.product_groups.edit_product_group_title')}
    >
      {isLoading ? (
        <ContentLoader />
      ) : (
        <div className="product-groups-modal-container">
          <div className="modal_row">
            <div className="left_text">{t('settings.product_groups.table_column_name')}: *</div>
            <div className="right_input">
              <input
                type="text"
                value={get(formValues, 'name', '')}
                onChange={(e) => onChange(e.target.value, 'name')}
              />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.product_groups.table_column_code')}: *</div>
            <div className="right_input">
              <input
                type="text"
                value={get(formValues, 'code', '')}
                onChange={(e) => onChange(e.target.value, 'code')}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

CompanyValuesModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  locationId: PropTypes.number.isRequired,
};

export default withTranslation()(CompanyValuesModal);
