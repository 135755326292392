import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons } from 'shared';
import './style.scss';

import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';

import { getCompanyTables, getByURL } from 'shared/Api';

import IverpanBarcodeModal from './components/Iverpan/IverpanBarcodeModal';
import IverpanFoilsModal from './components/Iverpan/IverpanFoilsModal';
import IverpanTapesModal from './components/Iverpan/IverpanTapesModal';
import PodravkaRastepModal from './components/Podravka/PodravkaRastepModal';
import KFKModal from './components/KFK/KFKModal';

const CustomDataTab = ({ t, companyId, locationId, companyShortCode }) => {
  const [isReadOnly, setIsReadOnly] = useState(false);

  const [tablesData, setTablesData] = useState({
    isLoading: true,
    companyTables: [],
    next: null,
    previous: null,
  });

  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedCompanyTable: null,
  });

  useEffect(() => {
    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) redirectToSettingsPage(companyId, locationId);
        else if (access === 1) setIsReadOnly(true);
      });
  }, [companyId, locationId]);

  useEffect(() => {
    const fetchData = async () => {
      if (companyShortCode) {
        const apiFilters = `?limit=100&company_code=${companyShortCode}`;
        const results = await getCompanyTables(apiFilters);
        setTablesData({
          isLoading: false,
          companyTables: results?.data ? Object.values(results.data) : [],
          count: get(results, 'data.count', 0),
          next: get(results, 'data.next', null),
          previous: get(results, 'data.previous', null),
        });
      }
    };

    fetchData();
  }, [companyShortCode]);

  const fetchPaginatedData = async (url) => {
    setTablesData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const results = await getByURL(url);
    setTablesData({
      isLoading: false,
      companyTables: results?.data ? Object.values(results.data) : [],
      count: get(results, 'data.count', 0),
      next: get(results, 'data.next', null),
      previous: get(results, 'data.previous', null),
    });
  };

  const closeCompanyTablesModal = () => {
    setModalData({
      isOpen: false,
      selectedCompanyTable: null,
    });
  };

  const handleTableRowClick = (table) => {
    if (!isReadOnly) {
      setModalData({
        isOpen: true,
        selectedCompanyTable: table,
      });
    }
  };

  const renderIverpanModal = (modalProps) => {
    switch (modalData.selectedCompanyTable.name) {
      case 'Iverpan Barcode':
        return <IverpanBarcodeModal {...modalProps} />;
      case 'Iverpan folije':
        return <IverpanFoilsModal {...modalProps} />;
      case 'Iverpan trake':
        return <IverpanTapesModal {...modalProps} />;
      default:
        return null;
    }
  };

  const renderModal = () => {
    const modalProps = {
      isOpen: modalData.isOpen,
      selectedCompanyTable: modalData.selectedCompanyTable,
      handleClose: closeCompanyTablesModal,
      t,
      isReadOnly,
    };

    switch (companyShortCode) {
      case 'kfk':
        return <KFKModal {...modalProps} />;
      case 'iverpan':
        return renderIverpanModal(modalProps);
      case 'podravka':
        return <PodravkaRastepModal {...modalProps} locationId={locationId} companyId={companyId} />;
      default:
        return null;
    }
  };

  return (
    <div className="company_tables-list">
      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span>{t('settings.custom_data.name')}</span>,
            accessor: 'name',
          },
        ]}
        data={tablesData.companyTables || []}
        defaultPageSize={100}
        loading={tablesData.isLoading}
        minRows={0}
        noDataText=""
        showPagination={false}
        selectedRow={null}
        sortable
        defaultSorted={[{ id: 'name', desc: true }]}
        manual={false}
        handleClick={(rowInfo) => handleTableRowClick(get(rowInfo, 'original'))}
      />
      <div>
        <span style={{ float: 'right' }}>
          <TableButtons previous={tablesData.previous} next={tablesData.next} fetchFunction={fetchPaginatedData} count={tablesData.count} />
        </span>
      </div>

      {modalData.isOpen && renderModal()}
    </div>
  );
};

CustomDataTab.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  companyShortCode: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  companyShortCode: get(state, 'app.companyConfig.short_code', null),
});

export default withRouter(connect(mapStateToProps)(withTranslation()(CustomDataTab)));
