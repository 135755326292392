import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import './styles.scss';

import { ACTIONS } from 'industry/role/definitions/actions';
import { SECTIONS } from 'industry/role/definitions/sections';
import { canPerformAction } from 'industry/role/selectors';

import { getWorkforceWorkerCategory, deleteWorkforceWorkerCategory, getByURL } from 'shared/Api';

import WorkerCategoriesModal from './WorkerCategoriesModal';

const WorkerCategoriesTable = ({ t, companyId, can }) => {
  const [tableData, setTableData] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [filters, setFilter] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'name',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchCategories = () => {
    setTableData((prevState) => ({ ...prevState, isLoading: true }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `?company=${companyId}&limit=15&order_by=${asc}${filters?.selectedSort}`;

    getWorkforceWorkerCategory(apiFilters)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      });
  };

  const fetchPaginatedCategories = (url) => {
    setTableData((prevState) => ({ ...prevState, isLoading: true }));

    getByURL(url)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    fetchCategories();
  }, [companyId, filters]);

  const handleShowConfirmationDialog = (row) => {
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteWorkforceWorkerCategory(deleteData?.id, `?company=${companyId}`);
    setShowConfirmationDialog(false);
    fetchCategories();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setFilter((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleAddNew = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleEdit = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchCategories();
  };

  return (
    <div className="worker-categories-container">
      <div className="worker-categories-container__table">
        <div className="worker-categories-container__table__filters">
          <div className="worker-categories-container__table__filters__actions">
            <Button
              type="add"
              disabled={!can(SECTIONS.WORKERS_TABS__WORKER_CATEGORIES_TAB, ACTIONS.ADD)}
              onClick={handleAddNew}
            >
              {t('page_content.human_resources.workers.worker_categories.add_new_category')}
            </Button>
          </div>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.workers.worker_categories.column_name')}</span>,
              accessor: 'name',
              style: { cursor: 'default' },
            },
          ]}
          minRows={0}
          noDataText=""
          defaultPageSize={15}
          data={tableData.data || []}
          loading={tableData.isLoading}
          enableEdit={can(SECTIONS.WORKERS_TABS__WORKER_CATEGORIES_TAB, ACTIONS.EDIT)}
          enableDelete={can(SECTIONS.WORKERS_TABS__WORKER_CATEGORIES_TAB, ACTIONS.DELETE)}
          onEdit={(original) => handleEdit(original)}
          onDelete={(original) => handleShowConfirmationDialog(original)}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => handleSorting(newSorted[0])}
        />
        <TableButtons
          next={tableData.next}
          count={tableData.count}
          previous={tableData.previous}
          fetchFunction={fetchPaginatedCategories}
        />
      </div>

      {
        modalData?.isOpen &&
        <WorkerCategoriesModal
          isOpen={modalData?.isOpen}
          handleCloseModal={handleCloseModal}
          initialValues={modalData?.selectedItem}
          closeModalAndRefetch={closeModalAndRefetch}
        />
      }

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'name')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </div>
  );
};

WorkerCategoriesTable.propTypes = {
  t: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    companyId: get(state, 'app.company.id', null),
    can: (section, action, useExceptions = false) => canPerformAction(state, section, action, useExceptions),
  };
};

export default connect(mapStateToProps)(withTranslation()(WorkerCategoriesTable));
