import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';

import { getWorkforceWorkingPlaces, getDepartments, getWorkforceBudgets, deleteWorkforceBudget, getByURL } from 'shared/Api';

import WorkingPlaceBudgetModal from './WorkingPlaceBudgetModal';

import '../styles.scss';

const WorkingPlaceBudgetTable = ({ t, locationId, companyId }) => {
  const [tableData, setTableData] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });
  const [filters, setFilters] = useState({
    selectedAscDesc: 'desc',
    selectedSort: 'year',
    workingPlaceFilter: null,
    departmentFilter: null,
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const [workigPlaceOptions, setWorkingPlaceOptions] = useState([]);

  const [departmentOptions, setDepartmentOptions] = useState([]);

  const fetchDepartments = async () => {
    const apiFilters = `?location=${locationId}&limit=999&is_production_department=false`;
    const res = await getDepartments(apiFilters);
    setDepartmentOptions(get(res, 'data.results', []));
  };

  const fetchWorkingPlaces = async () => {
    const apiFilters = `?department=${filters.departmentFilter?.id}`;
    const res = await getWorkforceWorkingPlaces(apiFilters);
    setWorkingPlaceOptions(get(res, 'data.results', []));
  };

  const fetchWorkingPlaceBudget = () => {
    setTableData((prevState) => ({ ...prevState, isLoading: true }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    let apiFilters = `?company=${companyId}&department_isnull=false&profession_isnull=false&limit=15&order_by=${asc}${filters?.selectedSort}`;

    if (filters?.departmentFilter) {
      apiFilters += `&department=${filters?.departmentFilter?.id}`;
    }

    if (filters?.workingPlaceFilter) {
      apiFilters += `&profession=${filters?.workingPlaceFilter?.id}`;
    }

    getWorkforceBudgets(apiFilters)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      });
  };

  const fetchPaginatedWorkingPlaceBudget = (url) => {
    setTableData((prevState) => ({ ...prevState, isLoading: true }));

    getByURL(url)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    fetchWorkingPlaceBudget();
  }, [companyId, filters]);

  useEffect(() => {
    fetchDepartments();
  }, [locationId]);

  useEffect(() => {
    if (filters.departmentFilter) fetchWorkingPlaces();
  }, [filters.departmentFilter]);

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewWorkingPlaceBudget = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchWorkingPlaceBudget();
  };

  const clearAllFilters = () => {
    setFilters((prevState) => ({
      ...prevState,
      departmentFilter: '',
    }));
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleShowConfirmationDialog = (row) => {
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    const apiFilters = `?company=${companyId}`;
    await deleteWorkforceBudget(deleteData?.id, apiFilters);
    setShowConfirmationDialog(false);
    fetchWorkingPlaceBudget();
  };

  return (
    <React.Fragment>
      <div className="working-place-budget">
        <h3>{t('page_content.administration.control_panel_tab.working_place_budget_heading')}</h3>

        <div className="working-place-budget__action-buttons">
          <Button
            type="add"
            onClick={handleAddNewWorkingPlaceBudget}
          >
            {t('page_content.administration.control_panel_tab.add_new_working_place_budget_button')}
          </Button>
        </div>

        <div className="department-budget__filters__dropdowns">
          <Select
            className="select-style"
            options={departmentOptions.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.administration.filter_by_department')}
            isClearable
            onChange={(e) => handleFilterChange('departmentFilter', e)}
            value={departmentOptions.find((a) => a.id === filters?.departmentFilter?.id) || ''}
            styles={selectModalStyles}
          />

          <Select
            className="select-style"
            options={workigPlaceOptions.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.administration.control_panel_tab.filter_by_working_place_placeholder')}
            isClearable
            onChange={(e) => handleFilterChange('workingPlaceFilter', e)}
            value={workigPlaceOptions.find((a) => a.id === filters?.workingPlaceFilter?.id) || ''}
            styles={selectModalStyles}
            isDisabled={!filters?.departmentFilter}
          />
          <div className="department-budget__filters__dropdowns__clear">
            <Button type="plain" onClick={clearAllFilters}>{t('page_content.administration.clear_all')}</Button>
          </div>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.working_place_column')}</span>,
              accessor: 'profession.name',
            },
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.net_salary_column')}</span>,
              accessor: 'net_salary',
            },
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.non_taxable_column')}</span>,
              accessor: 'non_taxable',
            },
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.gross_salary_column')}</span>,
              accessor: 'gross_salary',
            },
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.gross_salary_2_column')}</span>,
              accessor: 'gross_salary_2',
            },
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.food_fee_column')}</span>,
              accessor: 'food_fee',
            },
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.transport_fee_column')}</span>,
              accessor: 'transport_fee',
            },
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.year_column')}</span>,
              accessor: 'year',
            },
          ]}
          data={tableData?.data || []}
          defaultPageSize={100}
          loading={tableData.isLoading}
          minRows={0}
          noDataText=""
          enableEdit
          enableDelete
          onEdit={(original) => handleTableRowClick(original)}
          onDelete={(original) => handleShowConfirmationDialog(original)}
          defaultSorted={[{ id: 'year', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons
          previous={tableData.previous}
          next={tableData.next}
          fetchFunction={fetchPaginatedWorkingPlaceBudget}
          count={tableData.count}
        />
      </div>

      {modalData?.isOpen &&
        <WorkingPlaceBudgetModal
          t={t}
          isOpen={modalData?.isOpen}
          initialValues={modalData?.selectedItem}
          handleCloseModal={handleCloseModal}
          closeModalAndRefetch={closeModalAndRefetch}
          departmentOptions={departmentOptions}
        />}

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'profession.name')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </React.Fragment>
  );
};

WorkingPlaceBudgetTable.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

export default (withTranslation()(WorkingPlaceBudgetTable));
