import api from 'helpers/api';
import { Notification } from 'shared';

export const getDepartmentShifts = async (companyId, filters = '') => {
  const url = `/api/v1/shifts/department_shifts/?company=${companyId}${filters}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching department shifts', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedDepartments = async (url) => {
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching departments', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const editDepartmentLeaders = async (locationId, id, data) => {
  const url = `/api/v1/workforce/department_managers/${id}/?location=${locationId}`;
  try {
    const res = await api.put(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while editing department leaders', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAllDepartmentsTreeView = async (locationId) => {
  const url = `/api/v1/departments_tree/?location=${locationId}&order_by=name&is_production_department=false`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching departments', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAllWorkers = async (locationId, companyId, filters = '') => {
  const urlString = `/api/v1/workforce/workers/?limit=9999&company=${companyId}&location=${locationId}${filters}`;
  try {
    const res = await api.get(urlString);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching workers', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getDepartmentLeaders = async (locationId) => {
  const url = `/api/v1/workforce/department_managers/?location=${locationId}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting department leaders', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const saveDepartmentLeaders = async (data) => {
  const url = '/api/v1/workforce/department_managers/';
  try {
    const res = await api.post(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving department leaders', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getWorkingPlaces = async (departmentId) => {
  const url = `/api/v1/workforce/working_places/?department=${departmentId}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching working places', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addWorkingPlace = async (data) => {
  const url = '/api/v1/workforce/working_places/';
  try {
    const res = await api.post(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching working places', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const editWorkingPlace = async (data, id) => {
  const url = `/api/v1/workforce/working_places/${id}/?department=${data.department}`;
  try {
    const res = await api.put(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching working places', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteWorkingPlace = async (id, departmentId) => {
  const url = `/api/v1/workforce/working_places/${id}?department=${departmentId}`;
  try {
    const res = await api.delete(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching working places', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
