import { getByURL, postAndNotifyByURL, putAndNotifyByURL, deleteAndNotifyByURL } from './CustomService';

export const getNotificationsSetups = async (filters = '') => {
  return getByURL(`/api/v1/notifications/setups/${filters}`);
};

export const postNotificationSetup = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/notifications/setups/${filters}`, data);
};

export const putNotificationSetup = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/notifications/setups/${id}/${filters}`, data);
};

export const deleteNotificationSetup = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/notifications/setups/${id}/${filters}`);
};

export const getNotificationEvents = async (filters = '') => {
  return getByURL(`/api/v1/notifications/events/${filters}`);
};

export const getNotificationSubscribers = async (filters = '') => {
  return getByURL(`/api/v1/notifications/subscribers/${filters}`);
};

export const postNotificationSubscriber = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/notifications/subscribers/${filters}`, data);
};

export const putNotificationSubscriber = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/notifications/subscribers/${id}/${filters}`, data);
};

export const deleteNotificationSubscriber = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/notifications/subscribers/${id}/${filters}`);
};

export const getNotificationEventSubscribers = async (filters = '') => {
  return getByURL(`/api/v1/notifications/event_subscribers/${filters}`);
};

export const postNotificationEventSubscribers = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/notifications/event_subscribers/${filters}`, data);
};

export const putNotificationEventSubscribers = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/notifications/event_subscribers/${id}/${filters}`, data);
};

export const deleteNotificationEventSubscribers = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/notifications/event_subscribers/${id}/${filters}`);
};

export const getNotificationLogs = async (filters = '') => {
  return getByURL(`/api/v1/notifications/notification_logs/${filters}`);
};
