import { getByURL, getByURLBlob, postAndNotifyByURL, patchAndNotifyByURL } from './CustomService';

// -------------------- INDUSTRY DEVICES -------------------- //
export const getIndustryDevices = async (filters = '') => {
  return getByURL(`/api/v1/industry/devices/${filters}`);
};

export const getIndustryDevicesBlob = async (filters = '') => {
  return getByURLBlob(`/api/v1/industry/devices/${filters}`);
};

export const postIndustryDevice = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/industry/devices/${filters}`, data);
};

export const patchIndustryDevice = async (id, filters = '', data) => {
  return patchAndNotifyByURL(`/api/v1/industry/devices/${id}/${filters}`, data);
};
