import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Modal, Notification, ReactDatePicker } from 'shared';
import './style.scss';

class ShiftForm extends React.Component {
  constructor(props) {
    super();
    this.state = {
      shiftObject: {
        name: '',
        order: props.shifts.length + 1,
        default_begin: '',
        default_end: '',
      },
    };
    if (props.shift) {
      const { shift } = props;
      const updatedShiftObject = {
        ...this.state.shiftObject,
        name: shift.name || this.state.name,
        order: shift.order || this.state.order,
        default_begin: shift.default_begin || this.state.default_begin,
        default_end: shift.default_end || this.state.default_begin,
      };
      this.state = { shiftObject: updatedShiftObject };
    }
  }

  handleSave = () => {
    const { name, order, default_begin, default_end } = this.state.shiftObject;
    const { locationId, saveShift, shift, shifts, t } = this.props;
    const orderExists = shifts.some((s) => s.order === parseInt(order, 10));

    if (shift) {
      if (orderExists && order !== shift.order) {
        Notification('error', t('settings.shifts.shift_form.sequence_in_use'));
        return;
      }
      saveShift({ name, order, location: locationId, id: shift.id, default_begin, default_end });
    } else {
      if (orderExists) {
        Notification('error', t('settings.shifts.shift_form.sequence_in_use'));
        return;
      }
      saveShift({ name, order, location: locationId, default_begin, default_end });
    }
  }

  changeField = (key, value) => {
    this.setState((prevState) => ({
      shiftObject: {
        ...prevState.shiftObject,
        [key]: value,
      },
    }));
  }

  render() {
    const { name, order, default_begin, default_end } = this.state.shiftObject;
    const { isOpen, closeModal, shift, t } = this.props;

    let title = '';
    if (shift) {
      title = t('settings.shifts.shift_form.edit_shift');
    } else {
      title = t('settings.shifts.shift_form.add_shift');
    }

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        handleSave={this.handleSave}
        handleClose={closeModal}
        title={title}
        handleDelete={shift ? this.handleDelete : null}
        disableSave={!name || !default_begin || !default_end}
      >
        <div className="default-form">
          <table>
            <tbody>
              <tr>
                <td className="label">
                  {t('settings.shifts.shift_form.name')}*
                </td>
                <td className="value">
                  <input type="text" value={name} onChange={(e) => this.changeField('name', e.target.value)} />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('settings.shifts.shift_form.order')}
                </td>
                <td className="value">
                  <input type="number" value={order} onChange={(e) => this.changeField('order', e.target.value)} />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('settings.shifts.shift_form.default_begin')}*
                </td>
                <td className="datePicker_tableWrapper">
                  <ReactDatePicker
                    selected={default_begin ? moment(default_begin, 'HH:mm').toDate() : null}
                    onChange={(time) => this.changeField('default_begin', time ? moment(time).format('HH:mm') : null)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeCaption={t('date_picker_locale.time_translation')}
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('settings.shifts.shift_form.default_end')}*
                </td>
                <td className="datePicker_tableWrapper">
                  <ReactDatePicker
                    selected={default_end ? moment(default_end, 'HH:mm').toDate() : null}
                    onChange={(time) => this.changeField('default_end', time ? moment(time).format('HH:mm') : null)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeCaption={t('date_picker_locale.time_translation')}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }
}

ShiftForm.propTypes = {
  shift: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  saveShift: PropTypes.func.isRequired,
  shifts: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ShiftForm);
