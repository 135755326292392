import { ACTIONS, ACTION_GROUPS, createActions } from '../actions';
import { SECTIONS, SECTION_GROUPS, createSectionGroups } from '../sections';

const ROLE_CONFIG = {
  ADMIN: {
    name: 'Admin',
    forbiddenExceptions: {},
    permissions: {},
  },

  UPRAVA_READ_WRITE: {
    name: 'Uprava (read & write)',
    forbiddenExceptions: {},
    permissions: {
      // HR
      ...createSectionGroups(SECTION_GROUPS.HR, ACTION_GROUPS.ALL),
      ...createSectionGroups(SECTION_GROUPS.RATINGS, ACTION_GROUPS.ALL),
      ...createSectionGroups(SECTION_GROUPS.CONTROL_PANEL, ACTION_GROUPS.ALL),
      ...createSectionGroups(SECTION_GROUPS.WORKER_DETAILS, ACTION_GROUPS.ALL),
    },
  },

  UPRAVA_READ_ONLY: {
    name: 'Uprava (read only)',
    forbiddenExceptions: {},
    permissions: {
      // HR
      ...createSectionGroups(SECTION_GROUPS.HR, ACTION_GROUPS.READ_ONLY),
      ...createSectionGroups(SECTION_GROUPS.RATINGS, ACTION_GROUPS.READ_ONLY),
      ...createSectionGroups(SECTION_GROUPS.CONTROL_PANEL, ACTION_GROUPS.READ_ONLY),
      ...createSectionGroups(SECTION_GROUPS.WORKER_DETAILS, ACTION_GROUPS.READ_ONLY),
    },
  },

  KADROVSKA_VODITELJ: {
    name: 'Kadrovska voditelj',
    forbiddenExceptions: {
      // HR
      ...createSectionGroups(SECTION_GROUPS.CONTROL_PANEL, ACTION_GROUPS.ALL),
      [SECTIONS.ASSIGNED_EQUIPMENT_TABS]: ACTION_GROUPS.ALL,
      [SECTIONS.RATINGS_SCORING]: createActions(ACTIONS.VIEW),
      [SECTIONS.RATING_HISTORY]: createActions(ACTIONS.VIEW),
      [SECTIONS.WORKING_INJURIES_TAB]: createActions(ACTIONS.EDIT, ACTIONS.DELETE),
    },
    permissions: {
      // HR
      ...createSectionGroups(SECTION_GROUPS.HR, ACTION_GROUPS.ALL),
      [SECTIONS.RATINGS_TABS__COMPANY_VALUES_TAB]: ACTION_GROUPS.ALL,
      // Worker details
      ...createSectionGroups(SECTION_GROUPS.WORKER_DETAILS, ACTION_GROUPS.ALL),
    },
  },

  KADROVSKA_ZAMJENIK: {
    name: 'Kadrovska zamjenik',
    forbiddenExceptions: {
      // HR
      ...createSectionGroups(SECTION_GROUPS.CONTROL_PANEL, ACTION_GROUPS.ALL),
      ...createSectionGroups(SECTION_GROUPS.RATINGS, ACTION_GROUPS.ALL),
      [SECTIONS.ASSIGNED_EQUIPMENT_TABS]: ACTION_GROUPS.ALL,
      [SECTIONS.WORKING_INJURIES_TAB]: createActions(ACTIONS.EDIT, ACTIONS.DELETE),
    },
    permissions: {
      // HR
      ...createSectionGroups(SECTION_GROUPS.HR, ACTION_GROUPS.ALL),
      // Worker details
      ...createSectionGroups(SECTION_GROUPS.WORKER_DETAILS, ACTION_GROUPS.ALL),
    },
  },

  KADROVSKA_DIJELATNIK: {
    name: 'Kadrovska dijelatnik',
    forbiddenExceptions: {
      // HR
      ...createSectionGroups(SECTION_GROUPS.CONTROL_PANEL, ACTION_GROUPS.ALL),
      ...createSectionGroups(SECTION_GROUPS.RATINGS, ACTION_GROUPS.ALL),
      [SECTIONS.ASSIGNED_EQUIPMENT_TABS]: ACTION_GROUPS.ALL,
      [SECTIONS.WORKING_INJURIES_TAB]: createActions(ACTIONS.EDIT, ACTIONS.DELETE),
      // Worker details
      [SECTIONS.WORKER_DETAILS_TAB__GENERAL_INFO_TAB__SALARY_TABLE]: ACTION_GROUPS.ALL,
      [SECTIONS.WORKER_DETAILS_TAB__WORK_CONTRACTS_BOX]: createActions(ACTIONS.VIEW),
      [SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__DOCUMENT_TYPES_TAB__IS_MID]: createActions(ACTIONS.VIEW),
      [SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__ALL_DOCUMENTS_TAB__IS_MID]: createActions(ACTIONS.VIEW),
    },
    permissions: {
      // HR
      ...createSectionGroups(SECTION_GROUPS.HR, ACTION_GROUPS.ALL),
      // Worker details
      ...createSectionGroups(SECTION_GROUPS.WORKER_DETAILS, ACTION_GROUPS.ALL),
    },
  },

  VODITELJ: {
    name: 'Voditelj',
    forbiddenExceptions: {
      [SECTIONS.DEPARTMENT_MANAGER]: createActions(ACTIONS.VIEW),
      [SECTIONS.RATINGS_SCORING]: createActions(ACTIONS.VIEW),
      [SECTIONS.RATING_HISTORY]: createActions(ACTIONS.VIEW),
    },
    permissions: {
      // HR
      [SECTIONS.RATINGS_TABS]: ACTION_GROUPS.ALL,
      [SECTIONS.RATINGS_TABS__COMPANY_VALUES_TAB]: ACTION_GROUPS.ALL,
    },
  },

  VODITELJ_ZNR: {
    name: 'Voditelj ZNR',
    forbiddenExceptions: {
      [SECTIONS.WORKING_INJURIES_TAB]: createActions(ACTIONS.DELETE),
    },
    permissions: {
      // HR
      [SECTIONS.WORKING_INJURIES_TAB]: ACTION_GROUPS.ALL,
      [SECTIONS.ASSIGNED_EQUIPMENT_TABS]: ACTION_GROUPS.ALL,
    },
  },

  VODITELJ_IT: {
    name: 'Voditelj IT',
    forbiddenExceptions: {
    },
    permissions: {
      // HR
      [SECTIONS.ASSIGNED_EQUIPMENT_TABS]: ACTION_GROUPS.ALL,
    },
  },

  WORKER: {
    name: 'Worker',
    forbiddenExceptions: {
      // HR
      ...createSectionGroups(SECTION_GROUPS.HR, ACTION_GROUPS.ALL),
      ...createSectionGroups(SECTION_GROUPS.RATINGS, ACTION_GROUPS.ALL),
      ...createSectionGroups(SECTION_GROUPS.CONTROL_PANEL, ACTION_GROUPS.ALL),
      ...createSectionGroups(SECTION_GROUPS.WORKER_DETAILS, ACTION_GROUPS.ALL),
    },
    permissions: {},
  },
};

export default ROLE_CONFIG;
