import { getByURL, postAndNotifyByURL, putAndNotifyByURL, deleteAndNotifyByURL } from './CustomService';

export const getDepartments = async (filters = '') => {
  return getByURL(`/api/v1/departments/${filters}`);
};

export const postDepartment = async (data) => {
  return postAndNotifyByURL('/api/v1/departments/', data);
};

export const putDepartment = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/departments/${id}/${filters}`, data);
};

export const deleteDepartment = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/departments/${id}/${filters}`);
};
