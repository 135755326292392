import { getByURL, postAndNotifyByURL } from './CustomService';

// -------------------- ASSET TYPES -------------------- //
export const getAssetTypes = async (filters = '') => {
  return getByURL(`/api/v1/asset_types/${filters}`);
};

export const postAssetType = async (data, filters = '') => {
  return postAndNotifyByURL(`/api/v1/asset_types/${filters}`, data);
};
