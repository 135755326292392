import { get } from 'lodash';

export const canPerformAction = (state, section, action, useExceptions = false, autoPermission = true) => {
  const roleConfig = get(state, 'role.roleConfig');

  if (!roleConfig) return false;

  if (roleConfig.name === 'Admin') return true;

  let hasPermission = false;

  if (autoPermission && !useExceptions) {
    const sectionPermissions = roleConfig.permissions?.[section] || [];
    const sectionExceptions = roleConfig.forbiddenExceptions?.[section] || [];
    hasPermission = sectionPermissions.includes(action) && !sectionExceptions.includes(action);
  } else if (autoPermission && useExceptions) {
    if (useExceptions) {
      const sectionExceptions = roleConfig.forbiddenExceptions?.[section] || [];
      hasPermission = !sectionExceptions.includes(action);
    } else {
      const sectionPermissions = roleConfig.permissions?.[section] || [];
      hasPermission = sectionPermissions.includes(action);
    }
  }

  return hasPermission;
};

export const canPerformGroupAction = (state, sectionGroup, action, useExceptions = true) => {
  if (!sectionGroup) return false;
  return sectionGroup.every((section) => canPerformAction(state, section, action, useExceptions));
};
