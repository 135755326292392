import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Button, Table, TableButtons, ConfirmationModal } from 'shared';

import ShiftForm from './components/ShiftForm';

class Shifts extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    showShiftModal: false,
    showConfirmationDialog: false,
    deleteData: null,
  }

  showShiftModal = (showShiftModal, shift) => {
    this.setState({
      shift,
      showShiftModal,
    });
  }

  handleShowConfirmationModal = (shift) => {
    this.setState({ showConfirmationDialog: true, deleteData: shift });
  }

  deleteShift = () => {
    const { deleteShift } = this.props;

    this.setState({
      showShiftModal: false,
    });
    const shift = this.state.deleteData;
    deleteShift(shift);
    this.setState({ showConfirmationDialog: false });
  }

  saveShift = (shift) => {
    const { saveShift } = this.props;

    this.setState({
      showShiftModal: false,
    });

    saveShift(shift);
  }

  render() {
    const {
      t,
      next,
      count,
      shifts,
      previous,
      locationId,
      isReadOnly,
      fetchShifts,
      isFetchingShifts,
    } = this.props;

    const { shift, showShiftModal } = this.state;

    return (
      <div className="shifts__shifts">
        {
          showShiftModal ?
            <ShiftForm
              isOpen={showShiftModal}
              closeModal={() => { this.showShiftModal(false, null); }}
              locationId={locationId}
              saveShift={this.saveShift}
              deleteShift={this.deleteShift}
              shift={shift}
              shifts={shifts}
            /> : null
        }
        <div className="shifts__controls">
          <Button
            onClick={() => this.showShiftModal(true, null)}
            disabled={isReadOnly}
            type="add"
          >
            {t('settings.shifts.add_shift_button')}
          </Button>
        </div>
        <Table
          columns={[
            {
              Header: () => <span>{t('settings.shifts.table_column_name')}</span>,
              accessor: 'name',
              Cell: (row) => row.value || '-',
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('settings.shifts.table_column_order')}</span>,
              accessor: 'order',
              Cell: (row) => row.value || '-',
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('settings.shifts.table_column_default_begin')}</span>,
              accessor: 'default_begin',
              Cell: (row) => row.value || '-',
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('settings.shifts.table_column_default_end')}</span>,
              accessor: 'default_end',
              Cell: (row) => row.value || '-',
              style: { cursor: 'default' },
            },
          ]}
          data={shifts || []}
          defaultPageSize={100}
          loading={isFetchingShifts}
          showPagination={false}
          sortable={false}
          minRows={0}
          pagination={false}
          enableEdit
          enableDelete
          onEdit={(original) => this.showShiftModal(true, original)}
          onDelete={(original) => this.handleShowConfirmationModal(original)}
          isActionsDisabled={isReadOnly}
        />
        <TableButtons next={next} previous={previous} fetchFunction={fetchShifts} count={count} />

        <ConfirmationModal
          itemName={this.state.deleteData?.name || ''}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.deleteShift}
          type="warning"
        />
      </div>
    );
  }
}

Shifts.propTypes = {
  next: PropTypes.func,
  count: PropTypes.number,
  shifts: PropTypes.array,
  previous: PropTypes.func,
  fetchShifts: PropTypes.func,
  t: PropTypes.func.isRequired,
  saveShift: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  deleteShift: PropTypes.func.isRequired,
  isFetchingShifts: PropTypes.bool.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default withTranslation()(Shifts);
