import { Notification } from 'shared';

// -------------------- CONSTANTS -------------------- //
const errorStatuses = [400, 403, 404, 500];
const defaultErrorTitle = 'An error occurred while processing your request';
const defaultErrorBodyMessage = 'We could not perform your request, please try again';

// -------------------- FUNCTIONS -------------------- //
export const handleApiError = (error) => {
  const errorMsg = error?.message || defaultErrorBodyMessage;
  const messageTitle = error?.response?.statusText || defaultErrorTitle;

  Notification('error', `${messageTitle}.`, `${errorMsg}.`);
  return null;
};

export const validateApiResponse = (res) => {
  if (!res || errorStatuses.includes(res?.status)) throw new Error('');
};

export const validateAndNotifyApiResponse = (res) => {
  if (!res || errorStatuses.includes(res?.status)) throw new Error('');

  const requestType = res?.config?.method;
  let title = '';
  let message = '';

  switch (requestType) {
    case 'post':
      title = 'Creation Successful!';
      message = 'The data was successfully added.';
      break;
    case 'patch':
    case 'put':
      title = 'Update Successful!';
      message = 'The data was successfully updated.';
      break;
    case 'delete':
      title = 'Deletion Successful!';
      message = 'The data was successfully deleted.';
      break;
    default:
      return;
  }

  Notification('success', title, message);
};
