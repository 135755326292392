import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Modal, Table, Button, ReactDatePicker } from 'shared';
import { numberSeparatorFormat } from 'industry/helpers';
import { IconWarning } from 'shared/Icons';
import { colors } from 'shared/colors';

class ItemModal extends Component {
  render() {
    const {
      t,
      i18n,
      selectedItem: {
        event_type,
        start_time,
        end_time,
        status,
        order,
        note,
        formError,
        group,
        description,
      },
      isEdit,
      onChange,
      saveItem,
      removeItem,
      closeModal,
      searchOrder,
      query,
      orders,
      isLoadingOrders,
      groups,
      statuses,
      redirectToOrder,
      removePlannedSuffix,
      isReadOnly,
    } = this.props;

    const eventTypeOptions = [
      { value: 'order', title: t('page_content.microplanning.modal.order') },
      { value: 'changeover', title: t('page_content.microplanning.modal.changeover') },
      { value: 'cleaning', title: t('page_content.microplanning.modal.cleaning') },
      { value: 'product_trial', title: t('page_content.microplanning.modal.product_trial') },
    ];

    return (
      <Modal
        isOpen
        handleSave={!isReadOnly ? saveItem : null}
        handleClose={closeModal}
        handleDelete={!isReadOnly ? removeItem : null}
        title={t('page_content.microplanning.title')}
        error={formError && <span><IconWarning color={colors.red} height="14px" width="14px" /><span style={{ fontSize: '13px', marginLeft: '10px' }}>{t('page_content.microplanning.modal.error_msg')}</span></span>}
      >
        <div className="microplaning_form">
          <table>
            <tbody>
              <tr>
                <td className="label">
                  <label>
                    {t('page_content.microplanning.modal.line')}
                  </label>
                </td>
                <td className="value">
                  <select disabled={isReadOnly} onChange={(e) => onChange('group', e.target.value)} value={removePlannedSuffix(group) || null}>
                    {
                      groups.map((g) => (
                        <option
                          key={g.id}
                          value={g.id}
                        >
                          {g.title}
                        </option>))
                    }
                  </select>
                </td>
              </tr>

              <tr>
                <td className="label">
                  <label>
                    {t('page_content.microplanning.modal.event_type')}
                  </label>
                </td>
                <td className="value">
                  <select disabled={isReadOnly || ((event_type === 'changeover' || event_type === 'product_trial' || event_type === 'cleaning') && isEdit)} onChange={(e) => onChange('event_type', e.target.value)} value={event_type}>
                    {eventTypeOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.title}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>

              {
                event_type !== 'order' &&
                <tr>
                <td>
                  <label className="label">
                    {t('page_content.microplanning.modal.description')}
                  </label>
                </td>
                <td className="value">
                  <textarea
                    disabled={isReadOnly}
                    onChange={(e) => onChange('description', e.target.value)}
                  >
                    {description || ''}
                  </textarea>
                </td>
              </tr>
              }

              <tr>
                <td className="label">
                  <label htmlFor="name">
                    {t('page_content.microplanning.modal.start')}
                  </label>
                </td>
                <td className="value">
                  <ReactDatePicker
                    disabled={isReadOnly}
                    placeholderText="Start date"
                    selected={start_time ? moment(start_time).toDate() : null}
                    onChange={(e) => onChange('start_time', e)}
                    maxDate={end_time ? moment(end_time).toDate() : null}
                    showTimeSelect
                    timeCaption={t('date_picker_locale.time_translation')}
                  />
                </td>
              </tr>

              <tr>
                <td className="label">
                  <label htmlFor="name">
                    {t('page_content.microplanning.modal.end')}
                  </label>
                </td>
                <td className="value">
                  <ReactDatePicker
                    disabled={isReadOnly}
                    placeholderText={t('page_content.microplanning.modal.end_placeholder')}
                    selected={end_time ? moment(end_time).toDate() : null}
                    onChange={(e) => onChange('end_time', e)}
                    minDate={start_time ? moment(start_time).toDate() : null}
                    showTimeSelect
                    timeCaption={t('date_picker_locale.time_translation')}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <label className="label">
                    {t('page_content.microplanning.modal.note')}
                  </label>
                </td>
                <td className="value">
                  <textarea
                    disabled={isReadOnly}
                    onChange={(e) => onChange('note', e.target.value)}
                  >
                    {note || ''}
                  </textarea>
                </td>
              </tr>

              <tr>
                <td className="label">
                  <label htmlFor="name">
                    {t('page_content.microplanning.modal.status')}
                  </label>
                </td>
                <td className="value">
                  {statuses && statuses.length ?
                    <select disabled={isReadOnly || !isEdit} onChange={(e) => onChange('status', e.target.value)} value={status || null}>
                      <option value={null}>{t('page_content.microplanning.modal.status_placeholder')}.</option>
                      {
                        statuses.map((s) => (
                          <option key={s} value={s}>{t([`page_content.orders.statuses.${s}`])}</option>))
                      }
                    </select> :
                    <select disabled={isReadOnly || !isEdit} onChange={(e) => onChange('status', e.target.value)} value={status || null}>
                      <option value={null}>{t('page_content.microplanning.modal.status_placeholder')}.</option>
                      <option value="created">{t('page_content.microplanning.statuses.created')}</option>
                      <option value="in_progress">{t('page_content.microplanning.statuses.in_progress')}</option>
                      <option value="in_warehouse">{t('page_content.microplanning.statuses.in_warehouse')}</option>
                      <option value="in_transit">{t('page_content.microplanning.statuses.in_transit')}</option>
                      <option value="finished">{t('page_content.microplanning.statuses.finished')}</option>
                      <option value="delivered">{t('page_content.microplanning.statuses.delivered')}</option>
                      <option value="scheduled">{t('page_content.microplanning.statuses.scheduled')}</option>
                      <option value="paused">{t('page_content.microplanning.statuses.paused')}</option>
                      <option value="partial_delivery">{t('page_content.microplanning.statuses.partial_delivery')}</option>
                      <option value="delivered_to_customer">{t('page_content.microplanning.statuses.delivered_to_customer')}</option>
                    </select>}
                </td>
              </tr>
              {
                event_type === 'order' || (isEdit && (event_type === 'changeover' || event_type === 'product_trial' || event_type === 'cleaning')) ?
                  <tr>
                    <td className="label">
                      {t('page_content.microplanning.modal.order_label')}
                    </td>
                    <td className="value">
                      <p style={{ fontSize: '13px' }}>
                        <span style={{ fontWeight: '600' }}>{t('page_content.microplanning.modal.selected_order')} </span>
                        {order ?
                          <React.Fragment>
                            <a style={{ textDecoration: 'none', color: '#10ac84' }} href={redirectToOrder(order)} target="_blank" rel="noopener noreferrer">{order.external_id || ''}</a> / {order.customer_name || ''}
                          </React.Fragment>
                          : '-'}
                      </p>
                      {
                        event_type === 'order' &&
                        <input
                          onChange={(e) => searchOrder(e)}
                          placeholder={t('page_content.microplanning.modal.external_id_input_placeholder')}
                          value={query}
                          type="text"
                        />
                      }
                      {
                        query.length >= 3 && event_type === 'order' &&
                        <Table
                          style={{ userSelect: 'text', maxHeight: '250px', overflow: 'auto' }}
                          columns={[
                            {
                              Header: () => (<span>{t('page_content.microplanning.modal.external_id')}</span>),
                              accessor: 'external_id',
                              width: 100,
                            },
                            {
                              Header: () => (<span>{t('page_content.microplanning.modal.product_code')}</span>),
                              Cell: (row) => (row && row.original ? `${row.original.product_code || ''} (${row.original.customer_name || ''})` : ''),
                            },
                            {
                              width: 100,
                              Cell: (row) => (<Button
                                style={{
                                  padding: '8px 0',
                                  backgroundColor: row.original && row.original.external_id && order && order.external_id && row.original.external_id === order.external_id ? '#10ac84' : '#fff',
                                  color: row.original && row.original.external_id && order && order.external_id && row.original.external_id === order.external_id ? '#fff' : '#55555',
                                }}
                                onClick={() => onChange('order', row.original || null)}
                              >
                                {row.original && row.original.external_id && order && order.external_id && row.original.external_id === order.external_id ? t('page_content.microplanning.modal.button_selected') : t('page_content.microplanning.modal.button_select')}
                              </Button>),
                            },
                          ]}
                          data={orders}
                          loading={isLoadingOrders}
                          minRows={0}
                          F
                          noDataText=" "
                          showPagination={false}
                          defaultPageSize={10000}
                          manual={false}
                        />
                      }
                    </td>
                  </tr>
                  : ''
              }

              {
                isEdit && event_type === 'order' &&
                <tr>
                  <td className="label">
                    <label htmlFor="name">
                      {t('page_content.microplanning.modal.quantity')}
                    </label>
                  </td>
                  <td className="value">
                    <span style={{ fontSize: '15px' }}>{order && order.items_done && order.items_total ?
                      `${numberSeparatorFormat(i18n.language, order.items_done, 0, 0)} / ${numberSeparatorFormat(i18n.language, order.items_total, 0, 0)}`
                      : ''}</span>
                  </td>
                </tr>
              }

            </tbody>
          </table>
        </div>
      </Modal>
    );
  }
}

ItemModal.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object,
  selectedItem: PropTypes.object.isRequired,
  redirectToOrder: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  saveItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  searchOrder: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  orders: PropTypes.array.isRequired,
  isLoadingOrders: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  statuses: PropTypes.array,
  removePlannedSuffix: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default withTranslation()(ItemModal);
