import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Modal, ReactDatePicker } from 'shared';

import { addWorkingPlaces, editWorkingPlaces } from '../../../actions';
import { getWorkingPlaces } from '../../../../IndustryLocationHumanResources/actions';

const WorkerWorkingPlacesModal = ({
  t,
  workerId,
  companyId,
  showModal,
  currentUser,
  workerDetails,
  initialData = {},
  handleCloseModal,
  handleCloseModalAndRefetch,
}) => {
  const [modalData, setModalData] = useState(initialData);
  const [modalHelpers, setModalHelpers] = useState({
    workingPlaces: [],
    isLoading: false,
  });

  const fetchWorkingPlaces = async () => {
    setModalHelpers({ ...modalHelpers, isLoading: true });
    await getWorkingPlaces(get(workerDetails, 'location_department.id'), '&limit=9999')
      .then((response) => {
        setModalHelpers({
          workingPlaces: get(response, 'data.results', []),
          isLoading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        setModalHelpers({ ...modalHelpers, isLoading: false });
      });
  };

  useEffect(() => {
    fetchWorkingPlaces();
  }, []);

  const handleChange = (key, value) => {
    setModalData({ ...modalData, [key]: value });
  };

  const handleSaveModal = () => {
    const data = {
      ...modalData,
      worker: workerId,
      user: currentUser.id,
      is_current: modalData?.is_current || false,
      working_place: modalData.working_place.id,
    };

    if (modalData.id) editWorkingPlaces(modalData.id, companyId, data).then(() => handleCloseModalAndRefetch());
    else addWorkingPlaces(companyId, data).then(() => handleCloseModalAndRefetch());
  };

  return (
    <Modal
      title={modalData ?
        t('page_content.workforce_management.worker_details.working_places_tab.modal_title_edit') :
        t('page_content.workforce_management.worker_details.working_places_tab.modal_title_add')}
      isOpen={showModal}
      handleClose={handleCloseModal}
      disableSave={!modalData?.start_date || !modalData?.working_place}
      handleSave={handleSaveModal}
    >
      <div className="working_places_modal_container">

        <div className="modal_row">
          <div className="left_text">{t('page_content.workforce_management.worker_details.working_places_tab.table_column_start_date')}: *</div>
          <div className="right_datePicker">
            <ReactDatePicker
              placeholderText={t('page_content.workforce_management.worker_details.working_places_tab.modal_date_placeholder')}
              selected={modalData?.start_date ? moment(modalData.start_date, 'YYYY-MM-DD').toDate() : null}
              onChange={(date) => handleChange('start_date', date ? moment(date).format('YYYY-MM-DD') : null)}
            />
          </div>
        </div>

        <div className="modal_row">
          <div className="left_text">{t('page_content.workforce_management.worker_details.working_places_tab.table_column_end_date')}:</div>
          <div className="right_datePicker">
            <ReactDatePicker
              placeholderText={t('page_content.workforce_management.worker_details.working_places_tab.modal_date_placeholder')}
              selected={modalData?.end_date ? moment(modalData.end_date, 'YYYY-MM-DD').toDate() : null}
              onChange={(date) => handleChange('end_date', date ? moment(date).format('YYYY-MM-DD') : null)}
            />
          </div>
        </div>

        <div className="modal_row">
          <div className="left_text">{t('page_content.workforce_management.worker_details.working_places_tab.table_column_is_currently')}:</div>
          <div className="right_checkbox">
            <input type="checkbox" checked={modalData?.is_current} onChange={(e) => handleChange('is_current', e.target.checked)} />
          </div>
        </div>

        <div className="modal_row">
          <div className="left_text">{t('page_content.workforce_management.worker_details.working_places_tab.table_column_working_place')}: *</div>
          <div className="right_select">
            <Select
              options={modalHelpers?.workingPlaces || []}
              menuPosition="fixed"
              isLoading={modalHelpers.isLoading}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={t('page_content.workforce_management.worker_details.working_places_tab.modal_working_place_placeholder')}
              onChange={(value) => handleChange('working_place', value)}
              isSearchable
              value={modalHelpers?.workingPlaces.find((d) => d.id === (modalData?.working_place?.id || '')) || null}
              styles={selectModalStyles}
            />
          </div>
        </div>

      </div>
    </Modal>
  );
};

WorkerWorkingPlacesModal.propTypes = {
  workerId: PropTypes.number,
  t: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  showModal: PropTypes.bool.isRequired,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  workerDetails: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleCloseModalAndRefetch: PropTypes.func.isRequired,
};

export default (withTranslation()(WorkerWorkingPlacesModal));
