import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Button, DateFilters, TableButtons, ContentLoader } from 'shared';
import { defaultDateFormat } from 'shared/constants';
import { IconWarning } from 'shared/Icons';
import { colors } from 'shared/colors';
import '../../styles.scss';

import { styledIndividualGoalsStatus } from 'industry/helpers';

import { getWorkforceWorkers, getScoringIndividualGoals, getScoringIndividualGoalsBlob, getScoringGoalScores, getScoringGoalTemplates, getByURL } from 'shared/Api';

const IndividualGoalsRating = ({ t, selectedWorker, companyId, currentUser, locationId }) => {
  const [individualGoals, setIndividualGoals] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [additionalData, setAdditionalData] = useState({
    answerOptions: [],
    templateOptions: [],
    workers: [],
    isLoading: true,
  });

  const lastDayOfJune = moment().month(5).endOf('month');
  const isSameOrBeforeJune = moment().isSameOrBefore(lastDayOfJune);
  const startDate = isSameOrBeforeJune ? moment().month(0).startOf('month') : moment().month(6).startOf('month');
  const endDate = isSameOrBeforeJune ? lastDayOfJune : moment().month(11).endOf('month');
  const [selectedFilters, setSelectedFilters] = useState({
    filters: 'start_date',
    start: moment(startDate).toISOString(),
    end: moment(endDate).toISOString(),
  });

  const fetchWorkersForUniqueUsers = (data) => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));

    const uniqueUsers = [...new Set(data.map((item) => item.user))];

    const fetchPromises = uniqueUsers.map((user) => getWorkforceWorkers(`?company=${companyId}&location=${locationId}&user=${user}`));

    Promise.all(fetchPromises)
      .then((results) => {
        const workersMap = results
          .flatMap((res) => get(res, 'data.results', []))
          .map((worker) => ({
            id: worker.user.id,
            name: worker.name,
            last_name: worker.last_name,
          }));

        setAdditionalData((prevState) => ({
          ...prevState,
          workers: workersMap,
          isLoading: false,
        }));
      })
      .catch(() => {
        setAdditionalData((prevState) => ({
          ...prevState,
          workers: [],
          isLoading: false,
        }));
      });
  };

  const fetchIndividualGoals = () => {
    setIndividualGoals((prevState) => ({ ...prevState, isLoading: true }));

    let filters = `?company=${companyId}&worker=${selectedWorker.id}&order_by=-created_at&limit=15`;

    if (selectedFilters?.start && selectedFilters?.end && selectedFilters?.filters) {
      filters += `&${selectedFilters.filters}_after=${moment(selectedFilters.start).format('YYYY-MM-DD')}&${selectedFilters.filters}_before=${moment(selectedFilters.end).format('YYYY-MM-DD')}`;
    }

    getScoringIndividualGoals(filters)
      .then((res) => {
        const data = get(res, 'data.results', []);

        fetchWorkersForUniqueUsers(data);

        setIndividualGoals({
          data,
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', null),
          isLoading: false,
        });
      });
  };

  const fetchPaginatedIndividualGoals = (url) => {
    setIndividualGoals((prevState) => ({ ...prevState, isLoading: true }));

    getByURL(url)
      .then((res) => {
        const data = get(res, 'data.results', []);

        fetchWorkersForUniqueUsers(data);

        setIndividualGoals({
          data,
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', null),
          isLoading: false,
        });
      });
  };

  const fetchAllIndividualScores = () => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));

    const filters = `?company=${companyId}&order_by=-score&limit=9999`;

    getScoringGoalScores(filters)
      .then((res) => {
        setAdditionalData((prevState) => ({
          ...prevState,
          answerOptions: get(res, 'data.results', []),
          isLoading: false,
        }));
      });
  };

  const fetchIndividualGoalTemplates = () => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));

    const apiFilters = `?company=${companyId}&limit=9999&order_by=-name&department=${selectedWorker?.location_department?.id}`;

    getScoringGoalTemplates(apiFilters)
      .then((res) => {
        setAdditionalData((prevState) => ({
          ...prevState,
          templateOptions: get(res, 'data.results', []),
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchIndividualGoals();
    fetchIndividualGoalTemplates();
    fetchAllIndividualScores();
  }, []);

  useEffect(() => {
    fetchIndividualGoals();
  }, [selectedFilters]);

  const handleSelectedDateFilter = (filters, start, end) => {
    setSelectedFilters({ filters, start, end });
  };

  const getNameAndScore = (id) => {
    const data = additionalData?.answerOptions?.find((option) => option?.id === id) || '-';
    return data?.name ? `${data?.name || '-'} (${data?.score || '-'} ${t('page_content.administration.rating.points')})` : '-';
  };

  const renderGoalRow = (goal) => {
    const submitter = additionalData?.workers?.find((worker) => worker.id === goal?.user);
    return (
    <div key={goal.id} className="modal_row">
      <div className="left_text">
        <div className="left_text__half">
          <label>{t('page_content.administration.rating.individual_goals_tab.field_placeholder_goal_name')}: {goal?.name || '-'}</label>
          <label>{t('page_content.administration.rating.individual_goals_tab.field_placeholder_goal_kpi')}: {goal?.kpi || '-'}</label>
        </div>

        <div className="left_text__half" style={{ alignItems: 'center' }}>
          <label>{t('page_content.administration.rating.individual_goals_tab.field_placeholder_goal_start')}: {goal?.start_date ?
            moment(goal?.start_date, 'YYYY-MM-DD').format(defaultDateFormat) : '-'}</label>
          <label>{t('page_content.administration.rating.individual_goals_tab.field_placeholder_goal_end')}: {goal?.deadline ?
            moment(goal?.deadline, 'YYYY-MM-DD').format(defaultDateFormat) : '-'}</label>
        </div>

        <div className="left_text__half" style={{ alignItems: 'center' }}>
          <label style={styledIndividualGoalsStatus(goal?.real_end_date ? 'completed' : '')}>
            {goal?.real_end_date ?
              `${t('page_content.administration.rating.individual_goals_tab.field_placeholder_goal_real_end_positive')}: ${moment(goal.real_end_date, 'YYYY-MM-DD').format(defaultDateFormat)}`
              :
              t('page_content.administration.rating.individual_goals_tab.field_placeholder_goal_real_end')}
          </label>
        </div>
      </div>

      <div className="right_input_individual_goals">
        <div className="right_input_individual_goals__select_administration">
          <p>{getNameAndScore(goal?.score?.id) || '-'}</p>
        </div>

        {
          !goal?.real_end_date &&
            <div style={{ width: '100%' }}>
              <span>{t('page_content.administration.rating.individual_goals_tab.field_placeholder_goal_note')}:</span>
              <p>{goal?.note || '-'}</p>
            </div>
        }

        {
          goal?.real_end_date &&
            <>
              <span>{t('page_content.administration.rating.individual_goals_tab.field_placeholder_goal_note')}: {goal?.note || '-'}</span>
              <span>{t('page_content.administration.rating.individual_goals_tab.field_placeholder_goal_evaluator')}: {submitter?.name && submitter?.last_name ? `${submitter.name} ${submitter.last_name}` : '-'}</span>
            </>
        }
      </div>
    </div>
    );
  };

  const exportToExcel = () => {
    let filters = `?company=${companyId}&worker=${selectedWorker.id}&order_by=-created_at}&format=xlsx`;

    if (selectedFilters?.start && selectedFilters?.end && selectedFilters?.filters) {
      filters += `&${selectedFilters.filters}_after=${moment(selectedFilters.start).format('YYYY-MM-DD')}&${selectedFilters.filters}_before=${moment(selectedFilters.end).format('YYYY-MM-DD')}`;
    }

    getScoringIndividualGoalsBlob(filters)
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Individual_Goals.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const individualGoalsFilterOptions = [
    { id: 'start_date', label: t('page_content.administration.rating.individual_goals_tab.filter_option_start_date'), showTime: false },
    { id: 'deadline', label: t('page_content.administration.rating.individual_goals_tab.filter_option_end_date'), showTime: false },
    { id: 'real_end_date', label: t('page_content.administration.rating.individual_goals_tab.filter_option_real_end_date'), showTime: false },
  ];

  return (
    <div className="ratings_screen_individual_goals">
      <div className="ratings_screen_individual_goals__header_filters">
        <div>
          <DateFilters
            selectedFilterProp={handleSelectedDateFilter}
            filterOptions={individualGoalsFilterOptions}
            defaultDate={selectedFilters}
          />
        </div>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Button type="export" onClick={() => exportToExcel()}>{t('page_content.administration.rating.individual_goals_tab.button_export_to_excel')}</Button>
        </div>
      </div>

      {
        !additionalData.isLoading && !individualGoals.isLoading ?
          <div className="custom_inputs_view">
          {
            !currentUser?.worker_id ?
              <span className="ratings_screen_individual_goals__warning">
                <IconWarning color={colors.red} height="18px" width="18px" />
                <p>{t('page_content.administration.rating.no_rating')}</p>
              </span>
              :
              individualGoals?.data?.length > 0 ? individualGoals?.data?.map((goal, index) => (renderGoalRow(goal, index)))
                :
                <span className="ratings_screen_individual_goals__warning">
                  <IconWarning color={colors.red} height="18px" width="18px" />
                  <p>{t('page_content.administration.rating.individual_goals_tab.error_no_individual_goals')}</p>
                </span>
          }
          </div> : <ContentLoader />
      }

      <TableButtons
        previous={individualGoals?.previous}
        fetchFunction={fetchPaginatedIndividualGoals}
        count={individualGoals?.count}
        next={individualGoals?.next}
      />
    </div>
  );
};

IndividualGoalsRating.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  selectedWorker: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    companyId: get(state, 'app.company.id', null),
    locationId: get(state, 'app.location.id', null),
  };
};

export default connect(mapStateToProps)(withTranslation()(IndividualGoalsRating));
