import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { selectModalStyles } from 'styles/modules/reactSelect';

import { Modal, ReactDatePicker } from 'shared';
import './styles.scss';

class TimetableShiftForm extends React.Component {
  constructor(props) {
    super();
    let date = null;
    let shift_begin = null;
    let shift_end = null;
    let shift = '';
    const changeReason = '';

    if (props.shiftTime) {
      const { shiftTime } = props;
      date = shiftTime.date;
      shift_begin = shiftTime.shift_begin;
      shift_end = shiftTime.shift_end;
      shift = shiftTime.shift;
    }

    if (props.dateAndTime) {
      const {
        shifts,
      } = props;
      const {
        start, end, shiftName,
      } = props.dateAndTime;

      const day = start.getDate();
      const month = start.getMonth() + 1;
      const startHour = start.getHours();
      const startMinutes = start.getMinutes();
      const endHour = end.getHours();
      const endMinutes = end.getMinutes();

      date = `${start.getFullYear()}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`;
      shift_begin = `${startHour > 9 ? startHour : `0${startHour}`}:${startMinutes > 9 ? startMinutes : `0${startMinutes}`}`;
      shift_end = `${endHour > 9 ? endHour : `0${endHour}`}:${endMinutes > 9 ? endMinutes : `0${endMinutes}`}`;

      if (shiftName) {
        const s = shifts.find((sh) => sh.name === shiftName);
        if (s) {
          shift = s;
        }
      }
    }

    this.state = {
      date,
      shift_begin,
      shift_end,
      shift,
      changeReason,
      isDeleteMode: false,
    };
  }

  handleDelete = () => {
    this.setState({ isDeleteMode: true });
  }

  handleConfirmDelete = () => {
    const { shiftTime, deleteShiftTime, locationId, dateAndTime, asset } = this.props;
    const { date, changeReason } = this.state;
    let id = null;

    if (shiftTime && shiftTime.id) {
      id = shiftTime.id;
    } else {
      id = dateAndTime.shiftTimeId;
    }
    const isDefault = dateAndTime && dateAndTime.isDefault ? dateAndTime.isDefault : false;
    const data = {
      shifttime: id,
      date,
      asset: asset.id,
      change_reason: changeReason,
    };
    deleteShiftTime(id, locationId, data, isDefault);
    this.setState({ isDeleteMode: false });
  }

  handleSave = () => {
    const { date, shift, shift_begin, shift_end, changeReason } = this.state;
    const { locationId, saveShiftTime, shiftTime, dateAndTime, asset } = this.props;

    const shift_id = shift ? (typeof shift === 'object' ? shift.id : shift) : '';
    const isDefault = dateAndTime && dateAndTime.isDefault ? dateAndTime.isDefault : false;

    let id;
    let isEdit;
    if (dateAndTime && dateAndTime.shiftTimeId) {
      id = dateAndTime.shiftTimeId;
      isEdit = true;
    } else {
      id = shiftTime && shiftTime.shift_id ? shiftTime.shift_id : '';
      isEdit = false;
    }

    const shiftData = {
      shift: shift_id,
      date,
      shift_begin,
      shift_end,
      asset: asset.id || null,
    };

    if (isEdit) {
      shiftData.change_reason = changeReason;
    }

    saveShiftTime(id, shiftData, locationId, isEdit, isDefault);

    setTimeout(() => {
      this.setState({
        shift_begin: null,
        shift_end: null,
        shift: '',
        date: null,
        changeReason: '',
      });
    }, 1000);
  }

  changeField = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  render() {
    const { isOpen, closeModal, shiftTime, isLoadingState, t, asset, shifts, dateAndTime } = this.props;
    const { shift_begin, shift_end, shift, changeReason, isDeleteMode } = this.state;

    let isEdit;
    if (dateAndTime && dateAndTime.shiftTimeId) {
      isEdit = true;
    } else {
      isEdit = false;
    }

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        handleSave={isDeleteMode ? this.handleConfirmDelete : this.handleSave}
        saveText={isDeleteMode ? t('settings.shifts.shift_time_form.confirm_delete') : t('settings.shifts.shift_time_form.save')}
        handleClose={closeModal}
        handleDelete={shiftTime && !isDeleteMode ? this.handleDelete : null}
        title={shiftTime ? t('settings.shifts.shift_time_form.edit_shift_time') : t('settings.shifts.shift_time_form.add_shift_time')}
        disableSave={!shift || (isEdit && !changeReason)}
      >
        {
          isLoadingState &&
          <div
            className="reason-form-loader"
            style={{
              borderLeftColor: 'transparent',
            }}
          />
        }
        <div className="timetable_modal_container">
          {isDeleteMode ? (
            <div className="modal_row">
              <div className="left_text">{`${t('settings.shifts.shift_time_form.change_reason')} *`}</div>
              <div className="right_input">
                <input
                  type="text"
                  value={changeReason}
                  onChange={(e) => this.changeField('changeReason', e.target.value)}
                  required
                />
              </div>
            </div>
          ) : (
            <>
              <div className="modal_row">
                <div className="left_text">{`${t('settings.shifts.shift_form.asset')}`}</div>
                <div className="right_input">
                  <input disabled type="text" value={asset && asset.name ? asset.name : ''} />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{`${t('settings.shifts.shift_time_form.begin')} *`}</div>
                <div className="right_datePicker">
                  <ReactDatePicker
                    selected={shift_begin ? moment(shift_begin, 'HH:mm').toDate() : ''}
                    onChange={(time) => (time ? this.changeField('shift_begin', moment(time).format('HH:mm')) : '')}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeCaption={t('date_picker_locale.time_translation')}
                  />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{`${t('settings.shifts.shift_time_form.end')} *`}</div>
                <div className="right_datePicker">
                  <ReactDatePicker
                    selected={shift_end ? moment(shift_end, 'HH:mm').toDate() : ''}
                    onChange={(time) => (time ? this.changeField('shift_end', moment(time).format('HH:mm')) : '')}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeCaption={t('date_picker_locale.time_translation')}
                  />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{`${t('settings.shifts.shift_time_form.shift')} *`}</div>
                <div className="right_select">
                  <Select
                    options={shifts}
                    getOptionLabel={(sh) => sh.name}
                    getOptionValue={(sh) => sh.id}
                    isSearchable
                    menuPosition="fixed"
                    onChange={(value) => this.changeField('shift', value)}
                    value={(shifts.find((s) => s.id === shift.id)) || ''}
                    styles={selectModalStyles}
                  />
                </div>
              </div>
              {
                isEdit &&
                <div className="modal_row">
                <div className="left_text">{`${t('settings.shifts.shift_time_form.change_reason')} *`}</div>
                <div className="right_input">
                  <input
                    type="text"
                    value={changeReason}
                    onChange={(e) => this.changeField('changeReason', e.target.value)}
                    required
                  />
                </div>
              </div>
              }
            </>
          )}
        </div>
      </Modal>
    );
  }
}

TimetableShiftForm.propTypes = {
  shifts: PropTypes.array,
  shiftTime: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  saveShiftTime: PropTypes.func.isRequired,
  deleteShiftTime: PropTypes.func.isRequired,
  isLoadingState: PropTypes.bool.isRequired,
  dateAndTime: PropTypes.any,
  t: PropTypes.func.isRequired,
  asset: PropTypes.object,
};

TimetableShiftForm.defaultProps = {
  shifts: [],
  showAll: false,
  dateAndTime: null,
};

export default withTranslation()(TimetableShiftForm);
