import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ValuesMapper } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { defaultDateFormat } from 'shared/constants';
import { IconSearch } from 'shared/Icons';

import api from 'helpers/api';
import {
  getWorkforceBudgets,
  getPartners,
  getWorkforceWorkers,
  getDepartments,
  getByURL,
} from 'shared/Api';

import { transformNameOrSurnameString } from '../helpers';
import '../styles.scss';

const Workers = ({
  t,
  history,
  companyId,
  locationId,
  customDepartment,
}) => {
  const activeFilterOptions = [
    { id: 'active', name: t('page_content.administration.active') },
    { id: 'inactive', name: t('page_content.administration.inactive') },
  ];

  const searchByOptions = [
    { id: 'name', name: t('page_content.administration.first_or_last_name') },
  ];

  const debounceTimeoutRef = useRef(null);

  const [tableData, setTableData] = useState({
    data: [],
    count: null,
    next: null,
    previous: null,
    isLoading: true,
  });

  const [query, setQuery] = useState(null);
  const [filters, setFilters] = useState({
    activityFilter: { id: 'active', name: t('page_content.administration.active') },
    departmentFilter: '',
    companiesFilter: '',
    selectedSearchBy: { id: 'name', name: t('page_content.administration.first_or_last_name') },
    selectedAscDesc: 'desc',
    selectedSort: 'active_from',
  });

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);

  const [budgetData, setBudgetData] = useState({
    companyBudget: 0,
    departmentBudget: 0,
  });

  const fetchWorkers = () => {
    setTableData((prevState) => ({ ...prevState, isLoading: true }));

    let urlFilters = `?company=${companyId}&location=${locationId}&limit=30&extend_data=true`;
    if (filters?.selectedSearchBy && query) {
      if (filters?.selectedSearchBy?.id === 'name') {
        if (query.includes(' ')) {
          const [firstName, lastName] = query.split(' ');
          urlFilters += `&name=${firstName}&last_name=${lastName}`;
        } else {
          urlFilters += `&first_or_last_name=${query}`;
        }
      } else {
        urlFilters += `&${filters?.selectedSearchBy?.id}=${query}`;
      }
    }

    if (filters?.activityFilter?.id === 'active' || filters?.activityFilter?.id === 'inactive') {
      urlFilters += `&is_active=${filters?.activityFilter.id === 'active' ? 'true' : 'false'}`;
    }

    if (filters?.departmentFilter) {
      const departmentFilterValue = filters?.departmentFilter;
      urlFilters += `&${customDepartment ? `${customDepartment}` : 'department'}=${customDepartment ? departmentFilterValue.id : departmentFilterValue.name}`;
    }

    if (filters?.companiesFilter) {
      const companiesFilterValue = filters?.companiesFilter;
      urlFilters += `&partner=${companiesFilterValue.id}`;
    }

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    if (filters?.selectedSort === 'external_id') urlFilters += `&order_by=${asc}numeric_external_id`;
    else urlFilters += `&order_by=${asc}${filters?.selectedSort}`;

    getWorkforceWorkers(urlFilters)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          count: get(res, 'data.count', null),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          isLoading: false,
        });
      });
  };

  const fetchDepartments = async () => {
    const apiFilters = `?location=${locationId}&limit=999&is_production_department=false`;
    const res = await getDepartments(apiFilters);
    setDepartmentOptions(get(res, 'data.results', []));
  };

  const fetchCompanies = async () => {
    const apiFilters = `?company=${companyId}`;
    const res = await getPartners(apiFilters);
    setCompaniesOptions(get(res, 'data.results', []));
  };

  const fetchCompanyBudget = async () => {
    const res = await getWorkforceBudgets(`?company=${companyId}&department_isnull=true&profession_isnull=true&year=${moment().year()}`);
    setBudgetData((prevState) => ({ ...prevState, companyBudget: get(res, 'data.results.[0].budget', 0) }));
  };

  const fetchDepartmentBudget = async () => {
    const res = await getWorkforceBudgets(`?company=${companyId}&department=${filters?.departmentFilter?.id}&profession_isnull=true&year=${moment().year()}`);
    setBudgetData((prevState) => ({ ...prevState, departmentBudget: get(res, 'data.results.[0].budget', 0) }));
  };

  useEffect(() => {
    fetchDepartments();
    fetchCompanies();
    fetchCompanyBudget();
  }, []);

  useEffect(() => {
    fetchWorkers();
    if (filters?.departmentFilter?.id) fetchDepartmentBudget();
  }, [filters]);

  useEffect(() => {
    if (query !== null) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        fetchWorkers();
      }, 300);
    }
  }, [query]);

  const fetchPaginatedWorkersList = (url) => {
    setTableData((prevState) => ({ ...prevState, isLoading: true }));

    getByURL(url)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          count: get(res, 'data.count', null),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          isLoading: false,
        });
      });
  };

  const clearAllFilters = () => {
    setQuery('');
    setFilters((prevState) => ({
      ...prevState,
      activityFilter: '',
      departmentFilter: '',
      companiesFilter: '',
      selectedSearchBy: { id: 'name', name: t('page_content.administration.first_or_last_name') },
    }));
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSorting = (sortData) => {
    let column = sortData.id;

    if (column === 'location_department.name') {
      column = 'location_department';
    }

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: column,
    }));
  };

  const exportToExcel = () => {
    let urlFilters = '';
    if (filters?.selectedSearchBy && query) {
      if (filters?.selectedSearchBy?.id === 'name') {
        urlFilters += `&first_or_last_name=${query}`;
      } else {
        urlFilters += `&${filters?.selectedSearchBy?.id}=${query}`;
      }
    }

    if (filters?.activityFilter?.id === 'active' || filters?.activityFilter?.id === 'inactive') {
      urlFilters += `&is_active=${filters?.activityFilter.id === 'active' ? 'true' : 'false'}`;
    }

    if (filters?.departmentFilter) {
      const departmentFilterValue = filters?.departmentFilter;
      urlFilters += `&${customDepartment ? `${customDepartment}` : 'department'}=${customDepartment ? departmentFilterValue.id : departmentFilterValue.name}`;
    }

    if (filters?.companiesFilter) {
      const companiesFilterValue = filters?.companiesFilter;
      urlFilters += `&partner=${companiesFilterValue.id}`;
    }

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    urlFilters += `&order_by=${asc}${filters?.selectedSort}`;

    api.get(`/api/v1/workforce/workers/?company=${companyId}&location=${locationId}${urlFilters}&format=xlsx&limit=${tableData?.count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Workers.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const redirectToWorkerDetails = (row) => {
    const workerId = row.original.id || null;
    history.push(`/${companyId}/industry/location/${locationId}/administration/worker/${workerId}`);
  };

  const tableColumnConfig = [
    {
      Header: () => <span>PIN</span>,
      accessor: 'external_id',
      width: 70,
      Cell: (row) => (row?.value || '-'),
    },
    {
      Header: () => <span>{t('page_content.administration.first_name')}</span>,
      accessor: 'name',
      Cell: (row) => (row.value ? transformNameOrSurnameString(row.value) : '-'),
    },
    {
      Header: () => <span>{t('page_content.administration.last_name')}</span>,
      accessor: 'last_name',
      Cell: (row) => (row.value ? transformNameOrSurnameString(row.value) : '-'),
    },
    {
      Header: () => <span>{t('page_content.administration.hire_date')}</span>,
      accessor: 'active_from',
      width: 110,
      Cell: (row) => (row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.administration.termination_date')}</span>,
      accessor: 'active_to',
      width: 110,
      Cell: (row) => (row.original?.is_active ? '-' : row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.administration.department')}</span>,
      accessor: `${customDepartment}.name` || 'department',
      Cell: (row) => (row?.value || '-'),
    },
    {
      Header: () => <span>{t('page_content.administration.working_place')}</span>,
      accessor: 'workingplaces',
      Cell: (row) => (row?.value?.length ? <ValuesMapper data={row?.value} accessor="working_place.name" sliceNumber={1} /> : '-'),
      sortable: false,
    },
    {
      Header: () => <span>{t('page_content.administration.net_salary_excluding_transport_food')}</span>,
      accessor: 'salaries',
      Cell: (row) => {
        const netSalary = parseFloat(get(row?.value, '[0].net_salary', 0), 10) || 0;
        const totalSalary = (netSalary).toFixed(2);
        return totalSalary > 0 ? `${totalSalary} €` : '-';
      },
    },
    {
      Header: () => <span>{t('page_content.administration.transport_allowance')}</span>,
      accessor: 'transport_fee',
      width: 90,
      Cell: (row) => (row?.value > 0 ? `${row?.value} €` : '-'),
    },
    {
      Header: () => <span>{t('page_content.administration.food_allowance')}</span>,
      accessor: 'food_fee',
      width: 90,
      Cell: (row) => (row?.value > 0 ? `${row?.value} €` : '-'),
    },
    {
      Header: () => <span>{t('page_content.administration.net_salary_including_transport_food')}</span>,
      accessor: 'salaries',
      Cell: (row) => {
        const netSalary = parseFloat(get(row?.value, '[0].net_salary', 0), 10) || 0;
        const foodFee = parseFloat(row?.original?.food_fee, 10) || 0;
        const transportFee = parseFloat(row?.original?.transport_fee, 10) || 0;
        const totalSalary = (netSalary + foodFee + transportFee).toFixed(2);
        return totalSalary > 0 ? `${totalSalary} €` : '-';
      },
    },
    {
      Header: () => <span>{t('page_content.administration.budget')}</span>,
      width: 90,
      Cell: () => ('-'),
    },
    {
      Header: () => <span>{t('page_content.administration.gross_salary_1')}</span>,
      width: 90,
      accessor: 'salaries',
      Cell: (row) => {
        const grossSalary = parseFloat(get(row?.value, '[0].gross_salary', 0), 10) || 0;
        const totalSalary = (grossSalary).toFixed(2);
        return totalSalary > 0 ? `${totalSalary} €` : '-';
      },
    },
    {
      Header: () => <span>{t('page_content.administration.gross_salary_2')}</span>,
      width: 90,
      accessor: 'salaries',
      Cell: (row) => {
        const grossSalary = parseFloat(get(row?.value, '[0].gross_salary_2', 0), 10) || 0;
        const totalSalary = (grossSalary).toFixed(2);
        return totalSalary > 0 ? `${totalSalary} €` : '-';
      },
    },
    {
      Header: () => <span>Budžet Bruto 2 (masa-ukupni trošak)</span>,
      Cell: () => ('-'),
    },
    {
      Header: () => <span>{t('page_content.administration.additional_allowances')}</span>,
      width: 90,
      Cell: () => ('-'),
    },
    {
      Header: () => <span>{t('page_content.administration.total_score')}</span>,
      Cell: () => ('-'),
    },
  ];

  return (
    <div className="workers">
      <div className="workers__info">
        <div className="workers__info__card">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className="workers__info__card__label">
              <span className="key">Ukupan budžet kompanije: {budgetData?.companyBudget} €</span>
            </p>
            <p className="workers__info__card__label">
              <span className="key">Stvarni trošak kompanije: 0 €</span>
            </p>
            <hr style={{ width: '100%' }} />
            <p className="workers__info__card__label">
              Razlika (u eurima): <span style={{ color: 'red' }}>0 €</span>
            </p>
            <p className="workers__info__card__label">
              Razlika (u %): <span style={{ color: 'red' }}>0%</span>
            </p>
          </div>
        </div>

        {
          filters?.departmentFilter &&
          <div className="workers__info__card">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p className="workers__info__card__label">
                <span className="key">Ukupan budžet odjela: {budgetData?.departmentBudget} €</span>
              </p>
              <p className="workers__info__card__label">
                <span className="key">Stvarni trošak odjela: 0 €</span>
              </p>
              <hr style={{ width: '100%' }} />
              <p className="workers__info__card__label">
                Razlika (u eurima): <span style={{ color: 'green' }}>0 €</span>
              </p>
              <p className="workers__info__card__label">
                Razlika (u %): <span style={{ color: 'green' }}>0%</span>
              </p>
            </div>
          </div>
        }
      </div>
      <div className="workers__filters">
        <div className="workers__filters__input-container">
          <div className="workers__filters__input-container__input_field">
            <input
              onChange={(e) => setQuery(e.target.value)}
              placeholder={t('page_content.administration.search_by')}
              value={query || ''}
            />
            {query && <button
              onClick={() => setQuery('')}
            >&times;</button>}
          </div>
          <Select
            className="select-style"
            options={searchByOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={(e) => handleFilterChange('selectedSearchBy', e)}
            value={filters?.selectedSearchBy}
            styles={selectModalStyles}
          />
          <div className="workers__filters__input-container__icon_container">
            <IconSearch
              color="#555"
              height="26px"
              width="26px"
            />
          </div>
        </div>
      </div>
      <div className="workers__filters__dropdowns">
        <Select
          className="select-style"
          options={activeFilterOptions}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          placeholder={t('page_content.administration.filter_by_activity')}
          isClearable
          onChange={(e) => handleFilterChange('activityFilter', e)}
          value={activeFilterOptions.find((a) => a.id === filters?.activityFilter?.id) || ''}
          styles={selectModalStyles}
        />
        <Select
          className="select-style"
          options={departmentOptions.sort((a, b) => a.name.localeCompare(b.name))}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          placeholder={t('page_content.administration.filter_by_department')}
          isClearable
          onChange={(e) => handleFilterChange('departmentFilter', e)}
          value={departmentOptions.find((a) => a.id === filters?.departmentFilter?.id) || ''}
          styles={selectModalStyles}
        />
        <Select
          className="select-style"
          options={companiesOptions}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          placeholder={t('page_content.administration.filter_by_company')}
          isClearable
          isSearchable
          onChange={(e) => handleFilterChange('companiesFilter', e)}
          value={companiesOptions.find((a) => a.id === filters?.companiesFilter?.id) || ''}
          styles={selectModalStyles}
        />
        <div className="workers__filters__dropdowns__clear">
          <Button type="plain" onClick={clearAllFilters}>{t('page_content.administration.clear_all')}</Button>
        </div>

        <div className="workers__filters__dropdowns__export">
          <Button type="export" onClick={exportToExcel}>{t('page_content.administration.export_to_excel')}</Button>
        </div>
      </div>

      <div className="workers__table-container">
        <Table
          style={{ userSelect: 'text' }}
          columns={tableColumnConfig}
          data={tableData.data}
          minRows={0}
          defaultPageSize={30}
          noDataText=" "
          showPagination={false}
          loading={tableData.isLoading}
          defaultSorted={[{ id: 'active_from', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
          handleClick={(rowInfo) => redirectToWorkerDetails(rowInfo)}
        />
        <TableButtons
          previous={tableData.previous}
          next={tableData.next}
          fetchFunction={fetchPaginatedWorkersList}
          count={tableData.count}
        />
      </div>
    </div>
  );
};

Workers.propTypes = {
  t: PropTypes.func.isRequired,
  customDepartment: PropTypes.string,
  history: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    customDepartment: get(state, 'app.companyConfig.config.custom_department'),
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(Workers)));
