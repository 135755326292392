import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import '../styles.scss';

import { ACTIONS } from 'industry/role/definitions/actions';
import { SECTIONS } from 'industry/role/definitions/sections';
import { canPerformAction } from 'industry/role/selectors';

import { getValueScores, getPaginatedValueScores, deleteValueScore } from '../../../actions';

import ValueScoresModal from './ValueScoresModal';

const ValueScoresTable = ({ t, companyId, can }) => {
  const [valueScores, setValueScores] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [valueScoresFilters, setValueScoresFilters] = useState({
    selectedAscDesc: 'desc',
    selectedSort: 'score',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchValueScores = () => {
    setValueScores((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = valueScoresFilters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&order_by=${asc}${valueScoresFilters?.selectedSort}`;

    getValueScores(companyId, apiFilters)
      .then((res) => {
        setValueScores({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setValueScores((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedValueScores = (url) => {
    setValueScores((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedValueScores(url)
      .then((res) => {
        setValueScores({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setValueScores((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchValueScores();
  }, [companyId, valueScoresFilters]);

  const handleShowConfirmationDialog = (row) => {
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteValueScore(deleteData?.id, companyId);
    setShowConfirmationDialog(false);
    fetchValueScores();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setValueScoresFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewValueScore = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchValueScores();
  };

  return (
    <React.Fragment>
      <div className="company_values_table">
        <h3>{t('page_content.human_resources.control_panel.company_values.value_scores')}</h3>

        <div className="table_action_buttons">
        <Button
          type="add"
          disabled={!can(SECTIONS.CONTROL_PANEL_TABS__KFK_VALUES_TAB, ACTIONS.ADD)}
          onClick={handleAddNewValueScore}
        >
          {t('page_content.human_resources.control_panel.company_values.value_scores')}
        </Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.control_panel.company_values.add_new_value_scores')}</span>,
              accessor: 'name',
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('page_content.human_resources.control_panel.company_values.score')}</span>,
              accessor: 'score',
              style: { cursor: 'default' },
            },
          ]}
          data={valueScores.data || []}
          defaultPageSize={100}
          loading={valueScores.isLoading}
          minRows={0}
          noDataText=""
          enableEdit
          enableDelete
          onEdit={(row) => handleTableRowClick(row)}
          onDelete={(row, e) => handleShowConfirmationDialog(row, e)}
          isActionsDisabled={!can(SECTIONS.CONTROL_PANEL_TABS__KFK_VALUES_TAB, ACTIONS.EDIT) || !can(SECTIONS.CONTROL_PANEL_TABS__KFK_VALUES_TAB, ACTIONS.DELETE)}
          defaultSorted={[{ id: 'score', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons
          previous={valueScores.previous}
          next={valueScores.next}
          fetchFunction={fetchPaginatedValueScores}
          count={valueScores.count}
        />
      </div>

      {modalData?.isOpen &&
      <ValueScoresModal
        t={t}
        isOpen={modalData?.isOpen}
        initialValues={modalData?.selectedItem}
        handleCloseModal={handleCloseModal}
        closeModalAndRefetch={closeModalAndRefetch}
      />}

      <ConfirmationModal
        itemName={`${get(deleteData, 'name')}`}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleDeleteItem}
        type="warning"
      />
    </React.Fragment>
  );
};

ValueScoresTable.propTypes = {
  t: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    companyId: get(state, 'app.company.id', null),
    can: (section, action, useExceptions = false) => canPerformAction(state, section, action, useExceptions),
  };
};

export default connect(mapStateToProps)(withTranslation()(ValueScoresTable));
