import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons } from 'shared';
import { defaultDateFormat } from 'shared/constants';

import { styledWorkInjuryStatusOptions } from 'industry/helpers';

import { getWorkingInjuries, getPaginatedWorkingInjuries } from '../../actions';

// import WorkingInjuriesModal from './WorkingInjuriesModal';

const WorkingInjuriesTab = ({ t, locationId, workerId }) => {
  const [sorting, setSorting] = useState({
    selectedSort: 'date',
    selectedAscDesc: 'desc',
  });

  const [tableData, setTableData] = useState({
    isLoading: true,
    workingInjuries: [],
    next: null,
    previous: null,
  });

  // const [modalData, setModalData] = useState({
  //   isOpen: false,
  //   selectedInjury: null,
  // });

  const fetchInjuries = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getWorkingInjuries(locationId, `&worker=${workerId}`)
      .then((re) => {
        setTableData({
          isLoading: false,
          workingInjuries: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  useEffect(() => {
    fetchInjuries();
  }, [locationId, sorting]);

  const handleSorting = (sortData) => {
    const column = sortData.id;

    setSorting({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    });
  };

  const fetchPaginatedData = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedWorkingInjuries(url)
      .then((re) => {
        setTableData({
          isLoading: false,
          workingInjuries: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  // const closeModal = () => {
  //   setModalData({
  //     isOpen: false,
  //     selectedCompanyTable: null,
  //   });
  // };

  // const handleTableRowClick = (injury) => {
  //   setModalData({
  //     isOpen: true,
  //     selectedInjury: injury,
  //   });
  // };

  const eventLocationOptions = [
    { value: 'workplace', name: t('page_content.human_resources.injuries.location_options_workplace') },
    { value: 'travel', name: t('page_content.human_resources.injuries.location_options_travel') },
  ];

  const statusesOptions = [
    { value: 'resolved', name: t('page_content.human_resources.injuries.status_resolved') },
    { value: 'denied', name: t('page_content.human_resources.injuries.status_denied') },
    { value: 'unresolved', name: t('page_content.human_resources.injuries.status_unresolved') },
    { value: 'in_progress', name: t('page_content.human_resources.injuries.status_in_progress') },
  ];

  return (
    <div>
      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span>{t('page_content.human_resources.injuries.date')}</span>,
            accessor: 'date',
            style: {
              cursor: 'default',
            },
            Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
          },
          {
            Header: () => <span>{t('page_content.human_resources.injuries.worker')}</span>,
            accessor: 'worker',
            style: {
              cursor: 'default',
            },
            Cell: (row) => (row && row.value ? `${row.value.name} ${row.value.last_name}` : '-'),
          },
          {
            Header: () => <span>{t('page_content.human_resources.injuries.event_location')}</span>,
            accessor: 'event_location',
            style: {
              cursor: 'default',
            },
            Cell: (row) => (row?.value ? eventLocationOptions?.find((val) => val.value === row.value)?.name : '-'),
          },
          {
            Header: () => <span>{t('page_content.human_resources.injuries.injured_body_part')}</span>,
            accessor: 'injured_body_part',
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.injuries.injury_source')}</span>,
            accessor: 'injury_source',
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.injuries.injury_origin')}</span>,
            accessor: 'injury_origin',
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.injuries.activity_undertaken')}</span>,
            accessor: 'activity_undertaken',
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.injuries.planned_resolution_date')}</span>,
            accessor: 'planned_resolution_date',
            style: {
              cursor: 'default',
            },
            Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
          },
          {
            Header: () => <span>{t('page_content.human_resources.injuries.status')}</span>,
            accessor: 'status',
            Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span style={row?.value && statusesOptions?.find((val) => val.value === row?.value)?.name ? styledWorkInjuryStatusOptions(row?.value) : {}}>
                {row?.value ? statusesOptions?.find((val) => val.value === row?.value)?.name : '-'}</span>
            </div>,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.injuries.responsible_person')}</span>,
            accessor: 'responsible_person',
            style: {
              cursor: 'default',
            },
            Cell: (row) => (row && row.value ? `${row.value.name} ${row.value.last_name}` : '-'),
          },
        ]}
        data={tableData.workingInjuries || []}
        defaultPageSize={30}
        loading={tableData.isLoading}
        minRows={0}
        noDataText=""
        showPagination={false}
        selectedRow={null}
        sortable
        defaultSorted={[{ id: 'date', desc: true }]}
        onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        // handleClick={(rowInfo) => handleTableRowClick(get(rowInfo, 'original'))}
      />
      <div>
        <span style={{ float: 'right' }}>
          <TableButtons previous={tableData.previous} next={tableData.next} fetchFunction={fetchPaginatedData} count={tableData.count} />
        </span>
      </div>

      {/* {modalData.isOpen &&
      <WorkingInjuriesModal
        t={t}
        isOpen={modalData.isOpen}
        initialValues={modalData.selectedInjury}
        onSave={closeModal}
        handleCloseModal={closeModal}
        isReadOnly
      />} */}
    </div>
  );
};

WorkingInjuriesTab.propTypes = {
  t: PropTypes.func.isRequired,
  workerId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    locationId: get(state, 'app.location.id', null),
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(WorkingInjuriesTab)));
