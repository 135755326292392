/* eslint-disable react/jsx-indent */
import React, { Component } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Notification, ReactDatePicker } from 'shared';
import { IconCalendar } from 'shared/Icons';

import { getCustomDateString } from 'shared/helpers';

import { getShifts } from './actions';
import {
  defaultEndDate,
  defaultStartDate,
  timeRangeEnum,
  timeRangeConfig,
} from './constants';

import ShiftPicker from '../ShiftPicker/index';

import './styles.scss';

class DatePicker extends Component {
  constructor() {
    super();
    this.state = {
      showMenu: false,
      isAllDay: false,
      weekNumber: null,
    };
  }

  componentDidMount() {
    const { locationId } = this.props;
    getShifts(locationId.id)
      .then((re) => {
        this.setState({
          shifts: get(re, 'data.results'),
        });
      })
      .catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { dateRange, startDate } = this.props;

    if (prevState.dateRange !== this.props.dateRange) {
      const weekNumber = (dateRange === timeRangeEnum.nth_week) ? moment(startDate).format('w') : null;
      this.state.weekNumber = weekNumber;
    }
  }

  getCustomToday = () => {
    const { locationConfig } = this.props;

    const dashboard_day_start = locationConfig && locationConfig.config && locationConfig.config.dashboard_day_start ? locationConfig.config.dashboard_day_start : '00:00:00';

    let start = new Date();
    start = start.setHours(dashboard_day_start.split(':')[0], dashboard_day_start.split(':')[1], 0, 0);
    start = moment(start);

    let end = new Date();
    end = end.setHours(dashboard_day_start.split(':')[0], dashboard_day_start.split(':')[1], 0, 0);
    end = moment(end).add(1, 'days');

    this.props.changeDate(this.state.range, moment(start), moment(end));

    this.setState({
      start,
      end,
    }, () => this.props.forceRefresh());
  }

  getCustomYesterday = () => {
    const { locationConfig } = this.props;

    const dashboard_day_start = locationConfig && locationConfig.config && locationConfig.config.dashboard_day_start ? locationConfig.config.dashboard_day_start : '00:00:00';

    let start = new Date();
    start = start.setHours(dashboard_day_start.split(':')[0], dashboard_day_start.split(':')[1], 0, 0);
    start = moment(start).subtract(1, 'days');

    let end = new Date();
    end = end.setHours(dashboard_day_start.split(':')[0], dashboard_day_start.split(':')[1], 0, 0);
    end = moment(end);

    this.props.changeDate(this.state.range, moment(start), moment(end));

    this.setState({
      start,
      end,
    }, () => this.props.forceRefresh());
  }

  handleShiftChange = (start, end) => {
    const { range } = this.state;
    const { changeDate, forceRefresh } = this.props;
    changeDate(range, moment(start), moment(end));
    // this.cleanComponentState();
    this.setState({
      start,
      end,
      range: timeRangeEnum.custom,
    }, () => {
      forceRefresh();
    });
  }

  cleanComponentState = () => {
    this.setState({
      end: undefined,
      range: undefined,
      showMenu: false,
      start: undefined,
    });
    document.removeEventListener('mousedown', this.handleClose, false);
  }

  handleClose = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.cleanComponentState();
  }

  handleDateChange = () => {
    const { forceRefresh, changeDate } = this.props;
    const {
      end,
      range,
      start,
    } = this.state;
    if (range) {
      changeDate(range, start, end);
      this.cleanComponentState();
      setTimeout(() => {
        forceRefresh();
      }, 30);
    }
  }

  handleChangeEnd = (t) => {
    const formatedDate = moment(t);

    if (this.state.isAllDay) {
      formatedDate._d.setHours(23, 59, 59, 999);
      this.setState({ end: formatedDate });
    } else {
      this.setState({ end: formatedDate });
    }
  }

  handleChangeStart = (t) => {
    const formatedDate = moment(t);
    if (this.state.isAllDay) {
      formatedDate._d.setHours(0, 0, 0, 0);
      this.setState({ start: formatedDate });
    } else {
      this.setState({ start: formatedDate });
    }
  }

  // handleSelectWeek = (startDay) => {
  //   const weekNumber = moment(startDay).format('w');
  //   const endDay = moment(startDay).add(7, 'day');
  //   this.setState({
  //     weekNumber,
  //     start: moment(startDay).startOf('day'),
  //     end: endDay.endOf('day'),
  //   });
  // }

  showMenu = () => {
    const { endDate, dateRange, startDate } = this.props;
    const weekNumber = (dateRange === timeRangeEnum.nth_week) ? moment(startDate).format('w') : null;
    this.setState({
      showMenu: true,
      range: dateRange,
      start: startDate,
      end: endDate,
      weekNumber,
    }, () => {
      document.addEventListener('mousedown', this.handleClose, false);
    });
  }

  toggleAllDay = (checked) => {
    if (this.state.start && this.state.end) {
      this.setState((prevState) => {
        const isAllDay = checked;
        let newStart = moment(prevState.start);
        let newEnd = moment(prevState.end);

        if (isAllDay) {
          newStart = newStart.startOf('day');
          newEnd = newEnd.endOf('day');
        } else {
          const current = moment();
          newStart = newStart.set({
            hour: current.hour(),
            minute: current.minute(),
            second: current.second(),
            millisecond: current.millisecond(),
          });
          newEnd = newEnd.set({
            hour: current.hour(),
            minute: current.minute(),
            second: current.second(),
            millisecond: current.millisecond(),
          });
        }

        return { isAllDay, start: newStart, end: newEnd };
      });
    }
  }

  render() {
    const {
      dateRange,
      endDate,
      startDate,
      t,
      locationConfig,
    } = this.props;

    const {
      end,
      range,
      showMenu,
      start,
      isAllDay,
      shifts,
      // weekNumber,
    } = this.state;

    let headLabel = timeRangeConfig()[dateRange] && timeRangeConfig()[dateRange].name;
    if (dateRange === timeRangeEnum.custom) {
      headLabel = getCustomDateString(startDate, endDate);
    }
    // if (dateRange === timeRangeEnum.nth_week) {
    //   headLabel = `Week ${weekNumber}, ${moment(start).format('Y')}`;
    // }

    const dashboard_day_start = locationConfig && locationConfig.config && locationConfig.config.dashboard_day_start ? locationConfig.config.dashboard_day_start : null;

    return (
      // eslint-disable-next-line no-return-assign
      <div className={`DatePicker ${showMenu ? 'DatePicker-active' : ''}`} ref={(node) => (this.node = node)}>
        <div className="DatePicker-label" onClick={this.showMenu}>
          <IconCalendar
            width="16px"
            height="16px"
          />
          <span>{headLabel}</span>
        </div>
        {
          showMenu && (
            <div className="DatePicker-menu">
              <header>
                <h3>
                  {t('page_content.dashboards.time_range_dropdown.choose_time_range')}
                </h3>
              </header>
              <main>
                <div className="flexContainer">
                  <div>
                    <button
                      className={range === timeRangeEnum.last_7_days ? 'active' : ''}
                      onClick={() => this.setState({ range: timeRangeEnum.last_7_days })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.last_7_days')}
                    </button>
                    <button
                      className={range === timeRangeEnum.this_month ? 'active' : ''}
                      onClick={() => this.setState({ range: timeRangeEnum.this_month })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.this_month')}
                    </button>
                    <button
                      className={range === timeRangeEnum.last_month ? 'active' : ''}
                      onClick={() => this.setState({ range: timeRangeEnum.last_month })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.last_month')}
                    </button>
                    <button
                      className={range === timeRangeEnum.last_12_months ? 'active' : ''}
                      onClick={() => this.setState({ range: timeRangeEnum.last_12_months })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.last_12_months')}
                    </button>
                    <button
                      className={range === timeRangeEnum.custom ? 'active' : ''}
                      onClick={() => this.setState({ range: timeRangeEnum.custom })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.custom_range')}
                    </button>
                  </div>
                  <div>
                    <button
                      className={range === timeRangeEnum.today || range === timeRangeEnum.customToday ? 'active' : ''}
                      onClick={() => this.setState({ range: dashboard_day_start ? timeRangeEnum.customToday : timeRangeEnum.today }, () => { this.getCustomToday(); })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.today')}
                    </button>
                    <button
                      className={range === timeRangeEnum.yesterday || range === timeRangeEnum.customYesterday ? 'active' : ''}
                      onClick={() => this.setState({ range: dashboard_day_start ? timeRangeEnum.customYesterday : timeRangeEnum.yesterday }, () => { this.getCustomYesterday(); })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.yesterday')}
                    </button>
                    <button
                      className={range === timeRangeEnum.last_24_hours ? 'active' : ''}
                      onClick={() => this.setState({ range: timeRangeEnum.last_24_hours })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.last_24_hours')}
                    </button>
                    <button
                      className={range === timeRangeEnum.this_week ? 'active' : ''}
                      onClick={() => this.setState({ range: timeRangeEnum.this_week })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.this_week')}
                    </button>
                    <button
                      className={range === timeRangeEnum.last_week ? 'active' : ''}
                      onClick={() => this.setState({ range: timeRangeEnum.last_week })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.last_week')}
                    </button>
                  </div>
                  <div>
                    <button
                      className={range === timeRangeEnum.last_12_hours ? 'active' : ''}
                      onClick={() => this.setState({ range: timeRangeEnum.last_12_hours })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.last_12_hours')}
                    </button>
                    <button
                      className={range === timeRangeEnum.last_3_hours ? 'active' : ''}
                      onClick={() => this.setState({ range: timeRangeEnum.last_3_hours })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.last_3_hours')}
                    </button>
                    <button
                      className={range === timeRangeEnum.last_hour ? 'active' : ''}
                      onClick={() => this.setState({ range: timeRangeEnum.last_hour })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.last_hour')}
                    </button>
                    <button
                      className={range === timeRangeEnum.last_30_minutes ? 'active' : ''}
                      onClick={() => this.setState({ range: timeRangeEnum.last_30_minutes })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.last_30_minutes')}
                    </button>
                    <button
                      className={range === timeRangeEnum.last_15_minutes ? 'active' : ''}
                      onClick={() => this.setState({ range: timeRangeEnum.last_15_minutes })}
                    >
                      {t('page_content.dashboards.time_range_dropdown.last_15_minutes')}
                    </button>
                  </div>
                </div>
                <ShiftPicker
                  className="dashboard-filter-shift-picker"
                  shifts={shifts}
                  handleShiftPickerChange={(startShift, endShift) => this.handleShiftChange(startShift, endShift)}
                  startDate={startDate}
                  endDate={endDate}
                />
                {
                  range === timeRangeEnum.custom &&
                  <div>
                    <div className="whole-days-checkbox">
                      <div>{t('page_content.dashboards.time_range_dropdown.all_day_button')}</div>
                      <input type="checkbox" onChange={(e) => this.toggleAllDay(e.target.checked)} checked={isAllDay} />
                    </div>
                    <div className="custom-picker">
                      <div style={{ width: '50%' }}>
                        <ReactDatePicker
                          placeholderText="Select start date"
                          maxDate={moment(end).toDate()}
                          selected={moment(start).toDate()}
                          selectsStart
                          startDate={moment(start).toDate()}
                          endDate={moment(end).toDate()}
                          onChange={this.handleChangeStart}
                          showTimeSelect={!isAllDay}
                          timeCaption={t('date_picker_locale.time_translation')}
                        />
                      </div>
                      <div style={{ width: '50%' }}>
                        <ReactDatePicker
                          placeholderText="Select end date"
                          selected={moment(end).toDate()}
                          selectsEnd
                          minDate={moment(start).toDate()}
                          startDate={moment(start).toDate()}
                          endDate={moment(end).toDate()}
                          onChange={this.handleChangeEnd}
                          showTimeSelect={!isAllDay}
                          timeCaption={t('date_picker_locale.time_translation')}
                        />
                      </div>
                    </div>
                  </div>
                }
                {/* {
                  range === timeRangeEnum.nth_week &&
                  <div>
                    <div className="custom-picker">
                      <ReactDatePicker
                        placeholderText="Select week"
                        showWeekNumbers
                        dateFormat="dd.MM.yyyy"
                        maxDate={moment().toDate()}
                        selected={moment(start).toDate()}
                        selectsStart
                        startDate={moment(start).toDate()}
                        endDate={moment(end).toDate()}
                        value={weekNumber ? `Week ${weekNumber}, ${moment(start).format('Y')}` : 'Select week...'}
                        onChange={this.handleSelectWeek}
                      />
                    </div>
                  </div>
                } */}
              </main>
              <footer>
                <button
                  disabled={range === timeRangeEnum.custom && (!start || !end)}
                  onClick={this.handleDateChange}
                >
                  {t('page_content.dashboards.time_range_dropdown.apply_button')}
                </button>
              </footer>
            </div>
          )
        }
      </div>
    );
  }
}

DatePicker.propTypes = {
  changeDate: PropTypes.func.isRequired,
  dateRange: PropTypes.string.isRequired,
  endDate: PropTypes.any.isRequired,
  startDate: PropTypes.any.isRequired,
  forceRefresh: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  locationConfig: PropTypes.object,
  locationId: PropTypes.object.isRequired,
};

DatePicker.defaultProps = {
  startDate: defaultStartDate,
  endDate: defaultEndDate,
};

const mapStateToProps = (state) => {
  return {
    locationConfig: state.app.locationConfig,
  };
};

export default withTranslation()(connect(mapStateToProps, null)(DatePicker));
