import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { Modal, ReactDatePicker } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';
import '../style.scss';

const LaunchModal = ({ t, showLaunchModal, handleClose, lines, handleSave }) => {
  const [selectedLine, setSelectedLine] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleSelect = (e) => {
    setSelectedLine(e);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Modal handleSave={() => handleSave(selectedLine, selectedDate)} title={t('page_content.orders.order_details.line_plans_tab.launch_modal_title')} isOpen={showLaunchModal} handleClose={handleClose}>
      <div className="modal_padding">
        <div className="modal_row">
          <div className="label_left">
            <label>{t('page_content.orders.order_details.line_plans_tab.launch_modal_line_label')}</label>
          </div>
          <div className="right_select">
            <Select
              options={lines}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isSearchable
              menuPosition="fixed"
              placeholder={t('page_content.orders.order_details.line_plans_tab.launch_modal_line_placeholder')}
              onChange={handleSelect}
              value={selectedLine || ''}
              styles={selectModalStyles}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="label_left">
            <label>{t('page_content.orders.order_details.line_plans_tab.launch_modal_date_label')}</label>
          </div>
          <div className="input_right">
            <ReactDatePicker
              placeholderText={t('page_content.orders.order_details.line_plans_tab.launch_modal_date_placeholder')}
              selected={selectedDate}
              onChange={(date) => handleDateChange(date)}
              showTimeSelect
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

LaunchModal.propTypes = {
  t: PropTypes.func.isRequired,
  showLaunchModal: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  lines: PropTypes.array,
};

export default LaunchModal;
