import React, { Component } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import api from 'helpers/api';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, Button, TableButtons, ContentLoader, ReactDatePicker } from 'shared';
import { selectStyles } from 'styles/modules/reactSelect';
import { IconSearch } from 'shared/Icons';

import { numberSeparatorFormat } from 'industry/helpers';
import { defaultDateTimeFormat } from 'shared/constants';

import { getProductTypes, getGoodsMovement, getPaginatedGoodsMovement, getOrders } from '../actions';
import '../styles.scss';

class GoodsMovement extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    this.state = {
      productTypes: [],
      selectedProductType: null,
      goods: [],
      previous: null,
      next: null,
      count: null,
      isLoadingGoods: false,
      startDate: null,
      endDate: null,
      orders: [],
      selectedOrder: null,
      palletSearchQuery: '',
      exportLoader: false,
      selectedAscDesc: 'desc',
      selectedSort: 'created_at',
      isLoadingOrders: false,
    };
  }

  componentDidMount() {
    const { companyId } = this.props;

    this.fetchGoodsMovement();

    getProductTypes(companyId)
      .then((re) => {
        this.setState({
          productTypes: get(re, 'data.results') || [],
        });
      });
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchGoodsMovement();
    });
  }

  handleOrderSearch = (inputValue) => {
    const { companyId, locationId } = this.props;

    if (inputValue?.length >= 3) {
      this.setState({ inputValue, isLoadingOrders: true });

      let filters = '';

      if (this.timerRef.current) {
        clearTimeout(this.timerRef.current);
        this.timerRef.current = undefined;
      }

      this.timerRef.current = setTimeout(() => {
        this.timerRef.current = undefined;
        filters += '&order_by=-updated_at,-created_at';

        if (inputValue) {
          filters += `&external_id=${inputValue}`;
        }

        getOrders(companyId, locationId, filters)
          .then((res) => {
            this.setState({
              orders: get(res, 'data.results') || [],
              isLoadingOrders: false,
            });
          });
      }, 500);
    } else {
      this.setState({ inputValue, orders: [] });
    }
  };

  onFilterChange = (key, value) => {
    if (key === 'palletSearchQuery') {
      this.setState({
        [key]: value,
      });
      if (this.timerRef.current) {
        clearTimeout(this.timerRef.current);
        this.timerRef.current = undefined;
      }
      this.timerRef.current = setTimeout(() => {
        this.timerRef.current = undefined;
        if (value.length < 1 || value.length >= 1) {
          this.fetchGoodsMovement();
        }
      }, 500);
    } else {
      this.setState({
        [key]: value,
      }, () => {
        this.fetchGoodsMovement();
      });
    }
  }

  clearFilters = () => {
    this.setState({
      selectedOrder: null,
      selectedProductType: null,
      startDate: null,
      endDate: null,
      palletSearchQuery: '',
    }, () => {
      this.fetchGoodsMovement();
    });
  }

  fetchGoodsMovement = () => {
    const { companyId, order } = this.props;
    const { selectedProductType, startDate, endDate, selectedOrder, palletSearchQuery, selectedSort, selectedAscDesc } = this.state;

    this.setState({
      isLoadingGoods: true,
    });

    let filters = '';

    if (selectedProductType && selectedProductType.id) {
      filters += `&product_type=${selectedProductType.id}`;
    }

    if (startDate) {
      filters += `&created_at_after=${moment(startDate).startOf('day').utc().toISOString()}`;
    }

    if (endDate) {
      filters += `&created_at_before=${moment(endDate).endOf('day').utc().toISOString()}`;
    }

    if (!order && selectedOrder && selectedOrder.id) {
      filters += `&order=${selectedOrder.id}`;
    }

    if (order && order.id) {
      filters += `&order=${order.id}`;
    }

    if (palletSearchQuery) {
      filters += `&pallet_external_id=${palletSearchQuery}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort || 'created_at'}`;

    getGoodsMovement(companyId, filters)
      .then((re) => {
        this.setState({
          goods: get(re, 'data.results') || [],
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isLoadingGoods: false,
        });
      });
  }

  exportToExcel = () => {
    const { selectedProductType, startDate, endDate, selectedOrder, palletSearchQuery } = this.state;
    const { companyId, order } = this.props;
    this.setState({
      exportLoader: true,
    });
    let filters = '';

    if (selectedProductType && selectedProductType.id) {
      filters += `&product_type=${selectedProductType.id}`;
    }

    if (startDate) {
      filters += `&created_at_after=${moment(startDate).startOf('day').utc().toISOString()}`;
    }

    if (endDate) {
      filters += `&created_at_before=${moment(endDate).endOf('day').utc().toISOString()}`;
    }

    if (!order && selectedOrder && selectedOrder.id) {
      filters += `&order=${selectedOrder.id}`;
    }

    if (order && order.id) {
      filters += `&order=${order.id}`;
    }

    if (palletSearchQuery) {
      filters += `&pallet_external_id=${palletSearchQuery}`;
    }

    api.get(`/api/v1/wms/goods_movements/?&company=${companyId}${filters}&order_by=-created_at&format=xlsx&limit=99999`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'goods_movement.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({
          exportLoader: false,
        });
      });
  }

  fetchPaginatedGoodsMovement = (url) => {
    this.setState({
      isLoadingGoods: true,
    });

    getPaginatedGoodsMovement(url)
      .then((re) => {
        this.setState({
          goods: get(re, 'data.results') || [],
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isLoadingGoods: false,
        });
      });
  }

  render() {
    const { t, i18n, order, companyId, locationId } = this.props;
    const {
      palletSearchQuery,
      goods,
      isLoadingGoods,
      previous,
      next,
      productTypes,
      selectedProductType,
      startDate,
      endDate,
      orders,
      selectedOrder,
      count,
      exportLoader,
      isLoadingOrders,
    } = this.state;

    return (
      <div className="warehouse_container">
        <div className="warehouse_toolbar_area">
          <div className="warehouse_toolbar_input_container">
            <input
              onChange={(e) => { this.onFilterChange('palletSearchQuery', e.target.value); }}
              placeholder={t('page_content.warehouse.search_by_pallet')}
              value={palletSearchQuery}
              type="text"
            />
            <div className="warehouse_toolbar_icon_container">
              <IconSearch
                color="#555"
                height="25px"
                width="25px"
              />
            </div>
          </div>
          {
            !order &&
            <Select
              options={orders}
              getOptionLabel={(option) => option.external_id}
              getOptionValue={(option) => option.id}
              isSearchable
              isClearable
              placeholder={t('page_content.warehouse.filter_by_order')}
              onChange={(o) => { this.onFilterChange('selectedOrder', o ?? ''); }}
              onInputChange={(e) => this.handleOrderSearch(e)}
              value={selectedOrder || null}
              styles={selectStyles}
              isLoading={isLoadingOrders}
              loadingMessage={() => <ContentLoader />}
            />
          }

          <Select
            options={productTypes}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            isClearable
            placeholder={t('page_content.warehouse.filter_by_product_type')}
            onChange={(productType) => { this.onFilterChange('selectedProductType', productType ?? ''); }}
            value={selectedProductType || null}
            styles={selectStyles}
          />
          <div style={{ width: '200px' }}>
            <ReactDatePicker
              placeholderText={t('page_content.orders.start_date_placeholder')}
              selected={startDate ? moment(startDate).toDate() : null}
              onChange={(e) => this.onFilterChange('startDate', e)}
              timeCaption={t('date_picker_locale.time_translation')}
            />
          </div>
          <div style={{ width: '200px' }}>
            <ReactDatePicker
              placeholderText={t('page_content.orders.end_date_placeholder')}
              selected={endDate ? moment(endDate).toDate() : null}
              onChange={(e) => this.onFilterChange('endDate', e)}
              timeCaption={t('date_picker_locale.time_translation')}
            />
          </div>
          <div className="warehouse_clearFilters_button">
            <Button onClick={this.clearFilters}>
              {t('shared.clear_button')}
            </Button>
          </div>
          <div className="warehouse_exportExcel_button">
            <Button
              type="export"
              onClick={this.exportToExcel}
              loading={exportLoader}
            >{t('page_content.warehouse.export_to_excel_button')}</Button>
          </div>
        </div>

        <div className="warehouse_table_area">
          <Table
            style={{ userSelect: 'text' }}
            columns={[
              {
                Header: () => <span>{t('page_content.warehouse.goods_movement_table.created_at')}</span>,
                accessor: 'created_at',
                width: 180,
                Cell: (row) => (row && row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.goods_movement_table.external_id')}</span>,
                accessor: 'order',
                width: 150,
                Cell: (row) => (row && row.value && row.value.external_id ? <Link to={`/${companyId}/industry/location/${locationId}/orders/${row.value.id}`}>
                  {row.value.external_id}
                </Link> : ''),
              },
              {
                Header: () => <span>{t('page_content.warehouse.goods_movement_table.name')}</span>,
                accessor: 'product_type',
                Cell: (row) => {
                  const name = (row && row.value && row.value.name ? row.value.name : '-');
                  const code = (row && row.value && row.value.code ? row.value.code : '-');
                  return name || code;
                },
              },
              {
                Header: () => <span>{t('page_content.warehouse.goods_movement_table.movement_type')}</span>,
                accessor: 'movement_type',
                Cell: (row) => (row && row.value ? t([`page_content.warehouse.goods_movement_types.${row.value}`]) : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.goods_movement_table.batch')}</span>,
                accessor: 'batch',
                width: 200,
                Cell: (row) => (row && row.value ? row.value : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.goods_movement_table.quantity')}</span>,
                width: 150,
                accessor: 'quantity',
                Cell: (row) => (row && row.value && row.original.unit ? `${numberSeparatorFormat(i18n.language, parseFloat(row.value), 2, 2, true)} ${row.original.unit}` : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.goods_movement_table.partner')}</span>,
                accessor: 'partner',
                width: 200,
                Cell: (row) => (row && row.value ? `${row.value.name}` : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.goods_movement_table.pallet')}</span>,
                accessor: 'pallet',
                width: 100,
                Cell: (row) => (row && row.value ? `${row.value.external_id}` : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.goods_movement_table.warehouse')}</span>,
                accessor: 'warehouse',
                width: 200,
                Cell: (row) => (row && row.value ? `${row.value.name} (${row.value.code})` : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.goods_movement_table.position')}</span>,
                accessor: 'warehouse_position',
                width: 100,
                Cell: (row) => (row && row.value && row.value.external_id ? row.value.external_id : '-'),
              },
            ]}
            data={goods || []}
            minRows={0}
            showPagination={false}
            defaultPageSize={30}
            noDataText=" "
            loading={isLoadingGoods}
            defaultSorted={[{ id: 'created_at', desc: true }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchPaginatedGoodsMovement} count={count} />
        </div>
      </div>
    );
  }
}
GoodsMovement.propTypes = {
  i18n: PropTypes.object,
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  order: PropTypes.object,
};

export default (withTranslation()(GoodsMovement));
