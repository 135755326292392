import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { defaultDateFormat, defaultISODateFormat } from 'shared/constants';
import userGreen from 'shared/Icons/workforce/user-green.png';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { IconPopup } from 'shared/Icons';
import { ContentLoader } from 'shared';
import './styles.scss';

import {
  getWorkforceWorkerDetails,
  getScoringWorkerData,
  getScoringWorkerEvaluations,
  getScoringEvaluations,
  getWorkforceWorkerWorkingPlaces,
  getWorkforceSalaries,
  getWorkforceInjuries,
  getWorkforceDocuments,
  getWorkforceAbsences,
  getWorkforceEmployments,
} from 'shared/Api';

import WarningDocumentsModal from './components/WarningDocumentsModal';
import WorkingInjuriesModal from './components/WorkingInjuriesModal';
import WorkingPlacesModal from './components/WorkingPlacesModal';
import WorkContractsModal from './components/WorkContractsModal';
import GeneralInfoTable from './components/GeneralInfoTable';
import DocumentsModal from './components/DocumentsModal';
import AbsencesModal from './components/AbsencesModal';
import SalariesModal from './components/SalariesModal';
import RatingsModal from './components/RatingsModal';

const IndustryLocationAdministrationWorkerDetails = ({
  match: { params: { workerId } },
  locationId,
  companyId,
  t,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [workerDetails, setWorkerDetails] = useState({});
  const [modal, setModal] = useState({
    isOpen: false,
    name: '',
  });

  const fetchScoringData = async () => {
    // ------------------- Personnel data ------------------- //
    // fetch Personnel data worker evaluations
    const scoringWorkerData = get(
      await getScoringWorkerData(`?company=${companyId}&worker=${workerId}&year=${moment().year()}&limit=9999&order_by=-updated_at&is_active=true`),
      'data.results',
      [],
    );
    const workerDataScore = scoringWorkerData?.reduce((total, item) => total + ((!item?.score || item?.score === '-' || !item?.definition?.is_active) ? 0 : item.score), 0);

    // ------------------- Company values ------------------- //
    // fetch Company values worker evaluations
    const scoringCompanyValuesWorkerEvaluations = get(
      await getScoringWorkerEvaluations(`?company=${companyId}&worker=${workerId}&limit=9999&order_by=-updated_at&is_active=true`),
      'data.results',
      [],
    );
    // fetch each Company values evaluations
    const scoringCompanyValuesEvaluationsPromises = scoringCompanyValuesWorkerEvaluations?.map(async (evaluation) =>
      get(await getScoringEvaluations(`?company=${companyId}&worker_evaluation=${evaluation.id}`), 'data.results', [])
    );
    const scoringEvaluations = await Promise.all(scoringCompanyValuesEvaluationsPromises);
    // flatten evaluations
    const concatenatedScoringEvaluations = [].concat(...scoringEvaluations);
    // filter evaluations by current year
    const filteredScoringEvaluations = concatenatedScoringEvaluations?.filter((evaluation) => evaluation?.created_at?.includes(moment().year()));
    const companyValuesScore = filteredScoringEvaluations?.reduce((total, item) => total + (!item?.option?.score?.score ? 0 : item.option.score.score), 0);

    // ------------------- Total score ------------------- //
    // calculate total score from; Company values and Personnel data
    const totalScore = workerDataScore + companyValuesScore;

    return totalScore;
  };

  const [infoCards, setInfoCards] = useState([
    {
      title: t('page_content.administration.department'),
      value: '-',
      accessor: (details) => details?.location_department?.name,
    },
    {
      title: t('page_content.administration.working_place'),
      value: '-',
      modalName: 'working_places',
      modalButtonText: t('page_content.administration.history'),
      fetchData: getWorkforceWorkerWorkingPlaces,
      urlFilters: `?company=${companyId}&worker=${workerId}&is_current=true&order_by=-start_date&limit=1`,
      accessor: (data) => get(data, 'data.results[0].working_place.name', '-'),
    },
    {
      title: t('page_content.administration.work_contracts'),
      value: '-',
      modalName: 'work_contracts',
      modalButtonText: t('page_content.administration.history'),
      fetchData: getWorkforceEmployments,
      urlFilters: `?company=${companyId}&location=${locationId}&worker=${workerId}&order_by=-created_at`,
      accessor: (data) => {
        const employments = get(data, 'data.results', []);
        const currentEmployment = employments?.find((employment) => !employment.end_working);
        return (
          <div>
            {
              currentEmployment ?
                currentEmployment?.employment_type === 'F' ?
                  `${t('page_content.human_resources.employments.typeOfEmploymentOptions.fixed_job')}. ` :
                  `${t('page_content.human_resources.employments.typeOfEmploymentOptions.permanent_job')}. `
                : ''
            }
            {
              currentEmployment ?
                currentEmployment?.fixed_term_length === 1 ? `${t('page_content.human_resources.employments.workingPeriodOptions.tryout_1m')}. ` :
                  currentEmployment?.fixed_term_length === 3 ? `${t('page_content.human_resources.employments.workingPeriodOptions.tryout_3m')}. ` :
                    currentEmployment?.fixed_term_length === 6 ? `${t('page_content.human_resources.employments.workingPeriodOptions.tryout_6m')}. ` : ''
                : ''
            }
          </div>
        );
      },
    },
    {
      title: t('page_content.administration.net_salary_excluding_transport_food'),
      value: '-',
      modalName: 'salaries',
      modalButtonText: t('page_content.administration.history'),
      fetchData: getWorkforceSalaries,
      urlFilters: `?company=${companyId}&limit=1&order_by=-date&worker=${workerId}`,
      accessor: (data) => (get(data, 'data.results[0].net_salary') ? `${get(data, 'data.results[0].net_salary', '-')} €` : '-'),
    },
    {
      title: `${t('page_content.administration.transport_allowance')} | ${t('page_content.administration.food_allowance')}`,
      value: '-',
      accessor: (details) => `${details?.transport_fee}€ | ${details?.food_fee}€`,
    },
    {
      title: t('page_content.administration.total_seniority'), // Need to calculate Total seniority + KFK seniority
      value: '-',
      accessor: (details) => (
        <span>
          {details?.seniority_years || '-'}{t('page_content.human_resources.workers.column_seniority_in_y')} {details?.seniority_months || '-'}{t('page_content.human_resources.workers.column_seniority_in_m')} {details?.seniority_days || '-'}{t('page_content.human_resources.workers.column_seniority_in_d')}
          <br />
          ({details?.seniority_date ? moment(details?.seniority_date).format(defaultDateFormat) : ''})
        </span>
      ),
    },
    {
      title: t('page_content.administration.total_injuries_last_3_years'),
      value: '-',
      modalName: 'injuries',
      modalButtonText: t('page_content.administration.history'),
      fetchData: getWorkforceInjuries,
      urlFilters: `?company=${companyId}&location=${locationId}&worker=${workerId}&limit=300&date_before=${moment().endOf('year').format('YYYY-MM-DD')}&date_after=${moment().subtract(2, 'years').startOf('year').format('YYYY-MM-DD')}`,
      accessor: (data) => data?.data?.count,
    },
    {
      title: t('page_content.administration.total_score'),
      value: '-',
      modalName: 'ratings',
      modalButtonText: t('page_content.administration.history'),
      fetchData: fetchScoringData,
      urlFilters: '',
      accessor: (data) => data,
    },
    {
      title: t('page_content.administration.certifications'),
      value: '-',
      fetchData: getWorkforceDocuments,
      urlFilters: `?company=${companyId}&worker=${workerId}&limit=999&order_by=-updated_at`,
      modalName: 'documents',
      modalButtonText: t('page_content.administration.history'),
      accessor: (data) => (data?.data?.results?.some((item) => item?.document_type?.name === 'Certifikati') ?
      <img src={checkMarkTrue} width="28px" height="28px" alt="" /> : <img src={checkMarkFalse} width="28px" height="28px" alt="" />),
    },
    {
      title: t('page_content.administration.warnings'),
      value: '-',
      fetchData: getWorkforceDocuments,
      urlFilters: `?company=${companyId}&worker=${workerId}&limit=999&order_by=-updated_at`,
      modalName: 'warningDocuments',
      modalButtonText: t('page_content.administration.history'),
      accessor: (data) => (data?.data?.results?.filter((item) => item?.document_type?.name === 'Opomena na radu')?.length || 0),
    },
    {
      title: t('page_content.administration.sick_leave.sick_leave_last_three_years'),
      value: '-',
      fetchData: getWorkforceAbsences,
      urlFilters: `?company=${companyId}&worker=${workerId}&limit=9999&order_by=-updated_at&absence_type=sick-leave&start_date=${moment().subtract(3, 'years').startOf('year').format(defaultISODateFormat)}&end_date=${moment().endOf('year').format(defaultISODateFormat)}`,
      modalName: 'sick_leave',
      modalButtonText: t('page_content.administration.history'),
      accessor: (data) => {
        const absences = get(data, 'data.results', []);
        return absences.reduce((acc, absence) => acc + (absence.number_of_days || 0), 0);
      },
    },
  ]);

  const fetchPersonnelDataRatings = async () => {
    if (workerId) {
      const res = await getScoringWorkerData(`?company=${companyId}&worker=${workerId}&year=${moment().year()}&limit=9999&order_by=-updated_at&is_active=true`);
      const results = get(res, 'data.results', []);
      const filteredResults = results?.filter((item) => ['company_working', 'all_working'].includes(item.definition.code) && Object.keys(item.humanized_score).length > 0);
      const all_working = filteredResults?.filter((item) => item.definition.code === 'all_working');
      const company_working = filteredResults?.filter((item) => item.definition.code === 'company_working');
      setWorkerDetails((prevState) => ({
        ...prevState,
        all_working: all_working?.[0]?.humanized_score || {},
        company_working: company_working?.[0]?.humanized_score || {},
      }));
      setLoading(false);
    } else setLoading(false);
  };

  const fetchWorkingPlaces = async () => {
    if (workerId) {
      const data = await getWorkforceWorkerWorkingPlaces(`?company=${companyId}&worker=${workerId}&is_current=true&order_by=-start_date&limit=9999`);
      const results = get(data, 'data.results', [])
      const extractedNames = results?.map((workingPlace) => ({
        ...workingPlace,
        workingPlaceName: workingPlace?.working_place?.name,
      }));
      setWorkerDetails((prevState) => ({
        ...prevState,
        worker_working_places: extractedNames,
      }));
      setLoading(false);
    } else setLoading(false);
  };

  const fetchWorkerDetails = () => {
    setLoading(true);

    const apiFilters = `?company=${companyId}&location=${locationId}`;
    getWorkforceWorkerDetails(workerId, apiFilters)
      .then((resp) => {
        const worker = get(resp, 'data', []);
        if (worker) {
          setWorkerDetails(worker);
          fetchPersonnelDataRatings();
          fetchWorkingPlaces();
        } else {
          setLoading(false);
        }
      });
  };

  const fetchInfoCardData = async () => {
    try {
      // Use Promise.allSettled to handle multiple asynchronous operations concurrently
      const updatedInfoCards = await Promise.allSettled(infoCards.map(async (card) => {
        if (card.fetchData) {
          try {
            // Fetch data using the provided fetchData function and urlFilters
            const data = await card.fetchData(card.urlFilters);
            // Use the accessor to extract the value from the fetched data
            const value = card.accessor ? card.accessor(data) : data.value;
            // Return the card with the fetched value
            return { ...card, value };
          } catch (error) {
            console.error(`Error fetching data for ${card.title}:`, error);
            return { ...card, value: 'Error' }; // Handle error case for individual card
          }
        } else if (card.accessor) {
          // Use the accessor to extract the value from workerDetails
          return { ...card, value: card.accessor(workerDetails) };
        }
        // Return the card as is if no fetchData or accessor function is provided
        return card;
      }));

      // Map the results to the infoCards array
      const finalInfoCards = updatedInfoCards.map((result, index) => {
        if (result.status === 'fulfilled') {
          return result.value;
        }
        // Log the error and return the card with an 'Error' value if the promise was rejected
        console.error(`Error processing card ${infoCards[index].title}:`, result.reason);
        return { ...infoCards[index], value: 'Error' }; // Handle error case for individual card
      });

      setInfoCards(finalInfoCards);
    } catch (error) {
      // Log any unexpected errors that occur during the process
      console.error('Error fetching info card data:', error);
    }
  };

  useEffect(() => {
    if (workerId) fetchWorkerDetails();
  }, [workerId]);

  useEffect(() => {
    if (workerDetails && Object.keys(workerDetails).length > 0) {
      fetchInfoCardData();
    }
  }, [workerDetails]);

  const renderModal = () => {
    const modalComponents = {
      working_places: WorkingPlacesModal,
      salaries: SalariesModal,
      injuries: WorkingInjuriesModal,
      ratings: RatingsModal,
      documents: DocumentsModal,
      sick_leave: AbsencesModal,
      warningDocuments: WarningDocumentsModal,
      work_contracts: WorkContractsModal,
    };

    const ModalComponent = modalComponents[modal.name];
    if (!ModalComponent) return null;

    return (
      <ModalComponent
        companyId={companyId}
        locationId={locationId}
        workerId={workerId}
        isOpen={modal?.isOpen}
        handleCloseModal={() => setModal({ isOpen: false, name: '' })}
        workerDetails={workerDetails}
      />
    );
  };

  const isBeforeSpecificTime = (targetTime) => {
    const currentTime = moment();
    const specificTime = moment(targetTime, 'HH:mm:ss');

    return currentTime.isBefore(specificTime);
  };

  return (
    <div className="administration-worker-details fullscreen">
      {isLoading ? <ContentLoader /> :
        <React.Fragment>
          <div className="administration-worker-details__breadcrumbs">
            <Link to={`/${companyId}/industry/location/${locationId}/administration/`} className="administration-worker-details__breadcrumbs__link">
              {t('page_content.administration.worker_list')}
            </Link>
            <p>{'>'}</p>
            <span>{` ${workerDetails?.name || ''} ${workerDetails?.last_name || ''}`}</span>
          </div>

          <div className="administration-worker-details__info-row">
            <div className="administration-worker-details__info-row__user-card">

              <div className="administration-worker-details__info-row__user-card__icon">
                <img src={workerDetails?.profile_picture?.full_size || userGreen} alt="" />
              </div>

              <div className="administration-worker-details__info-row__user-card__data">
                <div className="administration-worker-details__info-row__user-card__data__title">
                  <span>{workerDetails?.external_id || ''} | </span>
                  {` ${workerDetails?.name || ''} ${workerDetails?.last_name || ''}`}
                </div>
                <div className="administration-worker-details__info-row__user-card__data__description">
                  <span style={{ color: isBeforeSpecificTime(workerDetails?.department_shift?.end_time) ? 'green' : 'red' }}>
                  {t('page_content.administration.at_work')}
                  ({t('page_content.administration.until')} {workerDetails?.department_shift?.end_time ? moment(workerDetails?.department_shift?.end_time, 'HH:mm:ss').format('HH:mm') : '-'})</span>
                </div>
              </div>
            </div>

            {infoCards?.map((card) => (
              <div key={card.title} className="administration-worker-details__info-row__info-card">
                <div className="administration-worker-details__info-row__info-card-title">{card.title}</div>
                <div className="administration-worker-details__info-row__info-card-value">{card.value}</div>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => setModal({ isOpen: true, name: card?.modalName || '' })}
                  className="administration-worker-details__info-row__info-card-modal"
                >
                  {card?.modalButtonText && <IconPopup height="15px" width="15px" color="#555" />}
                  {card?.modalButtonText || ''}</div>
              </div>
            ))}
          </div>

          <GeneralInfoTable
            companyId={companyId}
            workerDetails={workerDetails}
          />

          {modal?.isOpen && renderModal()}
        </React.Fragment>}
    </div>
  );
};

IndustryLocationAdministrationWorkerDetails.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  // showWorkingInjuries: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      workerId: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    showWorkingInjuries: get(state, 'app.companyConfig.config.working_injuries', false),
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(IndustryLocationAdministrationWorkerDetails)));
