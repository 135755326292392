import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Modal, AssetPicker, MetricPicker } from 'shared';
import './style.scss';

import { getByURL } from 'shared/Api';

class AssetUtilizationsForm extends React.Component {
  constructor() {
    super();
    this.state = {
      assetUtilization: {
        name: '',
        asset: null,
        source_metric: null,
        target_metric: null,
        resolution: 1000,
        split_by_shift: 0,
        minimal_working_value: 1.000,
        stacked: 0,
        is_active: 1,
      },
      asset: null,
      source_metric: null,
      target_metric: null,
    };
  }

  componentDidMount() {
    const {
      assetUtilization,
      companyId,
    } = this.props;

    const state = this.state;

    if (assetUtilization !== null
      && typeof assetUtilization === 'object'
      && Object.prototype.hasOwnProperty.call(assetUtilization, 'id')
      && assetUtilization.id > 0) {
      getByURL(`/api/v1/industry/asset_utilizations/${assetUtilization.id}/?company=${companyId}`)
        .then(({ data }) => {
          state.assetUtilization = data;
          this.setState(state);
          if (data.asset > 0) {
            this.setAsset(data.asset);
            this.setMetrics({
              source_metric: data.source_metric,
              target_metric: data.target_metric,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  onSaveAssetUtilization = () => {
    const { assetUtilization } = this.state;
    if (!assetUtilization.name) {
      alert('Please enter a name.');
      return;
    }
    if (!assetUtilization.asset) {
      alert('Please select an asset.');
      return;
    }
    if (!assetUtilization.source_metric) {
      alert('Please select source metric.');
      return;
    }
    if (!assetUtilization.target_metric) {
      alert('Please select target metric.');
      return;
    }
    if (!assetUtilization.resolution) {
      alert('Please enter resolution.');
      return;
    }
    if (!assetUtilization.minimal_working_value) {
      alert('Please enter minimal working value.');
      return;
    }

    const { onSave } = this.props;
    onSave(assetUtilization);
  }

  onChange = (value, field) => {
    const {
      assetUtilization,
      asset,
    } = this.state;

    this.setState({
      asset,
      assetUtilization: {
        ...assetUtilization,
        [field]: value,
      },
    });
  }

  setMetrics = (params) => {
    const state = this.state;
    if (params !== null && typeof params === 'object' && Object.prototype.hasOwnProperty.call(params, 'source_metric') && params.source_metric !== null) {
      getByURL(`/api/v1/industry/metrics/${params.source_metric}/`)
        .then(({ data }) => {
          state.source_metric = data;
          state.assetUtilization.source_metric = params.source_metric;
          this.setState(state);
        });
    }
    if (params !== null && typeof params === 'object' && Object.prototype.hasOwnProperty.call(params, 'target_metric') && params.target_metric !== null) {
      getByURL(`/api/v1/industry/metrics/${params.target_metric}/`)
        .then(({ data }) => {
          state.target_metric = data;
          state.assetUtilization.target_metric = params.target_metric;
          this.setState(state);
        });
    }
  }

  setAsset = (assetId) => {
    const state = this.state;
    getByURL(`/api/v1/industry/assets/${assetId}/`)
      .then(({ data }) => {
        state.asset = data;
        state.assetUtilization.asset = data.id;
        this.setState(state);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  changeAsset = (assetSelected) => {
    const { asset } = this.state;
    if (assetSelected && assetSelected.id && (asset === null || assetSelected.id !== asset.id)) {
      this.setAsset(assetSelected.id);
      this.resetMetrics();
    }
  }

  resetMetrics = () => {
    const state = this.state;
    state.source_metric = null;
    state.target_metric = null;
    state.assetUtilization.source_metric = null;
    state.assetUtilization.target_metric = null;
    this.setState(state);
  }

  handleCancel = () => {
    const { closeModal } = this.props;
    this.setState({
      asset: null,
      source_metric: null,
      target_metric: null,
    }, () => {
      closeModal();
    });
  }

  render() {
    const {
      isOpen,
      isLoadingState,
      locationId,
      t,
    } = this.props;

    const {
      assetUtilization,
      asset,
      source_metric,
      target_metric,
    } = this.state;

    const modalTitle = (assetUtilization !== null && typeof assetUtilization === 'object' && Object.prototype.hasOwnProperty.call(assetUtilization, 'id') && assetUtilization.id > 0)
      ? t('settings.asset_utilization.asset_utilization_form.edit_title')
      : t('settings.asset_utilization.asset_utilization_form.add_title');

    return (
      <Modal
        isOpen={isOpen}
        handleSave={this.onSaveAssetUtilization}
        handleClose={this.handleCancel}
        title={modalTitle}
      >
        {
          isLoadingState &&
          <div
            className="reason-form-loader"
            style={{
              borderLeftColor: 'transparent',
            }}
          />
        }
        <div className="default-form">
          <table>
            <tbody>
              <tr>
                <td className="label">
                  {t('settings.asset_utilization.asset_utilization_form.name')}
                </td>
                <td className="value">
                  <input
                    type="text"
                    value={assetUtilization.name || ''}
                    onChange={(e) => { this.onChange(e.target.value, 'name'); }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t('settings.asset_utilization.asset_utilization_form.asset')}
                </td>
                <td>
                  <AssetPicker
                    changeAsset={(val) => this.changeAsset(val)}
                    resetMetric={() => { this.resetMetrics(); }}
                    asset={asset || null}
                    locationId={locationId}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t('settings.asset_utilization.asset_utilization_form.source_metric')}
                </td>
                <td>
                  {asset !== null && typeof asset === 'object' && Object.prototype.hasOwnProperty.call(asset, 'id') && asset.id > 0 &&
                    <MetricPicker
                      id="source"
                      changeMetric={(val) => {
                        this.setMetrics({ source_metric: val.id });
                        setTimeout(() => {
                          if (!val) {
                            return;
                          }
                          this.setMetrics({ source_metric: null });
                        }, 200);
                      }}
                      metric={source_metric}
                      asset={asset.id}
                    />}
                  {asset === null && <Select
                    options={[{ name: t('settings.asset_utilization.asset_utilization_form.source_metric_placeholder'), value: '' }]}
                    getOptionLabel={(asset_utilization) => asset_utilization.name}
                    getOptionValue={(asset_utilization) => asset_utilization.value}
                    placeholder={t('settings.asset_utilization.asset_utilization_form.source_metric_placeholder')}
                    onChange={() => { }}
                    value={t('settings.asset_utilization.asset_utilization_form.source_metric_placeholder')}
                    styles={selectModalStyles}
                  />}
                </td>
              </tr>
              <tr>
                <td>
                  {t('settings.asset_utilization.asset_utilization_form.target_metric')}
                </td>
                <td>
                  {asset !== null && typeof asset === 'object' && Object.prototype.hasOwnProperty.call(asset, 'id') && asset.id > 0 &&
                    <MetricPicker
                      id="target"
                      changeMetric={(val) => {
                        this.setMetrics({ target_metric: val.id });
                        setTimeout(() => {
                          if (!val) {
                            return;
                          }
                          this.setMetrics({ target_metric: null });
                        }, 200);
                      }}
                      metric={target_metric}
                      asset={asset.id}
                    />}
                  {asset === null && <Select
                    options={[{ name: t('settings.asset_utilization.asset_utilization_form.target_metric_placeholder'), value: '' }]}
                    getOptionLabel={(asset_utilization) => asset_utilization.name}
                    getOptionValue={(asset_utilization) => asset_utilization.value}
                    placeholder={t('settings.asset_utilization.asset_utilization_form.target_metric_placeholder')}
                    onChange={() => { }}
                    value={t('settings.asset_utilization.asset_utilization_form.target_metric_placeholder')}
                    styles={selectModalStyles}
                  />}
                </td>
              </tr>
              <tr>
                <td>
                  {t('settings.asset_utilization.asset_utilization_form.resolution')}
                </td>
                <td>
                  <input
                    type="number"
                    value={assetUtilization.resolution}
                    onChange={(e) => { this.onChange(+e.target.value, 'resolution'); }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t('settings.asset_utilization.asset_utilization_form.minimal_working_value')}
                </td>
                <td>
                  <input
                    type="number"
                    value={parseFloat(assetUtilization.minimal_working_value).toFixed(3)}
                    onChange={(e) => { this.onChange(+e.target.value, 'minimal_working_value'); }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t('settings.asset_utilization.asset_utilization_form.split_by_shift')}
                </td>
                <td>
                  <input
                    type="checkbox"
                    value="1"
                    name="split_by_shift"
                    checked={assetUtilization.split_by_shift}
                    onChange={(e) => { this.onChange(e.target.checked, 'split_by_shift'); }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t('settings.asset_utilization.asset_utilization_form.stacked')}
                </td>
                <td>
                  <input
                    type="checkbox"
                    value="1"
                    name="stacked"
                    checked={assetUtilization.stacked}
                    onChange={(e) => { this.onChange(e.target.checked, 'stacked'); }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t('settings.asset_utilization.asset_utilization_form.is_active')}
                </td>
                <td>
                  <input
                    type="checkbox"
                    name="is_active"
                    checked={assetUtilization.is_active}
                    onChange={(e) => { this.onChange(e.target.checked, 'is_active'); }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }
}

AssetUtilizationsForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  assetUtilization: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  isLoadingState: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AssetUtilizationsForm);
