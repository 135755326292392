import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { modalSizes } from 'shared/constants';
import { Modal, Table, TableButtons, Button, ContentLoader, ConfirmationModal } from 'shared';

import { postKFKProfileDefinitions, getByURL, deleteAndNotifyByURL, patchAndNotifyByURL } from 'shared/Api';

const KFKModal = ({
  t,
  isOpen,
  isReadOnly,
  handleClose,
  selectedCompanyTable,
}) => {
  const [currentView, setCurrentView] = useState('table');

  const [tableData, setTableData] = useState({
    isLoading: false,
    data: [],
    count: 0,
    next: null,
    previous: null,
    currentUrl: null,
  });

  const [selectedRow, setSelectedRow] = useState(null);
  const [isSelectedRowUpdated, setIsSelectedRowUpdated] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const [isImporting, setIsImporting] = useState(false);
  const [importData, setImportData] = useState(null);
  const [selectedSort, setSelectedSort] = useState('material');
  const [selectedAscDesc, setSelectedAscDesc] = useState('asc');

  const getTable = async (endpoint) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const tableFilter = `&order_by=${asc}${selectedSort}`;

    const results = await getByURL(`${endpoint}?limit=15${tableFilter}`);
    setTableData({
      isLoading: false,
      data: get(results, 'data.results', []),
      count: get(results, 'data.count', 0),
      next: get(results, 'data.next', null),
      previous: get(results, 'data.previous', null),
    });
  };

  useEffect(() => {
    if (selectedCompanyTable && selectedCompanyTable.endpoint) {
      getTable(selectedCompanyTable.endpoint);
    }
  }, [selectedCompanyTable, selectedAscDesc, selectedSort]);

  const onChange = (value, field) => {
    setSelectedRow({
      ...selectedRow,
      [field]: value,
    });
    setIsSelectedRowUpdated(true);
  };

  const onChangeImport = (value, field) => {
    setImportData({
      ...importData,
      [field]: value,
    });
  };

  const onChangeAttachment = (e) => {
    const file = e.target.files;
    if (file) {
      setImportData({
        ...importData,
        file: file && file[0] ? file[0] : null,
      });
    }
  };

  const fetchPaginatedTable = async (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const results = await getByURL(url);
    setTableData({
      isLoading: false,
      data: get(results, 'data.results', []),
      count: get(results, 'data.count', 0),
      next: get(results, 'data.next', null),
      previous: get(results, 'data.previous', null),
      currentUrl: url,
    });
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setCurrentView('table');
    setIsSelectedRowUpdated(false);
    handleClose();
  };

  const handleEditItem = (original) => {
    setSelectedRow(original);
    setCurrentView('form');
  };

  const handleSorting = (sortData) => {
    const column = sortData.id;
    setSelectedSort(column);
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
  };

  const handleShowConfirmationModal = (original) => {
    setDeleteData(original);
    setShowConfirmationDialog(true);
  };

  const handleDeleteItem = async () => {
    const row = deleteData;
    if (row?.id && selectedCompanyTable?.endpoint) {
      setTableData((prevState) => ({ ...prevState, isLoading: true, data: [] }));
      setCurrentView('table');
      await deleteAndNotifyByURL(`${selectedCompanyTable.endpoint}${row.id}/`);
      getTable(tableData?.currentUrl || selectedCompanyTable?.endpoint);
      setShowConfirmationDialog(false);
    }
  };

  const handleBack = () => {
    setSelectedRow(null);
    setCurrentView('table');
    setIsSelectedRowUpdated(false);
    setImportData(null);
  };

  const handleImportButton = () => {
    setSelectedRow(null);
    setCurrentView('import');
    setIsSelectedRowUpdated(false);
  };

  const handleImportData = async () => {
    setIsImporting(true);
    if (importData && importData.file) {
      const formData = new FormData();
      formData.append('file', importData.file);
      formData.append('delete_before_import', importData.delete_before_import || false);
      await postKFKProfileDefinitions(formData);
      setIsImporting(false);
      getTable(selectedCompanyTable.endpoint);
      handleBack();
    }
  };

  const updateItem = async () => {
    if (selectedCompanyTable?.endpoint) {
      setTableData((prevState) => ({ ...prevState, isLoading: true, data: [] }));
      setCurrentView('table');
      await patchAndNotifyByURL(`${selectedCompanyTable.endpoint}${selectedRow.id}/`, selectedRow);
      handleBack();
      getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
    }
  };

  const renderTable = () => {
    return (
      <div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}>
          <Button
            type="dark-blue"
            onClick={handleImportButton}
          >
            {t('settings.custom_data.import_data')}
          </Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('settings.custom_data.material')}</span>,
              accessor: 'material',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.article_number')}</span>,
              accessor: 'article_number',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.supplier_code')}</span>,
              accessor: 'supplier_code',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.kg_m')}</span>,
              accessor: 'kg_m',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.alloy')}</span>,
              accessor: 'alloy',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.finishing')}</span>,
              accessor: 'finishing',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.quantity_per_unit')}</span>,
              accessor: 'quantity_per_unit',
              Cell: (row) => (get(row, 'value', '-')),
              width: 80,
              style: {
                cursor: 'default',
              },
            },
          ]}
          data={tableData.data || []}
          loading={tableData.isLoading}
          defaultPageSize={15}
          minRows={0}
          enableEdit
          enableDelete
          onEdit={(original) => handleEditItem(original)}
          onDelete={(original) => handleShowConfirmationModal(original)}
          isActionsDisabled={isReadOnly}
          defaultSorted={[{ id: 'material', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons
          previous={tableData.previous}
          next={tableData.next}
          fetchFunction={fetchPaginatedTable}
          count={tableData.count}
        />
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="default-form">
        <Button onClick={handleBack}>{t('settings.custom_data.back')}</Button>
        <div className="custom_data_modal_container">
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.material')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'material', '')} onChange={(e) => onChange(e.target.value, 'material')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.article_number')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'article_number', '')} onChange={(e) => onChange(e.target.value, 'article_number')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.supplier_code')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'supplier_code', false)} onChange={(e) => onChange(e.target.value, 'supplier_code')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.kg_m')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'kg_m', '')} onChange={(e) => onChange(e.target.value, 'kg_m')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.alloy')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'alloy', '')} onChange={(e) => onChange(e.target.value, 'alloy')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.finishing')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'finishing', '')} onChange={(e) => onChange(e.target.value, 'finishing')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.quantity_per_unit')}:</div>
            <div className="right_input">
              <input type="number" value={get(selectedRow, 'quantity_per_unit', '')} onChange={(e) => onChange(e.target.value, 'quantity_per_unit')} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderImport = () => {
    if (isImporting) {
      return <ContentLoader />;
    }
    return (
      <div className="default-form">
        <Button onClick={handleBack}>{t('settings.custom_data.back')}</Button>
        <table>
          <tbody>
            <tr>
              <td className="label">
                {t('settings.custom_data.file')}:
              </td>
              <td className="value">
                <input
                  style={{ border: 'none' }}
                  type="file"
                  onChange={onChangeAttachment}
                  multiple={false}
                />
              </td>
            </tr>

            <tr>
              <td className="label">
                {t('settings.custom_data.delete_all_data')}:
              </td>
              <td className="value">
                <input type="checkbox" checked={get(importData, 'delete_before_import', false)} onChange={(e) => onChangeImport(e.target.checked, 'delete_before_import')} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Modal
      size={modalSizes.full}
      handleClose={handleCloseModal}
      handleSave={currentView === 'form' ? updateItem : currentView === 'import' ? handleImportData : null}
      disableSave={isSelectedRowUpdated ? false : !((importData && importData.file))}
      isOpen={isOpen}
      title={get(selectedCompanyTable, 'name', '')}
    >
      {currentView === 'table' && renderTable()}
      {currentView === 'form' && renderForm()}
      {currentView === 'import' && renderImport()}
      <ConfirmationModal
        itemName={`${get(deleteData, 'material')} | ${get(deleteData, 'article_number')}`}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleDeleteItem}
        type="warning"
      />
    </Modal>
  );
};

KFKModal.propTypes = {
  selectedCompanyTable: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

export default withTranslation()(KFKModal);
