import React from 'react';
import PropTypes from 'prop-types';

import CompanyBudgetTable from './CompanyBudgetTable';
import DepartmentBudgetTable from './DepartmentBudgetTable';
import WorkingPlaceBudgetTable from './WorkingPlaceBudgetTable';

import '../styles.scss';

const ControlPanel = ({ companyId, locationId }) => {
  return (
    <div className="control-panel">
      <CompanyBudgetTable
        locationId={locationId}
        companyId={companyId}
      />
      <DepartmentBudgetTable
        locationId={locationId}
        companyId={companyId}
      />
      <WorkingPlaceBudgetTable
        locationId={locationId}
        companyId={companyId}
      />
    </div>
  );
};

ControlPanel.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ControlPanel;
