// Define actions
export const ACTIONS = {
  VIEW: 'view',
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  EXPORT: 'export',
  REDIRECT: 'redirect',
  OPEN_MODAL: 'openModal',
};

// Helper function to create action arrays
export const createActions = (...actions) => actions;

// Define common action group combinations
export const ACTION_GROUPS = {
  // No permissions
  NONE: createActions(),

  // All possible permissions
  ALL: createActions(
    ACTIONS.VIEW,
    ACTIONS.ADD,
    ACTIONS.EDIT,
    ACTIONS.DELETE,
    ACTIONS.EXPORT,
    ACTIONS.REDIRECT,
    ACTIONS.OPEN_MODAL,
  ),

  // View only permissions
  READ_ONLY: createActions(
    ACTIONS.VIEW,
  ),
};
