import React, { useState, useEffect, useRef } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { IconSearch } from 'shared/Icons';
import { modalSizes } from 'shared/constants';
import { Modal, Table, TableButtons, Button, ConfirmationModal } from 'shared';
import '../../style.scss';

import { getByURL, postAndNotifyByURL, patchAndNotifyByURL, deleteAndNotifyByURL } from 'shared/Api';

const IverpanTapesModal = ({
  t,
  isOpen,
  isReadOnly,
  handleClose,
  selectedCompanyTable,
}) => {
  const debounceTimeoutRef = useRef(null);
  const [query, setQuery] = useState(null);
  const [currentView, setCurrentView] = useState('table');
  const [selectedSort, setSelectedSort] = useState('traka');
  const [selectedAscDesc, setSelectedAscDesc] = useState('asc');
  const [deleteData, setDeleteData] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const [tableData, setTableData] = useState({
    isLoading: false,
    data: [],
    count: 0,
    next: null,
    previous: null,
    currentUrl: null,
  });

  const [form, setForm] = useState({
    traka: '',
    program: '',
  });

  const getTable = async (endpoint) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let apiFilters = '?limit=15';
    if (query) {
      apiFilters += `&traka=${query}`;
    }

    if (!apiFilters.includes('order_by')) {
      const asc = selectedAscDesc === 'desc' ? '-' : '';
      apiFilters += `&order_by=${asc}${selectedSort}`;
    }

    const results = await getByURL(`${endpoint}${apiFilters}`);
    setTableData({
      isLoading: false,
      data: get(results, 'data.results', []),
      count: get(results, 'data.count', 0),
      next: get(results, 'data.next', null),
      previous: get(results, 'data.previous', null),
      currentUrl: tableData.currentUrl || endpoint,
    });
  };

  useEffect(() => {
    if (query !== null) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        if (selectedCompanyTable?.endpoint) {
          getTable(selectedCompanyTable.endpoint);
        }
      }, 300);
    }
  }, [query]);

  useEffect(() => {
    if (selectedCompanyTable?.endpoint) {
      getTable(selectedCompanyTable.endpoint);
    }
  }, [selectedCompanyTable, selectedAscDesc, selectedSort]);

  const onFormChange = (value, key) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const fetchPaginatedTable = async (url) => {
    setTableData((prevState) => ({ ...prevState, isLoading: true }));

    const results = await getByURL(url);
    setTableData({
      isLoading: false,
      data: get(results, 'data.results', []),
      count: get(results, 'data.count'),
      next: get(results, 'data.next', null),
      previous: get(results, 'data.previous', null),
      currentUrl: url,
    });
  };

  const handleCloseModal = () => {
    setForm({ traka: null, program: null });
    setCurrentView('table');
    handleClose();
  };

  const handleEditItem = (original) => {
    setForm({
      id: get(original, 'id', null),
      traka: get(original, 'traka', ''),
      program: get(original, 'program', ''),
    });
    setCurrentView('form');
  };

  const handleShowConfirmationDialog = (original) => {
    setShowConfirmationDialog(true);
    setDeleteData(original);
  };

  const handleDeleteItem = async () => {
    const row = deleteData;
    if (row?.id && selectedCompanyTable?.endpoint) {
      setTableData((prevState) => ({ ...prevState, isLoading: true, data: [] }));
      setCurrentView('table');
      await deleteAndNotifyByURL(`${selectedCompanyTable.endpoint}${row.id}/`);
      getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
      setShowConfirmationDialog(false);
    }
  };

  const handleBack = () => {
    setForm({ traka: null, program: null });
    setCurrentView('table');
  };

  const updateItem = async () => {
    if (selectedCompanyTable?.endpoint) {
      setTableData((prevState) => ({
        ...prevState,
        isLoading: true,
        data: [],
      }));
      setCurrentView('table');
      if (form?.id) {
        await patchAndNotifyByURL(`${selectedCompanyTable.endpoint}${form.id}/`, form);
        handleBack();
        getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
      } else {
        await postAndNotifyByURL(`${selectedCompanyTable.endpoint}`, form);
        handleBack();
        getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
      }
    }
  };

  const handleSorting = (sortData) => {
    const column = sortData.id;
    setSelectedSort(column);
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
  };

  const handleAddEntryButton = () => {
    setForm({ traka: null, program: null });
    setCurrentView('form');
  };

  const handleClearSearch = () => {
    setQuery('');
  };

  const onFilterChange = (val) => {
    setQuery(val);
  };

  const renderTable = () => {
    return (
      <div style={{ paddingBottom: '30px' }}>
        <div className="custom_data_filters inline_style">
          <div className="custom_data_filters_input">
            <input
              onChange={(e) => onFilterChange(e.target.value)}
              placeholder={t('settings.custom_data.search_by_tape')}
              value={query || ''}
            />
            {query && <button
              onClick={handleClearSearch}
            >&times;</button>}
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <Button
            type="add"
            style={{ margin: '10px' }}
            onClick={handleAddEntryButton}
          >
            {t('settings.custom_data.add_entry')}
          </Button>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('settings.custom_data.tape')}</span>,
              accessor: 'traka',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.program')}</span>,
              accessor: 'program',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
          ]}
          data={tableData.data || []}
          loading={tableData.isLoading}
          defaultPageSize={15}
          minRows={0}
          enableEdit
          enableDelete
          onEdit={(original) => handleEditItem(original)}
          onDelete={(original) => handleShowConfirmationDialog(original)}
          isActionsDisabled={isReadOnly}
          defaultSorted={[{ id: 'traka', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons
          previous={tableData.previous}
          next={tableData.next}
          fetchFunction={fetchPaginatedTable}
          count={tableData.count}
        />
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="default-form">
        <Button onClick={handleBack}>{t('settings.custom_data.back')}</Button>

        <div className="custom_data_modal_container">
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.tape')}:</div>
            <div className="right_input">
              <input type="text" value={form.traka || ''} onChange={(e) => onFormChange(e.target.value, 'traka')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.program')}:</div>
            <div className="right_input">
              <input type="text" value={form.program || ''} onChange={(e) => onFormChange(e.target.value, 'program')} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      size={modalSizes.full}
      handleClose={handleCloseModal}
      handleSave={currentView === 'form' ? updateItem : null}
      isOpen={isOpen}
      title={get(selectedCompanyTable, 'name', '')}
    >
      {currentView === 'table' && renderTable()}
      {currentView === 'form' && renderForm()}
      <ConfirmationModal
        itemName={`${get(deleteData, 'traka')} | ${get(deleteData, 'program')}`}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleDeleteItem}
        type="warning"
      />
    </Modal>
  );
};

IverpanTapesModal.propTypes = {
  selectedCompanyTable: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

export default withTranslation()(IverpanTapesModal);
