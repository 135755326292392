import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { numberSeparatorFormat } from 'industry/helpers';
import { Modal } from 'shared';

import { getBatchData } from '../../../actions';

class EditBatchAndWarehouseModal extends Component {
  constructor(props) {
    super(props);
    const isStavke = !!(props?.selectedBookingItem?.order_item?.group_name && props?.selectedBookingItem?.order_item?.group_name === 'Stavke');
    const isScrap = props?.selectedBookingItem?.is_scrap || false;

    this.state = {
      selectedWarehouse: props?.selectedBookingItem?.warehouse || '',
      selectedBatch: !(isStavke || isScrap) && props?.selectedBookingItem?.batch && props?.selectedBookingItem?.warehouse ? `${props.selectedBookingItem.batch}-${props.selectedBookingItem.warehouse}` : props.selectedBookingItem.batch || '',
      formError: false,
      batchList: [],
    };
  }

  componentDidMount() {
    const { companyId, selectedBookingItem, order } = this.props;

    let material_code = selectedBookingItem?.material_code || '';
    const isStavke = !!(selectedBookingItem?.order_item?.group_name && selectedBookingItem?.order_item?.group_name === 'Stavke');

    if (!isStavke) {
      if (!material_code) {
        const name = selectedBookingItem?.order_item?.name || '';
        const words = name.split(' ');
        const firstWord = words[0];

        material_code = firstWord;
      }

      let batchFilters = '';
      if (order && order.id) {
        batchFilters += `&order=${order.id}`;
      }

      getBatchData(companyId, material_code, batchFilters)
        .then((res) => {
          const { selectedBatch } = this.state;
          const batchList = res?.data?.results || [];
          const lastDashIndex = selectedBatch.lastIndexOf('-');
          const batchName = selectedBatch.substring(0, lastDashIndex);
          const warehouseCode = selectedBatch.substring(lastDashIndex + 1);
          const isBatchExists = batchList.some((batch) => batch.batch === batchName && batch?.warehouse?.code === warehouseCode);

          this.setState({
            batchList,
            selectedBatch: isBatchExists ? selectedBatch : '', // Keep the selection if it exists, otherwise reset it.
          });
        });
    }
  }

  onChangeWarehouse = (e) => {
    this.setState({
      selectedWarehouse: e.target.value || null,
    });
  }

  onChangeBatch = (e) => {
    const value = e.target.value;
    const lastDashIndex = value.lastIndexOf('-');
    const batchName = value.substring(0, lastDashIndex);
    const warehouseCode = value.substring(lastDashIndex + 1);

    this.setState({
      selectedBatch: batchName || '',
      selectedWarehouse: warehouseCode,
    });
  }

  onChangeBatchTextInput = (e) => {
    const value = e.target.value;
    this.setState({
      selectedBatch: value,
    });
  }

  onSaveBatchOrWarehouse = (isStavke) => {
    const { selectedBatch, selectedWarehouse } = this.state;
    const { onSaveBatchOrWarehouse, selectedBookingItem } = this.props;

    if ((!selectedBatch || !selectedWarehouse) && !isStavke) {
      this.setState({
        formError: true,
      });
      return '';
    }

    const orderItemBookingId = selectedBookingItem?.id || null;

    const isScrap = selectedBookingItem?.is_scrap || false;

    let data = {};
    if ((!isStavke || !isScrap) && orderItemBookingId && selectedBatch?.includes('-')) {
      const lastDashIndex = selectedBatch.lastIndexOf('-');
      const batchName = selectedBatch.substring(0, lastDashIndex);
      const warehouseCode = selectedBatch.substring(lastDashIndex + 1);
      data = {
        batch: batchName,
        warehouse: warehouseCode,
      };
    } else {
      data = {
        batch: selectedBatch || '',
        warehouse: selectedWarehouse,
      };
    }

    onSaveBatchOrWarehouse(orderItemBookingId, data);

    this.setState({
      selectedWarehouse: '',
      selectedBatch: '',
    });
  }

  getCellValue = (value, unit_code) => {
    const { i18n } = this.props;

    const unitCode = unit_code || null;
    const cellValue = value || null;

    if (unitCode === 'PCE') {
      return numberSeparatorFormat(i18n.language, cellValue, 0, 0, true);
    }
    return numberSeparatorFormat(i18n.language, cellValue, 3, 3, true);
  }

  closeEditBatchAndWarehouseModal = () => {
    const { closeEditBatchAndWarehouseModal } = this.props;

    this.setState({
      selectedWarehouse: '',
      selectedBatch: '',
    }, closeEditBatchAndWarehouseModal());
  }

  render() {
    const { selectedWarehouse, selectedBatch, formError, batchList } = this.state;

    const {
      t,
      isEditBatchAndWarehouseModalOpen,
      selectedBookingItem,
      listOfWarehouses,
      selectedBookingItemRow,
      enable_book_receipt,
    } = this.props;

    const isStavke = !!(selectedBookingItem?.order_item?.group_name && selectedBookingItem?.order_item?.group_name === 'Stavke');
    const isScrap = selectedBookingItem?.is_scrap || false;

    return (
      <Modal
        isOpen={isEditBatchAndWarehouseModalOpen}
        handleSave={() => this.onSaveBatchOrWarehouse(isStavke)}
        handleClose={this.closeEditBatchAndWarehouseModal}
        error={formError && !isStavke ? t('page_content.orders.order_details.bookings_tab.warehouse_form_error') : ''}
        title={selectedBookingItem?.order_item?.name || ''}
      >
        <div className="default-form">
          <table>
            <tbody>
              <tr>
                <td className="label">
                  {t('page_content.orders.order_details.bookings_tab.table_column_batch')}
                </td>
                <td className="value">
                  {
                    isStavke || isScrap ?
                      <input
                        style={{ width: '75%' }}
                        onChange={(e) => this.onChangeBatchTextInput(e)}
                        type="text"
                        value={selectedBatch}
                      /> :
                      <select
                        onChange={(e) => this.onChangeBatch(e)}
                        value={selectedBatch || ''}
                        className="filters_warehouse_dropdown"
                      >
                        <option value="" disabled>-</option>
                        {batchList.map((batch) => (
                          <option key={`${batch?.batch}-${batch?.warehouse?.code}`} value={`${batch?.batch}-${batch?.warehouse?.code}`}>
                            {batch?.batch} ({this.getCellValue(batch?.quantity, batch?.unit_code)} {batch?.unit_code})({batch?.warehouse?.name})
                          </option>
                        ))}
                      </select>
                  }
                </td>
              </tr>

              {
              (!isStavke || (selectedBookingItemRow.index === 0 && enable_book_receipt)) &&
                <tr>
                  <td className="label">
                    {t('page_content.orders.order_details.bookings_tab.table_column_warehouse')}
                  </td>
                  <td className="value">
                    <select
                      onChange={(e) => this.onChangeWarehouse(e)}
                      value={selectedWarehouse}
                      className="filters_warehouse_dropdown"
                      disabled={!(isStavke || isScrap)}
                    >
                      <option value="" disabled>-</option>
                      {listOfWarehouses.map((warehouse) => (
                        <option key={warehouse?.id} value={warehouse?.code}>
                          {warehouse?.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }
}

EditBatchAndWarehouseModal.propTypes = {
  i18n: PropTypes.object,
  t: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  enable_book_receipt: PropTypes.bool,
  selectedBookingItemRow: PropTypes.object,
  listOfWarehouses: PropTypes.array.isRequired,
  selectedBookingItem: PropTypes.object.isRequired,
  onSaveBatchOrWarehouse: PropTypes.func.isRequired,
  closeEditBatchAndWarehouseModal: PropTypes.func.isRequired,
  isEditBatchAndWarehouseModalOpen: PropTypes.bool.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default withTranslation()(EditBatchAndWarehouseModal);
