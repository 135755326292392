import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Modal, ContentLoader, ReactDatePicker } from 'shared';

import { modalSizes } from 'shared/constants';
import { selectModalStyles } from 'styles/modules/reactSelect';

import '../styles.scss';
import { addWorkerException, editWorkerException, getDepartmentShifts, getWorkerTimeSchedule } from '../../../actions';

const WorkerExceptionModal = ({
  t,
  isOpen,
  dayTypes,
  companyId,
  initialValues = {},
  workerDetails,
  handleCloseModal,
  closeModalAndRefetch,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingShifts, setIsLoadingShifts] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [shifts, setShifts] = useState([]);

  const fetchAllShifts = () => {
    setIsLoadingShifts(true);

    let apiFilters = '&limit=9999&order_by=department__name';

    if (workerDetails?.location_department) {
      apiFilters += `&department=${typeof workerDetails?.location_department === 'object'
        ? workerDetails?.location_department?.id
        : workerDetails?.location_department
      }`;
    }

    getDepartmentShifts(companyId, apiFilters)
      .then((res) => {
        setShifts(get(res, 'data.results', []));
        setIsLoadingShifts(false);
        setIsLoading(false);
      })
      .catch(() => {
        setShifts([]);
        setIsLoadingShifts(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAllShifts();
  }, []);

  const onChange = (key, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const fetchWorkerSchedules = () => {
    const date = formValues?.date ? moment(formValues?.date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    const apiFilters = `&worker=${workerDetails?.id}&from_date=${date}&to_date=${date}`;

    getWorkerTimeSchedule(companyId, apiFilters)
      .then((res) => {
        onChange('workerschedule_id', res?.data[date]?.workerschedule_id);
      })
      .catch(() => {
        onChange('workerschedule_id', null);
      });
  };

  useEffect(() => {
    fetchWorkerSchedules();
  }, [formValues?.date]);

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      worker: workerDetails?.id,
      company: companyId,
      date: formValues?.date ? moment(formValues.date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      day_type: typeof formValues?.day_type === 'object' ? formValues?.day_type?.id : formValues?.day_type,
      department: typeof workerDetails?.location_department === 'object' ? workerDetails?.location_department?.id : workerDetails?.location_department,
    };

    if (typeof formValues?.day_type === 'object' ? formValues?.day_type?.id : formValues?.day_type === 'working') {
      payload.department_shift = typeof formValues?.department_shift === 'object' ? formValues?.department_shift?.id : formValues?.department_shift;
    }

    if (formValues?.workerschedule_id) {
      await editWorkerException(formValues?.workerschedule_id, companyId, payload);
    } else {
      await addWorkerException(companyId, payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  return (
    <Modal
      isOpen={isOpen}
      handleSave={handleSave}
      disableSave={!formValues?.day_type || (formValues?.day_type === 'working' && !formValues?.department_shift)}
      size={modalSizes.medium}
      handleClose={handleCloseModal}
    >
      {isLoading ? (
        <ContentLoader />
      ) : (
        <div className="worker_exception_modal_container">
          <div className="modal_row">
            <div className="left_text">
              {t('page_content.human_resources.workers.date')}:
            </div>
            <div className="right_datePicker">
              <ReactDatePicker
                selected={formValues?.date ? moment(formValues?.date).toDate() : moment().toDate()}
                onChange={(e) => onChange('date', e)}
              />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">
              {t('page_content.human_resources.workers.day_type')}*:
            </div>
            <div className="right_select">
              <div style={{ width: '100%' }}>
                <Select
                  options={dayTypes}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isClearable
                  menuPosition="fixed"
                  onChange={(val) => onChange('day_type', val)}
                  value={dayTypes.find((val) => val.id === (formValues?.day_type?.id || formValues?.day_type))}
                  styles={selectModalStyles}
                  isLoading={isLoadingShifts}
                  loadingMessage={() => <ContentLoader />}
                />
              </div>
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">
              {`${t('page_content.human_resources.workers.shift')}${formValues?.day_type === 'working' ? '*' : ''}:`}
            </div>
            <div className="right_select">
              <div style={{ width: '100%' }}>
                <Select
                  options={shifts}
                  getOptionLabel={(option) => `${option?.name} (${moment(option?.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(option?.end_time, 'HH:mm:ss').format('HH:mm')})`}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isClearable
                  menuPosition="fixed"
                  onChange={(val) => onChange('department_shift', val)}
                  value={formValues?.department_shift ? formValues?.department_shift : shifts.find((val) => val.id === (formValues?.shiftId))}
                  styles={selectModalStyles}
                  isDisabled={!(typeof formValues?.day_type === 'object' ? formValues?.day_type?.id === 'working' : formValues?.day_type === 'working')}
                  isLoading={isLoadingShifts}
                  loadingMessage={() => <ContentLoader />}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

WorkerExceptionModal.propTypes = {
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  dayTypes: PropTypes.array,
  companyId: PropTypes.number.isRequired,
  workerDetails: PropTypes.object,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  initialValues: PropTypes.object,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(WorkerExceptionModal));
