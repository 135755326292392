import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import Select from 'react-select';
import PropTypes from 'prop-types';


import { IconComment } from 'shared/Icons';
import { Button, ReactDatePicker } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';

const AddRecord = ({ t, handleChangeRecord, handleCloseModal, handleSave, lines, data, orders, defaultSelectedRecord, filteredRecords, selectedRow }) => {
  const [stateData, setStateData] = useState([]);
  const [selectedLine, setSelectedLine] = useState(null);
  const [saveEnabled, setSaveEnabled] = useState(true);
  const [requiredFields, setRequiredFields] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [workOrder, setWorkOrder] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(defaultSelectedRecord);
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [initialData, setInitialData] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [newEntries, setNewEntries] = useState([]);

  useEffect(() => {
    setSelectedRecord(defaultSelectedRecord);
  }, [defaultSelectedRecord]);

  useEffect(() => {
    const values = [];
    setStateData([]);
    setSelectedLine(null);
    setSelectedOrder(null);
    if (data && data.entries.length) {
      data.entries.forEach((val) => {
        if (val.required) {
          values.push(val.id);
        }
      });
    }
    if (values.length) {
      setSaveEnabled(false);
    }
    setRequiredFields(values);
  }, [data]);

  useEffect(() => {
    let allRequired = true;
    requiredFields.forEach((d) => {
      const found = stateData.find((f) => f.id === d);
      if (found) {
        if ((found.type === 'text' && !found.dataValues.value_text) || (found.type === 'decimal' && !found.dataValues.value_decimal) || (found.type === 'numeric' && !found.dataValues.value_numeric)) {
          allRequired = false;
        }
      }
      if (!found) {
        allRequired = false;
      }
    });
    setSaveEnabled(allRequired);
  }, [stateData]);

  const handleSaveLog = () => {
    if (!saveEnabled) {
      return;
    }
    const logEntries = stateData.filter((d) => !d.dataValues.hasOwnProperty('id') || d.dataValues.hasOwnProperty('update_id')).map((d) => d.dataValues);

    const commonProps = {
      log_entries: logEntries,
      line: selectedLine,
      production_record: data.id,
      order: selectedOrder,
    };
    if (selectedRow) {
      handleSave({ ...commonProps }, selectedRow.id);
    } else if (selectedDateTime) {
      handleSave({ ...commonProps, time_created_override: selectedDateTime });
    } else {
      handleSave(commonProps);
    }
    setStateData([]);
    setSelectedLine(null);
    setSelectedOrder(null);
    setSelectedDateTime(new Date());
  };

  const handleSelectLine = (value) => {
    setSelectedLine(value.id);
  };

  const handleSelectRecord = async (value) => {
    handleChangeRecord(value.id);
    setSelectedRecord(value.id);
  };

  const handleSelectOrder = (value) => {
    if (value) {
      setWorkOrder(true);
    }
    setSelectedOrder(value.id);
  };

  const handleChangeDateTime = (value) => {
    setSelectedDateTime(value);
  };

  const handleChange = (e, val) => {
    let value;
    let inputId;
    let inputValue;
    let keyValue;

    if (val.entry_type !== 'time') {
      e.stopPropagation();
      inputId = parseInt(e.target.getAttribute('keyid'), 10);
      inputValue = e.target.value;
      keyValue = val.entry_type;
    }

    if (val.entry_type === 'time') {
      inputId = parseInt(val.id, 10);
      inputValue = e;
      keyValue = val.entry_type;
    }

    if (val.entry_type === 'numeric') {
      value = parseInt(inputValue, 10);
    } else if (val.entry_type === 'decimal') {
      value = parseFloat(inputValue);
    } else {
      value = inputValue;
    }

    if (val.entry_type === 'bool') {
      value = e.target.checked;
    }

    const dataFound = stateData.findIndex((d) => d.id === inputId);
    if (dataFound !== -1) {
      if (val.entry_type === 'bool') {
        setStateData((prevState) => {
          const newArray = prevState.map((item, index) => {
            if (index === dataFound) {
              return {
                ...item,
                dataValues: {
                  [`value_${keyValue}`]: value,
                  production_record_entry: val.id,
                },
                id: val.id,
                type: 'bool',
              };
            }
            return item;
          });
          return newArray;
        });
      } else {
        setStateData((prevState) => {
          const newArray = prevState.map((item, index) => {
            if (index === dataFound) {
              return {
                ...item,
                dataValues: {
                  [`value_${keyValue}`]: value,
                  production_record_entry: val.id,
                  value_bool: false,
                },
                id: val.id,
                type: val.entry_type,
              };
            }
            return item;
          });
          return newArray;
        });
      }
    } else if (val.entry_type === 'bool') {
      setStateData((prevState) => [...prevState, {
        dataValues: {
          [`value_${keyValue}`]: value,
          production_record_entry: val.id,
        },
        id: val.id,
        type: 'bool',
      }]);
    } else {
      setStateData((prevState) => [...prevState, {
        dataValues: {
          value_bool: false,
          [`value_${keyValue}`]: value,
          production_record_entry: val.id,
        },
        id: val.id,
        type: val.entry_type,
      }]);
    }
  };

  const handleInputChange = (val, index, key, value) => {
    const entryId = val?.id;
    const dataFound = stateData?.find((d) => d.id === entryId);

    if (dataFound) {
      const jsonData = dataFound?.dataValues?.value_json || [];

      if (index !== -1) {
        const newJsonValue = [...jsonData];
        newJsonValue[index][key] = value;
        setStateData((prevState) => {
          const newArray = prevState.map((item) => {
            if (item.id === entryId) {
              const dataValues = {
                ...item.dataValues,
                value_json: newJsonValue,
                value_bool: false,
                production_record_entry: val.id,
              };
            
              if (dataFound?.dataValues?.id) {
                dataValues.update_id = dataFound.dataValues.id;
              }
            
              return {
                ...item,
                dataValues,
                type: val.entry_type,
              };
            }
            return item;
          });
          return newArray;
        });
      }
    }
  };

  const handleAddNewEntry = (entryId) => {
    const dataFound = stateData.findIndex((d) => d.id === entryId);
    if (dataFound !== -1) {
      setStateData((prevState) => {
        const newArray = prevState.map((item, index) => {
          if (index === dataFound) {
            const newJsonValue = [...(item.dataValues.value_json || []), { hg: '', tg: '', p: '', time: '' }];
            const dataValues = {
              ...item.dataValues,
              value_json: newJsonValue,
            };
            if (dataFound?.dataValues?.id) {
              dataValues.update_id = dataFound.dataValues.id;
            }
            return {
              ...item,
              dataValues,
              type: 'json',
            };
          }
          return item;
        });
        return newArray;
      });
    } else {
      const dataValues = {
        value_json: [{ hg: '', tg: '', p: '', time: '' }],
      };
      if (dataFound?.dataValues?.id) {
        dataValues.update_id = dataFound.dataValues.id;
      }
      setStateData((prevState) => [...prevState, {
        id: entryId,
        dataValues,
        type: 'json',
      }]);
    }
  };

  useEffect(() => {
    if (selectedRow) {
      handleChangeDateTime(moment(selectedRow?.time_created).toDate());
      handleSelectOrder(selectedRow?.order);
      handleSelectLine(selectedRow?.line);
      if (selectedRow?.log_entries?.length > 0) {
        const newStateData = selectedRow?.log_entries?.map((entry) => {
          const dataValues = {
            id: entry?.id,
            [`value_${entry?.production_record_entry?.entry_type}`]: entry[`value_${entry?.production_record_entry?.entry_type}`],
            production_record_entry: entry?.production_record_entry?.id,
          };

          if (entry?.production_record_entry?.entry_type !== 'bool') {
            dataValues.value_bool = false;
          }

          return {
            id: entry?.production_record_entry?.id,
            dataValues,
            type: entry?.production_record_entry?.entry_type,
          };
        });

        setStateData(newStateData);
        setInitialData(newStateData);
      }
    }
  }, [selectedRow]);

  const renderInput = (val) => {
    let inputType;
    switch (val.entry_type) {
      case 'bool':
        inputType = 'checkbox';
        break;
      case 'numeric':
        inputType = 'number';
        break;
      case 'decimal':
        inputType = 'number';
        break;
      default:
        inputType = 'text';
    }


    return (
      <div key={val.id} className="modal_row">
        <div className="left_text">
          <label>
            {val.title}
            {val.required && ' *'}
            <Tooltip
              id="tooltip-comment"
              trigger={['hover']}
              placement="top"
              className="tooltip_margin"
              overlay={val.description || 'No description'}
              overlayClassName="where-filter-tooltip"
            >
              <span aria-describedby="tooltip-comment">
                <IconComment height="15px" width="17px" />
              </span>
            </Tooltip>
          </label>
        </div>
        {val.entry_type === 'json' && (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '300px', overflowX: 'scroll' }}>
            <Button type="add" style={{ marginBottom: '5px', width: '40%' }} onClick={() => handleAddNewEntry(val.id)}>
              {t('page_content.production_records.add_new_entry')}
            </Button>
            <table id="production_records_modal_table">
              <thead>
                <tr>
                  <th>Hg</th>
                  <th>Tg</th>
                  <th>P</th>
                  <th>{t('page_content.production_records.time')}</th>
                </tr>
              </thead>
              <tbody>
                {(stateData.find((o) => o.id === val.id)?.dataValues?.value_json ?? []).map((row, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <input type="text" value={row.hg} onChange={(e) => handleInputChange(val, index, 'hg', e.target.value)} disabled={!!initialData?.find((o) => o.id === val.id)?.dataValues?.value_json[index]} />
                      </td>
                      <td>
                        <input type="text" value={row.tg} onChange={(e) => handleInputChange(val, index, 'tg', e.target.value)} disabled={!!initialData?.find((o) => o.id === val.id)?.dataValues?.value_json[index]} />
                      </td>
                      <td>
                        <input type="text" value={row.p} onChange={(e) => handleInputChange(val, index, 'p', e.target.value)} disabled={!!initialData?.find((o) => o.id === val.id)?.dataValues?.value_json[index]} />
                      </td>
                      <td>
                        <ReactDatePicker
                          selected={row?.time ? moment(row?.time, 'HH:mm').toDate() : null}
                          onChange={(time) => handleInputChange(val, index, 'time', time ? moment(time).format('HH:mm') : '')}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeCaption={t('date_picker_locale.time_translation')}
                          disabled={!!initialData?.find((o) => o.id === val.id)?.dataValues?.value_json[index]}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>

            </table>
          </div>
        )}
        {val.entry_type === 'time' && (
          <div className="right_datePicker">
            <ReactDatePicker
              selected={stateData?.find((o) => o.id === val.id)?.dataValues?.value_time ? moment(stateData?.find((o) => o.id === val.id)?.dataValues?.value_time, 'HH:mm').toDate() : null}
              onChange={(time) => handleChange(time ? moment(time).format('HH:mm') : '', val)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={1}
              timeCaption={t('date_picker_locale.time_translation')}
              disabled={!!initialData?.find((o) => o.id === val.id)?.dataValues?.value_time}
            />
          </div>
        )}
        {val.entry_type === 'bool' && (
          <div className="right_checkbox">
            <input
              keyid={val.id}
              onChange={(e) => handleChange(e, val)}
              type="checkbox"
              checked={stateData?.find((o) => o.id === val.id)?.dataValues?.value_bool || false}
              disabled={!!initialData?.find((o) => o.id === val.id)?.dataValues?.value_bool}
            />
          </div>
        )}
        {(val.entry_type === 'numeric' || val.entry_type === 'decimal' || val.entry_type === 'text') && (
          <div className="right_input">
            <input
              keyid={val.id}
              onChange={(e) => handleChange(e, val)}
              type={inputType}
              value={stateData?.find((o) => o.id === val.id)?.dataValues[`value_${val.entry_type}`] || ''}
              disabled={!!initialData?.find((o) => o.id === val.id)?.dataValues[`value_${val.entry_type}`]}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="production_records_add_edit_modal">
      <div>
        <Button onClick={handleCloseModal}>{t('page_content.production_records.back_button')}</Button>
      </div>
      <span className="add_record_title">{t('page_content.production_records.add_new_record_title')}</span>
      <div className="fixed_inputs_view">
        <div className="modal_row edit_select">
          <div className="left_text"><label>{t('page_content.production_records.record_label')}</label></div>
          <div className="right_select">
            <Select
              options={filteredRecords}
              getOptionLabel={(record) => record.title}
              getOptionValue={(record) => record.id}
              isSearchable
              onChange={(value) => handleSelectRecord(value)}
              value={(filteredRecords.find((r) => r.id === selectedRecord)) || ''}
              styles={selectModalStyles}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text"><label>{t('page_content.production_records.line')} *</label></div>
          <div className="right_select">
            <Select
              options={lines}
              getOptionLabel={(line) => line.name}
              getOptionValue={(line) => line.id}
              isSearchable
              placeholder={t('page_content.production_records.all_lines')}
              onChange={(value) => handleSelectLine(value)}
              value={(lines.find((l) => l.id === selectedLine)) || ''}
              styles={selectModalStyles}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text"><label>{t('page_content.production_records.work_order')} *</label></div>
          <div className="right_select">
            <Select
              options={orders}
              getOptionLabel={(order) => <span><b>{order.id}</b> {order.customer_name}</span>}
              getOptionValue={(order) => order.id}
              isSearchable
              placeholder={t('page_content.production_records.work_order_placeholder')}
              onChange={(value) => handleSelectOrder(value)}
              value={(orders.find((o) => o.id === selectedOrder)) || ''}
              styles={selectModalStyles}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text"><label>{t('page_content.production_records.change_date_label')}</label></div>
          <div className="right_datePicker">
            <ReactDatePicker
              selected={selectedDateTime}
              onChange={handleChangeDateTime}
              maxDate={new Date()}
              minTime={new Date().setHours(0, 0)}
              maxTime={selectedDateTime.getDate() === new Date().getDate() ? new Date().setHours(new Date().getHours()) : new Date().setHours(23, 59, 59)}
              showTimeSelect
              timeCaption={t('date_picker_locale.time_translation')}
            />
          </div>
        </div>
      </div>
      <div className="custom_inputs_view">
        {data && data.entries.length && data.entries.map((val, i) => {
          if (val.entry_type === 'instruction') {
            return;
          }
          return renderInput(val, i);
        })}
      </div>
      <div className="save_button">
        <Button type="success" disabled={!saveEnabled || !selectedLine || !workOrder} onClick={handleSaveLog}>
        {t('page_content.production_records.save')}
        </Button>
      </div>
    </div>
  );
};

AddRecord.propTypes = {
  handleCloseModal: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  handleSave: PropTypes.func,
  lines: PropTypes.array,
  t: PropTypes.func,
  orders: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultSelectedRecord: PropTypes.number,
  handleChangeRecord: PropTypes.func,
  filteredRecords: PropTypes.array,
  selectedRow: PropTypes.object,
};

export default AddRecord;
