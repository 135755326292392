import { getByURL, postAndNotifyByURL, putAndNotifyByURL, deleteAndNotifyByURL } from './CustomService';

export const getPartners = async (filters = '') => {
  return getByURL(`/api/v1/partners/partners/${filters}`);
};

export const postPartner = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/partners/partners/${filters}`, data);
};

export const putPartner = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/partners/partners/${id}/${filters}`, data);
};

export const deletePartner = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/partners/partners/${id}/${filters}`);
};
