import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import api from 'helpers/api';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import Select from 'react-select';
import { selectStyles } from 'styles/modules/reactSelect';
import { Table, Button, Modal, TableButtons } from 'shared';
import { defaultDateTimeFormat, modalSizes } from 'shared/constants';
import { renderEventIcon, numberSeparatorFormat } from 'industry/helpers';
import { getByURL } from 'shared/Api';
import { getAssets, getAuditLogs } from '../../actions';
import CommentModal from './components/CommentModal';
import './style.scss';

class OrderAuditLog extends Component {
  constructor() {
    super();
    this.state = {
      isCommentModalOpen: false,
      assets: [],
      selectedAssetFilter: '',
      selectedLogTypeFilter: '',
      orderAuditLogs: [],
      isLoadingAuditLogData: true,
      isAuditLogModalOpen: false,
      auditLogModalData: null,
      filter: '',
      logTypes: [
        'comment',
        'booking',
        // 'booking_change',
        'system',
        // 'operator',
        // 'importer',
        'order_start',
        'order_end',
        'batch_change',
        // 'batch_record',
        'operator_checkin',
        'productivity_worker',
        // 'productivity',
      ],
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    getAssets(companyId, locationId)
      .then((res) => {
        this.setState({
          assets: get(res, 'data.results') || [],
        });
      });

    this.fetchAuditLogs();
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: key === 'filter' ? (value.length >= 2) ? value : '' : value,
    }, () => { this.fetchAuditLogs(); });
  }

  getAssetNameByID = (id) => {
    const { assets } = this.state;
    const foundAsset = assets.find((asset) => asset.id === id);
    return (foundAsset !== null && foundAsset !== undefined) ? foundAsset.name : '-';
  }

  fetchAuditLogs = () => {
    const { getOrderId } = this.props;
    const { selectedAssetFilter, selectedLogTypeFilter } = this.state;

    this.setState({
      isLoadingAuditLogData: true,
    });

    let filters = '';

    if (selectedAssetFilter && selectedAssetFilter !== 'all') {
      filters += `&asset__in=${selectedAssetFilter}`;
    }

    if (selectedLogTypeFilter && selectedLogTypeFilter !== 'all' && selectedLogTypeFilter !== 'productivity_worker') {
      filters += `&log_type=${selectedLogTypeFilter}`;
    }

    if (selectedLogTypeFilter && selectedLogTypeFilter !== 'all' && selectedLogTypeFilter === 'productivity_worker') {
      filters += '&log_type__in=productivity_worker,productivity';
    }

    const orderId = getOrderId();

    getAuditLogs(orderId, filters)
      .then((res) => {
        this.setState({
          orderAuditLogs: get(res, 'data.results') || [],
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count'),
          next: get(res, 'data.next'),
          isLoadingAuditLogData: false,
        });
      });
  }

  exportToExcelAuditLog = () => {
    const { countAuditLog } = this.state;
    const { getOrderId } = this.props;

    const orderId = getOrderId();

    api.get(`/api/v1/orders/order_audit_logs/?order=${orderId}&order_by=-created_at&format=xlsx&count=${countAuditLog || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'audit_log_list.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  openAuditLogModal = (rowInfo) => {
    this.setState({
      isAuditLogModalOpen: true,
      auditLogModalData: rowInfo.original || null,
    });
  }

  closeAuditLogModal = () => {
    this.setState({
      isAuditLogModalOpen: false,
      auditLogModalData: null,
    });
  }

  decimalToHoursMinutes = (decimalNumber) => {
    const hours = Math.floor(decimalNumber);
    const minutes = Math.round((decimalNumber - hours) * 60);

    const formattedTime = `${hours}:${minutes}`;

    return formattedTime;
  };

  displayAuditLogDetails = (row) => {
    const { i18n } = this.props;
    let displayStr = '';
    const data = row.original;
    const logType = data.log_type;
    const details = data.details;
    let json;
    try {
      json = JSON.parse(details);
    } catch (err) {
      json = null;
    }

    const pStyle = {
      margin: 0,
      padding: 0,
    };

    if (logType === 'batch_change'
      && json !== null
      && Object.prototype.hasOwnProperty.call(json, 'material_code')
      && Object.prototype.hasOwnProperty.call(json, 'batch')
      && Object.prototype.hasOwnProperty.call(json, 'warehouse')
      && Object.prototype.hasOwnProperty.call(json, 'quantity')
      && Object.prototype.hasOwnProperty.call(json, 'unit_code')
    ) {
      displayStr = (<div>
        {<p style={pStyle}>Materijal: {json.material_code}</p>}
        {<p style={pStyle}>Serija: {json.batch} ({json.warehouse}) {parseFloat(json.quantity).toFixed(3)} {json.unit_code}</p>}
      </div>);
    } else if (logType === 'productivity_worker'
      && json !== null
      && Object.prototype.hasOwnProperty.call(json, 'productivity')
      && Object.prototype.hasOwnProperty.call(json, 'time')
    ) {
      displayStr = (<div>
        {<p style={pStyle}>Produktivnost: {numberSeparatorFormat(i18n.language, json.productivity, 2, 2, true)}%</p>}
        {<p style={pStyle}>Trajanje: {this.decimalToHoursMinutes(json.time)}h</p>}
      </div>);
    } else {
      displayStr = (details.length > 100)
        ? `${details.substring(0, 100)}...`
        : `${details}`;
    }

    return (<span>
      {displayStr}
    </span>);
  }

  doAuditLogFiltering = (logs) => {
    const {
      filter,
    } = this.state;
    return (filter !== '')
      ? logs.filter((x) => x.log_text.toLowerCase().includes(filter.toLowerCase()) || x.details.toLowerCase().includes(filter.toLowerCase()))
      : logs;
  }

  clearFilters = () => {
    this.setState({
      selectedAssetFilter: '',
      selectedLogTypeFilter: '',
    }, () => {
      this.clearQueryFilter();
      this.fetchAuditLogs();
    });
  }

  clearQueryFilter = () => {
    this.setState({
      filter: '',
    }, () => {
      document.querySelector('[name="orders-filter"]').value = '';
      document.querySelector('[name="orders-filter"]').focus();
    });
  }

  openCommentModal = () => {
    this.setState({
      isCommentModalOpen: true,
    });
  }

  closeCommentModal = () => {
    this.setState({
      isCommentModalOpen: false,
    });
  }

  fetchByUrl = (url) => {
    this.setState({ isLoadingAuditLogData: true });

    getByURL(url)
      .then((re) => {
        this.setState({
          orderAuditLogs: get(re, 'data.results', []),
          previous: get(re, 'data.previous', null),
          count: get(re, 'data.count', null),
          next: get(re, 'data.next', null),
          isLoadingAuditLogData: false,
        });
      });
  }

  render() {
    const {
      assets,
      selectedAssetFilter,
      orderAuditLogs,
      selectedLogTypeFilter,
      logTypes,
      isAuditLogModalOpen,
      auditLogModalData,
      isLoadingAuditLogData,
      isCommentModalOpen,
      next,
      previous,
      count,
    } = this.state;

    const {
      t,
      getOrderId,
      isReadOnly,
    } = this.props;

    return (
      <div className="audit_log_container">
        {
          isCommentModalOpen &&
          <CommentModal
            t={t}
            isCommentModalOpen={isCommentModalOpen}
            closeCommentModal={this.closeCommentModal}
            assets={assets}
            getOrderId={getOrderId}
            fetchAuditLogs={this.fetchAuditLogs}
          />
        }
        <Modal
          isOpen={isAuditLogModalOpen}
          handleClose={this.closeAuditLogModal}
          size={modalSizes.large}
          title={t('page_content.orders.order_details.audit_log_tab.audit_log_modal.title')}
        >
          {
            isAuditLogModalOpen && auditLogModalData &&
            <div className="audit_log_details">
              <div>
                <p className="title">{t('page_content.orders.order_details.audit_log_tab.audit_log_modal.created')}</p>
                <p className="data"><strong>{auditLogModalData.created_at ? moment(auditLogModalData.created_at).format(defaultDateTimeFormat) : '-'}</strong></p>
              </div>
              <div>
                <p className="title">{t('page_content.orders.order_details.audit_log_tab.audit_log_modal.log_level')}</p>
                <p className="data"><strong>{auditLogModalData.log_level ? <span>{renderEventIcon(auditLogModalData.log_level)} {t([`page_content.orders.order_details.audit_log_tab.log_levels.${auditLogModalData.log_level}`])}</span> : '-'}</strong></p>
              </div>
              <div>
                <p className="title">{t('page_content.orders.order_details.audit_log_tab.audit_log_modal.user')}</p>
                <p className="data"><strong>{auditLogModalData.user && auditLogModalData.user.first_name && auditLogModalData.user.last_name ?
                  `${auditLogModalData.user.first_name} ${auditLogModalData.user.last_name}` : auditLogModalData.user && auditLogModalData.user.email ?
                    auditLogModalData.user.email : 'System'}</strong></p>
              </div>
              <div>
                <p className="title">{t('page_content.orders.order_details.audit_log_tab.audit_log_modal.log_text')}</p>
                <p className="data"><strong>{auditLogModalData.log_text || '-'}</strong></p>
              </div>
              <div>
                <p className="title">{t('page_content.orders.order_details.audit_log_tab.audit_log_modal.details')}</p>
                <p className="data"><strong>{auditLogModalData.details || '-'}</strong></p>
              </div>
            </div>
          }
        </Modal>
        <div className="filters_row">
          <div className="input_container">
            <input
              type="text"
              name="orders-filter"
              onKeyUp={(e) => { this.onFilterChange('filter', e.target.value); }}
              placeholder={t('page_content.orders.order_details.audit_log_tab.search_by_text')}
            />
          </div>

          <Select
            options={assets}
            getOptionLabel={(asset) => asset.name}
            getOptionValue={(asset) => asset.id}
            isSearchable
            menuPosition="fixed"
            placeholder={t('page_content.orders.order_details.audit_log_tab.filter_by_asset')}
            onChange={(value) => this.onFilterChange('selectedAssetFilter', value.id)}
            value={(assets.find((a) => a.id === selectedAssetFilter)) || ''}
            styles={selectStyles}
          />

          <Select
            options={logTypes.map((logType) => ({ value: logType, label: logType }))}
            getOptionLabel={(option) => t([`page_content.orders.order_details.audit_log_tab.log_types.${option.label}`])}
            getOptionValue={(option) => option.value}
            isSearchable
            menuPosition="fixed"
            placeholder={t('page_content.orders.order_details.audit_log_tab.filter_by_type')}
            onChange={(e) => this.onFilterChange('selectedLogTypeFilter', e.value)}
            value={(logTypes.map((logType) => ({ value: logType, label: logType })).find((logType) => logType.value === selectedLogTypeFilter)) || ''}
            styles={selectStyles}
          />
          <div>
            <Button onClick={this.clearFilters}>{t('shared.clear_button')}</Button>
          </div>
          <div className="action_buttons_wrapper">
            <div>
              <Button disabled={isReadOnly} onClick={this.openCommentModal}>{t('page_content.orders.order_details.audit_log_tab.enter_comment')}</Button>
            </div>
            <div>
              <Button type="export" onClick={this.exportToExcelAuditLog}>{t('page_content.orders.order_details.audit_log_tab.export_to_excel_button')}</Button>
            </div>
          </div>

        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.orders.order_details.audit_log_tab.table_column_created')}</span>,
              minWidth: 50,
              maxWidth: 180,
              accessor: 'created_at',
              Cell: (row) => <span>{row && row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.audit_log_tab.table_column_log_level')}</span>,
              minWidth: 50,
              maxWidth: 180,
              accessor: 'log_level',
              Cell: (row) => (<span>{row && row.value ? <span>{renderEventIcon(row.value)} {t([`page_content.orders.order_details.audit_log_tab.log_levels.${row.value}`])}</span> : '-'}</span>),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.audit_log_tab.table_column_asset')}</span>,
              minWidth: 50,
              maxWidth: 180,
              accessor: 'asset',
              Cell: (row) => <span>{row && row.value ? this.getAssetNameByID(row.value) : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.audit_log_tab.table_column_user')}</span>,
              minWidth: 50,
              maxWidth: 180,
              accessor: 'user',
              Cell: (row) => (<span>{row && row.value && row.value.first_name && row.value.last_name ?
                `${row.value.first_name} ${row.value.last_name}` : row && row.value && row.value.email ?
                  row.value.email : 'System'}</span>),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.audit_log_tab.table_column_log_type')}</span>,
              accessor: 'log_type',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.audit_log_tab.table_column_log_text')}</span>,
              accessor: 'log_text',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
            },
            {
              Header: () => <span className="order_audit_log_details">{t('page_content.orders.order_details.audit_log_tab.table_column_details')}</span>,
              accessor: 'details',
              Cell: (row) => <span>{row && row.value ? this.displayAuditLogDetails(row) : '-'}</span>,
            },
          ]}
          data={this.doAuditLogFiltering(orderAuditLogs) || []}
          minRows={0}
          defaultPageSize={100}
          handleClick={(rowInfo) => this.openAuditLogModal(rowInfo)}
          noDataText=" "
          trClassKey="log_type"
          checkLogTypeDetails
          showPagination={false}
          loading={isLoadingAuditLogData}
          sortable
          manual={false}
        />
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchByUrl} count={count} />
      </div>
    );
  }
}

OrderAuditLog.propTypes = {
  i18n: PropTypes.object,
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  getOrderId: PropTypes.func.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default withTranslation()(OrderAuditLog);
