import { getByURL, postAndNotifyByURL, putAndNotifyByURL, deleteAndNotifyByURL } from './CustomService';

export const getCompanyMembers = async (filters = '') => {
  return getByURL(`/api/v1/company_members/${filters}`);
};

export const postCompanyMember = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/company_members/${filters}`, data);
};

export const putCompanyMember = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/company_members/${id}/${filters}`, data);
};

export const deleteCompanyMember = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/company_members/${id}/${filters}`);
};
