import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import '../styles.scss';

import { ACTIONS } from 'industry/role/definitions/actions';
import { SECTIONS } from 'industry/role/definitions/sections';
import { canPerformAction } from 'industry/role/selectors';

import { deleteCompanyValue, getCompanyValues, getPaginatedCompanyValues } from '../../../actions';

import CompanyValuesModal from './CompanyValuesModal';

const CompanyValuesTable = ({ t, companyId, can }) => {
  const [companyValues, setCompanyValues] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [companyValuesFilters, setCompanyValuesFilters] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'name',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchCompanyValues = () => {
    setCompanyValues((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = companyValuesFilters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&order_by=${asc}${companyValuesFilters?.selectedSort}`;

    getCompanyValues(companyId, apiFilters)
      .then((res) => {
        setCompanyValues({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setCompanyValues((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedCompanyValues = (url) => {
    setCompanyValues((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedCompanyValues(url)
      .then((res) => {
        setCompanyValues({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setCompanyValues((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchCompanyValues();
  }, [companyId, companyValuesFilters]);

  const handleShowConfirmationDialog = (row) => {
    setShowConfirmationDialog(true);
    console.log('🚀 ~ handleShowConfirmationDialog ~ row:', row);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteCompanyValue(deleteData?.id, companyId);
    setShowConfirmationDialog(false);
    fetchCompanyValues();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setCompanyValuesFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewCompanyValue = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchCompanyValues();
  };

  return (
    <React.Fragment>
      <div className="company_values_table">
        <h3>{t('page_content.human_resources.control_panel.company_values.company_values')}</h3>

        <div className="table_action_buttons">
        <Button
          type="add"
          disabled={!can(SECTIONS.CONTROL_PANEL_TABS__KFK_VALUES_TAB, ACTIONS.ADD)}
          onClick={handleAddNewCompanyValue}
        >
          {t('page_content.human_resources.control_panel.company_values.add_new_company_value')}
        </Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.control_panel.company_values.name')}</span>,
              accessor: 'name',
              style: { cursor: 'default' },
            },
          ]}
          data={companyValues.data || []}
          defaultPageSize={100}
          loading={companyValues.isLoading}
          minRows={0}
          noDataText=""
          enableEdit
          enableDelete
          onEdit={(row) => handleTableRowClick(row)}
          onDelete={(row) => handleShowConfirmationDialog(row)}
          isActionsDisabled={!can(SECTIONS.CONTROL_PANEL_TABS__KFK_VALUES_TAB, ACTIONS.EDIT) || !can(SECTIONS.CONTROL_PANEL_TABS__KFK_VALUES_TAB, ACTIONS.DELETE)}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons
          previous={companyValues.previous}
          next={companyValues.next}
          fetchFunction={fetchPaginatedCompanyValues}
          count={companyValues.count}
        />
      </div>

      {modalData?.isOpen &&
      <CompanyValuesModal
        t={t}
        isOpen={modalData?.isOpen}
        initialValues={modalData?.selectedItem}
        handleCloseModal={handleCloseModal}
        closeModalAndRefetch={closeModalAndRefetch}
      />}

      <ConfirmationModal
        itemName={`${get(deleteData, 'name')}`}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleDeleteItem}
        type="warning"
      />
    </React.Fragment>
  );
};

CompanyValuesTable.propTypes = {
  t: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    companyId: get(state, 'app.company.id', null),
    can: (section, action, useExceptions = false) => canPerformAction(state, section, action, useExceptions),
  };
};

export default connect(mapStateToProps)(withTranslation()(CompanyValuesTable));
