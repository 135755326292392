import moment from 'moment';

const colors = [
  '#1abc9c',
  '#2980b9',
  '#e67e22',
  '#e74c3c',
  '#9b59b6',
  '#2ecc71',
];

export const getColor = (index) => {
  const randomIndex = index % colors.length;
  const color = colors[randomIndex];
  return {
    background: `${color}`,
  };
};

export const processOEE = (data) => {
  return data.map((item, index) => {
    const { start, end, ...rest } = item;
    return {
      ...rest,
      id: start,
      group: 1,
      start_time: moment(start),
      end_time: moment(end),
      canMove: false,
      color: getColor(index),
    };
  });
};
