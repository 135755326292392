import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { get } from 'lodash';

import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';

import { selectModalStyles } from 'styles/modules/reactSelect';

import { getProductTypes } from '../actions';

class CycleTimesModal extends Component {
  constructor(props) {
    super();
    this.timerRef = React.createRef();

    let productType = null;
    let asset = null;
    let target_per_time = 0;

    if (props.selectedCycleTime) {
      const { selectedCycleTime } = props;
      productType = selectedCycleTime.product_type || null;
      asset = selectedCycleTime.asset || null;
      target_per_time = parseFloat(selectedCycleTime.target_per_time).toFixed(1) || 0;
    }
    this.state = {
      productType,
      asset,
      target_per_time,
      productTypes: {
        options: [],
        isLoading: false,
      },
    };
  }

  onFieldChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  handleSaveOrEditCycleTime = () => {
    const { productType, asset, target_per_time } = this.state;
    const { handleSaveOrEditCycleTime, selectedCycleTime } = this.props;

    const data = {
      product_type: productType?.id || null,
      asset: asset?.id || null,
      target_per_time: target_per_time || 0,
    };

    const id = selectedCycleTime?.id ?? null;
    handleSaveOrEditCycleTime(id, data);
  }

  handleBlur = () => {
    const { target_per_time } = this.state;

    if (target_per_time) {
      const formattedValue = parseFloat(target_per_time).toFixed(1);
      this.setState((prevState) => ({ ...prevState, target_per_time: formattedValue }));
    }
  };

  handleProductTypeSearch = (inputValue) => {
    const { companyId } = this.props;

    if (inputValue?.length >= 3) {
      this.setState({ productTypes: { isLoading: true } });

      let filters = '';

      if (this.timerRef.current) {
        clearTimeout(this.timerRef.current);
        this.timerRef.current = undefined;
      }

      this.timerRef.current = setTimeout(() => {
        this.timerRef.current = undefined;

        filters += `?company=${companyId}&limit=999&code_contains=${inputValue}&order_by=name`;

        getProductTypes(filters).then((res) => {
          this.setState({ productTypes: { options: get(res, 'data.results', []), isLoading: false } });
        });
      }, 500);
    }
  };

  render() {
    const { productType, asset, target_per_time, productTypes } = this.state;
    const {
      t,
      oeeAssets,
      isModalOpen,
      isLoadingModal,
      handleCloseModal,
    } = this.props;

    return (
      <React.Fragment>
        <Modal
          size={modalSizes.medium}
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          handleSave={this.handleSaveOrEditCycleTime}
          disableSave={!productType}
        >
          {
            isLoadingModal ?
              <ContentLoader /> :
              <div className="default-form">
                <table>
                  <tbody>
                    <tr>
                      <td className="label">{t('page_content.oee.modal.product_type')} *</td>
                      <td className="input">
                        <Select
                          options={productTypes?.options || []}
                          getOptionLabel={(option) => `${option.code} - ${option.name}`}
                          getOptionValue={(option) => option.id}
                          isLoading={productTypes.isLoading}
                          isSearchable
                          menuPosition="fixed"
                          placeholder={t('page_content.oee.modal.product_type_placeholder')}
                          onInputChange={(e) => this.handleProductTypeSearch(e)}
                          onChange={(e) => this.onFieldChange('productType', e || null)}
                          value={productType || null}
                          styles={selectModalStyles}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">{t('page_content.oee.modal.asset')}</td>
                      <td className="input">
                        <Select
                          options={oeeAssets}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isSearchable
                          isClearable
                          menuPosition="fixed"
                          placeholder={t('page_content.oee.modal.asset_placeholder')}
                          onChange={(e) => this.onFieldChange('asset', e || null)}
                          value={asset || null}
                          styles={selectModalStyles}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">{t('page_content.oee.target_time')}</td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('target_per_time', e.target.value)}
                          onBlur={this.handleBlur}
                          value={target_per_time || ''}
                          type="number"
                          step="0.1"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          }

        </Modal>
      </React.Fragment>
    );
  }
}

CycleTimesModal.propTypes = {
  t: PropTypes.func.isRequired,
  selectedCycleTime: PropTypes.object,
  oeeAssets: PropTypes.array.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  companyId: PropTypes.number.isRequired,
  isLoadingModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSaveOrEditCycleTime: PropTypes.func.isRequired,
};

export default CycleTimesModal;
