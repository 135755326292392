import { formatTypes } from '../../constants';
import { colors } from '../../colors';

export const operations = ['avg', 'min', 'max', 'sum', 'count'];
export const singleMetricOperations = ['avg', 'last', 'min', 'max', 'sum', 'count'];

export const groupBy = [
  // { label: 'none', value: 'none' },
  { label: 'auto', value: 'auto' },
  { label: 'seconds', value: 'seconds' },
  { label: 'minutes', value: 'minutes' },
  { label: 'hours', value: 'hours' },
];

export const defaultSettings = {
  area: {
    axis: {
      x: {
        show: true,
      },
      side: 'left',
      y1: {
        show: true,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
      y2: {
        show: false,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
    },
    legend: {
      show: true,
      position: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    },
    appearance: {
      grid: true,
      tooltip: true,
      title: 'left',
    },
    annotation: {
      value: null,
      name: '',
      thickness: 1,
      dashed: false,
      color: '#3182bd',
    },
  },
  bar: {
    axis: {
      x: {
        show: true,
      },
      side: 'left',
      y1: {
        show: true,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
      y2: {
        show: false,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
    },
    legend: {
      show: true,
      position: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    },
    appearance: {
      grid: true,
      tooltip: true,
      stack_bars: true,
      title: 'left',
      oee_gantt: false,
      oee_gantt_asset: null,
    },
    annotation: {
      value: null,
      name: '',
      thickness: 1,
      dashed: false,
      color: '#3182bd',
    },
  },
  gauge: {
    showTitlebar: true,
    colors: [colors.red],
    customColors: false,
    min: 0,
    max: 100,
    unit_text: '',
    appearance: {
      title: 'left',
      backgroundColor: '#FFFFFF',
    },
  },
  sankey: {
    legend: {
      show: true,
    },
    appearance: {
      tooltip: true,
      title: 'left',
    },
  },
  line: {
    axis: {
      x: {
        show: true,
      },
      oneYAxis: false,
      addOffset: false,
      side: 'left',
      y1: {
        show: true,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
      y2: {
        show: false,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
    },
    legend: {
      show: true,
      position: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    },
    appearance: {
      grid: true,
      tooltip: true,
      title: 'left',
      oee_gantt: false,
      oee_gantt_asset: null,
    },
    annotation: {
      value: null,
      name: '',
      thickness: 1,
      dashed: false,
      color: '#3182bd',
    },
  },
  step: {
    axis: {
      x: {
        show: true,
      },
      oneYAxis: false,
      addOffset: false,
      side: 'left',
      y1: {
        show: true,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
      y2: {
        show: false,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
    },
    legend: {
      show: true,
      position: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    },
    appearance: {
      grid: true,
      tooltip: true,
      title: 'left',
    },
    annotation: {
      value: null,
      name: '',
      thickness: 1,
      dashed: false,
      color: '#3182bd',
    },
  },
  scatter: {
    axis: {
      x: {
        show: true,
      },
      oneYAxis: false,
      addOffset: false,
      side: 'left',
      y1: {
        show: true,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
      y2: {
        show: false,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
    },
    legend: {
      show: true,
      position: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    },
    appearance: {
      grid: true,
      tooltip: true,
      title: 'left',
    },
    annotation: {
      value: null,
      name: '',
      thickness: 1,
      dashed: false,
      color: '#3182bd',
    },
  },
  map: {
    center: {
      lat: 47.0509411,
      lng: 6.6048067,
    },
    speedLimit: 90,
    tracking: true,
    zoom: 5,
    appearance: {
      title: 'left',
    },
  },
  pie: {
    legend: {
      show: true,
    },
    appearance: {
      tooltip: true,
      title: 'left',
    },
  },
  scalar: {
    showUnit: true,
    unit_text: '',
    showLabel: true,
    showTitlebar: true,
    showDatapoint: true,
    showCalculatedValue: false,
    appearance: {
      title: 'left',
    },
  },
  gantt: {
    axis: {
      x: {
        show: true,
      },
      oneYAxis: false,
      addOffset: false,
      side: 'left',
      y1: {
        show: true,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
      y2: {
        show: false,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
    },
    legend: {
      show: true,
      position: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    },
    appearance: {
      grid: true,
      tooltip: true,
      title: 'left',
      oneline_events: false,
    },
    annotation: {
      value: null,
      name: '',
      thickness: 1,
      dashed: false,
      color: '#3182bd',
    },
  },
  histogram: {
    axis: {
      x: {
        show: true,
      },
      side: 'left',
      y1: {
        show: true,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
      y2: {
        show: false,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
    },
    legend: {
      show: true,
      position: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    },
    appearance: {
      grid: true,
      tooltip: true,
      stack_bars: true,
      title: 'left',
    },
    annotation: {
      value: null,
      name: '',
      thickness: 1,
      dashed: false,
      color: '#3182bd',
    },
  },
  percent: {
    axis: {
      x: {
        show: true,
      },
      oneYAxis: false,
      addOffset: false,
      side: 'left',
      y1: {
        show: true,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
      y2: {
        show: false,
        min: null,
        max: null,
        label: '',
        format: formatTypes.float,
      },
    },
    legend: {
      show: true,
      position: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    },
    appearance: {
      grid: true,
      tooltip: true,
      title: 'left',
    },
    annotation: {
      value: null,
      name: '',
      thickness: 1,
      dashed: false,
      color: '#3182bd',
    },
  },
  realtime_widget: {
    inactivity: 20,
  },
  table: {
    colors: [colors.red],
    customColors: false,
  },
  tablerange: {
    unit_text: '',
    unit_decimals: '',
    colors: [colors.red],
    customColors: false,
  },
  orderstable: {
    assets_in: [],
    columns: [],
  },
  label: {
    labelText: '',
    appearance: {
      show_divider: true,
    },
  },
};
