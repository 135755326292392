import { getByURL, postByURL, deleteByURL } from './CustomService';

export const getUserRoles = async (filters = '') => {
  return getByURL(`/api/v1/role_users/${filters}`);
};

export const postUserRole = async (filters = '', data) => {
  return postByURL(`/api/v1/role_users/${filters}`, data);
};

export const deleteUserRole = async (id, filters = '') => {
  return deleteByURL(`/api/v1/role_users/${id}/${filters}`);
};
