import { getByURL, postAndNotifyByURL, patchAndNotifyByURL, deleteAndNotifyByURL } from './CustomService';

export const getRoleResources = async (filters = '') => {
  return getByURL(`/api/v1/role_resources/${filters}`);
};

export const postRoleResource = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/role_resources/${filters}`, data);
};

export const patchRoleResource = async (id, filters = '', data) => {
  return patchAndNotifyByURL(`/api/v1/role_resources/${id}/${filters}`, data);
};

export const deleteRoleResource = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/role_resources/${id}/${filters}`);
};
