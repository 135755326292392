import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { get, sortBy } from 'lodash';
import { withTranslation } from 'react-i18next';
import { ReactTableDefaults } from 'react-table';

import { modalSizes } from 'shared/constants';
import { Modal, Table, Button } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';

import {
  postDepartmentTechnology,
  putDepartmentTechnology,
  postOperationTechnology,
  putOperationTechnology,
  deleteOperationTechnology,
  deleteOperationTechnologyWithoutNotification,

  getWorkOperations,
} from 'shared/Api';

const TechnologyModal = ({
  t,
  title,
  locationId,
  modalState,
  departments,
  technology = {},
  handleModalClose,
  handleModalCloseAndRefetch,
}) => {
  const [missingFields, setMissingFields] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [modalData, setModalData] = useState(technology);

  const fetchDepartmentSteps = async (departmentId) => {
    let departmentSteps = [];
    await getWorkOperations(`?department=${departmentId}`)
      .then((res) => { departmentSteps = get(res, 'data.results', []); })
      .catch((error) => console.error(error));
    return departmentSteps;
  };

  const stepColumnDefaults = { ...ReactTableDefaults.column, headerClassName: 'step_table_override_header', className: 'step_table_override' };

  const handleStepJsonChange = (value, index, key = '') => {
    const newSteps = [...modalData.steps];

    if (key === 'default_duration') newSteps[index] = { ...newSteps[index], default_duration: value };
    else if (value?.id) {
      newSteps[index] = { ...value, order: index };
      const newAvailableSteps = modalData?.department?.departmentOperations?.filter((step) => {
        return !newSteps.find((techStep) => {
          return (techStep.operation?.id === step.id || techStep.id === step.id);
        });
      });
      setModalData((prevState) => ({
        ...prevState,
        availableDepartmentSteps: newAvailableSteps,
        steps: newSteps,
      }));
    }

    setModalData((prevState) => ({
      ...prevState,
      steps: newSteps,
    }));
  };

  const removeStepValue = async (row) => {
    if (row?.operation?.id) {
      await deleteOperationTechnology(row?.id, `?technology=${row?.technology}`)
        .then(() => {
          setModalData((prevState) => ({
            ...prevState,
            initialTechnologySteps: prevState.initialTechnologySteps.filter((step) => step.operation.id !== row.operation.id),
            availableDepartmentSteps: [...prevState.availableDepartmentSteps, ...prevState.department.departmentOperations.filter((step) => step.id === row.operation.id)],
            steps: prevState.steps.filter((step) => step.id !== row.id),
          }));
        });
    } else if (!row) {
      setModalData((prevState) => ({
        ...prevState,
        steps: prevState.steps.filter((step) => step !== row),
      }));
    }
  };

  const handleInsertNewEmptyStep = () => {
    setModalData((prevState) => ({
      ...prevState,
      steps: [...prevState.steps, ''],
    }));
  };

  const handleModalData = async (value, key) => {
    if (key === 'department') {
      const allDepartmentSteps = await fetchDepartmentSteps(value);
      const availableDepartmentSteps = [...allDepartmentSteps];
      setModalData((prevState) => ({
        ...prevState,
        steps: Array(1).fill(''),
        initialTechnologySteps: [],
        department: { id: value, departmentOperations: allDepartmentSteps },
        availableDepartmentSteps,
      }));
    } else setModalData((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleCreateOrUpdateTechnology = async () => {
    if (!modalData) return;

    if (modalData?.id) {
      if (modalData?.name !== technology.name && modalData?.department?.id === technology?.department?.id) {
        await putDepartmentTechnology(modalData?.id, `?location=${locationId}`, { name: modalData.name, department: modalData.department.id })
          .then(() => handleModalCloseAndRefetch());
      } else {
        modalData?.steps?.forEach(async (step) => {
          if (!step?.operation) {
            // if a new step was added first check if it was in the initial steps, and if it wasn't add it
            if (modalData?.initialTechnologySteps?.some((initialStep) => initialStep?.order === step?.order)) {
              const initialStep = modalData?.initialTechnologySteps?.find((initial) => initial?.order === step?.order);
              await putOperationTechnology(initialStep.id, `?technology=${modalData?.id}`, { operation: step.id, order: step.order, technology: modalData.id, default_duration: step.default_duration });
            } else await postOperationTechnology(`?technology=${modalData?.id}`, { operation: step.id, order: step.order, technology: modalData.id, default_duration: step.default_duration });
            // if the step was in the initial steps, check if the duration was changed and update it
          } else if (step?.operation?.id && modalData?.initialTechnologySteps?.find((initialStep) => initialStep?.order === step?.order)?.default_duration !== step.default_duration) {
            const initialStep = modalData?.initialTechnologySteps?.find((initial) => initial?.order === step?.order);
            await putOperationTechnology(initialStep.id, `?technology=${modalData?.id}`, { operation: step.operation.id, order: step.order, technology: modalData.id, default_duration: step.default_duration });
          }
        });

        // if the department was changed, update technology with new department
        if (modalData?.department?.id !== technology?.department?.id) {
          technology?.initialTechnologySteps?.forEach(async (step) => {
            await deleteOperationTechnologyWithoutNotification(step.id, `?technology=${modalData?.id}`);
          });

          await putDepartmentTechnology(modalData?.id, `?location=${locationId}`, { name: modalData.name, department: modalData.department.id })
            .then(() => handleModalCloseAndRefetch());
        } else handleModalCloseAndRefetch();
      }
    } else {
      postDepartmentTechnology(`?location=${locationId}`, { name: modalData.name, department: modalData.department.id })
        .then(async (res) => {
          const techId = get(res, 'data.id', null);

          if (techId && modalData?.steps?.length > 0 && modalData?.steps?.some((step) => step && typeof step === 'object')) {
            // eslint-disable-next-line no-return-await
            const stepPromises = modalData?.steps?.map(async (step) => await postOperationTechnology(`?technology=${techId}`, { operation: step.id, order: step.order, technology: techId, default_duration: step.default_duration }));
            await Promise.all(stepPromises).then(() => handleModalCloseAndRefetch());
          }
        });
    }
  };

  const stepsColumnDefinition = [
    {
      Header: () => <span className="step_modal_input">#</span>,
      width: 75,
      Cell: (row) => <span>{row.index + 1}</span>,
      style: { cursor: 'default' },
    },
    {
      Header: () => {
        return (
          <div className="step_table_header_wrapper">
            <div>{t('page_content.projects.products_tab.steps_modal.table_column_stepName')}</div>
            <div className="add_new_button">
              <Button type="add" onClick={handleInsertNewEmptyStep} disabled={modalData?.availableDepartmentSteps?.length === 0}>
                {t('page_content.department.modal_new_step_button')}
              </Button>
            </div>
          </div>
        );
      },
      Cell: (row) => {
        return (
          <span className="step_modal_input">
            <Select
              isDisabled={modalData?.availableDepartmentSteps?.length === 0}
              options={modalData?.availableDepartmentSteps}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={`${t('page_content.projects.products_tab.table_column_step')} ${row.index + 1}`}
              onChange={(value) => { handleStepJsonChange(value, row.index); }}
              value={row?.original?.operation ? row?.original?.operation : row?.original}
              styles={selectModalStyles}
              menuPosition="fixed"
            />
          </span>
        );
      },
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.projects.products_tab.steps_modal.modal_column_default_duration')}</span>,
      Cell: (row) => (
        <span className="step_modal_input">
          <input
            type="text"
            value={
              row?.original?.default_duration && row?.original?.default_duration !== '0.00'
                ? row?.original?.default_duration
                : row?.original?.operation?.default_duration || ''
            }
            onChange={(e) => handleStepJsonChange(e.target.value, row.index, 'default_duration')}
            onInput={(e) => {
              const value = e.target.value;
              if (!/^\d*\.?\d{0,2}$/.test(value)) {
                e.target.value = value.slice(0, -1);
              }
            }}
            placeholder={t('page_content.projects.products_tab.steps_modal.modal_column_default_duration')}
          />
        </span>
      ),
      width: 200,
    },
  ];

  useEffect(() => {
    if (Object.values(missingFields).some((element) => element === true) || !submitted) {
      return;
    }
    handleCreateOrUpdateTechnology();
  }, [missingFields, submitted]);

  return (
    <Modal
      title={title}
      isOpen={modalState}
      size={modalSizes.large}
      handleClose={handleModalClose}
      handleSave={() => {
        setMissingFields((prevState) => ({ ...prevState, name: !modalData.name, department: !modalData.department }));
        setSubmitted(true);
      }}
    >
      <form className="default-form department-technology-details-form">
        <table>
          <tbody>
            <tr>
              <td className="label">
                {`${t('page_content.technology.table_column_name')} *`}
              </td>
              <td className="input">
                <input
                  type="text"
                  onChange={(e) => { handleModalData(e.target.value, 'name'); }}
                  value={modalData?.name || ''}
                  style={missingFields.name ? { borderColor: 'red' } : {}}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.technology.table_column_department')}
              </td>
              <td className={`input ${missingFields.department ? 'warning' : 'success'}`}>
                <Select
                  options={sortBy(departments, [(value) => value.name.toLowerCase()])}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  menuPosition="fixed"
                  placeholder={t('page_content.technology.select_placeholder')}
                  onChange={(value) => { handleModalData(value.id, 'department'); }}
                  value={departments.find((d) => d.id === modalData?.department?.id) || ''}
                  styles={selectModalStyles}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>

      <Table
        style={{ userSelect: 'text', background: 'white' }}
        columns={stepsColumnDefinition}
        data={modalData?.steps}
        defaultPageSize={10}
        column={stepColumnDefaults}
        minRows={0}
        noDataText=""
        enableDelete
        onDelete={(original) => removeStepValue(original)}
        showPagination={technology.steps.length > 10}
        sortable={false}
      />

    </Modal>
  );
};

TechnologyModal.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  modalState: PropTypes.bool.isRequired,
  departments: PropTypes.array.isRequired,
  technology: PropTypes.object.isRequired,
  locationId: PropTypes.number.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalCloseAndRefetch: PropTypes.func.isRequired,
};

export default (withTranslation()(TechnologyModal));
