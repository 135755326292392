import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { getLocale } from 'shared/DatePicker/constants';

import './styles.scss';

const ReactDatePicker = ({
  t,
  popperProps,
  popperPlacement,
  className,
  dateFormat,
  timeFormat,
  showTimeSelect,
  showTimeSelectOnly,
  selected,
  onChange,
  placeholderText,
  timeCaption,
  strictParsing,
  ...props
}) => {
  const isValidDate = (date) => {
    // Handle Moment object
    if (date?._isAMomentObject) return date.isValid();
    // Handle Date object
    return date instanceof Date && !Number.isNaN(date.getTime());
  };

  // Convert Moment to Date (not really necessary, but just in case)
  const getDateValue = (value) => {
    if (value?._isAMomentObject) return value.isValid() ? value.toDate() : null;
    return isValidDate(value) ? value : null;
  };

  return (
    <DatePicker
      popperProps={{ strategy: 'fixed', ...popperProps }}
      popperPlacement={popperPlacement}
      className={className}
      dateFormat={!showTimeSelect ? [dateFormat, 'dd.MM.yyyy.', 'd.M.yyyy', 'd.M.yyyy.', 'dd.MM.yy', 'dd.MM.yy.', 'd.M.yy', 'd.M.yy.'] : !showTimeSelectOnly ? `${dateFormat} ${timeFormat}` : timeFormat}
      timeFormat={timeFormat}
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly}
      selected={getDateValue(selected)}
      onChange={(date) => onChange(date)}
      placeholderText={placeholderText || t('shared.react_date_picker.default_placeholder')}
      timeCaption={timeCaption || t('shared.react_date_picker.default_time_caption')}
      strictParsing={strictParsing}
      locale={getLocale(t)}
      {...props}
    />
  );
};

ReactDatePicker.propTypes = {
  t: PropTypes.func.isRequired,
  popperProps: PropTypes.object,
  popperPlacement: PropTypes.string,
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  selected: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
  timeCaption: PropTypes.string,
  strictParsing: PropTypes.bool,
};

ReactDatePicker.defaultProps = {
  popperProps: {},
  popperPlacement: 'bottom-start',
  className: 'datepicker_input',
  dateFormat: 'dd.MM.yyyy',
  timeFormat: 'HH:mm',
  showTimeSelect: false,
  showTimeSelectOnly: false,
  onChange: () => {},
  selected: null,
  placeholderText: '',
  timeCaption: '',
  strictParsing: false,
};

export default (withTranslation()(ReactDatePicker));
