import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Select, { components } from 'react-select';

import { defaultDateTimeFormat } from 'shared/constants';
import { selectStyles } from 'styles/modules/reactSelect';
import { Button, ContentLoader, Notification, ReactDatePicker } from 'shared';

import { checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import { IconZoomInArrows, IconFavorite, IconCloudDownload, IconPopup, IconSearch, ThreeItemsIcon, FourItemsIcon, SixItemsIcon } from 'shared/Icons';
import { getPhotos, addToFavorite, getPhotosUrl, markDefect, getCompanyShortCode } from './actions';

import './style.scss';

const cameraLabels = [
  { id: 0, label: 'B' },
  { id: 1, label: 'C' },
  { id: 2, label: 'A' },
  { id: 3, label: 'D' },
];

const originalDefectDimensions = [1200, 1200];

class PhotoLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      photos: [],
      galleryCardsWrapper: 'gallery_cards_wrapper_3',
      selectedImage: null,
      selectedSortByValue: '',
      searchInputValue: '',
      selectedSearchMenuValue: 'lot',
      gridSize: 3,
      next: null,
      previous: null,
      currentUrl: null,
      start_date: null,
      end_date: null,
      only_favorites: false,
      only_defects: false,
      company_short_code: null,
      numOfPhotosPerPage: 12,
    };

    this.getImageMarker = this.getImageMarker.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { assetId, companyId },
      },
      locationId,
    } = this.props;

    getCompanyShortCode(companyId)
      .then((re) => {
        this.setState({
          company_short_code: re.data.short_code || null,
        });
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          this.setState({
            isReadOnly: true,
          });
        }
      });

    this.fetchPhotos(assetId);
  }

  onSearchChange = (e) => {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    e.preventDefault();

    const searchInputValue = e.target.value;
    this.setState({
      searchInputValue,
    }, () => {
      this.fetchPhotos(assetId);
    });
  }

  onChangeNumOfPhotos = (e) => {
    const numOfPhotosPerPage = e || 12;
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    this.setState({
      numOfPhotosPerPage,
    }, () => {
      this.fetchPhotos(assetId);
    });
  }

  getImageMarker = (photoRef, coords, radius) => {
    const c = document.createElement('canvas');
    c.height = originalDefectDimensions[0];
    c.width = originalDefectDimensions[1];
    const ctx = c.getContext('2d');
    ctx.lineWidth = 6;
    ctx.strokeStyle = 'black';
    ctx.beginPath();
    ctx.arc(coords[0], coords[1], radius, 0, 2 * Math.PI);
    ctx.stroke();
    if (photoRef && photoRef.hasChildNodes() === false) {
      photoRef.appendChild(c);
    }
  }

  fetchPhotos = (assetId) => {
    const { selectedSortByValue, searchInputValue, selectedSearchMenuValue, start_date, end_date, only_favorites, only_defects, numOfPhotosPerPage } = this.state;
    this.setState({
      isLoading: true,
    });

    let filters = '';

    if (selectedSortByValue) {
      filters += `&order_by=${selectedSortByValue}`;
    }

    if (searchInputValue && selectedSearchMenuValue) {
      filters += `&${selectedSearchMenuValue}=${searchInputValue}`;
    }

    if (start_date) {
      filters += `&created_at_after=${moment(start_date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`;
    }

    if (end_date) {
      filters += `&created_at_before=${moment(end_date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`;
    }

    if (only_favorites) {
      filters += '&is_favorite=true';
    }

    if (only_defects) {
      filters += '&is_defect=true';
    }

    getPhotos(assetId, filters, numOfPhotosPerPage)
      .then((re) => {
        if (re.status === undefined || re.status === 400 || re.status === 403 || re.status === 404 || re.status === 500) {
          throw new Error('');
        }
        this.setState({
          isLoading: false,
          photos: re.data.results,
          next: re.data.next,
          previous: re.data.previous,
          currentUrl: null,
        });
      })
      .catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  navigateNext = () => {
    const { next } = this.state;

    if (!next) return;

    this.setState({
      isLoading: true,
    });
    getPhotosUrl(next)
      .then((re) => {
        this.setState({
          isLoading: false,
          photos: re.data.results,
          next: re.data.next,
          previous: re.data.previous,
          currentUrl: next,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  navigatePrevious = () => {
    const { previous } = this.state;

    if (!previous) return;

    this.setState({
      isLoading: true,
    });
    getPhotosUrl(previous)
      .then((re) => {
        this.setState({
          isLoading: false,
          photos: re.data.results,
          next: re.data.next,
          previous: re.data.previous,
          currentUrl: previous,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  handleGridSize = (value) => {
    if (!value) {
      this.setState({
        galleryCardsWrapper: 'gallery_cards_wrapper_3',
        gridSize: 3,
      });
    } else if (value === 3) {
      this.setState({
        galleryCardsWrapper: 'gallery_cards_wrapper_3',
        gridSize: 3,
      });
    } else if (value === 4) {
      this.setState({
        galleryCardsWrapper: 'gallery_cards_wrapper_4',
        gridSize: 4,
      });
    } else if (value === 6) {
      this.setState({
        galleryCardsWrapper: 'gallery_cards_wrapper_6',
        gridSize: 6,
      });
    }
  }

  openModal = (photo) => {
    this.setState({
      selectedImage: photo,
    });
  }

  closeModal = () => {
    this.setState({
      selectedImage: null,
    });
  }

  handleSortBy = (value) => {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    this.setState({
      selectedSortByValue: value,
    }, () => {
      this.fetchPhotos(assetId);
    });
  }

  handleSearchMenuValue = (value) => {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    this.setState({
      selectedSearchMenuValue: value,
    }, () => {
      this.fetchPhotos(assetId);
    });
  }

  handleDeleteFilters = () => {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;
    this.setState({
      selectedSearchMenuValue: 'lot',
      selectedSortByValue: '',
      searchInputValue: '',
      start_date: null,
      end_date: null,
      only_favorites: false,
      only_defects: false,
      numOfPhotosPerPage: 12,
    });
    setTimeout(() => {
      this.fetchPhotos(assetId);
    }, 50);
  }

  markDefect = (photo) => {
    const { selectedSortByValue, searchInputValue, selectedSearchMenuValue, currentUrl, only_favorites, numOfPhotosPerPage, only_defects } = this.state;
    const {
      match: {
        params: { assetId },
      },
    } = this.props;
    let filters = '';
    if (photo && photo.id) {
      markDefect({ is_true_defect: !photo.is_true_defect }, photo.id)
        .then(() => {
          if (only_defects) {
            filters += '&is_defect=true';
          }

          if (selectedSortByValue) {
            filters += `&order_by=${selectedSortByValue}`;
          }

          if (searchInputValue && selectedSearchMenuValue) {
            filters += `&${selectedSearchMenuValue}=${searchInputValue}`;
          }

          if (only_favorites) {
            filters += '&is_favorite=true';
          }

          if (currentUrl) {
            getPhotosUrl(currentUrl)
              .then((re) => {
                this.setState({
                  isLoading: false,
                  photos: re.data.results,
                  next: re.data.next,
                  previous: re.data.previous,
                  currentUrl,
                });
              });
          } else {
            getPhotos(assetId, filters, numOfPhotosPerPage)
              .then((re) => {
                if (re.status === undefined || re.status === 400 || re.status === 403 || re.status === 404 || re.status === 500) {
                  throw new Error('');
                }
                this.setState({
                  photos: re.data.results,
                  isLoading: false,
                  selectedImage: null,
                  currentUrl: null,
                });
              })
              .catch((error) => {
                return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
              });
          }
        });
    }
  }

  addToFavorite = (photo) => {
    const { selectedSortByValue, searchInputValue, selectedSearchMenuValue, currentUrl, only_favorites, numOfPhotosPerPage, only_defects } = this.state;
    const {
      match: {
        params: { assetId },
      },
    } = this.props;
    let filters = '';
    if (photo && photo.id) {
      addToFavorite({ is_favorite: !photo.is_favorite }, photo.id).then(() => {
        if (only_defects) {
          filters += '&is_defect=true';
        }

        if (selectedSortByValue) {
          filters += `&order_by=${selectedSortByValue}`;
        }

        if (searchInputValue && selectedSearchMenuValue) {
          filters += `&${selectedSearchMenuValue}=${searchInputValue}`;
        }

        if (only_favorites) {
          filters += '&is_favorite=true';
        }

        if (currentUrl) {
          getPhotosUrl(currentUrl)
            .then((re) => {
              this.setState({
                isLoading: false,
                photos: re.data.results,
                next: re.data.next,
                previous: re.data.previous,
                currentUrl,
              });
            });
        } else {
          getPhotos(assetId, filters, numOfPhotosPerPage)
            .then((re) => {
              if (re.status === undefined || re.status === 400 || re.status === 403 || re.status === 404 || re.status === 500) {
                throw new Error('');
              }
              this.setState({
                photos: re.data.results,
                isLoading: false,
                selectedImage: null,
                currentUrl: null,
              });
            })
            .catch((error) => {
              return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
            });
        }
      });
    }
  }

  toDataURL(url) {
    return fetch(url, { cache: 'no-cache' }).then((response) => {
      return response.blob();
    }).then((blob) => {
      return URL.createObjectURL(blob);
    });
  }

  download = async (imageObj) => {
    const a = document.createElement('a');
    a.href = await this.toDataURL(imageObj.image.full_size);
    a.download = imageObj.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  handleChangeStartDate = (t) => {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    const formatedDate = moment(t);
    this.setState({
      start_date: formatedDate._d,
    }, () => {
      this.fetchPhotos(assetId);
    });
  }

  handleChangeEndDate = (t) => {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    const formatedDate = moment(t);
    this.setState({
      end_date: formatedDate._d,
    }, () => {
      this.fetchPhotos(assetId);
    });
  }

  handleOnlyFavorites = () => {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    this.setState((prevState) => ({
      only_favorites: !prevState.only_favorites,
    }), () => {
      this.fetchPhotos(assetId);
    });
  }

  openInNewTab = (photo) => {
    window.open(photo.image.full_size);
  }

  backToPhotoLibrary = () => {
    const { history } = this.props;
    const urlPart = window.location.href.split('/asset')[0];
    const url = new URL(urlPart);
    const base = url.pathname;
    history.push(base);
  }

  handleOnlyDefects = () => {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    this.setState((prevState) => ({
      only_defects: !prevState.only_defects,
    }), () => {
      this.fetchPhotos(assetId);
    });
  }

  render() {
    const { t } = this.props;
    const {
      isLoading,
      photos,
      galleryCardsWrapper,
      selectedImage,
      selectedSearchMenuValue,
      selectedSortByValue,
      searchInputValue,
      gridSize,
      next,
      previous,
      start_date,
      end_date,
      only_favorites,
      company_short_code,
      numOfPhotosPerPage,
      only_defects,
      isReadOnly,
    } = this.state;

    const inputSelectorOptions = [
      { value: 'lot', name: 'LoT' },
      { value: 'snowflake', name: 'Snowflake' },
    ];

    const sortBySelectorOptions = [
      { value: '-created_at', name: 'LoT' },
      { value: 'created_at', name: 'Snowflake' },
    ];

    const perPageSelectorOptions = [
      { value: '12', name: '12' },
      { value: '24', name: '24' },
      { value: '36', name: '36' },
    ];

    const layoutOptions = [
      { value: 3, label: t('page_content.product_library.three_items'), icon: ThreeItemsIcon },
      { value: 4, label: t('page_content.product_library.four_items'), icon: FourItemsIcon },
      { value: 6, label: t('page_content.product_library.six_items'), icon: SixItemsIcon },
    ];

    const { Option, SingleValue } = components;
    const IconOption = (props) => (
      <Option {...props}>
        <props.data.icon width="60px" height="30px" />
        {props.data.label}
      </Option>
    );

    const ValueOption = (props) => (
      <SingleValue {...props}>
        <props.data.icon width="60px" height="30px" />
        {t('page_content.product_library.layout')}
      </SingleValue>
    );

    const selectStylesLayout = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: 'fit-content',
        minHeight: '34px',
        height: '36px',
        padding: 0,
        fontSize: '13px',
        color: '#555',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0px 0px 0px 5px',
      }),
      singleValue: (provided) => ({
        ...provided,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '5px',
      }),
      clearIndicator: (provided) => ({
        ...provided,
        padding: '0px 3px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: 10,
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: 200,
        borderRadius: 0,
        zIndex: 99,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 99,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '5px',
      }),
    };

    return (
      <div className="industry-tab fullscreen photo_library_container">
        <Button style={{ width: 'fit-content' }} onClick={this.backToPhotoLibrary}>{t('page_content.product_library.back_button')}</Button>
        <p className="gallery_title">
          {t('page_content.product_library.gallery_breadcrumbs')}</p>
        <div className="filter_row">
        <div className="input_container_search">
            <div className="input_field_style">
            <input
              onChange={this.onSearchChange}
              value={searchInputValue}
              placeholder={t(t('page_content.product_library.search_placeholder'))}
              type={selectedSearchMenuValue && selectedSearchMenuValue === 'lot' ? 'number' : 'text'}
            />
              </div>
              <Select
                className="select-style"
                options={inputSelectorOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                isSearchable
                onChange={(s) => this.handleSearchMenuValue(s.value)}
                value={(inputSelectorOptions.find((opt) => opt.value === selectedSearchMenuValue)) || ''}
                styles={selectStyles}
              />
            <div className="icon_container">
                <IconSearch
                  color="#555"
                  height="26px"
                  width="26px"
                />
            </div>
          </div>
          <Select
            options={sortBySelectorOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            isSearchable
            isClearable
            placeholder={t('page_content.product_library.sort_by_placeholder')}
            onChange={(s) => this.handleSortBy(s?.value ?? '')}
            value={(sortBySelectorOptions.find((opt) => opt.value === selectedSortByValue)) || ''}
            styles={selectStyles}
          />

          <div className="datepicker_container">
            <div style={{ width: '200px' }}>
              <ReactDatePicker
                placeholderText={t('page_content.product_library.start_date_placeholder')}
                selected={start_date ? moment(start_date).toDate() : null}
                selectsStart
                onChange={this.handleChangeStartDate}
                showTimeSelect
                timeCaption={t('date_picker_locale.time_translation')}
              />
            </div>
            <div style={{ width: '200px' }}>
              <ReactDatePicker
                placeholderText={t('page_content.product_library.end_date_placeholder')}
                selected={end_date ? moment(end_date).toDate() : null}
                selectsStart
                onChange={this.handleChangeEndDate}
                showTimeSelect
                timeCaption={t('date_picker_locale.time_translation')}
              />
            </div>
          </div>

          <div className="checkbox_container">
            <input
              style={{ marginRight: '5px' }}
              type="checkbox"
              checked={only_favorites}
              onChange={this.handleOnlyFavorites}
            />
            {t('page_content.product_library.only_favorites')}
          </div>
          <div className="checkbox_container">
            <input
              style={{ marginRight: '5px' }}
              type="checkbox"
              checked={only_defects}
              onChange={this.handleOnlyDefects}
            />
            {t('page_content.product_library.only_defects')}
          </div>

          <Select
            options={perPageSelectorOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            isSearchable
            isClearable
            placeholder={t('page_content.product_library.select_limit')}
            onChange={(s) => this.onChangeNumOfPhotos(s?.value ?? 12)}
            value={(perPageSelectorOptions.find((opt) => opt.value === numOfPhotosPerPage)) || ''}
            styles={selectStyles}
          />

          <Button onClick={this.handleDeleteFilters}>
            {t('shared.clear_button')}
          </Button>

          <Select
            defaultValue={layoutOptions.find((val) => val.value === gridSize)}
            options={layoutOptions}
            isSearchable={false}
            isClearable={false}
            onChange={(value) => this.handleGridSize(value?.value ?? 3)}
            value={layoutOptions.find((val) => val.value === gridSize)}
            components={{ Option: IconOption, SingleValue: ValueOption }}
            styles={selectStylesLayout}
          />
        </div>

        {
          isLoading ?
            <ContentLoader /> :
            photos && photos.length > 0 &&
            <div className={galleryCardsWrapper}>
              {photos.map((photo) => {
                let cameraDefectLabel = '';
                const cameraId = (Object.prototype.hasOwnProperty.call(photo.data, 'camera_id') === true) ? photo.data.camera_id : null;
                if (cameraId !== null) {
                  const cameraDefectData = cameraLabels.find((x) => x.id === cameraId);
                  if (typeof cameraDefectData === 'object') {
                    cameraDefectLabel = cameraDefectData.label;
                  }
                }
                const defectCenter = (Object.prototype.hasOwnProperty.call(photo.data, 'defect_center') === true) ? photo.data.defect_center : null;
                const defectRadius = (Object.prototype.hasOwnProperty.call(photo.data, 'defect_radius') === true) ? photo.data.defect_radius : null;

                let program_name = '';
                let program_mass = '';

                program_name = photo && photo.data && photo.data.selected_program && photo.data.selected_program.product && photo.data.selected_program.product.short_name ? photo.data.selected_program.product.short_name : '';
                program_mass = photo && photo.data && photo.data.selected_program && photo.data.selected_program.product && photo.data.selected_program.product.data && photo.data.selected_program.product.data.mass ? photo.data.selected_program.product.data.mass : '';

                if (!program_name) {
                  program_name = photo && photo.data && photo.data.selected_program && photo.data.selected_program.value && photo.data.selected_program.value.product && photo.data.selected_program.value.product.short_name ? photo.data.selected_program.value.product.short_name : '';
                }
                if (!program_mass) {
                  program_mass = photo && photo.data && photo.data.selected_program && photo.data.selected_program.value && photo.data.selected_program.value.product && photo.data.selected_program.value.product.data && photo.data.selected_program.value.product.data.mass ? photo.data.selected_program.value.product.data.mass : '';
                }

                const selected_program_label = `${program_name} ${program_mass}`;

                return (
                  <div className="gallery_card" key={photo.id}>
                    <div className="gallery_image-container">
                      <img src={photo.image.thumbnail} className="gallery_image" alt="gallery" />
                      {photo.is_defect === true && defectCenter !== null && defectRadius !== null &&
                        <span
                          className="gallery_image-marker"
                          ref={(ref) => {
                            this.getImageMarker(ref, defectCenter, defectRadius);
                          }}
                        />}
                    </div>
                    {(company_short_code === 'iverpan' || company_short_code === 'bauwerk' || company_short_code === 'dukat') && !isReadOnly &&
                      <div onClick={() => this.markDefect(photo)} className="toggle">
                        <label className="switch">
                          <input type="checkbox" checked={photo.is_true_defect} />
                          <span className="slider round" />
                        </label>
                      </div>}
                    {!isReadOnly && <div className="icons_gallery_card">
                      <span onClick={() => this.addToFavorite(photo)} style={{ marginRight: '8px' }}><IconFavorite height="18px" width="18px" color={photo.is_favorite ? 'white' : 'none'} /></span>
                      <span onClick={() => this.download(photo)} style={{ marginRight: '8px' }}><IconCloudDownload height="18px" width="18px" /></span>
                      <span onClick={() => this.openModal(photo)} style={{ marginRight: '8px' }}><IconZoomInArrows height="18px" width="18px" /></span>
                      <span onClick={() => this.openInNewTab(photo)}><IconPopup height="18px" width="18px" color="white" /></span>
                    </div>}
                    <p className="gallery_image_name">
                      {moment(photo.created_at).format(defaultDateTimeFormat)}
                      {cameraDefectLabel.length > 0 &&
                        <label className="gallery_image_camera_position">
                          {t('page_content.product_library.camera')}: {cameraDefectLabel}
                        </label>}
                      <br />
                      {selected_program_label || ''}
                    </p>
                  </div>
                );
              })}
            </div>
        }

        <div className="pagination_container">
          <Button
            disabled={!previous}
            onClick={() => {
              this.navigatePrevious();
            }}

          >Previous</Button>
          <Button
            disabled={!next}
            onClick={() => {
              this.navigateNext();
            }}

          >Next</Button>
        </div>

        {
          selectedImage !== null &&
          <div id="myModal" className="modal">
            <div style={{ position: 'absolute' }}>
              <p className="modal_close_button" onClick={this.closeModal}>X</p>
              <img className="modal_content" src={selectedImage.image.full_size} alt="modal" />
              <div className="modal_bottom_icons">
                <span onClick={() => this.addToFavorite(selectedImage)} style={{ marginRight: '8px' }}><IconFavorite height="18px" width="18px" color={selectedImage.is_favorite ? 'white' : 'none'} /></span>
                <span onClick={() => this.download(selectedImage)}><IconCloudDownload height="18px" width="18px" /></span>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

PhotoLibrary.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  locationId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  locationId: get(state, 'app.location.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(PhotoLibrary));
