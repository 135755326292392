import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Notification } from 'shared';
import api from 'helpers/api';
import ProfileMenu from './components/ProfileMenu';
import HelpMenu from './components/HelpMenu';
import CompanySettingsMenu from './components/CompanySettingsMenu';
import Logo from './components/Logo';
import Fullscreen from './components/Fullscreen';
import Notifications from './components/Notifications/index';
import './styles.scss';

const defaultLanguages = [
  { target: { value: 'en', name: 'English' } },
  { target: { value: 'hr', name: 'Croatian' } },
  { target: { value: 'sl', name: 'Slovenian' } },
];

const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    width: '100px',
    minHeight: '34px',
    height: '36px',
    padding: 0,
    fontSize: '13px',
    color: '#555',
    marginRight: '5px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '34px',
    padding: '0px 0px 0px 5px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0px 3px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    paddingRight: 10,
    color: 'black',
    svg: {
      width: '15px',
      height: '15px',
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: 100,
    borderRadius: 0,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '13px',
    fontWeight: 500,
    padding: '6px 12px',
  }),
};

const TopHeader = ({
  company,
  currentUser,
  logout,
  currentLocation,
  selectedLanguage,
  changeLanguage,
  settings,
  customLogo,
  notificationsCenter,
  pushNotifications,
}) => {
  const [isPushNotificationSupported, setIsPushNotificationSupported] = useState(true);
  const [isPushNotificationPermissionGranted, setIsPushNotificationPermissionGranted] = useState(true);
  const [isServiceWorkerRegistered, setIsServiceWorkerRegistered] = useState(false);

  const checkSupport = () => {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      setIsPushNotificationSupported(false);
    }
  };

  const urlBase64ToUint8Array = (base64String) => {
    // eslint-disable-next-line no-mixed-operators
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  const requestPOSTToServer = (data) => {
    return api.post('/api/v1/push/web/', data)
      .then((response) => {
        Notification('success', 'Notifications', 'Setup was successfull');
        setIsServiceWorkerRegistered(true);
        return response.data;
      })
      .catch(() => {
        return Notification('success', 'Notifications', 'Error sending subscription to server');
      });
  };

  const getBrowserName = () => {
    const userAgent = navigator.userAgent;

    if (userAgent.includes('Chrome') && !userAgent.includes('Chromium')) {
      return 'Chrome';
    } if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      return 'Safari';
    } if (userAgent.includes('Firefox')) {
      return 'Firefox';
    } if (userAgent.includes('Edg')) {
      return 'Edge';
    } if (userAgent.includes('Trident') || userAgent.includes('MSIE')) {
      return 'Internet Explorer';
    } if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
      return 'Opera';
    }
    return 'Unknown';
  };

  const registerServiceWorker = async () => {
    const applicationServerKey = 'BFsTRZm-MZXenT_j4n4sZC_u0RcVAjpxgqUqFFE3wVz_2f_l69tZVrITLWqRaDuGE6J7IqzMYlwyNaIxi1sTSDA';

    if ('serviceWorker' in navigator) {
      try {
        // Unregister existing service workers
        const existingRegistrations = await navigator.serviceWorker.getRegistrations();
        for (const registration of existingRegistrations) {
          // eslint-disable-next-line no-await-in-loop
          await registration.unregister();
        }

        // Register the new service worker
        const swRegistration = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/custom-service-worker.js`);
        navigator.serviceWorker.ready.then((reg) => {
          reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
          }).then((sub) => {
            const registration_id = sub.endpoint;
            const data = {
              browser: getBrowserName().toUpperCase(),
              p256dh: btoa(String.fromCharCode.apply(null, new Uint8Array(sub.getKey('p256dh')))),
              auth: btoa(String.fromCharCode.apply(null, new Uint8Array(sub.getKey('auth')))),
              name: 'LOCAL_TEST',
              registration_id,
            };
            requestPOSTToServer(data);
          });
        });
        return swRegistration;
      } catch (error) {
        console.error('Service worker registration failed:', error);
      }
    } else {
      console.warn('Service workers are not supported in this browser.');
    }
  };

  const requestNotificationPermission = async () => {
    const permission = await window.Notification.requestPermission();
    setIsPushNotificationPermissionGranted(permission === 'granted');
  };

  const checkNotificationSetup = async () => {
    const registration = await navigator.serviceWorker.getRegistration();
    setIsServiceWorkerRegistered(!!registration);

    const permission = window.Notification.permission;
    setIsPushNotificationPermissionGranted(permission === 'granted');
  };

  useEffect(() => {
    if ((pushNotifications || notificationsCenter) &&
    currentUser?.is_admin) {
      checkSupport();
      checkNotificationSetup();
    }
  }, [pushNotifications, notificationsCenter]);

  const handleEnableNotifications = async () => {
    const permission = window.Notification.permission;
    if (permission === 'denied') {
      // Inform the user that notifications are blocked
      alert('Notifications are blocked. Please enable them in your browser settings.');
    }
    await requestNotificationPermission();
    await checkNotificationSetup();
    registerServiceWorker();
  };

  return (
    <div className={`top-header${window?.location?.href?.includes('app-dev') ? ' dev' : window?.location?.href?.includes('localhost') ? ' localhost' : ''}`}>
      <div className="top-header-left">
        <div className="logo">
          <Logo height={40} customLogo={customLogo} />
        </div>
        <div className="company-name">
          {company ? company.name : ''}
        </div>
      </div>
      <div className="header-controls">
        <Select
          styles={selectStyles}
          options={defaultLanguages}
          getOptionLabel={(language) => language.target.name}
          getOptionValue={(language) => language.target.value}
          onChange={changeLanguage}
          value={defaultLanguages.find((d) => d.target.value === selectedLanguage)}
        />

        {company && company.id && currentLocation ?
          <CompanySettingsMenu
            companyId={company ? company.id : ''}
            currentLocation={currentLocation}
          /> : ''}

        {(notificationsCenter || pushNotifications) &&
          <Notifications
            companyId={company.id}
            currentUser={currentUser}
            handleEnableNotifications={handleEnableNotifications}
            isPushNotificationSupported={isPushNotificationSupported}
            isPushNotificationPermissionGranted={isPushNotificationPermissionGranted}
            isServiceWorkerRegistered={isServiceWorkerRegistered}
            locationId={currentLocation?.id}
          />}

        <HelpMenu
          theme="theme-light"
        />

        <ProfileMenu
          currentUser={currentUser}
          companyId={company ? company.id : ''}
          logout={logout}
          theme="theme-light"
        />

        {company && company.id && currentLocation && !settings ?
          <div className="fullscreen_toggle">
            <Fullscreen />
          </div> : ''}
      </div>
    </div>
  );
};

TopHeader.propTypes = {
  company: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  currentLocation: PropTypes.object,
  selectedLanguage: PropTypes.string,
  settings: PropTypes.bool.isRequired,
  customLogo: PropTypes.string,
  notificationsCenter: PropTypes.bool.isRequired,
  pushNotifications: PropTypes.bool.isRequired,
};

TopHeader.defaultProps = {
  company: { name: '' },
  currentLocation: { name: '' },
  showNotifications: false,
};

const mapStateToProps = (state) => ({
  company: get(state, 'app.company'),
  currentLocation: get(state, 'app.location'),
  notificationsCenter: get(state, 'app.location.config.notifications_center', false),
  pushNotifications: get(state, 'app.location.config.push_notifications', false),
});

export default connect(mapStateToProps)(TopHeader);
